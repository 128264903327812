/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FuturePriceInsert { 
    /**
     * A reference to the primary key in the Prices Table
     */
    priceId: number;
    /**
     * A reference to the primary key in the SalesStock Table
     */
    salesStockId?: number;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    startDate: Date;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    endDate?: Date;
    price: number;
    isPromotion: boolean;
    promoTypeCode?: string;
    priceLevel?: number;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
}