import { Injectable } from '@angular/core';
import { GlobalsService } from '@services/global.service';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { DefaultSettingsService as SDKDefaultSettingsService } from '@modules/SDKs/sysnetApi/api/defaultSettings.service';
import { DefaultSettingView } from '@modules/SDKs/sysnetApi';
import Constants from '@hal.common.ui/utilities/Constants';
import { ServicesWrappingSysnetSDK } from './ServicesWrappingSysnetSDK';

@Injectable({
    providedIn: 'root'
})
export class DefaultSettingsService extends ServicesWrappingSysnetSDK {
    private orgId: string;
    private orgSettings: DefaultSettingView[] = [];

    constructor(private sdkDefaultSettingsService: SDKDefaultSettingsService, private globals: GlobalsService) {
        super([sdkDefaultSettingsService], globals);
        this.globals.$state.subscribe(async (newState) => {
            if (newState) {
                this.orgId = newState.selectedOrganisation?._id;
                this.orgSettings = [];
                const hasAccess = newState.user?.access?.length ?
                    newState.user.access.some((access) => access?.role?.application?.code === Constants.APPLICATION_CODES.SYSNET_CLOUD &&
                    access.location.organisationId === this.orgId) : false;
                if (this.orgId && hasAccess) {
                    this.orgSettings = await this.getOrganisationSettings(this.orgId);
                }
            }
        });

        if (this.globals.getState()?.selectedOrganisation?._id) {
            this.orgId = this.globals.getState().selectedOrganisation._id;
            this.orgSettings = [];
            this.getOrganisationSettings(this.orgId).then((settings) => {
                this.orgSettings = settings;
            });
        }
    }

    public getOrganisationSettings(organisationId: string): Promise<DefaultSettingView[]> {
        return this.sdkDefaultSettingsService.defaultSettingsGet
            (AppConfiguration.rsh.getStrVal('rid'),
            'OrganisationSettings',
            null, null, null, null, null, 200, 0, null, null, null, null, null, null, null,
            null, null, null, organisationId, null, 'body').toPromise();
    }

    public getVenueSettings(venueId: string): Promise<DefaultSettingView[]> {
        return this.sdkDefaultSettingsService.defaultSettingsGet
            (AppConfiguration.rsh.getStrVal('rid'),
            'VenueSettings',
            null, null, null, null, null, 200, 0, null, null, null, null, null, null, null,
            null, null, null, null, venueId, 'body').toPromise();
    }

    public async getOrganisationSettingValue(name: string, organisationId: string = this.orgId): Promise<string> {
        if (this.orgSettings.length === 0) {
            this.orgSettings = await this.getOrganisationSettings(organisationId);
        }
        const returnValue = this.orgSettings.find((setting) => setting.name === name)?.actualValue;
        return returnValue;
    }

    public getVenueSettingValue(name: string, venueSettings: DefaultSettingView[]): string {
        const returnValue = venueSettings.find((setting) => setting.name === name)?.actualValue;
        return returnValue;
    }

}
