import { GroupQuery, GroupSummaryQuery, GroupSummaryQueryInner } from '@modules/SDKs/sysnetApi';
import { GroupDescriptor, LoadOptions, SummaryDescriptor } from 'devextreme/data';
import * as _ from 'lodash';
import { IQueryFilter as SearchFilter } from 'src/app/types/GeneralTypes';

export interface IParserResult {
    search: SearchFilter | Record<string, unknown>;
    sort: string;
    group: GroupQuery;
    totalSummary: GroupSummaryQuery;
    groupSummary: GroupSummaryQuery;
}

interface IQueryFilter {
    [name: number]: IQueryObject[];
    $not?: IQueryFilter[];
    $and?: IQueryFilter[];
    $or?: IQueryFilter[];
    $distinct?: IQueryFilter[];
}
export interface IQueryObject {
    $gt?: number;
    $lt?: number;
    $eq?: string | number;
    $gte?: number;
    $lte?: number;
    $ne?: string | number;
    $not?: string | number;
    startswith?: string;
    $notILike?: string;
    $iLike?: string;
    endswith?: string;
    contains?: string;
    notcontains?: string;
    $is?: string | number | boolean;
    $in?: number[] | string[];
}
export class LoadOptionsHelper {
    public static loadOptionsParser(loadOptions: LoadOptions): IParserResult {

        // lots of deprecated attributes being used here, in particular the SortDescriptor and GroupDescriptor rely on deprecated attributes: KeySelector<T> | BaseGroupDescriptor
        let sort;
        if (Array.isArray(loadOptions.sort)) {
            loadOptions?.sort?.forEach((s) => {
                if (sort) { sort += ','; } else { sort = ''; }
                sort += ((s as unknown as { desc: boolean; }).desc ? '-' : '+') + ((s as unknown as { selector: string }).selector);
            });
        } else {
            sort = loadOptions.sort;

        }
        let search;
        if (loadOptions.searchExpr && loadOptions.searchValue) {
            if (loadOptions.searchOperation === '=') {
                search = { [loadOptions.searchExpr as string]: { $eq: loadOptions.searchValue } };
            } else if (loadOptions.searchOperation === 'contains') {
                search = { [loadOptions.searchExpr as string]: { $iLike: '*' + (loadOptions.searchValue as string) + '*' } };
            }
            if (loadOptions.filter && loadOptions.filter.$and) {
                const filter: IQueryFilter = LoadOptionsHelper.getSearchFromFilter(loadOptions.filter);
                filter.$and.push(search);
                search = filter;
            }
            if (loadOptions.filter && !loadOptions.filter?.$and) {
                const filter: IQueryFilter = LoadOptionsHelper.getSearchFromFilter(loadOptions.filter);
                const newFilter = { $and: [] };
                newFilter.$and.push(filter);
                newFilter.$and.push(search);
                search = newFilter;
            }
        } else if (loadOptions.filter) {
            search = LoadOptionsHelper.getSearchFromFilter(loadOptions.filter);
        }
        let group: GroupQuery;
        if (loadOptions.group) {
            group = [];
            (loadOptions.group as GroupDescriptor<unknown>[]).forEach((g) => {
                group.push({ fieldName: (g as { selector: string; }).selector, sort: ((g as unknown as { desc: boolean }).desc ? '-' : '+') });
            });
        }
        let totalSummary: GroupSummaryQuery;
        if (loadOptions.totalSummary) {
            totalSummary = [];
            (loadOptions.totalSummary as SummaryDescriptor<unknown>[]).forEach((g) => {
                if ((g as { selector: string; }).selector && (g as { summaryType: string; }).summaryType) {
                    totalSummary.push({ fieldName: (g as { selector: string; }).selector, type: (g as { summaryType: GroupSummaryQueryInner['type'] }).summaryType });
                }
            });
        }
        let groupSummary: GroupSummaryQuery;
        if (loadOptions.groupSummary) {
            groupSummary = [];
            (loadOptions.groupSummary as SummaryDescriptor<unknown>[]).forEach((g) => {
                if ((g as { selector: string; }).selector && (g as { summaryType: string; }).summaryType) {
                    groupSummary.push({ fieldName: (g as { selector: string; }).selector, type: (g as { summaryType: GroupSummaryQueryInner['type']; }).summaryType });
                }
            });
        }
        return { sort, search, group, totalSummary, groupSummary };
    }

    public static processFilterWithNot(filter): IQueryFilter {
        const andWithNot = {} as IQueryFilter;
        let and = {} as IQueryFilter;
        filter.forEach((ele) => {
            if (ele instanceof Array) {
                const data = LoadOptionsHelper.processFilterByDepth(ele);
                if (ele.indexOf('or') > 0) {
                    if (!data.hasOwnProperty('$or')) { if(data instanceof Array) { and.$or = data; } } else { and = data; }
                } else if (ele.indexOf('and') > 0) {
                    if (!data.hasOwnProperty('$and')) { if(data instanceof Array) { and.$and = data; } } else { and = data; }
                } else {
                    and = data;
                }
            }
        });
        const tempAry = [];
        tempAry.push(and);
        andWithNot.$not = tempAry; // put the NOT operator
        return andWithNot;
    }

    public static handleFilterDepthOne(filter): IQueryFilter {
        const matchTypes = {
            '>': '$gt',
            '<': '$lt',
            '=': '$eq',
            '>=': '$gte',
            '<=': '$lte',
            '!=': '$ne',
            '<>': '$not',
            startswith: 'startswith',
            endswith: 'endswith',
            contains: 'contains',
            notcontains: 'notcontains'
        };

        // {key:{matchType:value}}
        const key = filter[0];
        const matchType = matchTypes[filter[1]];
        if (!matchType) { throw new Error('Filter error: missing operator'); }
        const value = filter[2];

        const data = {};
        data[key] = {};
        if (['startswith', 'endswith', 'notcontains', 'contains'].indexOf(matchType) !== -1) {
            switch (matchType) {
                case 'startswith':
                    data[key] = { $iLike: (value.replace(/\*/g, '\\*') as string) + '*' };
                    break;
                case 'endswith':
                    data[key] = { $iLike: '*' + (value.replace(/\*/g, '\\*') as string) };
                    break;
                case 'contains':
                    data[key] = { $iLike: '*' + (value.replace(/\*/g, '\\*') as string) + '*' };
                    break;
                case 'notcontains':
                    data[key] = { $notILike: '*' + (value.replace(/\*/g, '\\*') as string) + '*' };
                    break;
                default:
                    break;
            }
        } else {
            if (value === null && matchType === '$eq') {
                data[key].$is = value;
            } else {
                data[key][matchType] = value;
            }
        }
        return data;
    }

    // NOTE: This is working for header filters except FilterBuilder, therefore keeping the code.
    public static extractVenueIdFilter(loadOptions: LoadOptions): string[] {
        const venueIds: string[] = [];
        if (!loadOptions.filter) { return null; }
        const isMultipleFilter = Array.isArray(loadOptions.filter[0]) ? true : false;
        let isFilteringByVenueId = false;
        if (isMultipleFilter) { // filter by more than one field
            const isFilteredByOtherFields = LoadOptionsHelper.isFilteredByOtherFields(loadOptions);
            if (!isFilteredByOtherFields) { return LoadOptionsHelper.filterOnlyByMultipleVenueIds(loadOptions, venueIds); }
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < loadOptions.filter.length; i++) {
                const element = loadOptions.filter[i];
                const index = loadOptions.filter.indexOf(element);
                const length = loadOptions.filter.length;
                if (!Array.isArray(element)) { continue; }
                const innerElement = element[0];
                if (Array.isArray(innerElement)) {
                    isFilteringByVenueId = LoadOptionsHelper.isFilteredByVenueId(innerElement[0]);
                    if (!isFilteringByVenueId) { continue; }
                    element.forEach((item) => {
                        if (!Array.isArray(item)) { return; }
                        venueIds.push(item[2]);
                    });
                    LoadOptionsHelper.setFilterWithoutVenueIds(loadOptions, index, length);
                    break;
                } else {
                    isFilteringByVenueId = LoadOptionsHelper.isFilteredByVenueId(innerElement);
                    if (!isFilteringByVenueId) { continue; }
                    venueIds.push(element[2]);
                    LoadOptionsHelper.setFilterWithoutVenueIds(loadOptions, index, length);
                    break;
                }
            }
        } else { // filter by only one field
            isFilteringByVenueId = LoadOptionsHelper.isFilteredByVenueId(loadOptions.filter[0]);
            if (!isFilteringByVenueId) { return null; }
            venueIds.push(loadOptions.filter[2]);
            loadOptions.filter = null;
        }
        return venueIds.length > 0 ? venueIds : null;
    }

    public static isFilteredByOtherFields(loadOptions: LoadOptions): boolean {
        return loadOptions.filter.some((element) => (Array.isArray(element) && element[0] !== 'venueId'));
    }

    public static isFilteredByVenueId(element: string): boolean {
        return element === 'venueId' ? true : false;
    }

    public static setFilterWithoutVenueIds(loadOptions: LoadOptions, index: number, length: number): void {
        if (length === 3) {
            loadOptions.filter = (index === (length - 1)) ? [...loadOptions.filter[0]] : [...loadOptions.filter[2]];
        } else {
            (index === (length - 1)) ? loadOptions.filter.splice(index - 1, 2) : loadOptions.filter.splice(index, 2);
        }
    }

    public static filterOnlyByMultipleVenueIds(loadOptions: LoadOptions, venueIds: string[]): string[] {
        loadOptions.filter.forEach((element) => {
            if (!Array.isArray(element)) { return; }
            venueIds.push(element[2]);
        });
        loadOptions.filter = null;
        return venueIds;
    }

    private static getSearchFromFilter(filter): IQueryFilter {
        if (!filter) { return null; }
        let search: IQueryFilter = {};
        const data = LoadOptionsHelper.processFilterByDepth(filter);

        if (filter.indexOf('or') > 0) {
            if (data.hasOwnProperty('$or')) { search = data; } else { if(data instanceof Array) { search.$or = data; } }
        } else if (filter.indexOf('and') > 0) {
            if (data.hasOwnProperty('$and')) { search = data; } else { if(data instanceof Array) { search.$and = data; } }
        } else {
            search = data;
        }

        return search;
    }

    private static getFilterDepth(filter): number {
        if (!(filter instanceof Array)) {
            return 0;
        }
        const depth = [];
        filter.forEach((ele) => {
            depth.push(LoadOptionsHelper.getFilterDepth(ele) + 1);
        });
        return _.max(depth, (num) => num);
    }

    private static processFilterByDepth(filter): IQueryFilter {

        if (LoadOptionsHelper.getFilterDepth(filter) === 1) {
            return LoadOptionsHelper.handleFilterDepthOne(filter);
        }

        if (LoadOptionsHelper.getFilterDepth(filter) === 2) {
            if (filter.indexOf('or') > 0) {
                const or = [];
                filter.forEach((ele) => {
                    if (ele instanceof Array) {
                        or.push(LoadOptionsHelper.processFilterByDepth(ele));
                    }
                });
                return or;
            } else if (filter.indexOf('and') > 0) {
                const and = [];
                filter.forEach((ele) => {
                    if (ele instanceof Array) {
                        and.push(LoadOptionsHelper.processFilterByDepth(ele));
                    }
                });
                return and;
            } else if (filter[0] === '!') {
                return LoadOptionsHelper.processFilterWithNot(filter);
            }
        }

        if (LoadOptionsHelper.getFilterDepth(filter) === 3) {
            if (filter.indexOf('or') > 0) {
                let or = [];
                filter.forEach((ele) => {
                    if (ele instanceof Array) {
                        const data = LoadOptionsHelper.processFilterByDepth(ele);
                        const temp = {} as IQueryFilter;
                        if (data instanceof Array) {
                            if (ele.indexOf('and') > 0) {
                                temp.$and = data;
                                or.push(temp);
                            } else if (ele.indexOf('or') > 0) {
                                temp.$or = data;
                                or.push(temp);
                            } else {
                                or = or.concat(data);
                            }
                        } else if (data instanceof Object) {
                            or.push(data);
                        } else {
                            throw new Error('filter parse error');
                        }
                    }
                });
                return or;
            } else if (filter.indexOf('and') > 0) {
                let and = [];
                filter.forEach((ele) => {
                    if (ele instanceof Array) {
                        const data = LoadOptionsHelper.processFilterByDepth(ele);
                        const temp = {} as IQueryFilter;
                        if (data instanceof Array) {
                            if (ele.indexOf('and') > 0) {
                                temp.$and = data;
                                and.push(temp);
                            } else if (ele.indexOf('or') > 0) {
                                temp.$or = data;
                                and.push(temp);
                            } else {
                                and = and.concat(data);
                            }
                        } else if (data instanceof Object) {
                            and.push(data);
                        } else {
                            throw new Error('filter parse error');
                        }
                    }
                });
                return and;
            } else if (filter[0] === '!') {
                return LoadOptionsHelper.processFilterWithNot(filter);
            } else {
                throw new Error('filter parse error');
            }
        }

        if (LoadOptionsHelper.getFilterDepth(filter) >= 4) {
            if (filter.indexOf('and') > 0) {
                let search = {} as IQueryFilter;
                filter.forEach((ele) => {
                    if (LoadOptionsHelper.getFilterDepth(ele) >= 4) {
                        const subSearch = LoadOptionsHelper.processFilterByDepth(ele);
                        if (!search.$and) { search = { $and: [] }; }
                        search.$and.push(subSearch);
                    } else if (ele instanceof Array) {
                        let _search = {} as IQueryFilter;
                        const data = LoadOptionsHelper.processFilterByDepth(ele);
                        if (data instanceof Array) {
                            _search.$or = data;
                        } else if (data instanceof Object) {
                            _search = data;
                        } else {
                            throw new Error('filter parse error');
                        }
                        if (!search.$and) { search = { $and: [] }; }
                        search.$and.push(_search);
                    }
                });
                return search;
            } else if (filter.indexOf('or') > 0) {
                let search = {} as IQueryFilter;
                filter.forEach((ele) => {
                    if (LoadOptionsHelper.getFilterDepth(ele) >= 4) {
                        const subSearch = LoadOptionsHelper.processFilterByDepth(ele);
                        if (!search.$or) { search = { $or: [] }; }
                        search.$or.push(subSearch);
                    } else if (ele instanceof Array) {
                        let _search = {} as IQueryFilter;
                        const data = LoadOptionsHelper.processFilterByDepth(ele);
                        if (data instanceof Array) {
                            _search.$or = data;
                        } else if (data instanceof Object) {
                            _search = data;
                        } else {
                            throw new Error('filter parse error');
                        }
                        if (!search.$or) { search = { $or: [] }; }
                        search.$or.push(_search);
                    }
                });
                return search;
            } else if (filter[0] === '!') {
                return LoadOptionsHelper.processFilterWithNot(filter);
            } else {
                throw new Error('filter parse error');
            }
        }
    }
}
