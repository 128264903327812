/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProductInsert { 
    plu?: number;
    /**
     * A reference to the primary key in the Factors Table
     */
    factorId?: number;
    fullName?: string;
    shortName?: string;
    /**
     * A reference to the primary key in the Containers Table
     */
    containerId?: number;
    /**
     * A reference to the primary key in the PluGroups Table
     */
    pluGroupId: number;
    /**
     * A reference to the primary key in the RevenueGroups Table
     */
    revenueGroupId?: number;
    averageCost?: number;
    lastCost?: number;
    allowDecimalQtys?: boolean;
    externalReferenceCode?: string;
    /**
     * A reference to the primary key in the PluGroups Table
     */
    linkedPluGroupId?: number;
    /**
     * A reference to the primary key in the TaxRates Table
     */
    taxRateId: number;
    active?: boolean;
    flaggedForDeletion?: boolean;
    includeInExport?: boolean;
    openPriceItem?: boolean;
    /**
     * A reference to the primary key in the PrintGroups Table
     */
    printGroupId?: number;
    ticketExpiryPeriodType?: ProductInsert.TicketExpiryPeriodTypeEnum;
    ticketExpiryDuration?: number;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    ticketValidDateTime?: Date;
    modifier?: ProductInsert.ModifierEnum;
    displayImageUrl?: string;
    labelImageUrl?: string;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
    /**
     * This determines whether discounts can be applied to this package.
     */
    allowPackageDiscounts?: boolean;
    /**
     * Allows price reductions if a package item is substituted and its price is less.
     */
    allowPackagePriceReductions?: boolean;
    /**
     * Allows price increase if a package item is substituted and its price is more.
     */
    allowPackagePriceIncrease?: boolean;
    /**
     * When calculating reports determines how revenue is allocated to products.
     */
    packageRevenueAttributionMethod?: ProductInsert.PackageRevenueAttributionMethodEnum;
    /**
     * A reference to the primary key in the Rangers Table
     */
    rangeId?: number;
}
export namespace ProductInsert {
    export type TicketExpiryPeriodTypeEnum = 'Minutes' | 'Hours' | 'Days' | 'Weeks' | 'Months' | 'Years';
    export const TicketExpiryPeriodTypeEnum = {
        Minutes: 'Minutes' as TicketExpiryPeriodTypeEnum,
        Hours: 'Hours' as TicketExpiryPeriodTypeEnum,
        Days: 'Days' as TicketExpiryPeriodTypeEnum,
        Weeks: 'Weeks' as TicketExpiryPeriodTypeEnum,
        Months: 'Months' as TicketExpiryPeriodTypeEnum,
        Years: 'Years' as TicketExpiryPeriodTypeEnum
    };
    export type ModifierEnum = 'No modifier' | 'Normal modifier' | 'Group modifier' | 'Position modifier' | 'Printer change modifier' | 'Order Number modifier' | 'Reason Code modifier' | 'Member Fees' | 'Ticket modifier' | 'Package' | 'Timer' | 'Non-kitchen printed';
    export const ModifierEnum = {
        NoModifier: 'No modifier' as ModifierEnum,
        NormalModifier: 'Normal modifier' as ModifierEnum,
        GroupModifier: 'Group modifier' as ModifierEnum,
        PositionModifier: 'Position modifier' as ModifierEnum,
        PrinterChangeModifier: 'Printer change modifier' as ModifierEnum,
        OrderNumberModifier: 'Order Number modifier' as ModifierEnum,
        ReasonCodeModifier: 'Reason Code modifier' as ModifierEnum,
        MemberFees: 'Member Fees' as ModifierEnum,
        TicketModifier: 'Ticket modifier' as ModifierEnum,
        Package: 'Package' as ModifierEnum,
        Timer: 'Timer' as ModifierEnum,
        NonKitchenPrinted: 'Non-kitchen printed' as ModifierEnum
    };
    export type PackageRevenueAttributionMethodEnum = 'None' | 'Cost Percentage' | 'Retail Percentage' | 'Manual Percentage' | 'null';
    export const PackageRevenueAttributionMethodEnum = {
        None: 'None' as PackageRevenueAttributionMethodEnum,
        CostPercentage: 'Cost Percentage' as PackageRevenueAttributionMethodEnum,
        RetailPercentage: 'Retail Percentage' as PackageRevenueAttributionMethodEnum,
        ManualPercentage: 'Manual Percentage' as PackageRevenueAttributionMethodEnum,
        Null: 'null' as PackageRevenueAttributionMethodEnum
    };
}