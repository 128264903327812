import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GlobalsService } from '@services/global.service';
import { UtilityService } from '@services/utility.service';
import { Properties as dxPopupOptions } from 'devextreme/ui/popup';
import { BarcodeScanner, EnumBarcodeFormat, EnumLocalizationMode } from 'dynamsoft-javascript-barcode';
import { Region } from 'dynamsoft-javascript-barcode/dist/types/interface/region';
import { VideoDeviceInfo } from 'dynamsoft-javascript-barcode/dist/types/interface/videodeviceinfo';
import { ErrorMessages } from 'src/app/utilities/ErrorMessages';
import { Properties as dxLookupOptions, ValueChangedEvent } from 'devextreme/ui/lookup';
import { DxLookupComponent } from 'devextreme-angular';
import { DxConfig, ILookupDataSourceConfig, IValidationRules, ValidationFn } from 'src/app/Constants';
import { FactorsDataGridService } from '@services/grid/factors-data-grid.service';
import { StockDataGridService } from '@services/grid/stock-data-grid.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions, Store } from 'devextreme/data';
import { BarcodeInsert, BarcodeView, ContainerView, FactorView, StockView } from '@modules/SDKs/sysnetApi';
import { CustomDialogOptions } from 'devextreme/ui/dialog';
import { BarcodesService } from '@services/sysnet/barcodes.service';
import { ClickEvent } from 'devextreme/ui/button';
import { ProductsDataGridService } from '@services/grid/products-data-grid.service';
import { ILookupDataSourceSetupReturnObj } from 'src/app/types/interfaces/GeneralService';

interface IFactorViewWithContainer extends FactorView {
  Container: ContainerView;
}

enum ScanItemType {
  Stock = 'Stock',
  Product = 'Product'
}
@Component({
  selector: 'app-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss']
})
export class BarcodeScannerComponent implements OnInit, OnDestroy {
  public pScanner: BarcodeScanner = null;
  public checkBarcodeNotFound = true;
  public barcodeInputValidationStatus = 'valid';
  public stockInputValidationStatus = 'valid';
  public factorInputValidationStatus = 'valid';
  public productInputValidationStatus = 'valid';
  public inputValidationErrors = [{ message: 'Required' }];
  public scanPopupConfig: dxPopupOptions = {
    title: 'Scan Barcode',
    visible: false
  };
  public dxLookupConfig: dxLookupOptions = DxConfig.lookup;
  public lookupConfig = {
    factorId: {
      dataSourceLoadMode: 'processed',
      dataSourceService: this.factorsDataGridService,
      dataSourceServiceSearchFnView: 'FactorsList',
      dataSourceServiceSearchFnRouteId: undefined,
      dataSourceServiceSearchFn: 'searchFactorsByStockId',
      dataSourceServiceGetFn: 'getFactor',
      primaryKey: 'factorId',
      dataSortBy: 'factorId'
    },
    stock: {
      dataSourceLoadMode: 'processed',
      dataSourceService: this.stockDataGridService,
      dataSourceServiceSearchFn: 'searchStockByMultipleSearchExpr',
      primaryKey: 'stockId',
      dataSortBy: 'inventoryCode',
      dataSourceServiceGetFn: 'getStock'
    },
    product: {
      dataSourceLoadMode: 'processed',
      dataSourceService: this.productsDataGridService,
      dataSourceServiceSearchFn: 'searchProducts',
      dataSourceServiceGetFn: 'getProduct',
      primaryKey: 'productId',
      dataSortBy: 'fullName'
    }
  };
  public assignBarcodePopupConfig: dxPopupOptions = {
    title: 'Assign Barcode',
    maxHeight: 400,
    visible: false,
    onHidden: (e) => {
      this.barcodeInputValidationStatus = 'valid';
      if (this.itemType === ScanItemType.Stock) {
        this.assignBarcodeFactorsLookup.instance.reset();
        this.assignBarcodeStockLookup.instance.reset();
        this.assignBarcodeFactorsLookup.isValid = true;
        this.assignBarcodeStockLookup.isValid = true;
        this.stockInputValidationStatus = 'valid';
        this.factorInputValidationStatus = 'valid';
      }
      if (this.itemType === ScanItemType.Product) {
        this.assignBarcodeProductLookup.instance.reset();
        this.assignBarcodeProductLookup.isValid = true;
        this.productInputValidationStatus = 'valid';
      }
    }
  };
  public validationRules: IValidationRules = {};
  public factorsDataSource;
  public stockDataSource;
  public productDataSource;
  public assignBarcodeForm: { productId?: number, stockId?: number, factorId?: number } = {}
  public assignBarcodeLoadPanelVisible = false;
  public barcodeInput: string;
  public assignBarcodeFormFactors = [];
  public loadPanelVisible = false;
  @Input() public showBarcode = false;
  @Input() public scanOnlyBarcode = false;
  @Input() public scanningFrom;
  @Input() public itemType = ScanItemType.Stock;
  @Output() public readonly scanSuccess = new EventEmitter();
  @Output() public readonly scannerClose = new EventEmitter();
  @ViewChild('assignBarcodeFactorsLookup', { static: false }) public assignBarcodeFactorsLookup: DxLookupComponent;
  @ViewChild('assignBarcodeStockLookup', { static: false }) public assignBarcodeStockLookup: DxLookupComponent;
  @ViewChild('assignBarcodeProductLookup', { static: false }) public assignBarcodeProductLookup: DxLookupComponent;
  constructor(
    private elementRef: ElementRef,
    private utils: UtilityService,
    public globals: GlobalsService,
    private stockDataGridService: StockDataGridService,
    private factorsDataGridService: FactorsDataGridService,
    private barcodesService: BarcodesService,
    private productsDataGridService: ProductsDataGridService
  ) { }

  public ngOnInit(): void {
    this.validationRules = {
      factorId: [ValidationFn.getRequiredRule()],
      stockId: [ValidationFn.getRequiredRule()],
      barcode: [ValidationFn.getRequiredRule()],
      productId: [ValidationFn.getRequiredRule()]
    };
    this.factorsDataSource = this.setupLookupDataSource(this.lookupConfig.factorId);
    this.stockDataSource = this.setupLookupDataSource(this.lookupConfig.stock);
    this.productDataSource = this.setupLookupDataSource(this.lookupConfig.product);
    this.assignBarcodePopupConfig.maxHeight = this.itemType === ScanItemType.Stock ? 400 : 300;

    if (this.scanningFrom === 'BarcodesList') { this.checkBarcodeNotFound = false; }
  }

  public ngOnDestroy(): void {
    const canvasArr = Array.from(document.querySelectorAll('.dce-video-container canvas'));
    canvasArr.forEach((canvas) => { this.releaseCanvas(canvas as HTMLCanvasElement); });
    if (this.pScanner) {
      (this.pScanner).destroyContext();
    }
    this.checkBarcodeNotFound = true;
  }

  public setupLookupDataSource(lookupConfig): ILookupDataSourceSetupReturnObj {
    return {
      store: new CustomStore({
        key: lookupConfig.primaryKey,
        loadMode: lookupConfig.dataSourceLoadMode,
        load: async (loadOptions: LoadOptions) => {
          try {
            const view = lookupConfig.dataSourceServiceSearchFnView;
            const routeId = lookupConfig.dataSourceServiceSearchFnRouteId;
            return await lookupConfig.dataSourceService[lookupConfig.dataSourceServiceSearchFn](loadOptions, view, routeId);
          } catch (error) { /* empty */ }
        },
        byKey: async (key) => {
          try {
            if (!key) { return null; }
            return await lookupConfig.dataSourceService[lookupConfig.dataSourceServiceGetFn](key);
          } catch (error) { /* empty */ }
        }
      }),
      sort: lookupConfig.dataSortBy,
      pageSize: 10,
      paginate: true
    };
  }

  public async setScanSettings(scanner: BarcodeScanner): Promise<void> {
    const scanSettings = await scanner.getScanSettings();
    scanSettings.whenToPlaySoundforSuccessfulRead = 'unique';
    scanSettings.whenToVibrateforSuccessfulRead = 'unique';
    await scanner.updateScanSettings(scanSettings);
    await scanner.setResolution(1920, 1080); // Full HD
    await scanner.updateRuntimeSettings('speed');

    const runtimeSettings = await scanner.getRuntimeSettings();
    runtimeSettings.barcodeFormatIds = EnumBarcodeFormat.BF_ALL; // All common barcode types
    runtimeSettings.expectedBarcodesCount = 1; // Single barcode scan
    runtimeSettings.localizationModes = [EnumLocalizationMode.LM_SCAN_DIRECTLY, EnumLocalizationMode.LM_CONNECTED_BLOCKS];
    runtimeSettings.deblurLevel = 0; // ~ Deblur Modes - [DM_BASED_ON_LOC_BIN , DM_THRESHOLD_BINARIZATION]
    const region: Region = {
      regionLeft: 20,
      regionRight: 80,
      regionTop: 25,
      regionBottom: 75,
      regionMeasuredByPercentage: 1
    };
    runtimeSettings.region = region;
    await scanner.updateRuntimeSettings(runtimeSettings);
  }
  // fix ios canvas memory usage limit
  public releaseCanvas(canvas: HTMLCanvasElement): void {
    canvas.width = 1;
    canvas.height = 1;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, 1, 1);
    }
  }

  public async scanSuccessHandler(barcode: string): Promise<void> {
    this.scanPopupConfig.visible = false;
    this.loadPanelVisible = true;
    if (this.scanOnlyBarcode) {
      this.scanSuccess.emit(barcode);
      return;
    }
    const res = await this.getBarcode(barcode);
    if (res === undefined && this.checkBarcodeNotFound) {
      this.barcodeInput = barcode;
      const customDialogConfigs: CustomDialogOptions = {
        showTitle: false,
        messageHtml: `<br><div class="barcode-prompt-heading">Barcode not found</div><br><div class="barcode-prompt-barcode">${barcode}</div> <br>
        <div style="margin: 0px 20px;"><center>Assign this barcode to existing ${this.itemType === ScanItemType.Stock ? 'stock item' : 'product' }?</center></div>`,
        buttons: [
          { text: 'Yes', onClick: () => true, stylingMode: 'contained', type: 'default', elementAttr: { class: 'pop-up' } },
          { text: 'No', onClick: () => false, stylingMode: 'outlined', type: 'default', elementAttr: { class: 'pop-up' } }
        ]
      };
      this.scannerClosing();
      this.utils.openDxCustomDialog(customDialogConfigs).then((dialogResult) => {
        if (dialogResult) {
          this.assignBarcodePopupConfig.visible = true;
        }
      });
      this.loadPanelVisible = false;
      return;
    }
    this.loadPanelVisible = false;
    this.scanSuccess.emit(barcode);
  }

  public scannerClosing(): void {
    this.scannerClose.emit();
 }

  public async getBarcode(barcode: string): Promise<BarcodeView> {
    try {
      const search = { barcode: { $eq: barcode } };
      const barcodesRes = await this.barcodesService.searchBarcodes(null, null, null, null, 1, null, 'BarcodesWithStockDetails', search);
      return barcodesRes.body[0] ?? undefined;
    } catch (error) {
      const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} retrieving barcode. ` + instruction, 'OK', 10000);
      return undefined;
    }
  }
  public async assignBarcode(): Promise<void> {
    if (!this.barcodeInput) {
      this.barcodeInputValidationStatus = 'invalid';
      return null;
    }
    if (!this.assignBarcodeForm.stockId && this.itemType === ScanItemType.Stock) {
      this.stockInputValidationStatus = 'invalid';
      return null;
    }
    if (!this.assignBarcodeForm.factorId && this.itemType === ScanItemType.Stock) {
      this.factorInputValidationStatus = 'invalid';
      return null;
    }
    if (!this.assignBarcodeForm.productId && this.itemType === ScanItemType.Product) {
      this.productInputValidationStatus = 'invalid';
      return null;
    }
    try {
      this.assignBarcodeLoadPanelVisible = true;
      const obj = { barcode: this.barcodeInput } as BarcodeInsert;
      if (this.itemType === ScanItemType.Stock) {
        obj.factorId = this.assignBarcodeForm.factorId;
      }
      if (this.itemType === ScanItemType.Product) {
        obj.productId = this.assignBarcodeForm.productId;
      }
      const res = await this.barcodesService.insertBarcode(obj);
      this.assignBarcodeLoadPanelVisible = false;
      this.assignBarcodePopupConfig.visible = false;
      this.scanSuccess.emit(res.body.barcode);
    } catch (error) {
      this.assignBarcodeLoadPanelVisible = false;
      const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} assigning barcode. ` + instruction, 'OK', 10000);
    }
  }
  public cancelAssignBarcode(): void {
    this.assignBarcodePopupConfig.visible = false;
  }
  public displayExprStock(item: StockView): string { return item && item.inventoryCode + ': ' + item.name; }
  public async onStockSelected(event: ValueChangedEvent): Promise<void> {
    if (event.value) {
      this.assignBarcodeFactorsLookup.instance.reset();
      const res = await this.factorsDataGridService.searchFactorsByStockId({ take: 200 }, 'FactorsList', event.value);
      this.assignBarcodeFormFactors = res.data;
    }
  }
  public displayExprFactors(item: IFactorViewWithContainer): string {
    return item && item.Container?.description;
  }
  public showAssignBarcode(barcode: string): void {
    this.barcodeInput = barcode;
    this.assignBarcodePopupConfig.visible = true;
  }

}
