/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SupplierInventoryCodeBulkInsertInner { 
    /**
     * A reference to the primary key in the Suppliers Table
     */
    supplierId?: number;
    supplierInventoryCode?: string;
    /**
     * A reference to the primary key in the Stock Table
     */
    stockId?: number;
    supplierDescription?: string;
    defaultCode?: boolean;
    expectedCost?: number;
    /**
     * A reference to the primary key in the Factors Table
     */
    factorId?: number;
    minOrderQty?: number;
    orderMultiple?: number;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
}