    <div class="count-stocktake-container">
        <div class="count-stocktake-wrapper">
            <div class="sub-header" *ngIf="!isStockMobile">
                <div i18n="Stocktake Entry|@@Stocktake_Entry" class="sub-header-item">Stocktake Entry</div>
                <div class="sub-header-item close-btn"><i class="dx-icon dx-icon-close" (click)="goBackToList()"></i></div>
            </div>
            <div class="venue-location">
                <div class="venue">{{stocktake.Location.Venue.venueName}} -</div>&nbsp;
                <div class="location">{{stocktake.Location.name}}</div>
            </div>
            <dx-tab-panel #countStocktakeTabPanel [items]="countStocktakeTabs" [swipeEnabled]="false"
                [animationEnabled]="true" [showNavButtons]="true" [selectedIndex]="0"
                [elementAttr]="{ class: 'count-stocktake-tab-panel' }" (onTitleClick)="onTitleClick($event)">
                <!-- Stocktake Count Tab -->
                <div *dxTemplate="let content of 'stocktakeCountTempl'" class="stocktake-count-container">
                    <div class="count-stocktake-form">
                        <div class="scan-search-wrapper">
                            <dx-text-box #scanBarcodeInput placeholder="Scan Barcode"
                                [(value)]="barcodeInput" (onEnterKey)="$searchTerm.next($event.event.target.value)" width="85%"
                                [inputAttr]="{ inputmode: 'numeric', pattern: '[0-9]*', enterkeyhint: 'enter' }" [validationStatus]="barcodeInputValidationStatus" [validationErrors]="barcodeInputValidationErrors"
                                (onInput)="barcodeInputValidationStatus = 'valid'">
                            </dx-text-box>
                            <dx-button icon="photo" (onClick)="openScanPopup()" [disabled]="false" height="50px" width="50px">
                            </dx-button>
                            <dx-button icon="find" (onClick)="openStocktakeItemPopup()" [disabled]="!enableItemSearchBtn" height="50px" width="50px">
                            </dx-button>
                        </div>
                        <div class="barcode-inv-wrapper">
                            <div class="info">
                                <span i18n="Barcode ID|@@Barcode_ID" class="label">Barcode ID</span>
                                <div class="info-item"><div><img src="../../../assets/img/icons/label-icons/barcode-scan.png"/></div><div class="value">{{barcode ? barcode.barcode : '-'}}</div></div>
                            </div>
                            <div class="info">
                                <span i18n="Inventory Code|@@Inventory_Code" class="label">Inventory Code</span>
                                <div class="info-item"><div><img src="../../../assets/img/icons/label-icons/inventory-code.png"/></div><div class="value">{{stock ? stock.inventoryCode : '-'}}</div></div>
                            </div>
                        </div>
                        <dx-form #countStocktakeForm validationGroup="countStocktakeGroup">
                            <dxi-item [template]="'stockDescriptionTemplate'" cssClass="highlighted-info"></dxi-item>
                            <dxi-item [template]="'factorTemplate'" [label]="{text: 'Factor', showColon: false}"
                                cssClass="highlighted-info factor-dropdown" [validationRules]="validationRules.factorId">
                            </dxi-item>
                            <dxi-item [template]="'noOfInnersTemplate'"></dxi-item>
                            <dxi-item [template]="'enterFactorsCountedTemplate'"
                                [label]="{text: 'Factors Counted', location: left}"
                                [validationRules]="validationRules.qtyStockEntered" cssClass="enter-factors-counted">
                            </dxi-item>
                            <dxi-item [template]="'addToStockCountBtnTemplate'"></dxi-item>
                            <dxi-item [template]="'multipleItemsCountTemplate'" *ngIf="stocktakeItem?.stocktakeItemsId">
                            </dxi-item>
        
                            <div *dxTemplate="let data of 'stockDescriptionTemplate'">
                                <div class="center item-info">{{stock ? stock.name : '-'}}</div>
                            </div>
                            <div *dxTemplate="let data of 'factorTemplate'">
                                <dx-lookup #factorsLookup [items]="availableFactors" [displayExpr]="displayExprFactors"
                                    valueExpr="factorId" placeholder="Select" searchPlaceholder="Search Description"
                                    [showClearButton]="false" [dropDownOptions]="dxLookupConfig.dropDownOptions"
                                    [disabled]="!isStocktakeItemLoaded" (onValueChanged)="onFactorSelected($event)"
                                    [(value)]="factorId" searchExpr="Container.description">
                                </dx-lookup>
                            </div>
                            <div *dxTemplate="let data of 'noOfInnersTemplate'">
                                <div class="inners-counted-wrapper">
                                    <div class="inner-info separator">
                                        <span i18n="No Inners|@@No_Inners" class="label-sm">No. of Inners</span>
                                        <span class="quantity">{{stocktakeItem && stocktakeItem.qtyStockEntered !== undefined ?
                                            stocktakeItem.qtyStockEntered : '-'}}</span>
                                        <span i18n="No Inners Counted|@@No_Inners_Counted" class="quantity-type">Counted</span>
                                    </div>
                                    <div class="inner-info">
                                        <span i18n="No Inners|@@No_Inners" class="label-sm">No. of Inners</span>
                                        <span class="quantity">{{!showQtyAtStocktake ? '' : (stocktakeItem && stocktakeItem.qtyAtStocktake !== undefined) ?
                                            stocktakeItem.qtyAtStocktake : '-'}}</span>
                                        <span i18n="No Inners At Stocktake|@@No_Inners_At_Stocktake" class="quantity-type">At
                                            Stocktake</span>
                                    </div>
                                </div>
                            </div>
                            <div *dxTemplate="let data of 'enterFactorsCountedTemplate'">
                                <dx-text-box #enterCount placeholder="0"
                                    [(value)]="countedFactors" (onEnterKey)="addToStockCount($event)" width="60%"
                                    [inputAttr]="{ inputmode: 'decimal', enterkeyhint: 'enter' }"
                                    valueChangeEvent="keyup">
                                </dx-text-box>
                            </div>
                            <div *dxTemplate="let data of 'addToStockCountBtnTemplate'">
                                <dx-button text="Add To Stock Count" type="default" (onClick)="addToStockCount($event)"
                                    [disabled]="!editing.allowUpdating || !isStocktakeItemLoaded || countedFactors === null || !!!factorId"
                                    validationGroup="countStocktakeGroup" width="100%">
                                </dx-button>
                            </div>
                            <div *dxTemplate="let content of 'multipleItemsCountTemplate'" id="entryCountAccordion">
                                <dx-accordion #entryCountAccordion [items]="detailGroups" [deferRendering]="false"
                                    [collapsible]="true" [animationDuration]="300" class="accordian-content"
                                    [selectedIndex]="-1">
                                    <div *dxTemplate="let group of 'title'; let index = index">
                                        <div>
                                            <span class="group-status" i18n="Counts Entered|@@Counts_Entered">{{countsEntered}}
                                                Counts Entered</span>
                                        </div>
                                    </div>
                                    <div *dxTemplate="let content of 'stocktakeEntryDetailsTempl'" class="accordian-body">
                                        <app-stocktake-entry-count-list [selectedStocktakeId]="stocktakeItem?.stocktakeItemsId"
                                            (totalStockEntryCount)="updateTotalStockEntryCount($event)"
                                            #stocktakeEntryCountList></app-stocktake-entry-count-list>
                                    </div>
                                </dx-accordion>
                            </div>
                        </dx-form>
                    </div>
                </div>
                <div *dxTemplate="let content of 'stocktakeListTempl'">
                    <app-stocktake-items-list-tabbed [isStockMobile]="isStockMobile" [selectedTab]="selectedTab" [showQtyAtStocktake]="showQtyAtStocktake"></app-stocktake-items-list-tabbed>
                </div>
            </dx-tab-panel>
        </div>
    </div>
<dx-load-panel #loadPanel [(visible)]="loadPanelVisible" message="Loading ..." [showIndicator]="true" [showPane]="true"
    [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
<app-stocktake-items-list (stocktakeItemSelected)="onStocktakeItemSelected($event)" #stocktakeItemsList>
</app-stocktake-items-list>

<app-barcode-scanner #barcodeScanner 
    [showBarcode]="showBarcodeScanner" 
    (scanSuccess)="scanSuccessHandler($event)" 
    (scannerClose)="scannerCloseHandler()">
</app-barcode-scanner>

<app-multi-item-barcode-selection-list (barcodeItemSelected)="onBarcodeItemSelected($event)"
    #multiItemBarcodeSelectionList></app-multi-item-barcode-selection-list>