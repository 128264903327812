import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { UtilityService } from '@services/utility.service';
import { DxTextBoxComponent } from 'devextreme-angular';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { IAddress } from 'src/app/types/interfaces/GeneralService';
@Component({
  selector: 'app-autocomplete-places',
  templateUrl: './autocomplete-places.component.html',
  styleUrls: ['./autocomplete-places.component.scss']
})
export class AutocompletePlacesComponent implements OnInit, AfterViewInit {

  @Output() public readonly setAddress: EventEmitter<IPlaceDetails> = new EventEmitter();
  @ViewChild('addresstext', { static: false }) public addresstext: DxTextBoxComponent;

  constructor(public utils: UtilityService) { }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
      this.getPlaceAutocomplete();
  }

  public invokeEvent(place: google.maps.places.PlaceResult): void {
    if (!place.geometry) {
      this.utils.openSnackbar(`No result found for ${place.name}`, 'OK', 10000);
      return;
    }
    const address: IAddress = {};
    place.address_components.forEach((details) => {
      address[details.types[0]] = details.short_name;
    });
    let completeAddress = '';
    if (address?.street_number) {
      completeAddress += `${address?.street_number} `;
    }
    if (address?.route) {
      completeAddress += `${address?.route}`;
    }
    const addressDetails: IPlaceDetails = {
      address: completeAddress,
      suburb: address?.locality,
      state: address?.administrative_area_level_1,
      postalCode: parseInt(address?.postal_code ?? '0', 10)
    };
    this.setAddress.emit(addressDetails);
  }

  private getPlaceAutocomplete(): void {
    const loader = new Loader({
      apiKey: AppConfiguration.GetConfiguration('GOOGLE_PLACES_API_KEY'),
      version: 'weekly',
      libraries: ['places']
    });
    const input = this.addresstext.instance.element().getElementsByTagName('input')[0];
    input.placeholder = 'Search from here';
    loader.load().then((google) => {
      const autocomplete = new google.maps.places.Autocomplete(input,
        {
          types: ['address'],
          fields: ['address_components', 'geometry'],
          componentRestrictions: { country: 'AU' }
        }
      );
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
          const place = autocomplete.getPlace();
          this.invokeEvent(place);
      });
    });
  }
}

export interface IPlaceDetails {
  address: string;
  suburb: string;
  state: string;
  postalCode: number;
}
