/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { StockDiscrepancyDetailListViewDiscrepancy } from './stockDiscrepancyDetailListViewDiscrepancy';
import { StockDiscrepancyDetailListViewFactorCalculations } from './stockDiscrepancyDetailListViewFactorCalculations';
import { StockDiscrepancyDetailListViewStock } from './stockDiscrepancyDetailListViewStock';
import { UpdateDateTime } from './updateDateTime';

export interface StockDiscrepancyDetailListView { 
    /**
     * The unique identifier of this record.
     */
    discrepancyDetailId?: number;
    /**
     * A reference to the primary key in the StockDiscrepancyHeaders Table
     */
    discrepancyId?: number;
    /**
     * A reference to the primary key in the Stock Table
     */
    stockId?: number;
    qtyInStock?: number;
    adjustmentQty?: number;
    adjustmentValue?: number;
    reason?: string;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    unitType?: StockDiscrepancyDetailListView.UnitTypeEnum;
    /**
     * A reference to the primary key in the Factors Table
     */
    factorId?: number;
    unitAdjustmentQty?: number;
    verified?: StockDiscrepancyDetailListView.VerifiedEnum;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    verifyDate?: Date;
    factorCalculations?: StockDiscrepancyDetailListViewFactorCalculations;
    stock?: StockDiscrepancyDetailListViewStock;
    discrepancy?: StockDiscrepancyDetailListViewDiscrepancy;
}
export namespace StockDiscrepancyDetailListView {
    export type UnitTypeEnum = 'Factor' | 'Inner' | 'Outer' | 'null';
    export const UnitTypeEnum = {
        Factor: 'Factor' as UnitTypeEnum,
        Inner: 'Inner' as UnitTypeEnum,
        Outer: 'Outer' as UnitTypeEnum,
        Null: 'null' as UnitTypeEnum
    };
    export type VerifiedEnum = 'not verified' | 'verified' | 'failed' | 'not required';
    export const VerifiedEnum = {
        NotVerified: 'not verified' as VerifiedEnum,
        Verified: 'verified' as VerifiedEnum,
        Failed: 'failed' as VerifiedEnum,
        NotRequired: 'not required' as VerifiedEnum
    };
}