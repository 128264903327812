<h1 id="textDialogTitle" mat-dialog-title i18n="yes no dialog title|@@ynDialog_title">
  {{title}}
</h1>
<div mat-dialog-content>
  <p id="dialogContent" *ngIf="content" i18n="yes no dialog content|@@ynDialog_content"></p>
  <mat-form-field class="w-200" id="text-dialog-form-field">
    <mat-label *ngIf="displayLabel">{{displayLabel}}</mat-label>
    <input matInput [(ngModel)]="val" cdkFocusInitial [maxlength]="maxLength" (keyup.enter)="onYesClick()" id="text-diag-comp-input" [required]="required">
    <mat-hint *ngIf="maxLength" align="start">{{val?.length || 0}}/{{maxLength}}</mat-hint>
    <button type="button" mat-button *ngIf="val && showClearBtn" matSuffix mat-icon-button aria-label="Clear" (click)="clear($event)"i18n="Close|@@Close"><mat-icon>close</mat-icon></button>
  </mat-form-field>
</div>
<div mat-dialog-actions class="text-dialog-btn-footer">
  <button type="button" i18n="confirm|@@confirm" id="textDialogConfirm" mat-flat-button class="td-btn individual-button responsive-font-size" [class.individual-button-inactive]="!val" color="primary" (click)="onYesClick()">{{yesBtnLabel}}</button>
  <button type="button" i18n="cancel|@@cancel" id="textDialogCancel" mat-stroked-button class="td-btn secondary-button responsive-font-size" (click)="onNoClick()">{{noBtnLabel}}</button>
</div>