import { IBarcode } from '@services/grid/barcodes-data-grid.service';

export class BarcodesHelper {

    public static getStockAndFactorUniqueBarcodes(multiItemBarcodes: IBarcode[]): IBarcode[] {
        const tempBarcodeCustomArr: { barcodeId: number, stockId: number, factorId: number }[] = [];
        multiItemBarcodes.forEach((barcode) => {
            let factorId;
            let stockId;
            if (barcode.factorId) { // barcode linked to stock
                stockId = barcode.Factor?.Stock?.stockId;
                factorId = barcode.factorId;
            } else { // barcode linked to a product which is linked to stock via product.factorId
                stockId = barcode.Product?.Factor?.Stock?.stockId;
                factorId = barcode.Product?.factorId;
            }
            tempBarcodeCustomArr.push({ barcodeId: barcode.barcodeId, stockId, factorId });
        });

        const stockAndFactorUniqueBarcodeIds = tempBarcodeCustomArr.filter((item, index, array) =>
            array.findIndex((t) => (t.stockId === item.stockId && t.factorId === item.factorId)) === index)
            .map((custom) => custom.barcodeId);
        return multiItemBarcodes.filter((item) => stockAndFactorUniqueBarcodeIds.includes(item.barcodeId));
    }
}
