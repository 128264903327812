import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { Configuration, Organisation, UserResponse as User } from '@modules/SDKs/portalApi';
import { OrganisationView } from '@modules/SDKs/sysnetApi';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { BreakpointsTriggers } from '../Constants';
import { pages } from '../utilities/Pages';

export interface IPage {
  url: string;
  title: string;
  params?: { id?: string };
}

export interface IState {
  user?: User;
  organisations?: Organisation[];
  selectedOrganisation?: Organisation;
  auth0Token?: string;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalsService implements OnDestroy {

  /**
   * This is still experimental and I wouldn't rely heavily on it.
   */
  public $page: BehaviorSubject<IPage>;
  /**
   * Subscribe here if you need to be updated when the page changes. Useful for things like updating the page title when a sub-component changes.
   */
  public $router: Subscription;
  /**
   * Subscribe for the currently logged in user. This will fire when the user changes, as well as if current state has been set.
   * There is no initial state, so you will not get an initial event fire. This means that if the user is logged in, you will get
   * an event once we get the user from the API (this happens on login or page load if localStorage is set). If a user is not logged in,
   * you will not get an event until the user logs in.
   */
  // public $user: ReplaySubject<User>;
  public $state = new BehaviorSubject<IState>(null);
  public $events = {
    auth0TokenRefreshed: new Subject<string>()
  };
  public isMobile = false;
  public sidenav;

  // private _user: User;
  public page: IPage;
  private headerNotifications: Subject<string> = new Subject<'show' | 'hide'>();
  private popupNotifications: Subject<string> = new Subject<string>();
  private state: IState;

  constructor(private router: Router, private route: ActivatedRoute, private breakpointObserver: BreakpointObserver, private httpClient: HttpClient) {
    this.$page = new BehaviorSubject(pages.HOME);

    this.breakpointObserver.observe(BreakpointsTriggers.isMobileTrigger).subscribe((result) => {
      this.isMobile = result.matches;
    });
    this.subscribeToRouterEvents();
  }

  public hideHeader(): void {
    this.headerNotifications.next('hide');
  }

  public showHeader(): void {
    this.headerNotifications.next('show');
  }

  public getHeaderNotifications(): Observable<string> {
    return this.headerNotifications.asObservable();
  }

  public doPopupRequest(popupName: string): void {
    this.popupNotifications.next(popupName);
  }

  public getPopupRequests(): Observable<string> {
    return this.popupNotifications.asObservable();
  }

  public subscribeToRouterEvents(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let r = this.route.firstChild;
        let child = r;
        let iterations = 0; // to stop infinite loops if a route somehow becomes a child of itself.
        while (child && iterations < 10) {
          if (child.firstChild) {
            child = child.firstChild;
            r = child;
          } else {
            child = null;
          }
          iterations += 1;
        }
        const sub = r.data.pipe(first()).subscribe((d) => {
          this.page = { url: event.url, title: (d ? d.title : ''), params: (r && r.snapshot && r.snapshot.params) ? r.snapshot.params : {} };
          this.$page.next(this.page);
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this.$router.unsubscribe();
  }

  public getState(): IState {
    return this.state;
  }

  public setUser(user: User): void {
    this.state = { ...this.state, user };
    this.$state.next(this.state);
  }

  public setOrganisations(organisations: Organisation[]): void {
    this.state = { ...this.state, organisations };
    this.$state.next(this.state);
  }

  public setAuth0Token(auth0Token: string): void {
    this.$events.auth0TokenRefreshed.next(auth0Token);
  }

  public setSelectedOrganisation(selectedOrganisation: Organisation): void {
    localStorage.setItem('organisation', selectedOrganisation?._id);
    this.state = { ...this.state, selectedOrganisation };
    this.$state.next(this.state);
  }

  public setState(newState: IState): void {
    this.state = { ...this.state, ...newState };
    if (newState.selectedOrganisation) {
      localStorage.setItem('organisation', newState.selectedOrganisation._id);
    }
    this.$state.next(newState);
  }

  public getPortalConfiguration(): Configuration {
    const config = new Configuration({
      accessToken: localStorage.getItem('id_token')
      });

    return config;

  }

  public getPortalBaseUrl(): string {
    return AppConfiguration.GetChildConfiguration('hal.common').REST_API_HOST;
  }
}
