<dx-lookup
  [items]="options"
  [searchExpr]="['name', 'value']"
  valueExpr="value"
  applyButtonText="OK"
  itemTemplate="listItem"
  fieldTemplate="fieldItem"
  applyValueMode="useButtons"
  applyButtonText="Done"
  [showCancelButton]="false"
  [disabled]="disabled"
  (onClosed)="onConfirm()"
>
  <div *dxTemplate="let item of 'listItem'">
    <mat-checkbox
      class="checkbox"
      [checked]="checked(item)"
      color="primary"
      (change)="checkboxChange(item, $event)"
    >{{item.name}}</mat-checkbox>
  </div>
  <div *dxTemplate="let item of 'fieldItem'">
    <div class="inputField">{{fieldText}}</div>
  </div>
</dx-lookup>
