<!-- Repeat -->
<div class="repeat-freq-wrapper">
  <div class="main-header" i18n="Repeat|@@Repeat">Repeat</div>
  <dx-form [formData]="rruleData" labelLocation="top" [colCount]="8" (onFieldDataChanged)="onFormChanged($event)">
    <dxi-item dataField="repeatType" [colSpan]="8"  [label]="{text: ' ', showColon: false}" [template]="'repeatTypeTemplate'"></dxi-item>
    <div *dxTemplate="let data of 'repeatTypeTemplate'">
      <div class="repeat-freq-content">
          <dx-lookup [dataSource]="repeatTypes" displayExpr="label" valueExpr="type" class="repeat-freq-selector-repeatFreq"
              placeholder="Select" [searchEnabled]="false" [showClearButton]="false" (onValueChanged)="onFormChanged($event, 'repeatFreq')"
              [dropDownOptions]="dxLookupConfig.dropDownOptions" [(value)]="rruleData.repeatFreq">
          </dx-lookup>
          <p class="text" i18n="every|@@every" *ngIf="rruleData.repeatFreq && rruleData.repeatFreq !== 'yearly'"> {{rruleData.repeatFreq && rruleData.repeatFreq !== 'yearly' ? 'every' : ''}} </p>
          <dx-number-box class="repeat-freq" [(value)]="rruleData.repeatInterval" *ngIf="rruleData.repeatFreq && rruleData.repeatFreq !== 'yearly'"
            class="repeat-freq-selector-repeatInterval" (onValueChanged)="onFormChanged($event, 'repeatInterval')" [min]="1" format="#">
            <dx-validator [validationRules]="validationRules.require" validationGroup="offersDetailScheduleGroup"></dx-validator>
          </dx-number-box>
          <span class="text" i18n="month(s)|@@month(s)" [hidden]="rruleData.repeatFreq !== 'monthly'"> month(s) </span>
          <span class="text" i18n="week(s)|@@week(s)" [hidden]="rruleData.repeatFreq !== 'weekly'"> week(s) </span>
          <span class="text" i18n="day(s)|@@day(s)" [hidden]="rruleData.repeatFreq !== 'daily'"> day(s) </span>
          <span class="text" i18n="hour(s)|@@hour(s)" [hidden]="rruleData.repeatFreq !== 'hourly'"> hour(s) </span>
      </div>
    </div>
  </dx-form>
</div>

<!-- Repeat Content - Yearly/Monthly -->
<div class="repeat-radio-wrapper" *ngIf="rruleData.repeatFreq === 'yearly' || rruleData.repeatFreq === 'monthly'">
  <div class="repeat-radio-on">
    <dx-form [formData]="rruleData" labelLocation="top" [colCount]="8" (onFieldDataChanged)="onFormChanged($event)">
      <dxi-item dataField="name" [colSpan]="8" [label]="{text: ' ', showColon: false}" [template]="'radioOnTemplate'">
      </dxi-item>
      <div *dxTemplate="let data of 'radioOnTemplate'">
        <div class="repeat-selector-wrapper">
          <dx-radio-group [items]="radioOptionOn" layout="horizontal" valueExpr="value" displayExpr="text" [(value)]="rruleData.repeatChoice" (onValueChanged)="onFormChanged($event, 'repeatChoice')" class="repeat-selector-radio"></dx-radio-group>
          <dx-lookup [dataSource]="months" displayExpr="label" valueExpr="type" class="repeat-selector-field" (onValueChanged)="onFormChanged($event, 'repeatByMonth')"
              placeholder="Select" [searchEnabled]="false" [showClearButton]="false" [visible]="rruleData.repeatFreq === 'yearly'"
              [dropDownOptions]="dxLookupConfig.dropDownOptions" [(value)]="rruleData.repeatByMonth">
              <dx-validator validationGroup="offersDetailScheduleGroup">
                <dxi-validation-rule *ngIf="rruleData.repeatChoice === 'on' && rruleData.repeatFreq === 'yearly'" type="required"></dxi-validation-rule>
              </dx-validator>
          </dx-lookup>
          <dx-number-box [(value)]="rruleData.repeatByMonthDay" [max]="31" [min]="1" class="repeat-selector-field" (onValueChanged)="onFormChanged($event, 'repeatByMonthDay')" format="#">
            <dx-validator validationGroup="offersDetailScheduleGroup">
              <dxi-validation-rule *ngIf="rruleData.repeatChoice === 'on'" type="required"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
          <div class="repeat-selector-field" *ngIf="rruleData.repeatFreq === 'monthly'"></div>
        </div>
      </div>
    </dx-form>
  </div>

  <div class="repeat-radio-on-the">
    <dx-form [formData]="rruleData" labelLocation="top" [colCount]="8" (onFieldDataChanged)="onFormChanged($event)">
      <dxi-item dataField="name" [colSpan]="8" [label]="{text: ' ', showColon: false}" [template]="'radioOnTheTemplate'">
      </dxi-item>
      <div *dxTemplate="let data of 'radioOnTheTemplate'">
        <div class="repeat-selector-wrapper">
          <dx-radio-group [items]="radioOptionOnThe" layout="horizontal" valueExpr="value" displayExpr="text" [(value)]="rruleData.repeatChoice" (onValueChanged)="onFormChanged($event, 'repeatChoice')" class="repeat-selector-radio"></dx-radio-group>
          <dx-lookup [dataSource]="weekOrder" displayExpr="label" valueExpr="type" class="repeat-selector-field"
              placeholder="Select" [searchEnabled]="false" [showClearButton]="false" (onValueChanged)="onFormChanged($event, 'repeatBySetPos')"
              [dropDownOptions]="dxLookupConfig.dropDownOptions" [(value)]="rruleData.repeatBySetPos">
              <dx-validator validationGroup="offersDetailScheduleGroup">
                <dxi-validation-rule *ngIf="rruleData.repeatChoice === 'onThe'" type="required"></dxi-validation-rule>
              </dx-validator>
          </dx-lookup>
          <dx-lookup [dataSource]="weekDay" displayExpr="label" valueExpr="type" class="repeat-selector-field"
              placeholder="Select" [searchEnabled]="false" [showClearButton]="false" (onValueChanged)="onFormChanged($event, 'repeatByDay')"
              [dropDownOptions]="dxLookupConfig.dropDownOptions" [(value)]="rruleData.repeatByDay">
              <dx-validator validationGroup="offersDetailScheduleGroup">
                <dxi-validation-rule *ngIf="rruleData.repeatChoice === 'onThe'" type="required"></dxi-validation-rule>
              </dx-validator>
          </dx-lookup>
          <div>
            <span class="text" i18n="Minus|@@Minus" [ngClass]="{'hide': rruleData.repeatFreq === 'monthly'}"> of </span>
          </div>
          <dx-lookup [dataSource]="months" displayExpr="label" valueExpr="type" class="repeat-selector-field" (onValueChanged)="onFormChanged($event, 'repeatByDayMonth')"
              placeholder="Select" [searchEnabled]="false" [showClearButton]="false" [ngClass]="{'hide': rruleData.repeatFreq === 'monthly'}"
              [dropDownOptions]="dxLookupConfig.dropDownOptions" [(value)]="rruleData.repeatByDayMonth">
              <dx-validator validationGroup="offersDetailScheduleGroup">
                <dxi-validation-rule *ngIf="rruleData.repeatChoice === 'onThe' && rruleData.repeatFreq === 'yearly'" type="required"></dxi-validation-rule>
              </dx-validator>
          </dx-lookup>
        </div>
      </div>
    </dx-form>
  </div>
</div>

<!-- Repeat Content - Weekly -->
<div *ngIf="rruleData.repeatFreq === 'weekly'">
  <dx-button-group
    [items]="weeklyDates"
    [focusStateEnabled]="false"
    [selectionMode]="multiple"
    [selectedItems]="weeklyDaySelection"
    (onSelectionChanged)="onWeeklyDaySelectionChanged($event)"
  ></dx-button-group>
</div>
<!-- End -->
<div>
  <div class="main-header" i18n="End|@@End">End</div>
  <dx-form [formData]="rruleData" labelLocation="top" [colCount]="8" (onFieldDataChanged)="onFormChanged($event)">
    <dxi-item dataField="endType" [colSpan]="2"  [label]="{text: ' ', showColon: false}" [template]="'endTypeTemplate'"></dxi-item>
    <div *dxTemplate="let data of 'endTypeTemplate'">
      <dx-lookup [dataSource]="endTypes" displayExpr="label" valueExpr="type" (onValueChanged)="onFormChanged($event, 'endType')"
          placeholder="Select" [searchEnabled]="false" [showClearButton]="false"
          [dropDownOptions]="dxLookupConfig.dropDownOptions" [(value)]="rruleData.endType">
      </dx-lookup>
    </div>
    <dxi-item dataField="endDate" [colSpan]="2" [label]="{text: ' ', showColon: false}" [template]="'endDateTemplate'" [visible]="rruleData.endType === 'onDate'">
    </dxi-item>
    <div *dxTemplate="let data of 'endDateTemplate'">
      <dx-date-box [(value)]="rruleData.endDate" type="date" [acceptCustomValue]="false" [displayFormat]="calenderDateFormat" (onValueChanged)="onFormChanged($event, 'endDate')">
        <dx-validator [validationRules]="validationRules.require" validationGroup="offersDetailScheduleGroup"></dx-validator>
      </dx-date-box>
    </div>
  </dx-form>
</div>
