<!-- <mat-icon class="inline m10">build</mat-icon><h1 class="inline" i18n="Coming Soon|@@comingSoon">Coming Soon</h1><mat-icon class="inline m10">build</mat-icon>
<p class="m10" i18n="under construction|@@underConstruction">This page is under construction, please check back soon.</p> -->
<div class="no-app">
    <img *ngIf="!smallScreen" src="../../../../assets/img/Aladdin.png" class="aladdin">
    <div *ngIf="smallScreen" class="aladdin-sml-bg">
      <img src="../../../../assets/img/empty-states-responsive/Genie/Genie.png" class="aladdin-sml" >
    </div>
    <div class="aladdin-content" [class.aladdin-content-extra]="!smallScreen">
      <div class="aladdin-caption" [class.aladdin-caption-extra]="!smallScreen" [class.aladdin-caption-sml]="smallScreen">
        <h1 i18n="home no app|@@home_noApp">Oh no, looks like we can't grant your wish today!</h1>
        <p i18n="home no app caption|@@home_noAppCaption">Normally you'd access your cloud apps from here. To learn more, get in touch with us</p>
        <button type="button" mat-raised-button id="learn-more" color="primary" onclick="window.open('https://hlaustralia.com.au/contact-us')" i18n="home no app btn|@@home_noAppBtn">I want to know more</button>
      </div>
    </div>
</div>