import { Injectable } from '@angular/core';
import { ICustomStoreLoadResult } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { StocktakeItemsDataEntryView } from '@modules/SDKs/sysnetApi';
import { StocktakeItemsDataEntryService } from '@services/sysnet/stocktake-items-data-entry.service';
import { LoadOptions } from 'devextreme/data';
import { IGroupQueryResultBody } from 'src/app/types/interfaces/GeneralService';
import { LoadOptionsHelper } from 'src/app/utilities/LoadOptionsHelper';

@Injectable({
  providedIn: 'root'
})
export class StocktakeItemsDataEntryDataGridService {

  constructor(
    private stocktakeItemsDataEntryService: StocktakeItemsDataEntryService
  ) {
  }

  // Search StocktakeItemsDataEntry
  public async searchStocktakeItemsDataEntry(loadOptions: LoadOptions, view?: string): Promise<ICustomStoreLoadResult<StocktakeItemsDataEntryView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const stocktakeItemsDataEntryRes = await this.stocktakeItemsDataEntryService.searchStocktakeItemsDataEntry(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take,
      loadOptions.skip, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<StocktakeItemsDataEntryView> = {
      data: parsedLoadOptions.group ? (stocktakeItemsDataEntryRes.body as unknown as IGroupQueryResultBody).data : stocktakeItemsDataEntryRes.body,
      totalCount: +stocktakeItemsDataEntryRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  // Insert StocktakeItemDataEntry
  public async insertStocktakeItemDataEntry(values): Promise<StocktakeItemsDataEntryView> {
    const stocktakeItemDataEntryResult = await this.stocktakeItemsDataEntryService.insertStocktakeItemsDataEntry(values);
    return stocktakeItemDataEntryResult.body;
  }

  public async deleteStocktakeItemDataEntry(values): Promise<void> {
    if (values) {
      await this.stocktakeItemsDataEntryService.deleteStocktakeItemsDataEntry(values);
    }
  }

  public async searchStocktakeItemsDataEntryByStocktakeId(loadOptions: LoadOptions, view?: string, stocktakeDataEntryId?: number): Promise<ICustomStoreLoadResult<StocktakeItemsDataEntryView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    if (stocktakeDataEntryId === undefined) {
      return { data: [], totalCount: 0, summary: null, groupCount: null };
    }
    const stocktakeIdSearchExpr = { stocktakeItemsId: { $eq: stocktakeDataEntryId } };
    if (parsedLoadOptions.search) {
      parsedLoadOptions.search = {
        $and: [parsedLoadOptions.search, stocktakeIdSearchExpr]
      };
    } else {
      parsedLoadOptions.search = stocktakeIdSearchExpr;
    }
    const stocktakeItemsDataEntryRes = await this.stocktakeItemsDataEntryService.searchStocktakeItemsDataEntry(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, '-stocktakeDataEntryId,-updateDateTime',
      loadOptions.take, loadOptions.skip, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<StocktakeItemsDataEntryView> = {
      data: parsedLoadOptions.group ? (stocktakeItemsDataEntryRes.body as unknown as IGroupQueryResultBody).data : stocktakeItemsDataEntryRes.body,
      totalCount: +stocktakeItemsDataEntryRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

}
