/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ContainerIdFKObject } from './containerIdFKObject';
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { FactorIdFKObject } from './factorIdFKObject';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { LinkedPluGroupIdFKObject } from './linkedPluGroupIdFKObject';
import { PluGroupIdFKObject } from './pluGroupIdFKObject';
import { PrintGroupIdFKObject } from './printGroupIdFKObject';
import { ProductView } from './productView';
import { RangeIdFKObject } from './rangeIdFKObject';
import { RevenueGroupIdFKObject } from './revenueGroupIdFKObject';
import { TaxRateIdFKObject } from './taxRateIdFKObject';
import { UpdateDateTime } from './updateDateTime';

export interface ProductsFKExternalKeysView extends ProductView { 
    factorIdFKObject?: FactorIdFKObject;
    containerIdFKObject?: ContainerIdFKObject;
    pluGroupIdFKObject?: PluGroupIdFKObject;
    revenueGroupIdFKObject?: RevenueGroupIdFKObject;
    linkedPluGroupIdFKObject?: LinkedPluGroupIdFKObject;
    taxRateIdFKObject?: TaxRateIdFKObject;
    printGroupIdFKObject?: PrintGroupIdFKObject;
    rangeIdFKObject?: RangeIdFKObject;
}
export namespace ProductsFKExternalKeysView {
}