import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { StocktakeItemsDataGridService } from '@services/grid/stocktake-items-data-grid.service';
import { GenericDataGridComponent, IDataGridActionColumn, IDataSourceConfig, IJoinTableColumnConfig, IStandardDataGridConfig, IDataGridColumnConfig } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { Properties as dxPopupOptions } from 'devextreme/ui/popup';
import { DxPopupComponent } from 'devextreme-angular';
import { GlobalsService } from '@services/global.service';
import { StockDataGridService } from '@services/grid/stock-data-grid.service';
import { GridConstants } from 'src/app/Constants';
import { StocktakeItemsListView } from '@modules/SDKs/sysnetApi';
import { StocktakeItem } from 'src/app/types/StockTypes';

@Component({
  selector: 'app-stocktake-items-list',
  templateUrl: './stocktake-items-list.component.html',
  styleUrls: ['./stocktake-items-list.component.scss']
})
export class StocktakeItemsListComponent implements OnInit {
  public isSelectBtnEnabled = false;
  public stocktakeItemsListDataSourceConfig: IDataSourceConfig<StocktakeItemsDataGridService> = {
    dataSourceName: 'stocktakeItemsList',
    dataSourcePrimaryKey: 'stocktakeItemsId',
    dataSourceLoadMode: 'processed',
    dataSourceService: this.stocktakeItemsDataGridService,
    dataSourceServiceSearchFnView: 'StocktakeItemsList',
    dataSourceServiceSearchFnRouteId: undefined,
    dataSourceServiceSearchFn: 'searchStocktakeItemsByStocktakeId',
    dataSourceServiceDeleteFn: 'deleteStocktakeItem'
  };
  public stocktakeItemsJoinTableColumnConfig: IJoinTableColumnConfig<StockDataGridService> = {
    stockId: {
      joinModelName: 'Stock',
      joinTableDataSource: {},
      joinTableDataSourceLoadMode: 'processed',
      joinTableDataSourceService: this.stockDataGridService,
      joinTableDataSourceServiceSearchFn: 'searchStock',
      joinTableDataSourceServiceGetFn: 'getStock',
      joinTablePrimaryKey: 'stockId',
      joinTableDataSortBy: 'inventoryCode'
    }
  };

  public stocktakeItemsListDataGridConfig: IStandardDataGridConfig = {};
  public stocktakeItemsListDataGridActionColumn: IDataGridActionColumn = { enabled: false };
  public popupConfig: dxPopupOptions = {
    title: 'Stocktake Items',
    visible: false,
    onShowing: (e) => {
      this.stocktakeItemsListDataGrid?.dataGridComponent?.instance.refresh();
      this.stocktakeItemsListDataGrid?.clearSelection();
    },
    onHiding: (e) => {
      this.isSelectBtnEnabled = false;
    }
  };
  @ViewChild('stocktakeItemsListDataGrid', { static: false }) public stocktakeItemsListDataGrid: GenericDataGridComponent;
  @ViewChild('stocktakeItemsPopup', { static: false }) public stocktakeItemsPopup: DxPopupComponent;
  @Output() public readonly stocktakeItemSelected = new EventEmitter<StocktakeItem>();
  constructor(
    private stocktakeItemsDataGridService: StocktakeItemsDataGridService,
    public globalsService: GlobalsService,
    private stockDataGridService: StockDataGridService
  ) { this.onDataGridSetup(); }

  public ngOnInit(): void {
  }

  public openPopup(stocktakeId: number): void {
    this.stocktakeItemsListDataSourceConfig.dataSourceServiceSearchFnRouteId = stocktakeId;
    this.popupConfig.visible = true;
  }

  public onDataGridSetup(): void { // custom config
    this.stocktakeItemsListDataGridConfig.columns = [
      {
        dataField: 'stockId',
        name: 'stockInvCode',
        dataType: 'number',
        minWidth: 102,
        width: 102,
        sortOrder: 'asc',
        caption: 'Inv Code',
        _castToIntWhenSearch: true,
        calculateDisplayValue: 'Stock.inventoryCode',
        lookup: {
          dataSource: this.stocktakeItemsJoinTableColumnConfig.stockId.joinTableDataSource,
          displayExpr: 'inventoryCode',
          valueExpr: 'stockId',
          allowClearing: false
        }
      },
      {
        dataField: 'stockId',
        name: 'stockName',
        dataType: 'string',
        minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
        caption: 'Stock Description',
        calculateDisplayValue: 'Stock.name',
        lookup: {
          dataSource: this.stocktakeItemsJoinTableColumnConfig.stockId.joinTableDataSource,
          displayExpr: 'name',
          valueExpr: 'stockId',
          allowClearing: false
        }
      }
    ] as IDataGridColumnConfig[];

    this.stocktakeItemsListDataGridConfig.onLoaded = () => {
      if (this.popupConfig.visible) { this.isSelectBtnEnabled = true; }
    };

    this.stocktakeItemsListDataGridConfig.columnChooser = {
      enabled: false
    };

    this.stocktakeItemsListDataGridConfig.stateStoring = {
      enabled: false
    };

    this.stocktakeItemsListDataGridConfig.selection = {
      mode: 'single'
    };

    this.stocktakeItemsListDataGridConfig.remoteOperations = {
      filtering: true,
      paging: true,
      sorting: true,
      summary: false,
      grouping: true,
      groupPaging: false
    };
  }

  public hidePopup(): void {
    this.stocktakeItemsPopup?.instance.hide();
  }

  public async select(): Promise<void> {
    const selectedItem = await this.stocktakeItemsListDataGrid?.dataGridComponent?.instance?.getSelectedRowsData()[0];
    if (!selectedItem) { return; }
    this.stocktakeItemSelected.emit(selectedItem);
    this.hidePopup();
  }

}
