/**
 * Portal API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocationResponseLocationType } from './locationResponseLocationType';
import { LocationResponseOrganisation } from './locationResponseOrganisation';

export interface LocationResponse { 
    name?: string;
    ownerUserId?: string;
    organisationId?: string;
    locationType?: LocationResponseLocationType;
    _id?: string;
    locationTypeId?: string;
    organisation?: LocationResponseOrganisation;
    addressLine1?: string;
    suburb?: string;
    postcode?: string;
    state?: string;
    countryCode?: string;
    active?: boolean;
    addressLine2?: string;
    sysnetDebtorId?: string;
    sysnetVenueId?: string;
}