<div class="image-component">
  <div class="lds-ellipsis" *ngIf="loading">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div *ngIf="!loading" class="preview-area">
    <div class="no-image-bg" *ngIf="!currentImgSrc">
      <div class="material-icons image-logo" i18n="insert_photo|@@insert_photo"> insert_photo </div>
    </div>
    <div class="img-bg" (click)="popUp = true;">
      <img *ngIf="currentImgSrc" [src]="currentImgSrc | bypassSanitizer" class="image">
    </div>
    <div *ngIf="componentConfig.editable" class="overlay-btn" [class.toolbar-stay]="!componentConfig.showToolBarOnHoverOnly"[@slide]="editMode">
      <div class="toolbar-left-panel">
        <button mat-button color="primary" id="editBtn" (click)="editMode = true;" [matTooltip]="Edit">
          <i class="material-icons" i18n="edit|@@edit">edit</i>
        </button>
      </div>
      <div class="toolbar-right-panel">
        <ng-container *ngIf="!btnDebounced">
          <button mat-button id="uploadBtn" (click)="chooseFile.click()" [matTooltip]="imgBtn.tooltip">
            <i class="material-icons">{{imgBtn.label}}</i>
            <input hidden type="file" accept="image/*" (change)="onFileChange($event)" #chooseFile>
          </button>
          <button *ngIf="confirmBeforeSave && currentImgSrc !== originalImageSource" matTooltip="Save Changes" mat-button
            id="saveBtn" (click)="save()"><i class="material-icons" i18n="done|@@done">done</i></button>
          <button *ngIf="componentConfig.canUndo && originalImageSource && currentImgSrc !== originalImageSource" matTooltip="Discard Changes" mat-button
            id="discardBtn" (click)="discard()"><i class="material-icons" i18n="undo|@@undo">undo</i></button>
          <button *ngIf="currentImgSrc" matTooltip="Remove Image" mat-button id="removeBtn" (click)="remove()"><i
              class="material-icons" i18n="delete|@@delete">delete</i></button>
        </ng-container>
        <div class="lds-ellipsis" *ngIf="btnDebounced">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="note" i18n=">Note: Image size limit 5MB.|@@imgSizeLimit">Note: Image size limit 5MB.</div>


  <div class="pop-up-bg" *ngIf="popUp" (click)="popUp=false"></div>
  <div class="img-pop-up" *ngIf="popUp">
    <img *ngIf="currentImgSrc" [src]="currentImgSrc | bypassSanitizer" class="image" style="z-index: -99;">
    <i class="material-icons" style="float: right; margin: 20px; font-size: xx-large; cursor: pointer;" (click)="popUp=false" i18n="close|@@close">close</i>
  </div>