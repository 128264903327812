import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from '@components/generic/page-not-found/page-not-found.component';
import { SignupComponent } from '@components/public/signup/signup.component';
import { Auth0Component } from './auth0/auth0.component';
import { ProtectedRoute } from './auth0/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [ProtectedRoute],
    // component: DashboardComponent,
    loadChildren: () => import('./modules/framework/framework.module').then((m) => m.FrameworkModule)
  },
  {
    path: 'login',
    component: Auth0Component,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: {
      title: 'Create Cloud Account'
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
