/* eslint-disable max-lines */
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UtilityService } from '@services/utility.service';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data';
import dxDataGrid, { Column, EditorPreparingEvent, EditorPreparedEvent, SavingInfo, Row, ColumnLookup, Properties as dxDataGridOptions } from 'devextreme/ui/data_grid';
import { Item } from 'devextreme/ui/menu';
import { BreakpointsTriggers, GridConstants, ILookupDataSourceConfig } from 'src/app/Constants';
import { ErrorMessages } from 'src/app/utilities/ErrorMessages';
import { EventInfo } from 'devextreme/events';
import { CustomDialogOptions } from 'devextreme/ui/dialog';
import { IExtendedCustomDialogOptions, IExtendedEditorPreparingEvent, IExtendedRow, IExtendedStateStoring, IGroupQueryResultBody } from 'src/app/types/interfaces/GeneralService';
import { ValidationCallbackData } from 'devextreme/common';
import { CellTemplateData } from 'devextreme/ui/calendar';
import { ColumnCellTemplateData } from 'devextreme/ui/tree_list';
import { EventsService } from '@services/events.service';
import { IEventMessage } from 'hal.events.client';
import { Subscription } from 'rxjs';
import logger from '@hal.common.ui/utilities/Logger';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { IDataGridColumnConfig } from '../generic-data-grid/generic-data-grid.component';

export interface ICustomStoreLoadResult<T={}> {
  data: T[] | IGroupQueryResultBody['data'];
  totalCount: number;
  summary: object[] | null;
  groupCount: number[] | number | null;
}
export interface IJoinTableColumnConfig<T={}> {
  [dataFieldName: string]: {
    joinModelName: string;
    joinTableDataSource: ILookupDataSourceConfig<T>;
    joinTableDataSourceLoadMode?: 'raw' | 'processed';
    joinTableDataSourceService: T | object;
    joinTableDataSourceServiceSearchFnView?: string;
    joinTableDataSourceServiceSearchFnRouteId?: number;
    joinTableDataSourceServiceSearchFn: string;
    joinTableDataSourceServiceGetFnView?: string;
    joinTableDataSourceServiceGetFn: string;
    joinTablePrimaryKey?: string;
    joinTableDataSortBy?: string;
    cachedObjects?: { [key: string]: string|number|boolean|object }[] // only needed if a data grid has two lookups referencing same table
    joinTablePrimaryKeyAlias?: string; // Use this property when the joined tables share the same primary key but have different names for that key in their respective tables.
  };
}

export interface IDataSourceConfig<T={}> {
  dataSourceName: string;
  dataSourcePrimaryKey: string;
  dataSourceLoadMode: 'raw' | 'processed';
  dataSourceService: T | object; // service for the main data source e.g. stock list data grid - stockService
  dataSourceServiceSearchFnView?: string;
  dataSourceServiceSearchFnRouteId?: number | string;
  dataSourceServiceSearchFnArgs?: { [key: string]: number | string | boolean };
  dataSourceServiceSearchFn: string;
  dataSourceServiceInsertFn?: string;
  dataSourceServiceUpdateFn?: string;
  dataSourceServiceDeleteFn?: string;
}

export interface IDataGridActionColumn {
  enabled: boolean;
  actionMenu?: [Item]; // length 1
  processActionMenu?: ((cellInfo, actionMenu: [Item]) => [Item]);
  onActionItemClick?: ($event, cellInfo) => void;
  onSaveClick?: (cellInfo) => boolean;
  onCancelClick?: () => boolean;
}

export { EditorPreparedEvent as IEditorPrepared, EditorPreparingEvent as IEditorPreparingEvent };

interface ICustomTemplate<T> {
  name: string;
  template: TemplateRef<T>;
}

export interface IQuickEntryDataGridCustomConfig {
    moveToNextRowAfterEnterKey?: boolean;
    onReset?: () => void;
    onLoading?: (loadOptions: LoadOptions) => void;
    onLoaded?: (result: ICustomStoreLoadResult) => void;
}

export interface IQuickEntryDataGridConfig extends dxDataGridOptions, IQuickEntryDataGridCustomConfig { }

export interface IDataGridReceiveEventsConfig<T={}> {
  eventsService: EventsService; // service that provides events.
  objectName: string; // name of the object to listen for events.
  tenantType: string; // type of tenant (e.g., 'venue', 'organisation').
  primaryKey: string; // primary key field name of the data grid.
  venueId?: string;
  organisationId?: string;
  eventCriteria?: T; // Criteria to match events.
  customEventHandler?: (event: IEventMessage) => { gridUpdateRequired: boolean, tooltipMessage?: string }; // handler for processing events.
  tooltipMessage?: string; // message to display when refresh is required.
}

@Component({
  selector: 'app-quick-entry-data-grid',
  templateUrl: './quick-entry-data-grid.component.html',
  styleUrls: ['./quick-entry-data-grid.component.scss']
})
export class QuickEntryDataGridComponent implements OnInit, OnDestroy {
  public selectedRowKey: string | number | object |null; // for single selection
  public filterRowVisible = true;
  public isSaving = false;
  public isUpdating = false;
  public customOnContentReady;
  public customOnSaving;
  public initEntryForm = false;
  public changeSet = [];
  public lastSelectedRowIndex = 0;
  public lastSelectedColumnIndex = 0;
  public lastMethod = 'insert';
  public gridUpdateRequired = false;
  public refreshRequiredTooltip = '';
  private subscriptions: Subscription[] = [];
  @Input() public dataSourceConfig: IDataSourceConfig;
  @Input() public joinTableColumnConfig: IJoinTableColumnConfig = {}; // services for other data source e.g. stock list data grid - categoryService, allergenService
  @Input() public dataGridConfig: IQuickEntryDataGridConfig = {}; // data grid config options
  @Input() public dataGridActionColumn: IDataGridActionColumn = { enabled: false };
  @Input() public customTemplates: ICustomTemplate<ColumnCellTemplateData>[] = [];
  @Input() public dataGridReceiveEventsConfig: IDataGridReceiveEventsConfig;
  @ViewChild(DxDataGridComponent, { static: false }) public dataGridComponent: DxDataGridComponent;
  constructor(
    private utils: UtilityService
  ) { }

  public ngOnInit(): void {
    this.setupGenericLookupDataSource();
    this.setupGenericDataGridDataSource();
    this.setupGenericDataGrid();
    this.setupListeningForEvents();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public updateSelection(selectedRowKeys: string[] | number[] | object[]): void {
    this.dataGridComponent?.instance.selectRows(selectedRowKeys, false);
  }

  public clearSelection(): void {
    this.selectedRowKey = null;
    this.dataGridComponent?.instance.clearSelection();
  }

  public clearStates(): void {
    this.dataGridComponent?.instance.clearFilter();
    this.dataGridComponent?.instance.cancelEditData();
    this.dataGridComponent?.instance.repaint();
    this.dataGridComponent?.instance.state(null);
    this.dataGridComponent?.instance.clearSelection();
    this.dataGridComponent?.instance.refresh();
    if (this.dataGridConfig.hasOwnProperty('onReset')) { this.dataGridConfig.onReset(); }
  }

  public showLoader(messageText = 'Loading...'): void {
    this.dataGridComponent?.instance.beginCustomLoading(messageText);
  }
  public hideLoader(): void {
    this.dataGridComponent?.instance.endCustomLoading();
  }
  public refresh(): void { // will reload
    this.dataGridComponent?.instance.refresh();
    this.gridUpdateRequired = false;
    this.refreshRequiredTooltip = '';
  }
  public repaint(): void { // without reload
    this.dataGridComponent?.instance.repaint();
  }
  public cancelEditData(): void {
    this.dataGridComponent?.instance.cancelEditData();
  }
  public hideColumnChooser(): void {
    this.dataGridComponent?.instance.hideColumnChooser();
  }

  public defaultOnContentReady(e: EventInfo<dxDataGrid>): void {
    if (this.customOnContentReady) {
      this.customOnContentReady(e);
    }

    if (e.component.hasEditData() && this.lastSelectedRowIndex > 0 && this.lastSelectedColumnIndex === this.getActionColumnIndex(e.component.getVisibleColumns())) {
      const actionColumn = e.component.getCellElement(this.lastSelectedRowIndex, this.lastSelectedColumnIndex);
      e.component.focus(actionColumn);
    }
    if (this.dataGridConfig.editing.allowAdding) {
      if (e.component.hasEditData()) { return; }
      e.component.addRow();
      if (this.lastMethod === 'insert') {
        this.lastSelectedColumnIndex = this.getFirstEditorIndex(e.component.getVisibleColumns());
        this.lastSelectedRowIndex = 0;
      }
      this.navigateToCell(this.lastSelectedRowIndex, this.lastSelectedColumnIndex, false);
      return;
    }
    if (!this.initEntryForm && this.dataGridConfig.editing.allowAdding &&
        this.dataGridConfig.editing.allowUpdating && this.dataGridComponent.instance.getVisibleRows().length > 0) {
      this.initEntryForm = true;
      this.navigateToCell(this.lastSelectedRowIndex, this.lastSelectedColumnIndex, false);
      return;
    }

    if (!this.initEntryForm && !this.dataGridConfig.editing.allowAdding &&
        this.dataGridConfig.editing.allowUpdating && this.dataGridComponent.instance.getVisibleRows().length > 0) {
      this.initEntryForm = true;
      this.lastSelectedColumnIndex = this.getFirstEditorIndex(e.component.getVisibleColumns());
      this.navigateToCell(this.lastSelectedRowIndex, this.lastSelectedColumnIndex);
      return;
    }
  }

  public async defaultOnSaving(e: EventInfo<dxDataGrid> & SavingInfo): Promise<void> {
    if (this.isSaving) {
      e.cancel = true;
      return;
    }
    this.isSaving = true;
    if (e.changes.length === 0) {
      e.cancel = true;
      return;
    }
    // if there are more than one changes, ignore the insert change
    if (e.changes.length > 1) {
      const addIndex = e.changes.findIndex((change) => change.type === 'insert');
      if (addIndex > -1) {
        e.changes.splice(addIndex, 1);
      }
    }
    this.lastMethod = e.changes[0].type;
    if (!this.customOnSaving) {
      this.isSaving = false;
      return;
    }
    await this.customOnSaving(e);
    this.isSaving = false;
  }

  public deleteItem(e: Event, cellInfo): void {
    e.preventDefault();
    const customDialogConfigs: IExtendedCustomDialogOptions = {
      dragEnabled: false,
      showTitle: false,
      hideOnOutsideClick: false,
      messageHtml: 'Are you sure you want to delete this record?',
      buttons: [
        { text: 'Yes', onClick: () => true, stylingMode: 'contained', type: 'normal', focusStateEnabled: true, activeStateEnabled: true },
        { text: 'No', onClick: () => false, stylingMode: 'outlined', type: 'normal', focusStateEnabled: true, activeStateEnabled: true }
      ],
      popupOptions: {
        onShown: (event) => {
          const element = event.component.element();
          const yesButton = element.querySelectorAll('.dx-dialog-button')[0] as HTMLElement;
          yesButton?.focus();
        },
        onInitialized: (event) => {
          event.component.registerKeyHandler('escape', () => {
            event.component.hide();
            const rowIndex = this.dataGridComponent.instance.getRowIndexByKey(cellInfo.key);
            const cellElement = this.dataGridComponent.instance.getCellElement(rowIndex, cellInfo.columnIndex);
            this.dataGridComponent.instance.focus(cellElement);
          });
        }
      }
    }
    this.utils.openDxCustomDialog(customDialogConfigs)
      .then(async (dialogResult) => {
        if (dialogResult) {
          const id = cellInfo.data[this.dataSourceConfig.dataSourcePrimaryKey];
          this.lastMethod = 'delete';

          try {
            await this.dataSourceConfig.dataSourceService[this.dataSourceConfig.dataSourceServiceDeleteFn](id);
            if (this.dataGridConfig.onRowRemoved) { this.dataGridConfig.onRowRemoved(cellInfo); }
            await this.dataGridComponent?.instance.refresh();
            this.lastSelectedRowIndex = 0;
            this.lastSelectedColumnIndex = this.getFirstEditorIndex(this.dataGridComponent.instance.getVisibleColumns());
            this.navigateToCell(this.lastSelectedRowIndex, this.lastSelectedColumnIndex, false);
            return;
          } catch (error) {
            this.showDataSourceCrudError(error, 'delete');
          }
        }
        const rowIndex = this.dataGridComponent.instance.getRowIndexByKey(cellInfo.key);
        const cellElement = this.dataGridComponent.instance.getCellElement(rowIndex, cellInfo.columnIndex);
        this.dataGridComponent.instance.focus(cellElement);
      });
  }

  public async undo(e: Event, cellInfo): Promise<void> {
    const changes = cellInfo.row?.modifiedValues;
    e.preventDefault();
    this.lastMethod = 'undo';
    cellInfo.component.cancelEditData();
    await this.dataGridComponent.instance.refresh();
    if (this.lastSelectedRowIndex === 0) {
      this.lastSelectedColumnIndex = this.getFirstEditorIndex(cellInfo.component.getVisibleColumns());
      this.navigateToCell(this.lastSelectedRowIndex, this.lastSelectedColumnIndex, false);
      return;
    }

    const modifiedCellIndexes = this.getNonNullIndexes(changes);
    modifiedCellIndexes.forEach((index) => {
      if (cellInfo.row.cells[index].column.allowEditing) {
        this.lastSelectedColumnIndex = index;
      }
    });
    this.navigateToCell(this.lastSelectedRowIndex, this.lastSelectedColumnIndex, false);
  }

  public getNonNullIndexes(changes): number[] {
    if (!changes) { return []; }
    const nonNullIndexes = [];
    for (let i = 0; i < changes.length; i++) {
        if (changes[i] !== null && changes[i] !== undefined) {
            nonNullIndexes.push(i);
        }
    }
    return nonNullIndexes;
  }

  public navigateToCell(rowIndex: number, columnIndex: number, editState = true): void {
    if (this.dataGridComponent.instance.getVisibleRows().length === 0) { return; }
    this.dataGridComponent.instance.editCell(rowIndex, columnIndex);
    const cellElement = this.dataGridComponent.instance.getCellElement(rowIndex, columnIndex);
    this.dataGridComponent.instance.focus(cellElement);
    if (!editState || !this.dataGridConfig.editing.allowUpdating) {
       this.dataGridComponent.instance.closeEditCell();
    }
  }

  // get the index of the first editable column
  public getFirstEditorIndex(columns: Column[]): number {
    return columns.findIndex((column: Column) => column.allowEditing);
  }

  // get the index of the last editable column
  public getLastEditorIndex(columns: Column[]): number {
    return columns.filter((column: Column) => column.allowEditing).pop()?.visibleIndex;
  }

  // get the index of the action column
  public getActionColumnIndex(columns: Column[]): number {
    return columns.findIndex((column) => column.type === 'buttons');
  }

  public onKeyDown(event, cellInfo): void {
    // capture the event when tabbing through the action column into the next row
    if (event.key === 'Tab' && !event.shiftKey) {
      // check if the current row is the entry row and it has inputed data, and the current grid has no other row
      const rows = this.dataGridComponent.instance.getVisibleRows();
      if (cellInfo.rowIndex === 0 && rows.length === 1 && rows[0].isNewRow) {
        if (rows[0].values.filter((value) => value !== null && value !== undefined).length > 0) {
          this.lastMethod = 'insert';
          cellInfo.component.saveEditData();
        }
        return;
      }

      // check if the current row is the last row and it has been modified
      if (cellInfo.rowIndex === rows.length - 1 && cellInfo.row.modified) {
        this.lastMethod = 'update';
        cellInfo.component.saveEditData();
      }
    }
  }

  // eslint-disable-next-line complexity
  private setupGenericDataGrid(): void {
    /*
     * Appearance related
     */
    this.dataGridConfig.showBorders = this.dataGridConfig.hasOwnProperty('showBorders') ? this.dataGridConfig.showBorders : true;
    this.dataGridConfig.showColumnLines = this.dataGridConfig.hasOwnProperty('showColumnLines') ? this.dataGridConfig.showColumnLines : true;
    this.dataGridConfig.showRowLines = this.dataGridConfig.hasOwnProperty('showRowLines') ? this.dataGridConfig.showRowLines : true;
    this.dataGridConfig.rowAlternationEnabled = this.dataGridConfig.hasOwnProperty('rowAlternationEnabled') ? this.dataGridConfig.rowAlternationEnabled : true;
    this.dataGridConfig.repaintChangesOnly = this.dataGridConfig.hasOwnProperty('repaintChangesOnly') ? this.dataGridConfig.repaintChangesOnly : true;
    this.dataGridConfig.errorRowEnabled = this.dataGridConfig.hasOwnProperty('errorRowEnabled') ? this.dataGridConfig.errorRowEnabled : false;

    /*
     * Columns related
     */
    this.dataGridConfig.columns = this.dataGridConfig.hasOwnProperty('columns') ? this.dataGridConfig.columns : [];
    this.dataGridConfig.allowColumnReordering = this.dataGridConfig.hasOwnProperty('allowColumnReordering') ? this.dataGridConfig.allowColumnReordering : true;
    this.dataGridConfig.allowColumnResizing = this.dataGridConfig.hasOwnProperty('allowColumnResizing') ? this.dataGridConfig.allowColumnResizing : true;
    this.dataGridConfig.columnResizingMode = this.dataGridConfig.hasOwnProperty('columnResizingMode') ? this.dataGridConfig.columnResizingMode : 'widget';
    this.dataGridConfig.columnHidingEnabled = this.dataGridConfig.hasOwnProperty('columnHidingEnabled') ? this.dataGridConfig.columnHidingEnabled : false;
    this.dataGridConfig.syncLookupFilterValues = this.dataGridConfig.hasOwnProperty('syncLookupFilterValues') ? this.dataGridConfig.syncLookupFilterValues : false;
    this.dataGridConfig.columnChooser = {
      enabled: true,
      mode: 'select',
      ...this.dataGridConfig.columnChooser // overwritten by implementation
    };
    this.dataGridConfig.columnFixing = {
      enabled: true,
      ...this.dataGridConfig.columnFixing
    };

    /*
     * this.dataGridConfig.onSelectionChanged
     */
    if (this.dataGridConfig.hasOwnProperty('onSelectionChanged')) {
      const customOnSelectionChanged = this.dataGridConfig.onSelectionChanged;
      this.dataGridConfig.onSelectionChanged = (e) => {
        this.defaultOnSelectionChanged(e);
        customOnSelectionChanged(e);
      };
    }

    /*
     * this.dataGridConfig.onContextMenuPreparing
     */
    this.dataGridConfig.onContextMenuPreparing = this.dataGridConfig.hasOwnProperty('onContextMenuPreparing') ? this.dataGridConfig.onContextMenuPreparing : (e) => { };

    /*
     * this.dataGridConfig.onEditorPreparing
     */
    const customOnEditorPreparing = this.dataGridConfig.onEditorPreparing;
    this.dataGridConfig.onEditorPreparing = (e: IExtendedEditorPreparingEvent) => {
      if (customOnEditorPreparing) { customOnEditorPreparing(e); }
      if (e.editorName === 'dxNumberBox') { e.editorOptions.step = 0; }
      if (e.parentType === 'dataRow' && e.allowEditing) {

        // navigation using arrow up and down when in editing state
        // only works for dxNumberBox and dxTextBox for now
        if (['dxNumberBox', 'dxTextBox'].includes(e.editorName)) {
          e.editorOptions.onKeyDown = (arg) => {
            if (arg.event.key === 'Enter') {
              if (!e.saveOnEnterKey) { return; }
              setTimeout(() => {
                if (this.dataGridComponent.instance.hasEditData()) {
                  this.dataGridComponent.instance.saveEditData();
                  return;
                }

                // pressing Enter Key without any changes will still move to the next row
                const currIndex: number = e.row.rowIndex;
                if (!this.dataGridConfig.moveToNextRowAfterEnterKey) { return; }
                if (this.lastSelectedRowIndex !== currIndex) { return; }
                if (currIndex === this.dataGridComponent.instance.getVisibleRows().length - 1) { return; }
                this.lastSelectedRowIndex = currIndex + 1;
                this.navigateToCell(this.lastSelectedRowIndex, this.lastSelectedColumnIndex);

              }, 10);
            }
            if (arg.event.key === 'ArrowUp' || arg.event.key === 'ArrowDown') {
              this.checkValueChange(e.editorOptions.value, arg.event.currentTarget.value, e.dataType, e.component, e.editorOptions.onValueChanged);
            }
            if (arg.event.key === 'Tab' && !arg.event.shiftKey) {
              const lastIndex = this.getLastEditorIndex(e.component.getVisibleColumns());
              if (lastIndex !== e.visibleIndex) { return; }
              this.checkValueChange(e.editorOptions.value, arg.event.currentTarget.value, e.dataType, e.component, e.editorOptions.onValueChanged);
              const actionColumnIndex = this.getActionColumnIndex(e.component.getVisibleColumns());
              const actionColumn = this.dataGridComponent.instance.getCellElement(this.lastSelectedRowIndex, actionColumnIndex);
              e.component.focus(actionColumn);
              arg.event.preventDefault();
              arg.event.stopImmediatePropagation();
            }
          };
        }

        // auto open dropdown when in editing state
        if (['dxSelectBox'].includes(e.editorName)) {
          e.editorOptions.onFocusIn = (args) => {
            if (args.component.isOpen) { return; }
            args.component.open();
            args.component.isOpen = true;
          };

          e.editorOptions.onFocusOut = (args) => {
            if (!args.component.isOpen) { return; }
            args.component.close();
            args.component.isOpen = false;
          };

          e.editorOptions.onKeyDown = (arg) => {
            if (arg.event.key === 'Tab' && !arg.event.shiftKey) {
              const lastIndex = this.getLastEditorIndex(e.component.getVisibleColumns());
              if (lastIndex !== e.visibleIndex) { return; }
              e.component.closeEditCell();
              const actionColumnIndex = this.getActionColumnIndex(e.component.getVisibleColumns());
              const actionColumn = this.dataGridComponent.instance.getCellElement(this.lastSelectedRowIndex, actionColumnIndex);
              e.component.focus(actionColumn);
              arg.event.preventDefault();
              arg.event.stopImmediatePropagation();
            }
            if (arg.event.key === 'Enter') {
              if (!e.saveOnEnterKey) { return; }
              setTimeout(() => {
                this.dataGridComponent.instance.saveEditData();
              }, 10);
            }
          };
        }
      }

    };
    this.dataGridConfig.onEditorPrepared = this.dataGridConfig.hasOwnProperty('onEditorPrepared') ? this.dataGridConfig.onEditorPrepared : (e) => { };

    /*
     * this.dataGridConfig.onSaving
     */
    this.customOnSaving = this.dataGridConfig.onSaving;
    this.dataGridConfig.onSaving = this.defaultOnSaving.bind(this);
    this.dataGridConfig.onRowPrepared = this.dataGridConfig.hasOwnProperty('onRowPrepared') ? this.dataGridConfig.onRowPrepared : (e) => { };
    this.customOnContentReady = this.dataGridConfig?.onContentReady;
    this.dataGridConfig.onContentReady = this.defaultOnContentReady.bind(this);

    /*
     * this.dataGridConfig.onRowValidating
     */
    const customOnRowValidating = this.dataGridConfig.onRowValidating;
    this.dataGridConfig.onRowValidating = (e) => {
      if (customOnRowValidating) { customOnRowValidating(e); }
      const rowIndex = this.dataGridComponent.instance.getRowIndexByKey(e.key);
      const rows: IExtendedRow[] = this.dataGridComponent.instance.getVisibleRows();
      rows[rowIndex].isValid = e.isValid;
      if (this.dataGridConfig.editing.allowAdding) {
        const editingRows = this.dataGridComponent.instance.getVisibleRows().filter((row: Row & { modified: boolean }) => row.isNewRow || row.modified);
        if (rowIndex === 0 && editingRows.length > 1) {
          // ignore entry row
          e.isValid = true;
          this.dataGridComponent.instance.repaintRows([rowIndex]);
          return;
        }
      }
      if (e.isValid) { return; }
      if (e.brokenRules.length === 0) { return; }
      const visibleColumnIndex = (e.brokenRules[0] as unknown as ValidationCallbackData).column.visibleIndex;
      const columnIndex = rows[rowIndex].cells.findIndex((cell) => cell.column.visibleIndex === visibleColumnIndex);
      setTimeout(() => {
        this.lastSelectedRowIndex = rowIndex;
        this.lastSelectedColumnIndex = columnIndex;
        this.navigateToCell(rowIndex, columnIndex);
      }, 0);
    };

    /*
     * this.dataGridConfig.onInitNewRow
     */
    this.dataGridConfig.onInitNewRow = this.dataGridConfig.hasOwnProperty('onInitNewRow') ? this.dataGridConfig.onInitNewRow : (e) => { };

    /*
     * this.dataGridConfig.onEditingStart
     */
    this.dataGridConfig.onEditingStart = this.dataGridConfig.hasOwnProperty('onEditingStart') ? this.dataGridConfig.onEditingStart : (e) => { };

    /*
    /*
     * this.dataGridConfig.onCellPrepared
     */
    this.dataGridConfig.onCellPrepared = this.dataGridConfig.hasOwnProperty('onCellPrepared') ? this.dataGridConfig.onCellPrepared : (e) => { };

    /*
    /*
     * this.dataGridConfig.onCellDblClick
     */
    this.dataGridConfig.onCellDblClick = this.dataGridConfig.hasOwnProperty('onCellDblClick') ? this.dataGridConfig.onCellDblClick : (e) => { };

    /*
     * this.dataGridConfig.onRowRemoved
     */
    this.dataGridConfig.onRowRemoved = this.dataGridConfig.hasOwnProperty('onRowRemoved') ? this.dataGridConfig.onRowRemoved : (e) => { };

    /*
     * this.dataGridConfig.onToolbarPreparing
     */
    if (this.dataGridConfig.hasOwnProperty('onToolbarPreparing')) {
      const customOnToolbarPreparing = this.dataGridConfig.onToolbarPreparing;
      this.dataGridConfig.onToolbarPreparing = (e) => {
        this.defaultOnToolBarPreparing(e);
        customOnToolbarPreparing(e);
      };
    } else {
      this.dataGridConfig.onToolbarPreparing = (e) => this.defaultOnToolBarPreparing(e);
    }
    /*
     * scrolling
     */
    this.dataGridConfig.scrolling = {
      columnRenderingMode: 'standard' // horizontal scrolling,
    };

    /*
     * renderAsync - Specifies whether to render rows after a user stops scrolling or at the same time
     * as the user scrolls the datagrid.
     */
    this.dataGridConfig.renderAsync = true;

    /*
     * selection
     */
    this.dataGridConfig.selection = {
      mode: 'none',
      ...this.dataGridConfig.selection
    };

    /*
     * pagination
     */
    this.dataGridConfig.pager = {
      visible: true,
      displayMode: 'adaptive',
      showInfo: true,
      showPageSizeSelector: true,
      allowedPageSizes: GridConstants.ALLOWED_PAGE_SIZES,
      ...this.dataGridConfig.pager
    };
    this.dataGridConfig.paging = {
      pageSize: 25,
      ...this.dataGridConfig.paging
    };

    /*
     * sorting
     */
    this.dataGridConfig.sorting = {
      mode: 'multiple',
      ...this.dataGridConfig.sorting
    };

    /*
     * editing
     */
    this.dataGridConfig.editing = {
      mode: 'batch',
      allowUpdating: false,
      allowDeleting: false,
      allowAdding: false,
      newRowPosition: 'first',
      ...this.dataGridConfig.editing
    };

    /*
     * export
     */
    this.dataGridConfig.export = {
      enabled: false,
      allowExportSelectedData: false,
      ...this.dataGridConfig.export
    };

    /*
     * search & filtering
     */
    this.dataGridConfig.searchPanel = {
      visible: true,
      placeholder: 'Search...',
      searchVisibleColumnsOnly: true,
      ...this.dataGridConfig.searchPanel
    };
    this.dataGridConfig.filterPanel = {
      visible: true,
      ...this.dataGridConfig.filterPanel
    };
    this.dataGridConfig.headerFilter = {
      visible: true,
      ...this.dataGridConfig.headerFilter
    };
    this.dataGridConfig.filterRow = {
      visible: false,
      ...this.dataGridConfig.filterRow
    };

    /*
     * grouping & summary
     */
    this.dataGridConfig.groupPanel = {
      visible: false,
      allowColumnDragging: true,
      emptyPanelText: '',
      ...this.dataGridConfig.groupPanel
    };
    this.dataGridConfig.grouping = {
      contextMenuEnabled: false,
      ...this.dataGridConfig.grouping
    };
    this.dataGridConfig.summary = {
      // WARNING! Avoid setting recalculateWhileEditing to true as it is breaking async validation on Enter key press!
      groupItems: [],
      totalItems: [],
      ...this.dataGridConfig.summary
    };

    /*
     * state storing
     */
    this.dataGridConfig.stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: this.dataSourceConfig.dataSourceName + 'GridSettings',
      ignoreColumnOptionNames: [],
      ...this.dataGridConfig.stateStoring
    } as IExtendedStateStoring;

    /*
     * Action Column
     */
    if (this.dataGridActionColumn.enabled) {
      this.dataGridConfig.columns.push({
        type: 'buttons', // this will overwrite the default command column e.g. Edit btn, Delete Btn
        cellTemplate: 'actionColumnTemplate',
        showInColumnChooser: false,
        minWidth: 90
      });

      if (!this.dataGridActionColumn.hasOwnProperty('onSaveClick')) { // generate default function if not specified
        this.dataGridActionColumn.onSaveClick = () => {
          this.dataGridComponent?.instance.saveEditData();
          return false;
        };
      }
    }

    const customOnFocusedCellChanging = this.dataGridConfig.onFocusedCellChanging;
    this.dataGridConfig.onFocusedCellChanging = (e) => {
      if (customOnFocusedCellChanging) { customOnFocusedCellChanging(e); }
      if (!this.dataGridConfig.editing.allowUpdating) { return; }
      if (this.lastSelectedRowIndex === e.newRowIndex) { return; }
      const prevRowIndex = this.lastSelectedRowIndex;
      setTimeout(() => {
        const prevRow: IExtendedRow = e.rows[prevRowIndex];
        // ignore entry row if no data is entered
        if (this.dataGridConfig.editing.allowAdding && prevRowIndex === 0 && prevRow.values.every((value) => value === null || value === undefined)) { return; }
        if (!prevRow?.modified && !prevRow?.isNewRow) { return; }
        e.component.saveEditData();
       }, 0);
    };

    this.dataGridConfig.onFocusedCellChanged = (e) => {
      if (e.column.allowEditing || e.column.type === 'buttons') {
        this.lastSelectedRowIndex = e.rowIndex;
        this.lastSelectedColumnIndex = e.columnIndex;
      }
      if (e.column.type === 'buttons' && !e.row.isNewRow) {
        setTimeout(() => {
          const firstButton = e.cellElement[0].children[0].children[0] as HTMLAnchorElement;
          firstButton?.focus();
        }, 0);
      }
    };

    this.dataGridConfig.onKeyDown = (e) => {
      if (this.isSaving) {
        e.event.preventDefault();
        return;
      }
      if (e.event?.key !== 'Escape') { return; }
      const row: IExtendedRow = this.dataGridComponent.instance.getVisibleRows()[this.lastSelectedRowIndex];
      if (!row.isNewRow && !row.modified) { return; }
      this.undo(e.event, { ...e, row });
    };

    this.dataGridConfig.keyboardNavigation = {
      enabled: true,
      editOnKeyPress: true,
      enterKeyAction: 'startEdit'
    };

    this.dataGridConfig.onReset = () => {
      this.lastSelectedColumnIndex = this.getFirstEditorIndex(this.dataGridComponent.instance.getVisibleColumns());
      this.lastSelectedRowIndex = 0;
      if (this.dataGridComponent.editing.allowAdding) { return; }
      this.initEntryForm = false;
    };

    this.dataGridConfig.onSaved = (e) => {
      if (!this.dataGridConfig.moveToNextRowAfterEnterKey) { return; }
      const prevIndex = this.dataGridComponent.instance.getRowIndexByKey(e.changes[0].key);
      if (prevIndex === this.dataGridComponent.instance.getVisibleRows().length - 1) { return; }
      if (this.lastSelectedRowIndex === prevIndex) {
        this.lastSelectedRowIndex = prevIndex + 1;
      }
      this.navigateToCell(this.lastSelectedRowIndex, this.lastSelectedColumnIndex);
    };

    this.dataGridConfig.onOptionChanged = (e) => {
      // check if page number is changed
      if (e.fullName === 'paging.pageIndex') {
        this.lastSelectedRowIndex = 0;
        this.initEntryForm = false;
        return;
      }

      if (this.dataGridComponent.editing.allowAdding) { return; }
      // onFocusedCellChanging and onFocusedCellChanged is not working properly when adding is disabled
      // the following check below are like a fallback for these events
      if (e.fullName === 'editing.editRowKey' && e.value) {
        const index = this.dataGridComponent.instance.getRowIndexByKey(e.value);
        this.lastSelectedRowIndex = index;
        if (this.dataGridComponent.instance.hasEditData() && e.previousValue !== null) {
          this.dataGridComponent.instance.saveEditData();
        }
        return;
      }

      if (e.fullName === 'focusedRowIndex' && e.value) {
        this.lastSelectedRowIndex = e.value;
        if (this.dataGridComponent.instance.hasEditData() && e.previousValue !== null) {
          this.dataGridComponent.instance.saveEditData();
        }
        return;
      }

      if (e.fullName === 'editing.editColumnName' && e.value) {
        const columns = this.dataGridComponent.instance.getVisibleColumns();
        const index = columns.findIndex((column) => column.dataField === e.value);
        this.lastSelectedColumnIndex = index;
        return;
      }
    };
  }

  private setupGenericLookupDataSource(): void {
    Object.keys(this.joinTableColumnConfig).forEach((joinTableColumn) => {
      const thisColumn = this.joinTableColumnConfig[joinTableColumn];
      Object.assign(thisColumn.joinTableDataSource, {
        store: new CustomStore({
          key: thisColumn.joinTablePrimaryKey,
          loadMode: thisColumn.joinTableDataSourceLoadMode || 'raw',
          load: async (loadOptions: LoadOptions) => {
            try {
              const view = thisColumn.joinTableDataSourceServiceSearchFnView;
              const routeId = thisColumn.joinTableDataSourceServiceSearchFnRouteId;
              if (loadOptions.searchExpr && loadOptions.searchValue && loadOptions.searchOperation === 'contains') {
                this.processLookupSearch(this.dataGridConfig.columns, thisColumn.joinTableDataSource, loadOptions);
              }
              if (loadOptions.searchExpr && loadOptions.sort) {
                this.processLookupSort(this.dataGridConfig.columns, thisColumn.joinTableDataSource, loadOptions);
              }
              return await thisColumn.joinTableDataSourceService[thisColumn.joinTableDataSourceServiceSearchFn](loadOptions, view, routeId);
            } catch (error) {
              this.showJoinTableDataSourceCrudError(error, 'load', thisColumn.joinModelName);
            }
          },
          byKey: async (key) => {
            try {
              if (!key) { return null; }
              const view = thisColumn.joinTableDataSourceServiceGetFnView;
              return await thisColumn.joinTableDataSourceService[thisColumn.joinTableDataSourceServiceGetFn](key, view);
            } catch (error) {
              this.showJoinTableDataSourceCrudError(error, 'load', thisColumn.joinModelName);
            }
          }
        }),
        sort: thisColumn.joinTableDataSortBy,
        pageSize: 10,
        paginate: true
      });
    });
  }

  private showJoinTableDataSourceCrudError(error: ErrorEvent, operation: 'load' | 'insert' | 'update' | 'delete', joinModelName: string): void {
    const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
    this.utils.openSnackbar('Failed to ' + operation + ' record from ' + joinModelName + ' . ' + instruction, 'OK', 10000);
  }

  private setupGenericDataGridDataSource(): void {
    this.dataGridConfig.dataSource = new CustomStore(
      {
        key: this.dataSourceConfig.dataSourcePrimaryKey,
        loadMode: this.dataSourceConfig.dataSourceLoadMode || 'raw',
        load: async (loadOptions: LoadOptions) => {
          const view = this.dataSourceConfig.dataSourceServiceSearchFnView;
          const routeId = this.dataSourceConfig.dataSourceServiceSearchFnRouteId;
          const customFilter = this.dataSourceConfig.dataSourceServiceSearchFnArgs ?? undefined;
          loadOptions.take = loadOptions.take || this.dataGridComponent.paging.pageSize;
          try {
            return await this.dataSourceConfig.dataSourceService[this.dataSourceConfig.dataSourceServiceSearchFn](loadOptions, view, routeId, customFilter);
          } catch (error) {
            this.showDataSourceCrudError(error, 'load');
            return {
              data: [],
              totalCount: 0,
              summary: null,
              groupCount: null
            };
          }
        },
        insert: async (values) => {
          try {
            if (this.dataSourceConfig.dataSourceServiceInsertFn) {
              await this.dataSourceConfig.dataSourceService[this.dataSourceConfig.dataSourceServiceInsertFn](values);
            }
          } catch (error) {
            this.showDataSourceCrudError(error, 'insert');
            return Promise.reject();
          }
        },
        update: async (key, values) => {
          try {
            if (this.dataSourceConfig.dataSourceServiceUpdateFn) {
              await this.dataSourceConfig.dataSourceService[this.dataSourceConfig.dataSourceServiceUpdateFn](key, values);
            }
          } catch (error) {
            this.showDataSourceCrudError(error, 'update');
            return Promise.reject();
          }
        },
        remove: async (key) => {
          try {
            if (this.dataSourceConfig.dataSourceServiceDeleteFn) {
              await this.dataSourceConfig.dataSourceService[this.dataSourceConfig.dataSourceServiceDeleteFn](key);
            }
          } catch (error) {
            this.showDataSourceCrudError(error, 'delete');
          }
        },
        onLoading: (loadOptions: LoadOptions) => {
          try {
            if (this.dataGridConfig.hasOwnProperty('onLoading')) {
              this.dataGridConfig.onLoading(loadOptions);
            }
          } catch (error) {
            const instruction = ErrorMessages.STANDARD_ERROR_MESSAGE;
            this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} on loading ` +
              this.dataSourceConfig.dataSourceName + ' data grid. ' + instruction, 'OK', 10000);
          }
        },
        onLoaded: (result) => {
          try {
            if (this.dataGridConfig.hasOwnProperty('onLoaded')) {
              this.dataGridConfig.onLoaded(result as unknown as ICustomStoreLoadResult);
            }
          } catch (error) {
            const instruction = ErrorMessages.STANDARD_ERROR_MESSAGE;
            this.utils.openSnackbar('An error occurred after loading ' + this.dataSourceConfig.dataSourceName + ' data grid. ' + instruction, 'OK', 10000);
          }
        }
      }
    );
  }

  private showDataSourceCrudError(error: ErrorEvent, operation: 'load' | 'insert' | 'update' | 'delete'): void {
    const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
    this.utils.openSnackbar('Failed to ' + operation + ' record from ' + this.dataSourceConfig.dataSourceName + ' data grid. ' + instruction, 'OK', 10000);
  }

  private processLookupSearch(columns: (Column | string | IDataGridColumnConfig)[], targetLookUpDataSource: ILookupDataSourceConfig | string, loadOptions: LoadOptions): void {
    columns.forEach((column) => {
      if ((column as Column).columns) { // multi level header
        this.processLookupSearch((column as Column).columns, targetLookUpDataSource, loadOptions);
      } else {
        // if this column has lookup and the lookup is the one that being search
        // check if this lookup is a number type (by checking if _castToIntWhenSearch is set to true )
        if ((column as Column).lookup?.dataSource === targetLookUpDataSource && (column as Column).lookup?.displayExpr === loadOptions.searchExpr &&
          (column as IDataGridColumnConfig)._castToIntWhenSearch) {
          loadOptions.searchValue = Number(loadOptions.searchValue);
          loadOptions.searchOperation = '=';
        }
      }
    });
  }

  private processLookupSort(columns: (Column | string)[], targetLookUpDataSource: ILookupDataSourceConfig | string, loadOptions: LoadOptions): void {
    columns.forEach((column) => {
      if ((column as Column).columns) { // multi level header
        this.processLookupSort((column as Column).columns, targetLookUpDataSource, loadOptions);
      } else {
        if ((column as Column).lookup?.dataSource === targetLookUpDataSource && (column as Column).lookup?.displayExpr === loadOptions.searchExpr) {
          loadOptions.sort = (column as Column).lookup.displayExpr;
        }
      }
    });
  }

  private defaultOnSelectionChanged(e): void {
    if (this.dataGridConfig.selection?.mode === 'single') { // for single selection
      const selectedRowKeys = e.component.getSelectedRowKeys();
      if (selectedRowKeys.length > 0) {
        this.selectedRowKey = selectedRowKeys[0]; // select first by default
      }
    }
  }

  private defaultOnToolBarPreparing(e): void {
    const toolbarItems = e.toolbarOptions.items;
    const customItems = [];
    customItems.push({
      locateInMenu: 'auto',
      location: 'after',
      showText: 'inMenu',
      template: 'refreshTemplate'
    });
    if (this.dataGridConfig?.stateStoring?.enabled) {
      customItems.push({
        locateInMenu: 'auto',
        location: 'after',
        showText: 'always',
        widget: 'dxButton',
        cssClass: 'reset-grid-btn',
        options: {
          text: 'Reset Grid',
          hint: 'Reset Grid',
          icon: 'undo',
          onClick: () => this.clearStates()
        }
      });
    }
    const searchPanelIndex = toolbarItems.findIndex((item) => item.name === 'searchPanel');
    if (searchPanelIndex > -1) {
      toolbarItems.splice(searchPanelIndex, 0, ...customItems);
    } else {
      toolbarItems.push(...customItems);
    }

    const addRowButtonIndex = toolbarItems.findIndex((item) => item.name === 'addRowButton');
    if (addRowButtonIndex > -1) {
      // remove add button
      toolbarItems.splice(addRowButtonIndex, 1);
    }
    const saveButtonIndex = toolbarItems.findIndex((item) => item.name === 'saveButton');
    if (saveButtonIndex > -1) {
      // remove save button
      toolbarItems.splice(saveButtonIndex, 1);
    }
    const revertButtonIndex = toolbarItems.findIndex((item) => item.name === 'revertButton');
    if (revertButtonIndex > -1) {
      // remove revert button
      toolbarItems.splice(revertButtonIndex, 1);
    }
  }

  private async checkValueChange(oldValue, currentValue, dataType: string, component, valueChangeEvent = null): Promise<void> {
    const newValue = dataType === 'number' ? currentValue === '' ? null : Number(currentValue) : currentValue;
    component.closeEditCell();
    if (newValue === oldValue) { return; }
    component.cellValue(this.lastSelectedRowIndex, this.lastSelectedColumnIndex, newValue);
    if (valueChangeEvent) { await valueChangeEvent({ value: newValue }); }
  }

  private setupListeningForEvents(): void {
    const config = this.dataGridReceiveEventsConfig;
    if (!config) { return; }

    this.subscriptions.push(
      config.eventsService.event$.subscribe((event: IEventMessage) => {
        try {
          const [app, tenantType, id, objectName, environment] = event.topic.split('_');
          if (event.isMe) { return; }
          if (objectName !== config.objectName) { return; }
          if (event.tennantType !== config.tenantType) { return; }
          if (tenantType === 'venue' && config.venueId && id !== config.venueId) { return; }
          if (tenantType === 'organisation' && config.organisationId && id !== config.organisationId) { return; }
          if (this.dataGridComponent.instance.pageCount() > 1 && ['insert', 'bulkInsert', 'delete'].includes(event.operation)) { return; }
          if (config.customEventHandler) {
            const result = config.customEventHandler(event);
            this.gridUpdateRequired = result.gridUpdateRequired;
            this.refreshRequiredTooltip = result.tooltipMessage || '';
          }
          this.checkEventCriteria(event.objectData, config.eventCriteria);
        } catch (err) {
          logger.error('An unexpected error occurred while receiving events from ' + this.dataSourceConfig.dataSourceName + ' data grid. Err: ' + err);
        }
      })
    );
  }

  private checkEventCriteria(data, criteria: object): void {
    if (this.isMatchingReference(data, criteria)) {
      this.gridUpdateRequired = true;
      this.refreshRequiredTooltip ||= 'Refresh required';
    }
  }

  private isMatchingReference(data, criteria: object): boolean {
    return Object.keys(criteria).every((key) => {
      const dataValue = data[key];
      const criteriaValue = criteria[key];
      return typeof dataValue === 'object' && typeof criteriaValue === 'object'
        ? this.isMatchingReference(dataValue, criteriaValue)
        : dataValue === criteriaValue;
    });
  }
}
