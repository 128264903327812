/* eslint-disable max-classes-per-file */
import { Breakpoints } from '@angular/cdk/layout';
import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import { Column } from 'devextreme/ui/data_grid';
import { Properties as dxLookupOptions } from 'devextreme/ui/lookup';
import { AsyncRule, CustomRule, EmailRule, PatternRule, RangeRule, RequiredRule, StringLengthRule } from 'devextreme/ui/validation_rules';
import { format } from 'devextreme/ui/widget/ui.widget';
import * as moment from 'moment';
import { ILookupDataSourceSetupReturnObj, IValidationCallbackOptionsData } from './types/interfaces/GeneralService';
import { DataSourceFilter } from './types/GeneralTypes';

export interface ILookupDataSourceConfig<T={}> {
    [dataFieldName: string]: {
        dataSourceLoadMode?: 'raw' | 'processed';
        dataSourceService: T;
        dataSourceServiceSearchFnView?: string;
        dataSourceServiceSearchFnRouteId?: number;
        dataSourceServiceSearchFn: string;
        joinTableDataSourceServiceGetFnView?: string;
        dataSourceServiceGetFn: string;
        dataSourceFilter?: DataSourceFilter | DataSourceFilter[] | string[];
        primaryKey?: string;
        dataSortBy?: string;
        _castToIntWhenSearch?: boolean;
    };
}

export const REGEXP = {
    INTEGER: /^[+-]?\b[0-9]+\b$/, // any integer decimal number with an optional leading plus or minus sign,
    GREATER_THAN_ZERO: /[0-9]*\.?[0-9]*[1-9]/,
    IS_VALID_URL:
    '^((?:(?:https):\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
    '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
    '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|' +
    '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
    '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?' +
    '(?:[/?#]\\S*)?|http:\\/\\/localhost(:[\\d]+)?(\\/[a-zA-Z./0-9_]*)?)$',
    WEBSITE: '^((?:(?:https?:\/\/)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z]{2,}))\/?)$',
    PHONE: /^[+]?\b[0-9]+$/
};

export enum ListDetailTabIndex { 'List', 'Detail' }

export class ValidationFn {
    public static getRequiredRule(message = 'Required'): RequiredRule { return { type: 'required', trim: true, message }; }
    public static getSmallIntRule(minValue = -32768): [PatternRule, RangeRule] {
        return [
            { type: 'pattern', pattern: REGEXP.INTEGER, message: 'Has to be an integer' },
            { type: 'range', min: minValue, max: 32767, message: `Has to be an integer between ${minValue} to 32767` }
        ];
    }

    public static getMaxLengthRule(length: number): StringLengthRule { return { type: 'stringLength', trim: false, max: length, message: 'Max length is ' + length.toString() }; }

    public static getMinLengthRule(length: number): StringLengthRule { return { type: 'stringLength', trim: false, min: length, message: 'Min length is ' + length.toString() }; }
    public static getIntegerRule(): [PatternRule, RangeRule] {
        return [
            { type: 'pattern', pattern: REGEXP.INTEGER, message: 'Has to be an integer' },
            { type: 'range', min: -2147483648, max: 2147483647, message: 'Has to be an integer between -2147483648 to 2147483647' }
        ];
    }
    public static getMinRule(min: number): RangeRule {
        return { type: 'range', min, message: 'Must be greater or equal to ' + min.toString() };
    }
    public static getMaxRule(max: number): RangeRule {
        return { type: 'range', max, message: 'Must be less than or equal to ' + max.toString() };
    }
    public static getDecimalNumRule(precision: number, scale: number, allowNegative = false, customScale: number = null): [PatternRule] | [RangeRule, PatternRule] {
        const digitsBefore = precision - scale;
        const digitsAfter = customScale ?? scale;
        const rules: (PatternRule | RangeRule)[] = [{
            type: 'pattern',
            pattern: new RegExp('^' + `${allowNegative ? '-?' : ''}` + '([0-9]{0,' + digitsBefore?.toString() + '})(\\.[0-9]{0,' + digitsAfter?.toString() + '})?$'),
            message: 'Up to ' + digitsBefore?.toString() + ' digits before decimal point and up to ' + digitsAfter?.toString() + ' decimal places'
        }];
        if (!allowNegative) {
            rules.unshift({ type: 'range', min: 0, message: 'Has to be a positive number' } as RangeRule);
        }
        return rules as [PatternRule] | [RangeRule, PatternRule];
    }
    public static getAsyncValidation<T>(message: string, validationCallback:
        ((options: { value?: string | number, rule?: string, validator?: object, data?: IValidationCallbackOptionsData, column?: string, formItem?: string }) =>
        PromiseLike<T>)): AsyncRule {
        return { type: 'async', reevaluate: false, message, validationCallback };
    }
    public static getPositiveIntegerRule(smallInt = false, nonZero = false): [PatternRule, RangeRule] {
        return [
            { type: 'pattern', pattern: REGEXP.INTEGER, message: 'Has to be an integer' },
            { type: 'range', min: nonZero ? 1 : 0, max: smallInt ? 32767 : 2147483647, message: `Has to be an integer ${nonZero ? 'from' : 'between'} 0 to ${smallInt ? '32767' : '2147483647'}` }
        ];
    }
    public static getCustomRule(message: string, validationCallback:
        ((options: { value?: string | number, rule?: string, validator?: object, data?: IValidationCallbackOptionsData, column?: string & Column, formItem?: string }) =>
        boolean)): CustomRule {
        { return { type: 'custom', reevaluate: false, ignoreEmptyValue: true, message, validationCallback }; }
    }
    public static getGreaterThanZeroRule(): PatternRule {
        return { type: 'pattern', pattern: REGEXP.GREATER_THAN_ZERO, message: 'Must be greater than 0' };
    }
    public static getEmailRule(): EmailRule {
        return { type: 'email' };
    }
    public static getPhoneRule(): PatternRule {
       return { type: 'pattern', pattern: REGEXP.PHONE, message: 'Has to be a valid Phone number' };
    }
    public static getWebsiteUrlRule(): PatternRule {
      return { type: 'pattern', pattern: REGEXP.WEBSITE, message: 'Has to be a valid Url' };
   }
}

export interface ICustomFieldTypes {
    fieldName: string;
    fieldType: string;
}

export class FormatFn {
    public static dateTimeShortFormat = 'dd/MM, hh:mm:ss a';
    public static dateTimeFormat = 'dd/MM/yyyy, hh:mm:ss a';
    public static dateTimeWithoutSecondsFormat = 'dd/MM/yyyy, hh:mm a';
    public static dateTimeTWithoutTimeZoneFormat = 'YYYY-MM-DDTHH:mm:ss';
    public static dateFormat = 'dd/MM/yyyy';
    public static currencyFormat: format = { type: 'currency', precision: 2, useCurrencyAccountingStyle: false };
    public static fixedPointFormat: format = { type: 'fixedPoint', precision: 2 };
    public static percentFormat: format = { type: 'percent' };
    public static applyPercentFormat(data): string {
        if (!isNaN(data)) {
            return data as string + '%';
        }
    }
    public static applyDollarSignFormat(data): string {
      if (!isNaN(data)) {
          return data as string + '$';
      }
  }
    public static applyNumberNameFormat(data): string {
        return data && data.number + ': ' + (data.name ?? '');
    }

    public static decimalPointFormat(value, decimalPoint = 2, shouldCast = true): number | string {
        if (value === null || value === undefined || isNaN(value)) { return value; }
        if (!shouldCast) { return value.toFixed(decimalPoint); }
        return Number(value.toFixed(decimalPoint));
    }
    public static cleanRelativeDateTimeFormat(item): string {
        if (!item) { return; }
        return moment(item).format(FormatFn.dateTimeTWithoutTimeZoneFormat);
    }

    public static handleCustomFieldTypes(values, customFieldTypes: ICustomFieldTypes[]): void {
        customFieldTypes.forEach(({ fieldName, fieldType }) => {
            if (fieldType === 'relativeDateTime' && values.hasOwnProperty(fieldName)) {
                values[fieldName] = FormatFn.cleanRelativeDateTimeFormat(values[fieldName]);
            }
        });
    }

    public static formatCurrencyWithDynamicPrecision(value: number): string {
        if (value == null) {
          return '';
        }
        const decimalPart = value.toString().split('.')[1] ? value.toString().split('.')[1] : '';
        const decimalLength = decimalPart.length;
        const formattedValue = (decimalLength === 3 || decimalLength === 4) ? value.toFixed(4) : value.toFixed(2);
        return `$${formattedValue}`;
    }
}

export class FilterFn {
    public static venueAndOrganisationFilterOperations: ('=' | '<>' | '<' | '<=' | '>' | '>=' | 'contains' | 'endswith' | 'isblank' | 'isnotblank' | 'notcontains' | 'startswith' | 'between' | 'anyof' | 'noneof')[] = ['=', '<>', 'isblank', 'isnotblank'];
}

export class CalculateFn {
    public static valueInStock(data): number {
        if (!(data?.qtyInStock && data?.Stock?.innersPerOuter && data?.averageCost)) { return 0; }
        return FormatFn.decimalPointFormat(((data.qtyInStock / data.Stock.innersPerOuter) * data.averageCost)) as number;
    }

    public static avgUnitCost(data): number {
        if (!(data?.averageCost && data?.Stock?.innersPerOuter)) { return 0; }
        return FormatFn.decimalPointFormat((data.averageCost / data.Stock.innersPerOuter), 4) as number;
    }

    public static lastUnitCost(data): number {
        if (!(data?.lastCost && data?.Stock?.innersPerOuter)) { return 0; }
        return FormatFn.decimalPointFormat((data.lastCost / data.Stock.innersPerOuter), 4) as number;
    }

    public static unitCostDiff(data): number {
        const lastUnitCost = CalculateFn.lastUnitCost(data);
        const avgUnitCost = CalculateFn.avgUnitCost(data);
        return (lastUnitCost && avgUnitCost) ? FormatFn.decimalPointFormat((lastUnitCost - avgUnitCost))as number : 0;
    }

    public static minimumValue(data): number {
        if (!(data?.minimum && data?.lastCost)) { return 0; }
        const lastUnitCost = CalculateFn.lastUnitCost(data);
        return FormatFn.decimalPointFormat((data.minimum * lastUnitCost)) as number;
    }

    public static maximumValue(data): number {
        if (!(data?.maximum && data?.lastCost)) { return 0; }
        const lastUnitCost = CalculateFn.lastUnitCost(data);
        return FormatFn.decimalPointFormat((data.maximum * lastUnitCost)) as number;
    }

    public static costDiff(data): number {
        if (!(data?.lastCost && data?.averageCost)) { return 0; }
        return FormatFn.decimalPointFormat((data.lastCost - data.averageCost))as number;
    }

    public static defaultWastageValue(data): number {
        if (!(data?.lastCost && data?.defaultWastage)) { return 0; }
        const lastUnitCost = CalculateFn.lastUnitCost(data);
        return FormatFn.decimalPointFormat((data.defaultWastage * lastUnitCost)) as number;
    }
}

export class DxConfig {
    public static lookup: dxLookupOptions = {
        showClearButton: true,
        dropDownOptions: {
            hideOnOutsideClick: true
        }
    };

    public static htmlEditorOptions = ['undo', 'redo', 'separator', { name: 'size', acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'] }, {
                                        name: 'font', acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'] },
                                       'separator', 'bold', 'italic', 'strike', 'underline', 'separator', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'separator', 'orderedList', 'bulletList', 'separator',
                                       { name: 'header', acceptedValues: [false, 1, 2, 3, 4, 5] }, 'separator', 'color', 'background', 'separator', 'link',
                                       'separator', 'clear', 'codeBlock', 'blockquote'];
}

export interface IValidationRules {
    [key: string]: (AsyncRule | StringLengthRule | RequiredRule | RangeRule | PatternRule | EmailRule | CustomRule)[];
}

export class BreakpointsTriggers {
    public static isMobileTrigger = [
        '(max-width: 1000px)',
        '(max-height: 800px)',
        Breakpoints.Handset,
        Breakpoints.Tablet
    ];
    public static sideNavAutoCollapseTrigger = [
        '(max-width: 1065px)'
    ];
    public static isXSmallScreenTrigger = [
        '(max-width: 500px)'
    ];
}

export class SysnetCloudConstants {
    public static readonly searchDefaultPagingMaxLimit = 200;
    public static readonly searchDefaultPagingLimit = 20;

    public static readonly DATA_ACCESS = {
        GET_STOCK: 'sc_get_stock',
        INSERT_STOCK: 'sc_insert_stock',
        UPDATE_STOCK: 'sc_update_stock',
        DELETE_STOCK: 'sc_delete_stock',
        GET_SALES_STOCK: 'sc_get_salesstock',
        INSERT_SALES_STOCK: 'sc_insert_salesstock',
        UPDATE_SALES_STOCK: 'sc_update_salesstock',
        DELETE_SALES_STOCK: 'sc_delete_salesstock',
        GET_STOCK_IN_LOCATION: 'sc_get_stockinlocations',
        INSERT_STOCK_IN_LOCATION: 'sc_insert_stockinlocations',
        UPDATE_STOCK_IN_LOCATION: 'sc_update_stockinlocations',
        DELETE_STOCK_IN_LOCATION: 'sc_delete_stockinlocations',
        GET_COOKING_DEPARTMENT: 'sc_get_cookingdepartments',
        INSERT_COOKING_DEPARTMENT: 'sc_insert_cookingdepartments',
        UPDATE_COOKING_DEPARTMENT: 'sc_update_cookingdepartments',
        DELETE_COOKING_DEPARTMENT: 'sc_delete_cookingdepartments',
        GET_CATEGORIES: 'sc_get_stockcategories',
        INSERT_CATEGORIES: 'sc_insert_stockcategories',
        UPDATE_CATEGORIES: 'sc_update_stockcategories',
        DELETE_CATEGORIES: 'sc_delete_stockcategories',
        GET_EXPENSE_GROUPS: 'sc_get_expensegroups',
        INSERT_EXPENSE_GROUPS: 'sc_insert_expensegroups',
        UPDATE_EXPENSE_GROUPS: 'sc_update_expensegroups',
        DELETE_EXPENSE_GROUPS: 'sc_delete_expensegroups',
        GET_PLU_GROUPS: 'sc_get_plugroups',
        GET_REVENUE_GROUPS: 'sc_get_revenuegroups',
        INSERT_REVENUE_GROUPS: 'sc_insert_revenuegroups',
        UPDATE_REVENUE_GROUPS: 'sc_update_revenuegroups',
        DELETE_REVENUE_GROUPS: 'sc_delete_revenuegroups',
        GET_STOCK_GROUPS: 'sc_get_stockgroups',
        INSERT_STOCK_GROUPS: 'sc_insert_stockgroups',
        UPDATE_STOCK_GROUPS: 'sc_update_stockgroups',
        DELETE_STOCK_GROUPS: 'sc_delete_stockgroups',
        GET_REPORT_GROUPS: 'sc_get_reportgroups',
        INSERT_REPORT_GROUPS: 'sc_insert_reportgroups',
        UPDATE_REPORT_GROUPS: 'sc_update_reportgroups',
        DELETE_REPORT_GROUPS: 'sc_delete_reportgroups',
        GET_DIETARY_INFORMATION: 'sc_get_dietaryinformation',
        INSERT_DIETARY_INFORMATION: 'sc_insert_dietaryinformation',
        UPDATE_DIETARY_INFORMATION: 'sc_update_dietaryinformation',
        DELETE_DIETARY_INFORMATION: 'sc_delete_dietaryinformation',
        GET_RANGES: 'sc_get_ranges',
        INSERT_RANGES: 'sc_insert_ranges',
        UPDATE_RANGES: 'sc_update_ranges',
        DELETE_RANGES: 'sc_delete_ranges',
        GET_STOCK_STATUSES: 'sc_get_stockstatuses',
        INSERT_STOCK_STATUSES: 'sc_insert_stockstatuses',
        UPDATE_STOCK_STATUSES: 'sc_update_stockstatuses',
        DELETE_STOCK_STATUSES: 'sc_delete_stockstatuses',
        GET_VENUE_LOCATIONS: 'sc_get_venuelocations',
        INSERT_VENUE_LOCATIONS: 'sc_insert_venuelocations',
        UPDATE_VENUE_LOCATIONS: 'sc_update_venuelocations',
        DELETE_VENUE_LOCATIONS: 'sc_delete_venuelocations',
        GET_LOCATION_SEGMENTS: 'sc_get_locationsegments',
        INSERT_LOCATION_SEGMENTS: 'sc_insert_locationsegments',
        UPDATE_LOCATION_SEGMENTS: 'sc_update_locationsegments',
        DELETE_LOCATION_SEGMENTS: 'sc_delete_locationsegments',
        GET_LOCATION_TYPES: 'sc_get_locationtypes',
        INSERT_LOCATION_TYPES: 'sc_insert_locationtypes',
        UPDATE_LOCATION_TYPES: 'sc_update_locationtypes',
        DELETE_LOCATION_TYPES: 'sc_delete_locationtypes',
        GET_CONTAINERS: 'sc_get_containers',
        INSERT_CONTAINERS: 'sc_insert_containers',
        UPDATE_CONTAINERS: 'sc_update_containers',
        DELETE_CONTAINERS: 'sc_delete_containers',
        GET_ZONES: 'sc_get_zones',
        INSERT_ZONES: 'sc_insert_zones',
        UPDATE_ZONES: 'sc_update_zones',
        DELETE_ZONES: 'sc_delete_zones',
        GET_SUPPLIERS: 'sc_get_suppliers',
        INSERT_SUPPLIERS: 'sc_insert_suppliers',
        UPDATE_SUPPLIERS: 'sc_update_suppliers',
        DELETE_SUPPLIERS: 'sc_delete_suppliers',
        GET_SUPPLIER_TYPES: 'sc_get_suppliertypes',
        GET_TAX_NAMES: 'sc_get_taxnames',
        INSERT_TAX_NAMES: 'sc_insert_taxnames',
        UPDATE_TAX_NAMES: 'sc_update_taxnames',
        DELETE_TAX_NAMES: 'sc_delete_taxnames',
        GET_TAX_RATES: 'sc_get_taxrates',
        INSERT_TAX_RATES: 'sc_insert_taxrates',
        UPDATE_TAX_RATES: 'sc_update_taxrates',
        DELETE_TAX_RATES: 'sc_delete_taxrates',
        INSERT_SUPPLIER_TYPES: 'sc_insert_suppliertypes',
        UPDATE_SUPPLIER_TYPES: 'sc_update_suppliertypes',
        DELETE_SUPPLIER_TYPES: 'sc_delete_suppliertypes',
        GET_PRICE_NAMES: 'sc_get_pricenames',
        INSERT_PRICE_NAMES: 'sc_insert_pricenames',
        UPDATE_PRICE_NAMES: 'sc_update_pricenames',
        DELETE_PRICE_NAMES: 'sc_delete_pricenames',
        GET_VARIETIES: 'sc_get_varieties',
        INSERT_VARIETIES: 'sc_insert_varieties',
        UPDATE_VARIETIES: 'sc_update_varieties',
        DELETE_VARIETIES: 'sc_delete_varieties',
        GET_DISCREPANCY_HEADERS: 'sc_get_stockdiscrepancyheaders',
        DELETE_DISCREPANCY_HEADERS: 'sc_delete_stockdiscrepancyheaders',
        INSERT_DISCREPANCY_HEADERS: 'sc_insert_stockdiscrepancyheaders',
        UPDATE_DISCREPANCY_HEADERS: 'sc_update_stockdiscrepancyheaders',
        GET_DISCREPANCY_DETAILS: 'sc_get_stockdiscrepancydetails',
        DELETE_DISCREPANCY_DETAILS: 'sc_delete_stockdiscrepancydetails',
        INSERT_DISCREPANCY_DETAILS: 'sc_insert_stockdiscrepancydetails',
        UPDATE_DISCREPANCY_DETAILS: 'sc_update_stockdiscrepancydetails',
        GET_STOCK_AUDIT_TRAIL : 'sc_get_stockaudittrail',
        GET_TRANSFER_HEADERS: 'sc_get_stocktransferheaders',
        INSERT_TRANSFER_HEADERS: 'sc_insert_stocktransferheaders',
        UPDATE_TRANSFER_HEADERS: 'sc_update_stocktransferheaders',
        DELETE_TRANSFER_HEADERS: 'sc_delete_stocktransferheaders',
        GET_STOCKTAKES: 'sc_get_stocktakes',
        INSERT_STOCKTAKES: 'sc_insert_stocktakes',
        UPDATE_STOCKTAKES: 'sc_update_stocktakes',
        DELETE_STOCKTAKES: 'sc_delete_stocktakes',
        GET_FACTORS: 'sc_get_factors',
        INSERT_FACTORS: 'sc_insert_factors',
        UPDATE_FACTORS: 'sc_update_factors',
        DELETE_FACTORS: 'sc_delete_factors',
        GET_BARCODES: 'sc_get_barcodes',
        INSERT_BARCODES: 'sc_insert_barcodes',
        UPDATE_BARCODES: 'sc_update_barcodes',
        DELETE_BARCODES: 'sc_delete_barcodes',
        GET_STOCKTAKEITEMS: 'sc_get_stocktakeitems',
        UPDATE_STOCKTAKEITEMS: 'sc_update_stocktakeitems',
        GET_SUPPLIERINVENTORYCODES: 'sc_get_supplierinventorycodes',
        INSERT_SUPPLIERINVENTORYCODES: 'sc_insert_supplierinventorycodes',
        UPDATE_SUPPLIERINVENTORYCODES: 'sc_update_supplierinventorycodes',
        DELETE_SUPPLIERINVENTORYCODES: 'sc_delete_supplierinventorycodes',
        INSERT_RECIPE_INGREDIENTS: 'sc_insert_recipeingredients',
        UPDATE_RECIPE_INGREDIENTS: 'sc_update_recipeingredients',
        DELETE_RECIPE_INGREDIENTS: 'sc_delete_recipeingredients',
        GET_RECEIVE_HEADERS: 'sc_get_stockreceivedheaders',
        INSERT_RECEIVE_HEADERS: 'sc_insert_stockreceivedheaders',
        UPDATE_RECEIVE_HEADERS: 'sc_update_stockreceivedheaders',
        DELETE_RECEIVE_HEADERS: 'sc_delete_stockreceivedheaders',
        GET_RECEIVE_DETAILS: 'sc_get_stockreceiveddetails',
        INSERT_RECEIVE_DETAILS: 'sc_insert_stockreceiveddetails',
        UPDATE_RECEIVE_DETAILS: 'sc_update_stockreceiveddetails',
        DELETE_RECEIVE_DETAILS: 'sc_delete_stockreceiveddetails',
        GET_CONFIGURATION_AND_SETTINGS_MODULE: 'sc_get_configuration_and_settings_module',
        GET_VENUE_SETTINGS: 'sc_get_venuesettings',
        INSERT_VENUE_SETTINGS: 'sc_insert_venuesettings',
        UPDATE_VENUE_SETTINGS: 'sc_update_venuesettings',
        GET_ORGANISATION_SETTINGS: 'sc_get_organisationsettings',
        INSERT_ORGANISATION_SETTINGS: 'sc_insert_organisationsettings',
        UPDATE_ORGANISATION_SETTINGS: 'sc_update_organisationsettings',
        GET_PURCHASE_ORDER_HEADERS: 'sc_get_purchaseorderheaders',
        INSERT_PURCHASE_ORDER_HEADERS: 'sc_insert_purchaseorderheaders',
        UDPATE_PURCHASE_ORDER_HEADERS: 'sc_update_purchaseorderheaders',
        DELETE_PURCHASE_ORDER_HEADERS: 'sc_delete_purchaseorderheaders',
        GET_PURCHASE_ORDER_DETAILS: 'sc_get_purchaseorderdetails',
        INSERT_PURCHASE_ORDER_DETAILS: 'sc_insert_purchaseorderdetails',
        UPDATE_PURCHASE_ORDER_DETAILS: 'sc_update_purchaseorderdetails',
        DELETE_PURCHASE_ORDER_DETAILS: 'sc_delete_purchaseorderdetails',
        GET_PRODUCTS: 'sc_get_products',
        INSERT_PRODUCTS: 'sc_insert_products',
        UPDATE_PRODUCTS: 'sc_update_products',
        DELETE_PRODUCTS: 'sc_delete_products',
        GET_DYNAMIC_PRICING: 'sc_get_dynamicpricing',
        GET_SALES_SPECIALS: 'sc_get_salesspecials',
        INSERT_SALES_SPECIALS: 'sc_insert_salesspecials',
        UPDATE_SALES_SPECIALS: 'sc_update_salesspecials',
        DELETE_SALES_SPECIALS: 'sc_delete_salesspecials',
        GET_MIX_N_MATCH: 'sc_get_mixandmatchpromotions',
        INSERT_MIX_N_MATCH: 'sc_insert_mixandmatchpromotions',
        UPDATE_MIX_N_MATCH: 'sc_update_mixandmatchpromotions',
        DELETE_MIX_N_MATCH: 'sc_delete_mixandmatchpromotions',
        GET_PRODUCTS_IN_LOCATION: 'sc_get_productsinlocations',
        INSERT_PRODUCTS_IN_LOCATION: 'sc_insert_productsinlocations',
        UPDATE_PRODUCTS_IN_LOCATION: 'sc_update_productsinlocations',
        DELETE_PRODUCTS_IN_LOCATION: 'sc_delete_productsinlocations',
        GET_PRODUCT_GROUPS: 'sc_get_plugroups',
        INSERT_PRODUCT_GROUPS: 'sc_insert_plugroups',
        UPDATE_PRODUCT_GROUPS: 'sc_update_plugroups',
        DELETE_PRODUCT_GROUPS: 'sc_delete_plugroups',
        GET_PRODUCT_GROUP_CATEGORIES: 'sc_get_productgroupcategories',
        GET_MULTI_ITEM_BARCODES: 'sc_get_multiitembarcodes',
        GET_PRICES: 'sc_get_products', // TODO: Replace with correct access once available
        GET_PRODUCT_PRICE: 'sc_get_productprices',
        INSERT_PRODUCT_PRICE: 'sc_get_productprices',
        UPDATE_PRODUCT_PRICE: 'sc_get_productprices',
        DELETE_PRODUCT_PRICE: 'sc_get_productprices',
        INSERT_PRODUCT_GROUP_CATEGORIES: 'sc_insert_productgroupcategories',
        UPDATE_PRODUCT_GROUP_CATEGORIES: 'sc_update_productgroupcategories',
        DELETE_PRODUCT_GROUP_CATEGORIES: 'sc_delete_productgroupcategories',
        INSERT_MIX_N_MATCH_PURCHASE_REQUIREMENTS: 'sc_insert_mixandmatchpurchaserequirements',
        UPDATE_MIX_N_MATCH_PURCHASE_REQUIREMENTS: 'sc_update_mixandmatchpurchaserequirements',
        DELETE_MIX_N_MATCH_PURCHASE_REQUIREMENTS: 'sc_delete_mixandmatchpurchaserequirements',
        GET_PACKAGE_ITEMS: 'sc_get_packageitems',
        INSERT_PACKAGE_ITEMS: 'sc_insert_packageitems',
        UPDATE_PACKAGE_ITEMS: 'sc_update_packageitems',
        DELETE_PACKAGE_ITEMS: 'sc_delete_packageitems',
        GET_PACKAGE_ITEM_SUBSTITUTES: 'sc_get_packageitemsubstitutes',
        INSERT_PACKAGE_ITEM_SUBSTITUTES: 'sc_insert_packageitemsubstitutes',
        UPDATE_PACKAGE_ITEM_SUBSTITUTES: 'sc_update_packageitemsubstitutes',
        DELETE_PACKAGE_ITEM_SUBSTITUTES: 'sc_delete_packageitemsubstitutes',
        GET_PACKAGE_ITEM_SUBSTITUTE_PRODUCT_GROUPS: 'sc_get_packageitemsubstituteproductgroups',
        INSERT_PACKAGE_ITEM_SUBSTITUTE_PRODUCT_GROUPS: 'sc_insert_packageitemsubstituteproductgroups',
        UPDATE_PACKAGE_ITEM_SUBSTITUTE_PRODUCT_GROUPS: 'sc_update_packageitemsubstituteproductgroups',
        DELETE_PACKAGE_ITEM_SUBSTITUTE_PRODUCT_GROUPS: 'sc_delete_packageitemsubstituteproductgroups',
        GET_OFFERS: 'sc_get_offers',
        INSERT_OFFERS: 'sc_insert_offers',
        UPDATE_OFFERS: 'sc_update_offers',
        DELETE_OFFERS: 'sc_delete_offers',
        GET_OFFER_RULES: 'sc_get_offerrules',
        INSERT_OFFER_RULES: 'sc_insert_offerrules',
        UPDATE_OFFER_RULES: 'sc_update_offerrules',
        DELETE_OFFER_RULES: 'sc_delete_offerrules',
        GET_OFFER_RULES_PRODUCT_GROUPS: 'sc_get_offerruleproductgroups',
        INSERT_OFFER_RULES_PRODUCT_GROUPS: 'sc_insert_offerruleproductgroups',
        UPDATE_OFFER_RULES_PRODUCT_GROUPS: 'sc_update_offerruleproductgroups',
        DELETE_OFFER_RULES_PRODUCT_GROUPS: 'sc_delete_offerruleproductgroups',
        GET_OFFER_RULES_PRODUCTS: 'sc_get_offerruleproducts',
        INSERT_OFFER_RULES_PRODUCTS: 'sc_insert_offerruleproducts',
        UPDATE_OFFER_RULES_PRODUCTS: 'sc_update_offerruleproducts',
        DELETE_OFFER_RULES_PRODUCTS: 'sc_delete_offerruleproducts',
        GET_OFFER_RULES_RANGES: 'sc_get_offerruleproductranges',
        INSERT_OFFER_RULES_RANGES: 'sc_insert_offerruleproductranges',
        UPDATE_OFFER_RULES_RANGES: 'sc_update_offerruleproductranges',
        DELETE_OFFER_RULES_RANGES: 'sc_delete_offerruleproductranges',
        GET_OFFER_VENUES: 'sc_get_offervenues',
        INSERT_OFFER_VENUES: 'sc_insert_offervenues',
        UPDATE_OFFER_VENUES: 'sc_update_offervenues',
        DELETE_OFFER_VENUES: 'sc_delete_offervenues',
        GET_OFFER_LOCATION_TYPES: 'sc_get_offerlocationtypes',
        INSERT_OFFER_LOCATION_TYPES: 'sc_insert_offerlocationtypes',
        UPDATE_OFFER_LOCATION_TYPES: 'sc_update_offerlocationtypes',
        DELETE_OFFER_LOCATION_TYPES: 'sc_delete_offerlocationtypes',
        GET_OFFER_LOCATIONS: 'sc_get_offerlocations',
        INSERT_OFFER_LOCATIONS: 'sc_insert_offerlocations',
        UPDATE_OFFER_LOCATIONS: 'sc_update_offerlocations',
        DELETE_OFFER_LOCATIONS: 'sc_delete_offerlocations',
        GET_SALES_SPECIAL_PRODUCTS_IN_LOCATION: 'sc_get_salesspecialproductsinlocation',
        INSERT_SALES_SPECIAL_PRODUCTS_IN_LOCATION: 'sc_insert_salesspecialproductsinlocation',
        UPDATE_SALES_SPECIAL_PRODUCTS_IN_LOCATION: 'sc_update_salesspecialproductsinlocation',
        DELETE_SALES_SPECIAL_PRODUCTS_IN_LOCATION: 'sc_delete_salesspecialproductsinlocation',
        GET_STOCK_CREATION_TEMPLATES: 'sc_get_stockcreationtemplates',
        INSERT_STOCK_CREATION_TEMPLATES: 'sc_insert_stockcreationtemplates',
        UPDATE_STOCK_CREATION_TEMPLATES: 'sc_update_stockcreationtemplates',
        DELETE_STOCK_CREATION_TEMPLATES: 'sc_delete_stockcreationtemplates',
        GET_STOCK_CREATION_TEMPLATE_FACTORS: 'sc_get_stockcreationtemplatefactors',
        INSERT_STOCK_CREATION_TEMPLATE_FACTORS: 'sc_insert_stockcreationtemplatefactors',
        UPDATE_STOCK_CREATION_TEMPLATE_FACTORS: 'sc_update_stockcreationtemplatefactors',
        DELETE_STOCK_CREATION_TEMPLATE_FACTORS: 'sc_delete_stockcreationtemplatefactors',
        GET_TEMPLATE_RANGE_STOCK_IN_LOCATIONS: 'sc_get_templaterangetostockinlocations',
        INSERT_TEMPLATE_RANGE_STOCK_IN_LOCATIONS: 'sc_insert_templaterangetostockinlocations',
        UPDATE_TEMPLATE_RANGE_STOCK_IN_LOCATIONS: 'sc_update_templaterangetostockinlocations',
        DELETE_TEMPLATE_RANGE_STOCK_IN_LOCATIONS: 'sc_delete_templaterangetostockinlocations',
        GET_TEMPLATE_RANGE_STOCK_IN_LOCATION_TYPES: 'sc_get_templaterangetostockinlocationtypes',
        INSERT_TEMPLATE_RANGE_STOCK_IN_LOCATION_TYPES: 'sc_insert_templaterangetostockinlocationtypes',
        UPDATE_TEMPLATE_RANGE_STOCK_IN_LOCATION_TYPES: 'sc_update_templaterangetostockinlocationtypes',
        DELETE_TEMPLATE_RANGE_STOCK_IN_LOCATION_TYPES: 'sc_delete_templaterangetostockinlocationtypes',
        GET_PRICE_CHANGES_HEADERS: 'sc_get_pricechangesheaders',
        INSERT_PRICE_CHANGES_HEADERS: 'sc_insert_pricechangesheaders',
        UPDATE_PRICE_CHANGES_HEADERS: 'sc_update_pricechangesheaders',
        DELETE_PRICE_CHANGES_HEADERS: 'sc_delete_pricechangesheaders',
        GET_PRICE_CHANGES: 'sc_get_pricechanges',
        INSERT_PRICE_CHANGES: 'sc_insert_pricechanges',
        UPDATE_PRICE_CHANGES: 'sc_update_pricechanges',
        DELETE_PRICE_CHANGES: 'sc_delete_pricechanges',
        GET_STOCK_MODULE: 'sc_get_stock_module',
        GET_STOCK_MOBILE_MODULE: 'sc_get_stock_mobile_module',
        GET_PRODUCTS_MODULE: 'sc_get_products_module'
    };

    public static readonly SYSNET_SETTINGS = {
        USE_FUTURE_PRICES: 'Use Future Prices',
        USE_RECIPE_MANAGEMENT: 'Use Recipe Management',
        USE_SALES_STOCK: 'Use Sales Stock',
        USE_STOCK_PRICES: 'Use Stock Prices',
        USE_STOCK_RECEIVE: 'Use Stock Receive',
        USE_SYSNET_SETTINGS: 'Use Sysnet Settings',
        USE_PURCHASE_ORDERS: 'Use Purchase Orders',
        SHOW_QUANTITY_IN_STOCKTAKE: 'Show Stock On Hand Quantities in Stocktakes',
        VENUE_DEFAULT_DELIVERY_INSTRUCTIONS: 'Venue Default Delivery Instructions',
        USE_PRODUCT_MANAGEMENT: 'Use Product Management',
        SET_PRICES_INCLUDING_TAX: 'Set prices including tax',
        USE_PRICE_MANAGEMENT: 'Use Price Management',
        USE_REPORTS: 'Use Reports',
        STOCK_LEVEL_VERIFICATION: 'Enforce Stocktake & Discrepancy Stock Level Verification'
    };

    public static readonly STOCK_MOBILE_SETTINGS = {
        USE_MOBILE_STOCK_TRANSFER: 'Use Mobile Stock Transfers',
        USE_MOBILE_STOCK_DISCREPANCIES: 'Use Mobile Stock Discrepancies',
        USE_MOBILE_STOCK_WASTAGE: 'Use Mobile Stock Wastage',
        USE_MOBILE_STOCK_STOCKTAKE: 'Use Mobile Stock Stocktake',
        USE_MOBILE_STOCK_INFO: 'Use Mobile Stock Info',
        USE_MOBILE_STOCK_MULTI_ITEM_BARCODES: 'Use Mobile Stock Multi Item Barcodes'
    };
}

export class WebhooksConstants {

    public static readonly DATA_ACCESS = {
        GET_SUBSCRIPTION: 'esapi_get_subscriptions',
        INSERT_SUBSCRIPTION: 'esapi_insert_subscriptions',
        UPDATE_SUBSCRIPTION: 'esapi_update_subscriptions',
        GET_CALLBACKHISTORY: 'esapi_get_callbackhistory'
    };

}

export class UserManagementConstants {

    public static readonly DATA_ACCESS = {
        GET_VENUE_USERS: 'get_venue_users',
        UPDATE_VENUE_USER_ACCESS: 'update_venue_user_access',
        DELETE_ORGANISATION_INVITE: 'delete_organisation_invite',
        UPDATE_USERS_ADMIN: 'update_users_admin'
    };

}

export const DISCREPANCY_TYPE = {
    DISCREPANCY: 0,
    WASTAGE: 1
};

export const DISCREPANCY_STATUS_COLORS = {
    NOT_VERIFIED: 'orange',
    NEGATIVE_ADJUSTMENT: 'red',
    POSITIVE_ADJUSTMENT: 'green'
}

export const DEFAULT_HOME_ROUTE = ['sysnet', 'home'];
export const DEFAULT_WEBHOOKS_HOME_ROUTE = ['webhooks', 'home'];
export const DEFAULT_USER_MANAGEMENT_HOME_ROUTE = ['user-management', 'home'];
export const DEFAULT_STOCK_MOBILE_ROUTE = ['sysnet', 'stock-mobile'];

export class GridConstants {
    public static readonly ALLOWED_PAGE_SIZES = [25, 50, 100];
    public static readonly STANDARD_COLUMN_MIN_WIDTH = 100;
    public static readonly DESCRIPTION_COLUMN_WIDTH = 325;
}

export class LookupConstants {
    public static readonly PAGE_SIZE = 10;

    public static setupLookupDataSource(lookupConfig: ILookupDataSourceConfig[0]): ILookupDataSourceSetupReturnObj {
        return {
          store: new CustomStore({
            key: lookupConfig.primaryKey,
            loadMode: lookupConfig.dataSourceLoadMode,
            load: async (loadOptions: LoadOptions) => {
                try {
                    if (loadOptions.searchValue && lookupConfig._castToIntWhenSearch) {
                        loadOptions.searchValue = Number(loadOptions.searchValue);
                        loadOptions.searchOperation = '=';
                    }
                    if (lookupConfig.dataSourceFilter) {
                      loadOptions.filter = lookupConfig.dataSourceFilter;
                    }
                    const view = lookupConfig.dataSourceServiceSearchFnView;
                    const routeId = lookupConfig.dataSourceServiceSearchFnRouteId;
                    return await lookupConfig.dataSourceService[lookupConfig.dataSourceServiceSearchFn](loadOptions, view, routeId);
                } catch (error) { /* empty */ }
            },
            byKey: async (key) => {
                try {
                    if (!key) { return null; }
                    return await lookupConfig.dataSourceService[lookupConfig.dataSourceServiceGetFn](key);
                } catch (error) { /* empty */ }
            }
          }),
          filter: lookupConfig.dataSourceFilter,
          sort: lookupConfig.dataSortBy,
          pageSize: LookupConstants.PAGE_SIZE,
          paginate: true
        };
      }
}

export const POPUP_SHADING_COLOR = 'rgba(0,0,0,0.3)';
