import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AuditLogsService } from './api/auditLogs.service';
import { BarcodesService } from './api/barcodes.service';
import { ContainersService } from './api/containers.service';
import { CookingDepartmentsService } from './api/cookingDepartments.service';
import { CountriesService } from './api/countries.service';
import { DefaultSettingsService } from './api/defaultSettings.service';
import { DietaryInformationService } from './api/dietaryInformation.service';
import { ExpenseGroupsService } from './api/expenseGroups.service';
import { FactorNotesService } from './api/factorNotes.service';
import { FactorsService } from './api/factors.service';
import { FuturePricesService } from './api/futurePrices.service';
import { IntegrationService } from './api/integration.service';
import { LocationSegmentsService } from './api/locationSegments.service';
import { LocationTypesService } from './api/locationTypes.service';
import { MixAndMatchLocationsService } from './api/mixAndMatchLocations.service';
import { MixAndMatchPromotionsService } from './api/mixAndMatchPromotions.service';
import { MixAndMatchPurchaseRequirementSetProductGroupsService } from './api/mixAndMatchPurchaseRequirementSetProductGroups.service';
import { MixAndMatchPurchaseRequirementSetProductsService } from './api/mixAndMatchPurchaseRequirementSetProducts.service';
import { MixAndMatchPurchaseRequirementsService } from './api/mixAndMatchPurchaseRequirements.service';
import { OfferLocationTypesService } from './api/offerLocationTypes.service';
import { OfferLocationsService } from './api/offerLocations.service';
import { OfferRuleProductGroupsService } from './api/offerRuleProductGroups.service';
import { OfferRuleProductRangesService } from './api/offerRuleProductRanges.service';
import { OfferRuleProductsService } from './api/offerRuleProducts.service';
import { OfferRulesService } from './api/offerRules.service';
import { OfferVenuesService } from './api/offerVenues.service';
import { OffersService } from './api/offers.service';
import { OrganisationSettingsService } from './api/organisationSettings.service';
import { OrganisationsService } from './api/organisations.service';
import { PackageItemSubstituteProductGroupsService } from './api/packageItemSubstituteProductGroups.service';
import { PackageItemSubstitutesService } from './api/packageItemSubstitutes.service';
import { PackageItemsService } from './api/packageItems.service';
import { PriceChangesService } from './api/priceChanges.service';
import { PriceChangesHeadersService } from './api/priceChangesHeaders.service';
import { PriceNamesService } from './api/priceNames.service';
import { PricesService } from './api/prices.service';
import { PrintGroupsService } from './api/printGroups.service';
import { ProductFuturePricesService } from './api/productFuturePrices.service';
import { ProductGroupCategoriesService } from './api/productGroupCategories.service';
import { ProductGroupsService } from './api/productGroups.service';
import { ProductPricesService } from './api/productPrices.service';
import { ProductsService } from './api/products.service';
import { ProductsInLocationsService } from './api/productsInLocations.service';
import { PurchaseOrderDetailsService } from './api/purchaseOrderDetails.service';
import { PurchaseOrderHeadersService } from './api/purchaseOrderHeaders.service';
import { RangesService } from './api/ranges.service';
import { RecipeIngredientsService } from './api/recipeIngredients.service';
import { RecipeMethodsService } from './api/recipeMethods.service';
import { ReportGroupsService } from './api/reportGroups.service';
import { RevenueGroupsService } from './api/revenueGroups.service';
import { SalesSpecialProductsInLocationService } from './api/salesSpecialProductsInLocation.service';
import { SalesSpecialsService } from './api/salesSpecials.service';
import { SalesStockService } from './api/salesStock.service';
import { StockService } from './api/stock.service';
import { StockAdjustmentsService } from './api/stockAdjustments.service';
import { StockAuditTrailService } from './api/stockAuditTrail.service';
import { StockAuditTrailTransactionTypesService } from './api/stockAuditTrailTransactionTypes.service';
import { StockCategoriesService } from './api/stockCategories.service';
import { StockCreationTemplateFactorsService } from './api/stockCreationTemplateFactors.service';
import { StockCreationTemplatesService } from './api/stockCreationTemplates.service';
import { StockDietaryInformationService } from './api/stockDietaryInformation.service';
import { StockDiscrepancyDetailsService } from './api/stockDiscrepancyDetails.service';
import { StockDiscrepancyHeadersService } from './api/stockDiscrepancyHeaders.service';
import { StockExtendedDetailsService } from './api/stockExtendedDetails.service';
import { StockGroupsService } from './api/stockGroups.service';
import { StockInLocationsService } from './api/stockInLocations.service';
import { StockReceivedDetailsService } from './api/stockReceivedDetails.service';
import { StockReceivedHeadersService } from './api/stockReceivedHeaders.service';
import { StockStatusesService } from './api/stockStatuses.service';
import { StockTransferDetailsService } from './api/stockTransferDetails.service';
import { StockTransferHeadersService } from './api/stockTransferHeaders.service';
import { StocktakeGroupsService } from './api/stocktakeGroups.service';
import { StocktakeItemsService } from './api/stocktakeItems.service';
import { StocktakeItemsDataEntryService } from './api/stocktakeItemsDataEntry.service';
import { StocktakesService } from './api/stocktakes.service';
import { SupplierInventoryCodesService } from './api/supplierInventoryCodes.service';
import { SupplierTypesService } from './api/supplierTypes.service';
import { SuppliersService } from './api/suppliers.service';
import { TaxNamesService } from './api/taxNames.service';
import { TaxRatesService } from './api/taxRates.service';
import { TemplateRangeToStockInLocationTypesService } from './api/templateRangeToStockInLocationTypes.service';
import { TemplateRangeToStockInLocationsService } from './api/templateRangeToStockInLocations.service';
import { VarietiesService } from './api/varieties.service';
import { VenueDetailsService } from './api/venueDetails.service';
import { VenueLocationsService } from './api/venueLocations.service';
import { VenueSettingsService } from './api/venueSettings.service';
import { VenuesService } from './api/venues.service';
import { ZonesService } from './api/zones.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AuditLogsService,
    BarcodesService,
    ContainersService,
    CookingDepartmentsService,
    CountriesService,
    DefaultSettingsService,
    DietaryInformationService,
    ExpenseGroupsService,
    FactorNotesService,
    FactorsService,
    FuturePricesService,
    IntegrationService,
    LocationSegmentsService,
    LocationTypesService,
    MixAndMatchLocationsService,
    MixAndMatchPromotionsService,
    MixAndMatchPurchaseRequirementSetProductGroupsService,
    MixAndMatchPurchaseRequirementSetProductsService,
    MixAndMatchPurchaseRequirementsService,
    OfferLocationTypesService,
    OfferLocationsService,
    OfferRuleProductGroupsService,
    OfferRuleProductRangesService,
    OfferRuleProductsService,
    OfferRulesService,
    OfferVenuesService,
    OffersService,
    OrganisationSettingsService,
    OrganisationsService,
    PackageItemSubstituteProductGroupsService,
    PackageItemSubstitutesService,
    PackageItemsService,
    PriceChangesService,
    PriceChangesHeadersService,
    PriceNamesService,
    PricesService,
    PrintGroupsService,
    ProductFuturePricesService,
    ProductGroupCategoriesService,
    ProductGroupsService,
    ProductPricesService,
    ProductsService,
    ProductsInLocationsService,
    PurchaseOrderDetailsService,
    PurchaseOrderHeadersService,
    RangesService,
    RecipeIngredientsService,
    RecipeMethodsService,
    ReportGroupsService,
    RevenueGroupsService,
    SalesSpecialProductsInLocationService,
    SalesSpecialsService,
    SalesStockService,
    StockService,
    StockAdjustmentsService,
    StockAuditTrailService,
    StockAuditTrailTransactionTypesService,
    StockCategoriesService,
    StockCreationTemplateFactorsService,
    StockCreationTemplatesService,
    StockDietaryInformationService,
    StockDiscrepancyDetailsService,
    StockDiscrepancyHeadersService,
    StockExtendedDetailsService,
    StockGroupsService,
    StockInLocationsService,
    StockReceivedDetailsService,
    StockReceivedHeadersService,
    StockStatusesService,
    StockTransferDetailsService,
    StockTransferHeadersService,
    StocktakeGroupsService,
    StocktakeItemsService,
    StocktakeItemsDataEntryService,
    StocktakesService,
    SupplierInventoryCodesService,
    SupplierTypesService,
    SuppliersService,
    TaxNamesService,
    TaxRatesService,
    TemplateRangeToStockInLocationTypesService,
    TemplateRangeToStockInLocationsService,
    VarietiesService,
    VenueDetailsService,
    VenueLocationsService,
    VenueSettingsService,
    VenuesService,
    ZonesService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
