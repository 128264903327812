/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Factors describe all the various ways stock can be moved or sold.  Example: Whiskey  is purchased by the case or dozen but  sold by the single bottle, 30ml nip and 15ml nip
 */
export interface FactorUpdate { 
    divider?: number;
    alhStockCode?: string;
    /**
     * A reference to the primary key in the Containers Table
     */
    containerId?: number;
    volumeDivider?: number;
    pourTime?: number;
    promptForSerial?: boolean;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
    status?: FactorUpdate.StatusEnum;
}
export namespace FactorUpdate {
    export type StatusEnum = 'active' | 'disabled';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Disabled: 'disabled' as StatusEnum
    };
}