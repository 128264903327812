/**
 * Portal API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FilePostResponse { 
    /**
     * the unique identifier of the uploaded file. Can be used to download the file on the GET /files/image/:id route or to Patch the file on the /file route.
     */
    _id?: string;
    /**
     * The name of the file uploaded
     */
    imageName?: string;
    /**
     * if this file is uploaded as a public file it will contain the publicly availabe http url that this file can be viewed at.
     */
    publicUrl?: string;
}