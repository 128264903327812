/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

export interface RecipeIngredientBulkInsertViewInsertedObject { 
    /**
     * The unique identifier of this record.
     */
    recipeIngredientId?: number;
    /**
     * A reference to the primary key in the Stock Table
     */
    recipeStockId?: number;
    /**
     * A reference to the primary key in the Stock Table
     */
    ingredientStockId?: number;
    /**
     * A reference to the primary key in the Factors Table
     */
    ingredientFactorId?: number;
    factorQty?: number;
    weightRequired?: boolean;
    displayOrder?: number;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    /**
     * The total number of (stock) inners required for this recipe.
     */
    innersQuantity?: number;
    /**
     * The unit cost of an ingredient calculated using lastCost (stock). This is either the cost for an individual inner or for a factor if is specified.
     */
    unitLastCost?: number;
    /**
     * The total cost of the ingredient for this recipe  (innersQuantity * stock.lastCost/stock.innersPerOuter. Calculated using the lastCost for the recipe the stock item
     */
    totalLastCost?: number;
    /**
     * The unit cost of an ingredient calculated using averageCost (stock). This is either the cost for an individual inner or for a factor if is specified.
     */
    unitAverageCost?: number;
    /**
     * The calculated value of this ingredient to the recipe (innersQuantity * stock.averageCost/stock.innersPerOuter). Calculated using the averageCost for the recipe stock item.
     */
    totalAverageCost?: number;
}