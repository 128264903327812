import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { UtilityService } from '@services/utility.service';
import { BarcodeScanner, EnumBarcodeFormat, EnumLocalizationMode } from 'dynamsoft-javascript-barcode';
import { Region } from 'dynamsoft-javascript-barcode/dist/types/interface/region';
import { VideoDeviceInfo } from 'dynamsoft-javascript-barcode/dist/types/interface/videodeviceinfo';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ErrorMessages } from 'src/app/utilities/ErrorMessages';
import { Properties as dxPopupOptions } from 'devextreme/ui/popup';
import { GlobalsService } from '@services/global.service';
import { DxFormComponent } from 'devextreme-angular';
declare let DSScanner: {
  addEventListener: (event: 'onError' | 'onBarcode' | 'onScannerReady' | 'onScannerDestroyed', callback: (data: string | Error | IBarcodeResult[]) => void) => void;
  Destroy: () => void;
  StartScanner: () => void;
  Create: (settings: {
    scanner: {
      key: string;
      frameTimeout: number;
      barcodeTimeout: number;
      beep: boolean;
    };
    viewport: {
      id: string;
    };
    camera: {
      facingMode: string;
      id: string;
    };
    barcode: {
      barcodeTypes: string[];
    };
  }) => void;
  getVideoDevices: (callback: (devices: VideoDeviceInfo[]) => void) => void;
  getScannerSettings: () => {
    scanner: { frameTimeout: number; barcodeTimeout: number; beep: boolean };
    camera: { facingMode: string; id: string };
    barcode: { barcodeTypes: string[] };
  };
  setScannerSettings: (settings: {
    scanner: { frameTimeout: number; barcodeTimeout: number; beep: boolean };
    camera: { facingMode: string; id: string };
    barcode: { barcodeTypes: string[] };
  }) => void;
  bin2String: (data: Uint8Array) => string;
};

interface IBarcodeResult {
  barcodeText: string;
  barcodeFormat: string;
}

enum BarcodeTypes {
  CODE128 = 'Code128',
  CODE39 = 'Code39',
  INTERL25 = 'Interl25',
  EAN13 = 'EAN13',
  EAN8 = 'EAN8',
  CODABAR = 'Codabar',
  CODE11 = 'Code11',
  UPCA = 'UPCA',
  UPCE = 'UPCE',
  INDUSTR25 = 'Industr25',
  CODE93 = 'Code93',
  MSIPLESSEY = 'MSIPlessey',
  DATABAROMNI = 'DataBarOmni',
  DATABARLIM = 'DataBarLim',
  DATABARSTACKED = 'DataBarStacked',
  DATABAREXP = 'DataBarExp',
  DATABAREXPSTACKED = 'DataBarExpStacked',
  DATAMATRIX = 'DataMatrix',
  PDF417 = 'PDF417',
  QRCODE = 'QRCode',
  AZTECCODE = 'AztecCode',
}

interface ISavedBarcodeConfig {
  cameraId?: string;
  barcodeTypes?: string[];
}
@Component({
  selector: 'app-barcode-camera',
  templateUrl: './barcode-camera.component.html',
  styleUrls: ['./barcode-camera.component.scss']
})
export class BarcodeCameraComponent implements OnInit, OnDestroy {
  public popupConfig: dxPopupOptions = {
    title: 'Barcode Config',
    visible: false
  };
  public isPermissionGiven = false;
  public pScanner: BarcodeScanner = null;
  public barcodeLibLoaded = false;
  public colCountByScreen;
  public $barcodeResults = new Subject<string | Error | IBarcodeResult[]>();
  public camDevices = [];
  public selectedDeviceId: string;
  public readonly defaultBarcodeTypes = ['Code128', 'EAN13', 'EAN8', 'UPCA', 'Industr25', 'Code93'];
  public barcodeTypes = [
    { value: false, name: 'Code 128', dimention: '1D', code: BarcodeTypes.CODE128 },
    { value: false, name: 'Code 39', dimention: '1D', code: BarcodeTypes.CODE39 },
    { value: false, name: 'Interleaved 2/5', dimention: '1D', code: BarcodeTypes.INTERL25 },
    { value: false, name: 'EAN-13', dimention: '1D', code: BarcodeTypes.EAN13 },
    { value: false, name: 'EAN-8', dimention: '1D', code: BarcodeTypes.EAN8 },
    { value: false, name: 'UPC-A', dimention: '1D', code: BarcodeTypes.UPCA },
    { value: false, name: 'Codabar', dimention: '1D', code: BarcodeTypes.CODABAR },
    { value: false, name: 'Code 11', dimention: '1D', code: BarcodeTypes.CODE11 },
    { value: false, name: 'UPC-E', dimention: '1D', code: BarcodeTypes.UPCE },
    { value: false, name: 'Industrial 2/5', dimention: '1D', code: BarcodeTypes.INDUSTR25 },
    { value: false, name: 'Code 93', dimention: '1D', code: BarcodeTypes.CODE93 },
    // { value: false, name: 'MSI Plessey', dimention: '1D', code: BarcodeTypes.MSIPLESSEY },
    { value: false, name: 'DataBar Omni/Truncated', dimention: '1D', code: BarcodeTypes.DATABAROMNI },
    { value: false, name: 'DataBar Limited', dimention: '1D', code: BarcodeTypes.DATABARLIM },
    { value: false, name: 'DataBar Stacked	', dimention: '1D', code: BarcodeTypes.DATABARSTACKED },
    { value: false, name: 'DataBar Expanded', dimention: '1D', code: BarcodeTypes.DATABAREXP },
    { value: false, name: 'DataBar Exp. Stacked', dimention: '1D', code: BarcodeTypes.DATABAREXPSTACKED },
    { value: false, name: 'DataMatrix', dimention: '2D', code: BarcodeTypes.DATAMATRIX },
    { value: false, name: 'Aztec', dimention: '2D', code: BarcodeTypes.AZTECCODE },
    { value: false, name: 'PDF-417', dimention: '2D', code: BarcodeTypes.PDF417 },
    { value: false, name: 'QR', dimention: '2D', code: BarcodeTypes.QRCODE }
  ];
  public barcodeType1Ds = [];
  public barcodeType2Ds = [];
  private $scanResultChanges: Subscription;
  @Output() public readonly scanSuccess = new EventEmitter();
  @ViewChild('barcodeTypeConfigForm', { static: false }) public barcodeTypeConfigForm: DxFormComponent;
  constructor(
    private elementRef: ElementRef,
    private utils: UtilityService,
    public globalsService: GlobalsService) {
      this.colCountByScreen = { lg: 8, md: 4, sm: 2 };
      this.$scanResultChanges = this.$barcodeResults.pipe(
        debounceTime(300)
      ).subscribe((barcodeResult) => {
        this.onBarcodeReady(barcodeResult);
      });
    }

  public ngOnInit(): void {
    // try {
    //   await this.initBarcodeCamera();
    //   if (this.pScanner == null) {
    //     this.pScanner = await BarcodeScanner.createInstance();
    //   }
    //   const scanner = this.pScanner;
    //   await this.setScanSettings(scanner);
    //   scanner.setUIElement(this.elementRef.nativeElement);
    //   scanner.onUniqueRead = (barcode: string) => {
    //     this.scanSuccess.emit(barcode);
    //   };
    //   await scanner.open();
    // } catch (ex) {
    //   console.error(ex);
    // }
    const selectedBarcodeTypes = this.setBarcodeTypeConfiguration();
    this.createScanner(selectedBarcodeTypes);
  }

  public ngOnDestroy(): void {
    // const canvasArr = Array.from(document.querySelectorAll('.dce-video-container canvas'));
    // canvasArr.forEach((canvas) => { this.releaseCanvas(canvas as HTMLCanvasElement); });
    // if (this.pScanner) {
    //   (this.pScanner).destroyContext();
    // }

    DSScanner.Destroy();
    if (this.$scanResultChanges) { this.$scanResultChanges.unsubscribe(); }
  }

  public screenByWidth(width): string | void {
    return width < 500 ? 'sm' : width < 1000 ? 'md' : 'lg';
  }

  // Dynamsoft Scanner Start
  // public async initBarcodeCamera(): Promise<void> {
  //   try {
  //     // Load the library on page load to speed things up.
  //     await BarcodeScanner.loadWasm();
  //     this.barcodeLibLoaded = true;
  //   } catch (ex: any) {
  //     this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} initializing barcode scanner.`, 'OK', 10000);
  //   }
  // }

  // public async setScanSettings(scanner: BarcodeScanner): Promise<void> {
  //   const scanSettings = await scanner.getScanSettings();
  //   scanSettings.whenToPlaySoundforSuccessfulRead = 'unique';
  //   scanSettings.whenToVibrateforSuccessfulRead = 'unique';
  //   await scanner.updateScanSettings(scanSettings);
  //   await scanner.setResolution(1920, 1080); // Full HD
  //   await scanner.updateRuntimeSettings('speed');

  //   const runtimeSettings = await scanner.getRuntimeSettings();
  //   runtimeSettings.barcodeFormatIds = EnumBarcodeFormat.BF_ALL; // All common barcode types
  //   runtimeSettings.expectedBarcodesCount = 1; // Single barcode scan
  //   runtimeSettings.localizationModes = [EnumLocalizationMode.LM_SCAN_DIRECTLY, EnumLocalizationMode.LM_CONNECTED_BLOCKS];
  //   runtimeSettings.deblurLevel = 0; // ~ Deblur Modes - [DM_BASED_ON_LOC_BIN , DM_THRESHOLD_BINARIZATION]
  //   const region: Region = {
  //     regionLeft: 20,
  //     regionRight: 80,
  //     regionTop: 25,
  //     regionBottom: 75,
  //     regionMeasuredByPercentage: 1,
  //   };
  //   runtimeSettings.region = region;
  //   await scanner.updateRuntimeSettings(runtimeSettings);
  // }
  // // fix ios canvas memory usage limit
  // public releaseCanvas(canvas: HTMLCanvasElement): void {
  //   canvas.width = 1;
  //   canvas.height = 1;
  //   const ctx = canvas.getContext('2d');
  //   if (ctx) {
  //     ctx.clearRect(0, 0, 1, 1);
  //   }
  // }
  // Dynamsoft Scanner End

  // DataSymbol Scanner Start
  public createScanner(selectedBarcodeTypes: string[]): void {
    const savedBarcodeConfig: ISavedBarcodeConfig = JSON.parse(localStorage.getItem('barcodeScannerConfig'));
    const dataSymbolScannerSettings = {
      scanner: {
        key: AppConfiguration.GetConfiguration('DATASYMBOL_CONFIG').license,
        frameTimeout: 100,
        barcodeTimeout: 1000,
        beep: true
      },
      viewport: {
        id: 'datasymbol-barcode-viewport'
      },
      camera: {
        facingMode: '',
        id: ''
      },
      barcode: {
        barcodeTypes: selectedBarcodeTypes
      }
    };
    /* eslint-disable-next-line no-console */
    DSScanner.addEventListener('onError', (err) => { console.error(err); });
    DSScanner.addEventListener('onBarcode', (barcodeResult) => {
      this.$barcodeResults.next(barcodeResult);
    });

    DSScanner.addEventListener('onScannerReady', () => {
      if (!this.isPermissionGiven) {
        DSScanner.Destroy();
      } else {
        DSScanner.StartScanner();
        this.barcodeLibLoaded = true;
      }
    });

    DSScanner.addEventListener('onScannerDestroyed', () => {
      if (!this.isPermissionGiven) {
        this.createScanner(selectedBarcodeTypes);
      }
    });

    DSScanner.getVideoDevices((devices) => {
      if (devices.length > 0) {
        if (devices.length === 1 && devices[0].label === 'Camera 1' && devices[0].deviceId === '') { // Setting 'back' environment before camera permission granted
          dataSymbolScannerSettings.camera.facingMode = 'environment';
          this.selectedDeviceId = 'environment';
          DSScanner.Create(dataSymbolScannerSettings);
        } else {
          this.isPermissionGiven = true;
          this.camDevices = devices.slice();
          if (savedBarcodeConfig?.cameraId) { // Set last selected camera if available
            if (['user', 'environment'].includes(savedBarcodeConfig?.cameraId)) {
              dataSymbolScannerSettings.camera.facingMode = savedBarcodeConfig?.cameraId;
            } else {
              dataSymbolScannerSettings.camera.id = savedBarcodeConfig?.cameraId;
            }
            this.selectedDeviceId = savedBarcodeConfig?.cameraId;
          } else { // Setting 'back' environment if no last selected camera
            dataSymbolScannerSettings.camera.facingMode = 'environment';
            this.selectedDeviceId = 'environment';
          }
          DSScanner.Create(dataSymbolScannerSettings);
        }
      } else {
        this.utils.openSnackbar('Devices not found', 'OK', 10000);
      }
    });

  }

  public onBarcodeReady(barcodeResult): void {
    const barcode = DSScanner.bin2String(barcodeResult[0]); // Set to get the first barcode out of scanned barcodes
    this.scanSuccess.emit(barcode);
  }

  public onDeviceChange(deviceId: string): void {
    let scannerSettings = DSScanner.getScannerSettings();
    if (['user', 'environment'].includes(deviceId)) {
      scannerSettings = { ...scannerSettings, camera: { id: '', facingMode: deviceId } };
    } else {
      scannerSettings = { ...scannerSettings, camera: { facingMode: '', id: deviceId } };
    }
    DSScanner.setScannerSettings(scannerSettings);

    this.saveBarcodeConfig(deviceId, null, 'camera');
  }

  public openScannerConfiguration(): void {
    this.popupConfig.visible = true;
  }

  public setBarcodeTypeConfiguration(): string[] {
    const savedBarcodeConfig: ISavedBarcodeConfig = JSON.parse(localStorage.getItem('barcodeScannerConfig'));
    const selectedBarcodeTypes = savedBarcodeConfig?.barcodeTypes ? savedBarcodeConfig?.barcodeTypes : [...this.defaultBarcodeTypes];
    this.barcodeTypes.forEach((barcodeType) => {
      barcodeType.value = selectedBarcodeTypes.includes(barcodeType.code);
      const item = {
        colSpan: 2,
        name: barcodeType.code,
        editorType: 'dxSwitch',
        label: { text: barcodeType.name, showColon: false },
        editorOptions: { value: barcodeType.value, onValueChanged: (event) => this.onBarcodeTypeConfigChange(event, barcodeType.code) }
      };
      if (barcodeType.dimention === '1D') {
        this.barcodeType1Ds.push(item);
      } else {
        this.barcodeType2Ds.push(item);
      }
    });
    return selectedBarcodeTypes;
  }

  public saveBarcodeConfig(selectedDeviceId: string, selectedBarcodeTypes: string[], configType: string): void {
    let config: ISavedBarcodeConfig = JSON.parse(localStorage.getItem('barcodeScannerConfig'));
    if (configType === 'camera') {
      config = { ...config, cameraId: selectedDeviceId };
    } else {
      config = { ...config, barcodeTypes: selectedBarcodeTypes };
    }
    localStorage.setItem('barcodeScannerConfig', JSON.stringify(config));
  }

  public onBarcodeTypeConfigChange(event, barcodeTypeCode): void {
    const scannerSettings = DSScanner.getScannerSettings();
    let selectedBarcodeTypes = scannerSettings.barcode.barcodeTypes;

    if (event.value) {
      if (selectedBarcodeTypes.indexOf(barcodeTypeCode) === -1) {
        selectedBarcodeTypes.push(barcodeTypeCode);
      }
    } else {
      selectedBarcodeTypes = selectedBarcodeTypes.filter((type) => type !== barcodeTypeCode);
    }

    scannerSettings.barcode.barcodeTypes = selectedBarcodeTypes;
    DSScanner.setScannerSettings(scannerSettings);

    this.saveBarcodeConfig(null, selectedBarcodeTypes, 'barcodeTypes');
  }

  public resetBarcodeTypeConfig(): void {
    this.utils.openDxConfirmDialog('Do you wish to reset barcode type config to its defaults?', 'Warning')
      .then((dialogResult) => {
        if (dialogResult) {
          const scannerSettings = DSScanner.getScannerSettings();
          scannerSettings.barcode.barcodeTypes = [...this.defaultBarcodeTypes];
          DSScanner.setScannerSettings(scannerSettings);

          this.saveBarcodeConfig(null, null, 'barcodeTypes');

          this.barcodeType1Ds.forEach((item) => {
            item.editorOptions.value = this.defaultBarcodeTypes.includes(item.name);
          });
          this.barcodeType2Ds.forEach((item) => {
            item.editorOptions.value = false;
          });
          this.barcodeTypeConfigForm.instance.repaint();
        }
      });
  }
  // DataSymbol Scanner End
}
