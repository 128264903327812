import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BypassSanitizerPipe } from '@pipes/bypass-sanitizer.pipe';
import { UnderConstructionComponent } from '@components/generic/under-construction/under-construction.component';
import { PageNotFoundComponent } from '@components/generic/page-not-found/page-not-found.component';
import { ImageComponent } from '@components/generic/image/image.component';
import { EditFloatingMenuComponent } from '@components/generic/edit-floating-menu/edit-floating-menu.component';
import { TextDialogComponent } from '@components/generic/text-dialog/text-dialog.component';
import { TourBoxComponent } from '@components/generic/tour-box/tour-box.component';
import { GenericDataGridComponent } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { DxTooltipModule } from 'devextreme-angular';
import { DevExtremeModule } from '@modules/devextreme.module';
import { GenericPopUpWithDataGridComponent } from '@components/generic/generic-pop-up-with-data-grid/generic-pop-up-with-data-grid.component';
import { SysnetCommonHeaderComponent } from '@components/dashboard/apps/sysnet/sysnet-common-header/sysnet-common-header.component';
import { SysnetCommonComponent } from '@components/dashboard/apps/sysnet/sysnet-common/sysnet-common.component';
import { RouterModule } from '@angular/router';
import { AddStockItemComponent } from '@components/dashboard/apps/sysnet/stock/update-stock/add-stock-item/add-stock-item.component';
import { MultipleLookupSelectionComponent } from '@components/generic/multiple-lookup-selection/multiple-lookup-selection.component';
import { BarcodeScannerComponent } from '@components/generic/barcode-scanner/barcode-scanner.component';
import { BarcodeCameraComponent } from '@components/generic/barcode-camera/barcode-camera.component';
import { StockMobileCommonHeaderComponent } from '@components/dashboard/apps/sysnet/stock-mobile/stock-mobile-common-header/stock-mobile-common-header.component';
import { CountStocktakeComponent } from '@components/dashboard/apps/sysnet/stock/stocktakes/count-stocktake/count-stocktake.component';
import { StocktakeItemsListComponent } from '@components/dashboard/apps/sysnet/stock/stocktakes/stocktake-items-list/stocktake-items-list.component';
import { StocktakeItemsListTabbedComponent } from '@components/dashboard/apps/sysnet/stock/stocktakes/stocktake-items-list-tabbed/stocktake-items-list-tabbed.component';
import { StocktakeEntryCountListComponent } from '@components/dashboard/apps/sysnet/stock/stocktakes/stocktake-entry-count-list/stocktake-entry-count-list.component';
import { AutocompletePlacesComponent } from '@components/generic/autocomplete-places/autocomplete-places.component';
import { AssignSupplierInvCodeComponent } from '@components/dashboard/apps/sysnet/stock/receive/assign-supplier-inv-code/assign-supplier-inv-code.component';
import { MultiItemBarcodeSelectionListComponent } from '@components/dashboard/apps/sysnet/stock/stocktakes/multi-item-barcode-selection-list/multi-item-barcode-selection-list.component';
import { RruleGeneratorComponent } from '@components/generic/rrule-generator/rrule-generator.component';
import { QuickEntryDataGridComponent } from '@components/generic/quick-entry-data-grid/quick-entry-data-grid.component';
import { AddStockFromTemplateDataGridComponent } from '@components/dashboard/apps/sysnet/stock/update-stock/add-stock-from-template-data-grid/add-stock-from-template-data-grid.component';
import { FormatFactorLabelPipe } from '../utilities/factorsHelper';
import { YesNoDialogComponent } from '../components/generic/yes-no-dialog/yes-no-dialog.component';
import { AngularMaterialModule } from './angular-material.module';

@NgModule({
    imports: [
        CommonModule,
        AngularMaterialModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        DevExtremeModule,
        DxTooltipModule,
        RouterModule
    ],
    declarations: [
        YesNoDialogComponent,
        TextDialogComponent,
        BypassSanitizerPipe,
        UnderConstructionComponent,
        PageNotFoundComponent,
        ImageComponent,
        EditFloatingMenuComponent,
        GenericDataGridComponent,
        GenericPopUpWithDataGridComponent,
        SysnetCommonComponent,
        SysnetCommonHeaderComponent,
        AddStockItemComponent,
        TourBoxComponent,
        MultipleLookupSelectionComponent,
        BarcodeScannerComponent,
        BarcodeCameraComponent,
        StockMobileCommonHeaderComponent,
        CountStocktakeComponent,
        StocktakeItemsListComponent,
        StocktakeItemsListTabbedComponent,
        StocktakeEntryCountListComponent,
        FormatFactorLabelPipe,
        AutocompletePlacesComponent,
        AssignSupplierInvCodeComponent,
        MultiItemBarcodeSelectionListComponent,
        RruleGeneratorComponent,
        QuickEntryDataGridComponent,
        AddStockFromTemplateDataGridComponent
    ],
    exports: [
        NgxSpinnerModule,
        YesNoDialogComponent,
        BypassSanitizerPipe,
        ImageComponent,
        EditFloatingMenuComponent,
        GenericDataGridComponent,
        DevExtremeModule,
        GenericPopUpWithDataGridComponent,
        SysnetCommonHeaderComponent,
        AddStockItemComponent,
        MultipleLookupSelectionComponent,
        BarcodeScannerComponent,
        BarcodeCameraComponent,
        StockMobileCommonHeaderComponent,
        CountStocktakeComponent,
        StocktakeItemsListComponent,
        StocktakeItemsListTabbedComponent,
        StocktakeEntryCountListComponent,
        FormatFactorLabelPipe,
        AutocompletePlacesComponent,
        AssignSupplierInvCodeComponent,
        MultiItemBarcodeSelectionListComponent,
        RruleGeneratorComponent,
        QuickEntryDataGridComponent,
        AddStockFromTemplateDataGridComponent,
        UnderConstructionComponent
    ]
})
export class SharedModule { }
