<dx-data-grid class="generic-data-grid"
    [dataSource]="dataGridConfig.dataSource"
    [keyExpr]="dataGridConfig.keyExpr"
    [columns] = "dataGridConfig.columns"
    [repaintChangesOnly]="dataGridConfig.repaintChangesOnly"
    [allowColumnReordering]="dataGridConfig.allowColumnReordering"
    [allowColumnResizing]="dataGridConfig.allowColumnResizing"
    [columnFixing]="dataGridConfig.columnFixing"
    [columnResizingMode]="dataGridConfig.columnResizingMode"
    [showBorders]="dataGridConfig.showBorders"
    [showColumnLines]="dataGridConfig.showColumnLines"
    [showRowLines]="dataGridConfig.showRowLines"
    [rowAlternationEnabled]="dataGridConfig.rowAlternationEnabled"
    [columnHidingEnabled]="dataGridConfig.columnHidingEnabled"
    [syncLookupFilterValues]="dataGridConfig.syncLookupFilterValues"
    [columnChooser]="dataGridConfig.columnChooser"
    [selection]="dataGridConfig.selection"
    [pager]="dataGridConfig.pager"
    [paging]="dataGridConfig.paging"
    [sorting]="dataGridConfig.sorting"
    [editing]="dataGridConfig.editing"
    [export]="dataGridConfig.export"
    [searchPanel]="dataGridConfig.searchPanel"
    [filterRow]="dataGridConfig.filterRow"
    [filterPanel]="dataGridConfig.filterPanel"
    [headerFilter]="dataGridConfig.headerFilter"
    [groupPanel]="dataGridConfig.groupPanel"
    [grouping]="dataGridConfig.grouping"
    [remoteOperations]="dataGridConfig.remoteOperations"
    [summary]="dataGridConfig.summary"
    [scrolling]="dataGridConfig.scrolling"
    [stateStoring]="dataGridConfig.stateStoring"
    [errorRowEnabled]="dataGridConfig.errorRowEnabled"
    [keyboardNavigation]="dataGridConfig.keyboardNavigation"
    (onSelectionChanged)="dataGridConfig.onSelectionChanged($event)"
    (onContextMenuPreparing)="dataGridConfig.onContextMenuPreparing($event)"
    (onEditorPreparing)="dataGridConfig.onEditorPreparing($event)"
    (onEditorPrepared)="dataGridConfig.onEditorPrepared($event)"
    (onToolbarPreparing)="dataGridConfig.onToolbarPreparing($event)"
    (onSaving)="dataGridConfig.onSaving($event)"
    (onRowPrepared)="dataGridConfig.onRowPrepared($event)"
    (onRowValidating)="dataGridConfig.onRowValidating($event)"
    (onSaved)="dataGridConfig.onSaved($event)"
    (onContentReady)="dataGridConfig.onContentReady($event)"
    (onEditCanceled)="dataGridConfig.onEditCanceled($event)"
    (onInitNewRow)="dataGridConfig.onInitNewRow($event)"
    (onEditingStart)="dataGridConfig.onEditingStart($event)"
    (onCellPrepared)="dataGridConfig.onCellPrepared($event)"
    (onCellDblClick)="dataGridConfig.onCellDblClick($event)"
    (onFocusedCellChanged)="dataGridConfig.onFocusedCellChanged($event)"
    (onFocusedCellChanging)="dataGridConfig.onFocusedCellChanging($event)"
>
<dxo-filter-row [visible]="filterRowVisible"></dxo-filter-row>
<ng-container *ngIf="dataGridConfig.selection?.mode === 'single'">
    <div *dxTemplate="let data of 'singleSelectCellTemplate'">
        <dx-radio-group [value]="selectedRowKey" [valueExpr]="dataSourceConfig.dataSourcePrimaryKey" [items]="[data.data]">
        </dx-radio-group>
    </div>
</ng-container>
<ng-container *ngIf="dataGridActionColumn.enabled">
    <div *dxTemplate="let cellInfo of 'actionColumnTemplate'">
        <ng-container *ngIf="cellInfo.row?.isEditing then editing else notEditing"></ng-container>
        <ng-template #editing>
            <a href="#" tabindex="0" (click)="dataGridActionColumn.onSaveClick(cellInfo)" i18n="save|@@save">Save</a>&nbsp;
            <a href="#" tabindex="0" (click)="dataGridActionColumn.onCancelClick()" i18n="cancel|@@cancel" (keydown)="onKeyDown($event, cellInfo)">Cancel</a>&nbsp;
        </ng-template>
        <ng-template #notEditing>
            <dx-menu cssClass="action-column-menu" [items]="dataGridActionColumn.processActionMenu(cellInfo, dataGridActionColumn.actionMenu)"
                (onItemClick)="dataGridActionColumn.onActionItemClick($event, cellInfo)">
            </dx-menu>
        </ng-template>
    </div>
</ng-container>
<ng-container *ngIf="dataGridConfig.hasEditMode">
    <div *dxTemplate="let data of 'editModeSwitchTemplate'">
        <span class="edit-mode" i18n="editMode|@@editMode">Edit Mode <dx-switch [value]="isEditMode" (onValueChanged)="onSwitchChanged($event)"></dx-switch></span>
    </div>
</ng-container>
</dx-data-grid>