/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { PurchaseOrderHeaderListViewSupplier } from './purchaseOrderHeaderListViewSupplier';
import { StockInLocationListViewLocation } from './stockInLocationListViewLocation';
import { UpdateDateTime } from './updateDateTime';

export interface PurchaseOrderHeaderListView { 
    /**
     * The unique identifier of this record.
     */
    purchaseOrderHeaderId?: number;
    orderNumber?: string;
    /**
     * A reference to the primary key in the Suppliers Table
     */
    supplierId?: number;
    /**
     * A reference to the primary key in the VenueLocations Table
     */
    locationId?: number;
    processed?: boolean;
    processedByUserId?: string;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    dateOrdered?: Date;
    comment?: string;
    deliveryInstructions?: string;
    emailSent?: boolean;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    requiredDateTime?: Date;
    isCreditCharge?: boolean;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    createdByUserId?: string;
    companyLogo?: string;
    companyName?: string;
    companyTradingAs?: string;
    businessNumber?: string;
    companyNumber?: string;
    companyAddress1?: string;
    companyAddress2?: string;
    companySuburb?: string;
    companyState?: string;
    companyPostalCode?: string;
    deliveryAddress1?: string;
    deliveryAddress2?: string;
    deliverySuburb?: string;
    deliveryState?: string;
    deliveryPostalCode?: string;
    deliveryEmail?: string;
    deliveryContactNumber?: string;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    companyWebsite?: string;
    companyEmail?: string;
    companyPhone?: string;
    received?: boolean;
    totalCost?: number;
    location?: StockInLocationListViewLocation;
    supplier?: PurchaseOrderHeaderListViewSupplier;
}