/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stores information about Stock (Inventory) items by location including par levels (minimum and maximum desired holdings), quantities on hand and last and average costs.  Quantity and costs information is updated based on stock movement processes like purchases (stock received), sales, discrepencies (wastage, theft etc) , transfers to other locations. 
 */
export interface StockInLocationUpdate { 
    qtyInStock?: number;
    averageCost?: number;
    lastCost?: number;
    minimum?: number;
    maximum?: number;
    onOrder?: number;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    dateLastSold?: Date;
    isActive?: boolean;
    counted?: boolean;
    qtyAtStocktake?: number;
    qtyStockEntered?: number;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    counterStart?: Date;
    qtySold?: number;
    defaultWastage?: number;
    forecast?: boolean;
    itemsPerContainer?: number;
    hideInStocktake?: boolean;
    awaitingCredit?: number;
    /**
     * A reference to the primary key in the VenueLocations Table
     */
    stockKeepingLocationId?: number;
    isDispensed?: boolean;
    liveQtyInStock?: number;
    /**
     * A reference to the primary key in the StockStatuses Table
     */
    stockStatusId?: number;
    binNo?: string;
    deposit?: number;
    excludeFromAutoReorder?: boolean;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    dateCostsChanged?: Date;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
    reason?: string;
}