<dx-popup
    [(visible)]="popupConfig.visible"
    [title]="popupConfig.title"
    [showCloseButton]="true"
    [hideOnOutsideClick]="false"
    [shading]="true"
    shadingColor="rgba(0, 0, 0, 0.3)"
    (onShowing)="popupConfig.onShowing($event)"
    (onHiding)="popupConfig.onHiding($event)"
    [fullScreen]="globalsService?.isMobile"
    >
    <div *dxTemplate="let data of 'content'">
        <app-generic-data-grid #genericDataGrid
            [dataSourceConfig]="genericDataSourceConfig"
            [dataGridConfig]="genericDataGridConfig"
            [joinTableColumnConfig]="genericJoinTableColumnConfig"
            [dataGridActionColumn]="genericDataGridActionColumn"
        ></app-generic-data-grid>
    </div>
</dx-popup>