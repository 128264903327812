/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

export interface StocktakeItemsDataEntryView { 
    /**
     * The unique identifier of this record.
     */
    stocktakeDataEntryId?: number;
    /**
     * A reference to the primary key in the StocktakeItems Table
     */
    stocktakeItemsId?: number;
    /**
     * The type of Stock Take Data Entry. Values are 'inners', 'outers', or 'factor'. If using factor a factorId must be specified.
     */
    type?: StocktakeItemsDataEntryView.TypeEnum;
    /**
     * A reference to the primary key in the Factors Table
     */
    factorId?: number;
    qtyCounted?: string;
    totalInners?: number;
    details?: string;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    reversed?: boolean;
    userName?: string;
}
export namespace StocktakeItemsDataEntryView {
    export type TypeEnum = 'Factor' | 'Inners' | 'Outers' | 'InnersOutersTotalCount';
    export const TypeEnum = {
        Factor: 'Factor' as TypeEnum,
        Inners: 'Inners' as TypeEnum,
        Outers: 'Outers' as TypeEnum,
        InnersOutersTotalCount: 'InnersOutersTotalCount' as TypeEnum
    };
}