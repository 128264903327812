/**
 * Portal API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserResponseAccess } from './userResponseAccess';

export interface UserResponse { 
    /**
     * The user's unique id
     */
    _id?: string;
    /**
     * The user's unique auth0 id
     */
    auth0UserId?: string;
    /**
     * The user's first name
     */
    firstName?: string;
    /**
     * The user's last name
     */
    lastName?: string;
    /**
     * The user's email
     */
    email?: string;
    /**
     * The user's mobile phone number
     */
    mobile?: string;
    /**
     * The user's is restricted to 1 OrganisationId
     */
    restrictToOrganisationId?: string;
    /**
     * The users auth0 client id
     */
    auth0ClientId?: string;
    /**
     * Is the user trusted to make admin related requests
     */
    isTrusted?: boolean;
    /**
     * An Array of Access Views
     */
    access?: Array<UserResponseAccess>;
    notificationTokens?: Array<string>;
    isIntegration?: boolean;
}