/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StockInLocationListViewStockPreferredSupplier } from './stockInLocationListViewStockPreferredSupplier';
import { StockInLocationListViewStockStockGroup } from './stockInLocationListViewStockStockGroup';

export interface StockInLocationListViewStock { 
    /**
     * The unique identifier of this record.
     */
    stockId?: number;
    inventoryCode?: number;
    name?: string;
    freight?: number;
    outers?: string;
    inners?: string;
    outersOnly?: boolean;
    minReorderQty?: number;
    mls?: number;
    discretionaryStatus?: number;
    /**
     * 0 = stock, 1 = recipe (for now)
     */
    type?: number;
    alternativeInventoryCode?: string;
    preferredSupplierInventoryCode?: string;
    liquorfileStockNo?: number;
    innersPerOuter?: number;
    stockGroup?: StockInLocationListViewStockStockGroup;
    preferredSupplier?: StockInLocationListViewStockPreferredSupplier;
    manufacturerDistributor?: StockInLocationListViewStockPreferredSupplier;
}