import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {
  public smallScreen = false;

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
  }
  public ngOnInit(): void {
    this.breakpointObserver.observe([
      '(max-width: 850px)',
      '(max-height: 600px)'
    ]).subscribe((result) => {
      this.smallScreen = result.matches;
    });
  }

}
