/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StockDiscrepancyDetailListViewFactorCalculationsFactor } from './stockDiscrepancyDetailListViewFactorCalculationsFactor';
import { StockDiscrepancyDetailListViewFactorCalculationsStock } from './stockDiscrepancyDetailListViewFactorCalculationsStock';

export interface StockTransferDetailsListViewFactorCalculations { 
    sourceLocationQtyInStock?: number;
    sourceLocationUnitQtyInStock?: number;
    destinationLocationUnitQtyInStock?: number;
    destinationLocationQtyInStock?: number;
    unitQtyToTransfer?: number;
    qtyToTransfer?: number;
    sourceLocationAfterUnitQtyInStock?: number;
    sourceLocationAfterQtyInStock?: number;
    destinationLocationAfterUnitQtyInStock?: number;
    destinationLocationAfterQtyInStock?: number;
    transferValue?: number;
    variance?: number;
    destinationUnitMaximum?: number;
    destinationUnitMinimum?: number;
    stock?: StockDiscrepancyDetailListViewFactorCalculationsStock;
    factor?: StockDiscrepancyDetailListViewFactorCalculationsFactor;
}