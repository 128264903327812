import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Lock } from 'auth0-lock';
import { GlobalsService } from '@services/global.service';
import { UsersService } from '@services/users.service';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment';
import { pages } from '../redux/store';
import AppConfiguration from '../../../../hal.common.ui/utilities/AppConfig';

@Injectable()
export class Auth0Service {

  // Initializing our Auth0Lock
  public lock: Auth0Lock;
  public $isAuthenticated = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private globals: GlobalsService,
    private usersService: UsersService
    ) {
    if (!AppConfiguration.GetConfiguration(AppConfiguration.AUTH0_APP_CLIENT_ID)) {
      // this is for unit tests to skip;
      return;
    }
    this.init();
  }

  public init(): void {
    this.lock = new Auth0Lock(AppConfiguration.GetConfiguration(AppConfiguration.AUTH0_APP_CLIENT_ID),
      AppConfiguration.GetConfiguration(AppConfiguration.AUTH0_DOMAIN), {
        theme: {
          logo: './assets/img/branding/hl-cloud/H&L-Cloud-LRG-Transparent.png',
          primaryColor: '#313C50'
          // primaryColor: '#77C59A',
        },
        languageDictionary: {
          title: 'H&L Cloud Portal'
        },
        container: 'login-container',
        configurationBaseUrl: 'https://cdn.au.auth0.com',
        allowedConnections: ['Username-Password-Authentication'],
        avatar: null,
        allowSignUp: false,
        closable: true,
        autoclose: true,
        rememberLastLogin: false,
        auth: {
          redirect: false,
          sso: true,
          params: {
            scope: 'openid email'
            // scope: 'openid email offline_access' // add the offline_access to generate a refresh token
          }
        }
    });
    // Listening for the authenticated event
    this.lock.on('authenticated', (authResult) => {
      // console.log('AUTHO LOGIN', authResult);
      // Use the token in authResult to getUserInfo() and save it to localStorage
      this.lock.getUserInfo(authResult.accessToken, async (error, profile) => {
        if (error) {
          AppConfiguration.logger.info(error);
          alert(error);

          return;
        }
        this.globals.setAuth0Token(authResult.idToken);
        this.setLoginLocalStorage(authResult, profile);

        const user = await this.usersService.getLoggedInUser();
        this.setRidChain();
        this.$isAuthenticated.next(true);
        this.globals.setState({ user, organisations: undefined, selectedOrganisation: undefined });
        this.router.navigate([pages.DASHBOARD.url]);
      });
    });

  }

  public login(): void {
    this.lock.hide(); // clear before show
    this.lock.show();
  }
  public logout(): void {
    // this.globals.setLoggedInUser(null);
    this.globals.setUser(null);
    this.$isAuthenticated.next(false);
    localStorage.removeItem('organisation');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('id_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('users_');
    localStorage.removeItem('prepareStocktakeConfig');
    this.globals.setSelectedOrganisation(undefined);
    this.globals.setOrganisations(undefined);

    this.router.navigate([pages.LOGIN.url]);
  }

  public setLoginLocalStorage(authResult, profile): void {
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('accessToken', authResult.accessToken);
    localStorage.setItem('profile', JSON.stringify(profile));
  }

  public setRidChain(): void {
    AppConfiguration.rsh.setVal(
      'rid_chain',
      'customer-portal-' + (Math.ceil(Math.random() * 10000)).toString() + '-' + moment(new Date()).format('DD-MM'));
  }

}
