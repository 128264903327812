import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { StockCloneInsert, StockView } from '@modules/SDKs/sysnetApi';
import { GlobalsService } from '@services/global.service';
import { StockDataGridService, StockTypesId } from '@services/grid/stock-data-grid.service';
import { StockGroupsDataGridService } from '@services/grid/stock-groups-data-grid.service';
import { UtilityService } from '@services/utility.service';
import { DxFormComponent, DxLookupComponent, DxPopupComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data';
import { Properties as dxLookupOptions } from 'devextreme/ui/lookup';
import { Properties as dxPopupOptions } from 'devextreme/ui/popup';
import { DxConfig, FormatFn, GridConstants, IValidationRules, LookupConstants, ValidationFn } from 'src/app/Constants';
import { ErrorMessages } from 'src/app/utilities/ErrorMessages';
import { StockCreationTemplatesDataGridService } from '@services/grid/stock-creation-templates-data-grid.service';
import { dxAccordionItem, Item } from 'devextreme/ui/accordion';
import { ILookupDataSourceSetupReturnObj } from 'src/app/types/interfaces/GeneralService';
import { StockCreationTemplatesComponent } from '../stock-creation-templates/stock-creation-templates.component';
import { AddStockFromTemplateDataGridComponent } from '../add-stock-from-template-data-grid/add-stock-from-template-data-grid.component';

export enum AddStockType { STOCK = 'Stock', RECIPE = 'Recipe' }
@Component({
  selector: 'app-add-stock-item',
  templateUrl: './add-stock-item.component.html',
  styleUrls: ['./add-stock-item.component.scss']
})
export class AddStockItemComponent implements OnInit {
  public static readonly STOCK_GROUP = 'stockGroupId';

  public popupConfig: dxPopupOptions = {
    visible: false,
    onHiding: (e) => {
      this.selectedStockCreationTemplateId = null;
      this.stockCreationTemplateList = [];
      this.lookupConfig.stockCreationTemplate.dataSourceFilter = [];
      this.isGenerateEditDetails = false;
      this.stockCreationTemplateLookup.instance.getDataSource().load();
      this.stockForm?.instance.resetValues();
    }
  };
  public dxLookupConfig: dxLookupOptions = DxConfig.lookup;
  public stock: StockView = {};
  public selectedStockCreationTemplateId: number;
  public stockNameInputTitle = '';
  public stockGroupDataSource;
  public stockCreationTemplateDataSource;
  public isGenerateEditDetails = false;
  public lookupConfig = {
    stockGroup: {
      dataSourceLoadMode: 'processed',
      dataSourceService: this.stockGroupsDataGridService,
      dataSourceServiceSearchFn: 'searchStockGroupsByMultipleSearchExpr',
      dataSourceServiceGetFn: 'getStockGroup',
      primaryKey: 'stockGroupId',
      dataSortBy: 'number'
    },
    stockCreationTemplate: {
      dataSourceLoadMode: 'processed',
      dataSourceService: this.stockCreationTemplatesDataGridService,
      dataSourceServiceSearchFn: 'searchStockCreationTemplates',
      dataSourceServiceGetFn: 'getStockCreationTemplate',
      primaryKey: 'stockCreationTemplateId',
      dataSourceFilter: []
    }
  };

  public types;
  public validationRules: IValidationRules = {};
  public currencyFormat = FormatFn.currencyFormat;
  public isClone;
  public stockCreationTemplateList = [];
  public stockFromTemplate = {};
  public readonly addStockType = AddStockType;

  public fromTemplateOverrideGrid = {
    factors: {
      key: 'templateFactorId',
      columns:  [
        {
          dataField: 'templateFactorId',
          dataType: 'string',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Id',
          allowFiltering: false,
          visible: false
        },
        {
          dataField: 'name',
          dataType: 'string',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Description',
          visible: true
        },
        {
          dataField: 'divider',
          dataType: 'number',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Divider',
          allowFiltering: false,
          visible: true
        },
        {
          type: 'buttons',
          cellTemplate: 'actionColumnTemplate',
          showInColumnChooser: false,
          minWidth: 90
        }
      ],
      editing: {
        mode: 'row',
        allowAdding: false,
        allowUpdating: false,
        allowDeleting: true,
        confirmDelete: false
      }
    },
    products: {
      key: 'templateFactorId',
      columns: [
        {
          dataField: 'templateFactorId',
          dataType: 'string',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Id',
          visible: false
        },
        {
          dataField: 'fullName',
          dataType: 'string',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Full Description',
          visible: true,
          validationRules: [ValidationFn.getMaxLengthRule(50)]
        },
        {
          dataField: 'shortName',
          dataType: 'string',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Short Description',
          visible: true,
          validationRules: [ValidationFn.getMaxLengthRule(16)]
        },
        {
          dataField: 'productGroup',
          dataType: 'string',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Product Group',
          allowEditing: false,
          visible: true
        },
        {
          dataField: 'factor',
          dataType: 'string',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Factor',
          allowEditing: false,
          visible: true
        },
        {
          type: 'buttons',
          cellTemplate: 'actionColumnTemplate',
          showInColumnChooser: false,
          minWidth: 90
        }
      ],
      editing: {
        mode: 'row',
        allowAdding: false,
        allowUpdating: true,
        allowDeleting: true,
        confirmDelete: false
      }
    },
    stockInLocations: {
      key: 'locationId',
      columns: [
        {
          dataField: 'number',
          dataType: 'string',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Location #',
          visible: true
        },
        {
          dataField: 'name',
          dataType: 'string',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Location',
          visible: true
        },
        {
          dataField: 'LocationType.description',
          dataType: 'string',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Location Type',
          visible: true
        },
        {
          type: 'buttons',
          cellTemplate: 'actionColumnTemplate',
          showInColumnChooser: false,
          minWidth: 90
        }
      ],
      editing: {
        mode: 'row',
        allowAdding: false,
        allowUpdating: false,
        allowDeleting: true,
        confirmDelete: false
      }
    }
  };
  public productsToRemove: number[] | number = -1; // needs to assign a value to be able to use it in the grid onChange(); cannot be [], null, or undefined.

  public accordionItems: Item[] = [
    { template: 'factorTempl', titleTemplate: 'factorTitleTemp' },
    { template: 'productTempl', titleTemplate: 'productTitleTemp' },
    { template: 'sILTempl', titleTemplate: 'sILTitleTemp' }
  ];

  public savePanelVisible = false;
  public loadPanelVisible = false;

  @Input() public type: AddStockType;
  @Output() public readonly stockItemSaved = new EventEmitter<number>();
  @ViewChild('addStockItemPopup', { static: false }) public addStockItemPopup: DxPopupComponent;
  @ViewChild(DxFormComponent, { static: false }) public stockForm: DxFormComponent;
  @ViewChild('stockCreationTemplates', { static: false }) public stockCreationTemplates: StockCreationTemplatesComponent;
  @ViewChild('fromTemplateOverrideGridFactors', { static: false }) public fromTemplateOverrideGridFactors: AddStockFromTemplateDataGridComponent;
  @ViewChild('fromTemplateOverrideGridProducts', { static: false }) public fromTemplateOverrideGridProducts: AddStockFromTemplateDataGridComponent;
  @ViewChild('fromTemplateOverrideGridSIL', { static: false }) public fromTemplateOverrideGridSIL: AddStockFromTemplateDataGridComponent;
  @ViewChild('stockCreationTemplateLookup', { static: false }) public stockCreationTemplateLookup: DxLookupComponent;
  constructor(public globalsService: GlobalsService,
              private stockGroupsDataGridService: StockGroupsDataGridService,
              private stockCreationTemplatesDataGridService: StockCreationTemplatesDataGridService,
              private stockDataGridService: StockDataGridService,
              private utils: UtilityService, private router: Router) {
    this.stockGroupDataSource = this.setupLookupDataSource(this.lookupConfig.stockGroup);
    this.stockCreationTemplateDataSource = this.setupTemplateLookupDataSource(this.lookupConfig.stockCreationTemplate);
    this.validationRules = {
      name: [ValidationFn.getRequiredRule(), ValidationFn.getMaxLengthRule(50)],
      inventoryCode: [...ValidationFn.getPositiveIntegerRule(),

                      ValidationFn.getAsyncValidation('Inv Code is not unique', (options) => this.stockDataGridService.isInventoryCodeUnique(options?.value))],
      lastCost: [...ValidationFn.getDecimalNumRule(19, 4, true)],
      stockGroupId: [ValidationFn.getRequiredRule()],
      type: [ValidationFn.getRequiredRule()]
    };
    this.types = this.stockDataGridService.types;
   }

  public ngOnInit(): void {}

  public openPopup(stock: StockView = null): void {
    this.stock.type = this.type === AddStockType.STOCK ? 0 : 1 ;
    this.popupConfig.title = this.type === AddStockType.STOCK ? 'Add Stock Item' : 'Add New Recipe' ;
    this.stockNameInputTitle = this.type === AddStockType.STOCK ? 'Stock Name' : 'Description' ;
    if (stock) {
      this.isClone = true;
      this.stock = stock;
    } else {
      this.isClone = false;
      this.stock.lastCost = null; // force the number box to be blank, otherwise by default it'll show 0
    }
    this.stock.inventoryCode = null; // force the number box to be blank, otherwise by default it'll show 0
    this.popupConfig.visible = true;
  }

  public setupLookupDataSource(lookupConfig): ILookupDataSourceSetupReturnObj {
    return {
      store: new CustomStore({
        key: lookupConfig.primaryKey,
        loadMode: lookupConfig.dataSourceLoadMode,
        load: async (loadOptions: LoadOptions) => {
          try {
            return await lookupConfig.dataSourceService[lookupConfig.dataSourceServiceSearchFn](loadOptions);
          } catch (error) { /* empty */ }
        },
        byKey: async (key) => {
          try {
            if (!key) { return null; }
            return await lookupConfig.dataSourceService[lookupConfig.dataSourceServiceGetFn](key);
          } catch (error) { /* empty */ }
        }
      }),
      sort: lookupConfig.dataSortBy,
      pageSize: LookupConstants.PAGE_SIZE,
      paginate: true
    };
  }

  public setupTemplateLookupDataSource(lookupConfig): ILookupDataSourceSetupReturnObj {
    return {
      store: new CustomStore({
        key: lookupConfig.primaryKey,
        loadMode: lookupConfig.dataSourceLoadMode,
        load: async (loadOptions: LoadOptions) => {
          try {
            if (lookupConfig.dataSourceFilter.length === 0) { return []; }
            loadOptions.filter = lookupConfig.dataSourceFilter;
            const result = await lookupConfig.dataSourceService[lookupConfig.dataSourceServiceSearchFn](loadOptions);
            this.stockCreationTemplateList.push(...result.data);
            return result;
          } catch (error) { /* empty */ }
        },
        byKey: async (key) => {
          try {
            if (!key) { return null; }
            return await lookupConfig.dataSourceService[lookupConfig.dataSourceServiceGetFn](key);
          } catch (error) { /* empty */ }
        }
      }),
      sort: lookupConfig.dataSortBy,
      pageSize: LookupConstants.PAGE_SIZE,
      paginate: true
    };
  }

  public displayExprStockGroup(item): string { return FormatFn.applyNumberNameFormat(item); }

  public async save(): Promise<void> {
    if (!this.stockForm?.instance.validate().isValid) { return null; }
    try {
      this.savePanelVisible = true;
      if (this.stock.inventoryCode === null) { this.stock.inventoryCode = undefined; } // if no input for invCode, don't send up null as it'll throw an error
      if (!this.stock.lastCost) { this.stock.lastCost = 0; }
      let res;
      let stockId;
      const { stockGroupId, name, inventoryCode, type, lastCost } = this.stock;

      if (!this.selectedStockCreationTemplateId) {
        if (this.isClone) {
          const obj: StockCloneInsert = { stockGroupId, name, inventoryCode };
          res = await this.stockDataGridService.cloneStock(
            this.stock.stockId,
            obj
          );
          stockId = res?.stockId;
        } else {
          const obj = { name, type, stockGroupId, inventoryCode, lastCost };
          res = await this.stockDataGridService.insertStock(obj);
          stockId = res?.stockId;
        }
      } else {
        const obj = { name, inventoryCode, cost: lastCost, stockCreationTemplateId: this.selectedStockCreationTemplateId };
        const override: {
          stockCreationTemplateFactorIdsFactorsToExclude?: number[],
          stockCreationTemplateFactorIdsProductsToExclude?: number[],
          productsToOverride?: object[],
          stockInLocationLocationIdsToExclude?: number[]
        } = {};
        if (this.fromTemplateOverrideGridFactors?.removedRows.length) {
          override.stockCreationTemplateFactorIdsFactorsToExclude = this.fromTemplateOverrideGridFactors.removedRows;
        }
        if (this.fromTemplateOverrideGridProducts?.removedRows.length) {
          override.stockCreationTemplateFactorIdsProductsToExclude = this.fromTemplateOverrideGridProducts.removedRows;
        }
        if (this.fromTemplateOverrideGridProducts?.updatedRows.length) {
          override.productsToOverride = this.fromTemplateOverrideGridProducts.updatedRows;
        }
        if (this.fromTemplateOverrideGridSIL?.removedRows.length) {
          override.stockInLocationLocationIdsToExclude = this.fromTemplateOverrideGridSIL.removedRows;
        }
        if (Object.keys(override).length !== 0) {
          obj['override'] = override;
        }
        res = await this.stockDataGridService.insertFromTemplate(obj);
        stockId = Number(res?.stock?.stockId);
      }

      this.stockItemSaved.emit(stockId);
      this.hidePopup();
    } catch (error) {
      const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} adding stock item. ` + instruction, 'OK', 10000);
    } finally {
      this.savePanelVisible = false;
    }
  }

  public hidePopup(): void {
    this.addStockItemPopup?.instance.hide();
  }

  public onStockGroupChanged(event): void {
    if (!event.value) { return; }
    this.lookupConfig.stockCreationTemplate.dataSourceFilter = ['stockGroupId', '=', event.value];
    this.stockCreationTemplateList = [];
    this.selectedStockCreationTemplateId = null;
    this.stockCreationTemplateLookup.instance.getDataSource().load();
  }

  public async generateEditDetails(): Promise<void> {
    this.loadPanelVisible = true;
    this.stockFromTemplate = await this.stockDataGridService.getFromTemplate(this.stock.name, this.selectedStockCreationTemplateId);
    this.popupConfig.title = 'Add Stock From Template - View / Edit Details';
    this.isGenerateEditDetails = true;
    this.loadPanelVisible = false;
  }

  public onContentReady(e): void {
    for (let i = 0; i < this.accordionItems.length; i++) {
      e.component.expandItem(i);
    }
  }

  public onFactorRemoved(e): void {
    this.productsToRemove = this.fromTemplateOverrideGridFactors &&
                            this.fromTemplateOverrideGridFactors?.removedRows.length ? this.fromTemplateOverrideGridFactors?.removedRows : -1;
  }

}
