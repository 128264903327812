/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

export interface StockTransferDetailView { 
    /**
     * The unique identifier of this record.
     */
    transferDetailId?: number;
    /**
     * A reference to the primary key in the StockTransferHeaders Table
     */
    transferId?: number;
    /**
     * A reference to the primary key in the Stock Table
     */
    stockId?: number;
    destinationQtyInStock?: number;
    sourceQtyInStock?: number;
    qtyToTransfer?: number;
    /**
     * calculated field - Avg Cost x Qty To Transfer.
     */
    valueToTransfer?: number;
    destMax?: number;
    destMin?: number;
    destQtyInStkAfter?: number;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    avgCost?: number;
    unitQtyToTransfer?: number;
    unitType?: StockTransferDetailView.UnitTypeEnum;
    /**
     * A reference to the primary key in the Factors Table
     */
    factorId?: number;
    /**
     * calculated field - Qty In Stk (Dest After)-Max (Dest).
     */
    variance?: number;
    /**
     * calculated field - -1x(@Qty in Stk (Dest B4) + Qty to Transfer - Qty In Stock (Dest After)).
     */
    adjustment?: number;
}
export namespace StockTransferDetailView {
    export type UnitTypeEnum = 'Factor' | 'Inner' | 'Outer' | 'null';
    export const UnitTypeEnum = {
        Factor: 'Factor' as UnitTypeEnum,
        Inner: 'Inner' as UnitTypeEnum,
        Outer: 'Outer' as UnitTypeEnum,
        Null: 'null' as UnitTypeEnum
    };
}