import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Properties as dxPopupOptions } from 'devextreme/ui/popup';
import { DxConfig, ValidationFn, IValidationRules, FormatFn } from 'src/app/Constants';
import { Properties as dxLookupOptions } from 'devextreme/ui/lookup';

export interface IRruleData {
  repeatByDay?: string;
  repeatByDayMonth?: string;
  repeatByMonth?: string;
  repeatByMonthDay?: string;
  repeatBySetPos?: string;
  repeatFreq?: string;
  repeatInterval?: number;
  repeatChoice?: string;
  endType?: string;
  endDate?: Date;
}

export interface IWeeklyDay {
  text: string;
  type: string;
}

@Component({
  selector: 'app-rrule-generator',
  templateUrl: './rrule-generator.component.html',
  styleUrls: ['./rrule-generator.component.scss']
})
export class RruleGeneratorComponent implements OnInit {

  public repeatTypes = [
    { type: 'yearly', label: 'Yearly' },
    { type: 'monthly', label: 'Monthly' },
    { type: 'weekly', label: 'Weekly' },
    { type: 'daily', label: 'Daily' },
    { type: 'hourly', label: 'Hourly' }
  ];
  public endTypes = [
    { type: 'never', label: 'Never' },
    { type: 'onDate', label: 'On Date' }
  ];
  public months = [
    { type: '1', label: 'Jan' },
    { type: '2', label: 'Feb' },
    { type: '3', label: 'Mar' },
    { type: '4', label: 'Apr' },
    { type: '5', label: 'May' },
    { type: '6', label: 'Jun' },
    { type: '7', label: 'Jul' },
    { type: '8', label: 'Aug' },
    { type: '9', label: 'Sep' },
    { type: '10', label: 'Oct' },
    { type: '11', label: 'Nov' },
    { type: '12', label: 'Dec' }
  ];
  public weekOrder = [
    { type: '1', label: 'First' },
    { type: '2', label: 'Second' },
    { type: '3', label: 'Third' },
    { type: '4', label: 'Fourth' },
    { type: '-1', label: 'Last' }
  ];
  public weekDay = [
    { type: 'SU', label: 'Sunday' },
    { type: 'MO', label: 'Monday' },
    { type: 'TU', label: 'Tuesday' },
    { type: 'WE', label: 'Wednesday' },
    { type: 'TH', label: 'Thursday' },
    { type: 'FR', label: 'Friday' },
    { type: 'SA', label: 'Saturday' },
    { type: 'MO,TU,WE,TH,FR', label: 'Weekday' },
    { type: 'SU,SA', label: 'Weekend' }
  ];
  public radioOptionOn = [
    { text: '', value: 'on' }
  ];

  public radioOptionOnThe = [
    { text: 'On the', value: 'onThe' }
  ];

  public weeklyDates: IWeeklyDay[] = [
    {
      text: 'Sun',
      type: 'default'
    },
    {
      text: 'Mon',
      type: 'default'
    },
    {
      text: 'Tue',
      type: 'default'
    },
    {
      text: 'Wed',
      type: 'default'
    },
    {
      text: 'Thu',
      type: 'default'
    },
    {
      text: 'Fri',
      type: 'default'
    },
    {
      text: 'Sat',
      type: 'default'
    }
  ];
  public weeklyDaySelection: IWeeklyDay[] = [];
  public dxLookupConfig: dxLookupOptions = DxConfig.lookup;
  public validationRules: IValidationRules = {};
  public rruleData: IRruleData = {};
  public rrule: string;
  public calenderDateFormat = FormatFn.dateFormat;

  @Input() public defaultRule: string;
  constructor() {
    this.defineValidationRules();
  }

  public ngOnInit(): void {
    const rule = this.defaultRule ?? '';
    const { endDate, repeatByDay, repeatByDayMonth, repeatByMonth, repeatByMonthDay, repeatBySetPos, repeatFreq, repeatInterval }: IRruleData = this.parseRRULE(rule);
    const repeatChoice = repeatBySetPos ? 'onThe' : 'on';
    const endType = endDate ? 'onDate' : 'never';
    this.controlRadioLabel(repeatFreq);
    this.rruleData = { ...this.rruleData, ...{
      endDate, repeatByDay, repeatByDayMonth, repeatByMonth, repeatByMonthDay, repeatBySetPos, repeatFreq, repeatInterval, repeatChoice, endType } };
    this.rrule = this.generateRRULE(this.rruleData);
  }

  public defineValidationRules(): void {
    this.validationRules = {
      require: [ValidationFn.getRequiredRule()]
    };
  }

  public onFormChanged(event, field?: string): void {
    if (field === 'repeatFreq') {
      this.controlRadioLabel(event.value);
    }
    this.rrule = this.generateRRULE(this.rruleData);
  }

  public controlRadioLabel(repeatTypes: string): void {
    if (repeatTypes === 'monthly') {
      this.radioOptionOn[0].text = 'On day';
    }
    if (repeatTypes === 'yearly') {
      this.radioOptionOn[0].text = 'On';
    }
  }

  public onWeeklyDaySelectionChanged(event): void {
    if (event.addedItems.length) {
      this.weeklyDaySelection = [...this.weeklyDaySelection, ...event.addedItems];
      this.rrule = this.generateRRULE(this.rruleData);
    }
    if (event.removedItems.length) {
      this.weeklyDaySelection = this.weeklyDaySelection.filter((item) => item.text !== event.removedItems[0].text);
      this.rrule = this.generateRRULE(this.rruleData);
    }
  }

  public generateRRULE({
    repeatFreq,
    repeatInterval,
    repeatChoice,
    repeatByMonth,
    repeatByMonthDay,
    repeatBySetPos,
    repeatByDay,
    repeatByDayMonth,
    endType,
    endDate
  }: IRruleData): string {

    if (!repeatFreq) {
      return null;
    }
    let rruleString = `FREQ=${repeatFreq?.toUpperCase()}`;

    if (repeatFreq === 'yearly') {
      if (repeatChoice === 'on') {
        rruleString += `;BYMONTH=${repeatByMonth};BYMONTHDAY=${repeatByMonthDay}`;
      }

      if (repeatChoice === 'onThe') {
        rruleString += `;BYDAY=${repeatByDay.split(',').map((day) => `${repeatBySetPos}${day}`).join(',')};BYMONTH=${repeatByDayMonth}`;
      }
    } else if (repeatFreq === 'monthly') {
      if (repeatChoice === 'on') {
        rruleString += `;BYMONTHDAY=${repeatByMonthDay}`;
      }

      if (repeatChoice === 'onThe') {
        rruleString += `;BYDAY=${repeatByDay.split(',').map((day) => `${repeatBySetPos}${day}`).join(',')}`;
      }
    } else if (repeatFreq === 'weekly') {
      if (this.weeklyDaySelection.length) {
        const weeklyByDay = this.weeklyDaySelection.map((item) => item.text.toUpperCase().substring(0, 2));
        rruleString += `;BYDAY=${weeklyByDay}`;
      }
    }

    if (repeatFreq !== 'yearly') {
      rruleString += `;INTERVAL=${repeatInterval}`;
    }

    if (endType && endType !== 'never' && endDate) {
      rruleString += `;UNTIL=${endDate.getFullYear()}${(endDate.getMonth() + 1).toString().padStart(2, '0')}${(endDate.getDate()).toString().padStart(2, '0')}T000000`;
    }

    return rruleString;
  }

  public parseRRULE(rruleString): IRruleData {
    const parameters = {
      repeatFreq: '',
      repeatInterval: 1,
      repeatByMonth: '',
      repeatByMonthDay: '',
      repeatBySetPos: '',
      repeatByDay: '',
      repeatByDayMonth: '',
      endDate: null
    };

    const parts = rruleString.split(';');
    parts.forEach((part) => {
      const [key, value] = part.split('=');
      switch (key) {
        case 'FREQ':
          parameters.repeatFreq = value.toLowerCase();
          break;
        case 'INTERVAL':
          parameters.repeatInterval = parseInt(value, 10);
          break;
        case 'BYDAY':
          if (parameters.repeatFreq !== 'weekly') {
            const bySetPos = value.match(/-?\d+/g)[0];
            parameters.repeatBySetPos = bySetPos;
            parameters.repeatByDay = value.split(',').map((day) => day.replace(bySetPos, '')).join(',');
            break;
          }
          this.weeklyDaySelection = value?.split(',')?.map((dateString) =>
            this.weeklyDates.find((item) => item.text.toUpperCase().substring(0, 2) === dateString)
          );
          break;
        case 'BYMONTH':
          if (parameters.repeatBySetPos) {
            parameters.repeatByDayMonth = value;
            break;
          }
          parameters.repeatByMonth = value;
          break;
        case 'BYMONTHDAY':
            parameters.repeatByMonthDay = value;
            break;
        case 'UNTIL':
          const year = parseInt(value.substring(0, 4), 10);
          const month = parseInt(value.substring(4, 6), 10) - 1;
          const day = parseInt(value.substring(6, 8), 10);
          parameters.endDate = new Date(year, month, day);
          break;
        default:
          break;
      }
    });
    return parameters;
  }

}
