/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ProductDetailViewFactor } from './productDetailViewFactor';
import { ProductsInLocationDetailViewProductPrintGroup } from './productsInLocationDetailViewProductPrintGroup';
import { ProductsInLocationDetailViewProductTaxRate } from './productsInLocationDetailViewProductTaxRate';
import { SupplierInventoryCodesWithDetailsViewSupplier } from './supplierInventoryCodesWithDetailsViewSupplier';

export interface ProductsInLocationDetailViewProduct { 
    /**
     * A reference to the primary key in the Containers Table
     */
    containerId?: number;
    fullName?: string;
    shortName?: string;
    plu?: number;
    /**
     * A reference to the primary key in the PluGroups Table
     */
    pluGroupId?: number;
    /**
     * A reference to the primary key in the RevenueGroups Table
     */
    revenueGroupId?: number;
    /**
     * A reference to the primary key in the Ranges Table
     */
    rangeId?: number;
    /**
     * A reference to the primary key in the TaxRates Table
     */
    taxRateId?: number;
    modifier?: ProductsInLocationDetailViewProduct.ModifierEnum;
    /**
     * A reference to the primary key in the PrintGroups Table
     */
    printGroupId?: number;
    averageCost?: number;
    lastCost?: number;
    /**
     * A reference to the primary key in the PluGroups Table
     */
    linkedPluGroupId?: number;
    allowDecimalQtys?: boolean;
    openPriceItem?: boolean;
    factor?: ProductDetailViewFactor;
    pluGroup?: SupplierInventoryCodesWithDetailsViewSupplier;
    revenueGroup?: SupplierInventoryCodesWithDetailsViewSupplier;
    printGroup?: ProductsInLocationDetailViewProductPrintGroup;
    taxRate?: ProductsInLocationDetailViewProductTaxRate;
    range?: SupplierInventoryCodesWithDetailsViewSupplier;
}
export namespace ProductsInLocationDetailViewProduct {
    export type ModifierEnum = 'No modifier' | 'Normal modifier' | 'Group modifier' | 'Position modifier' | 'Printer change modifier' | 'Order Number modifier' | 'Reason Code modifier' | 'Member Fees' | 'Ticket modifier' | 'Package' | 'Timer' | 'Non-kitchen printed';
    export const ModifierEnum = {
        NoModifier: 'No modifier' as ModifierEnum,
        NormalModifier: 'Normal modifier' as ModifierEnum,
        GroupModifier: 'Group modifier' as ModifierEnum,
        PositionModifier: 'Position modifier' as ModifierEnum,
        PrinterChangeModifier: 'Printer change modifier' as ModifierEnum,
        OrderNumberModifier: 'Order Number modifier' as ModifierEnum,
        ReasonCodeModifier: 'Reason Code modifier' as ModifierEnum,
        MemberFees: 'Member Fees' as ModifierEnum,
        TicketModifier: 'Ticket modifier' as ModifierEnum,
        Package: 'Package' as ModifierEnum,
        Timer: 'Timer' as ModifierEnum,
        NonKitchenPrinted: 'Non-kitchen printed' as ModifierEnum
    };
}