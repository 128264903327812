/**
 * @author Michelle Liu
 * @email michelle.liu@hlaustralia.com.au
 * @create date 2019-04-09
 * @description A component that supports display, update and upload images
 */

import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import logger from '@hal.common.ui/utilities/Logger';
import { UtilityService } from '@services/utility.service';
import { ImagesService, IImgUploadResponse } from '@services/images.service';
import { FilesService as PortalAPIFilesService } from '@modules/SDKs/portalApi';
import { GlobalsService } from '@services/global.service';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IImage } from 'src/app/types/interfaces/GeneralService';

/**
 * IPassiveInput
 * @description the config for Passive Mode
 * @param confirmBeforeSave if the user need to confirm the image selection by clicking the confirm button
 *   when set to true: the user need to click the tick icon to confirm the selected image
 *   when set to false: the image would be confirmed directly on file select
 * @param imageSource base64encode string to display the image which would feed into <img [src]="xxxxx"> in html
 */
export interface IPassiveInput {
  confirmBeforeSave: boolean;
  imageSource: string | ArrayBuffer;
}

/**
 * ISelfContainedInput
 * @description the config for Self Contained Mode
 * @param imageId Image _id from MongoDB, the image component would use this id to do a get image API call
 * @param locationId locationId that used to store against image object
 * @param organisationId organisationId that used to store against image object
 * @param description image description
 * @param objectType object type that linked to this image e.g. StockItem
 * @param objectId objectId of the linked object e.g. 97 StockId
 * @param publishStatus 'PRIVATE' (by default) or 'PUBLIC'
 */
export interface ISelfContainedInput {
  imageId: string;
  // imageName: string; // comment it out for now, doesn't need it at this stage
  locationId: string;
  organisationId: string;
  description: string;
  objectType: string;
  objectId: string;
  publishStatus: 'PRIVATE' | 'PUBLIC';
}

/**
 * IImageComponentInput
 * @description the input for this image component
 * @param config the configuration either a IPassiveInput type or ISelfContainedInput type
 * @param showToolBarOnHoverOnly the tool bar will always show or only show when mouse hover over
 * @param editable show tool bar or not. Tool bar will hide in non-editable mode (view only)
 */
export interface IImageComponentInput {
  config: IPassiveInput | ISelfContainedInput;
  showToolBarOnHoverOnly: boolean; // when set to false the tool bar would always show
  editable: boolean;
  canUndo?: boolean;
}

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  animations: [
    trigger('slide', [
      state('false', style({ transform: 'translateX(0)' })),
      state('true', style({ transform: 'translateX(-50%)' })),
      transition('* => *', animate(300))
    ])
  ]
})

/**
 * @description This component can be used in two modes - Passive Mode and Self-Contained Mode:
 *
 *  _Passive Mode_: (Example in stock-add component in customer portal)
 *    This component would display the image according the base64encode string the parent component feeds in via 'imageSource'
 *    and output the File object via 'fileToUpload' to the parent component and it is up to the parent component to upload the image
 * @param componentConfig Input for Passive Mode:
 *   {
 *      config: { // must be a type of IPassiveInput
 *        confirmBeforeSave: xxx,
 *        imageSource: xxx, // base64encode string for displaying the image e.g. data:image/jpeg;base64,VPB4AAQSkZJ...
 *      },
 *      showToolBarOnHoverOnly: xxx,
 *      editable: xxx,
 *   };
 * @returns {fileToUpload} @type {File} Output for Passive Mode for parent component to use e.g. upload etc
 *   only will emit in Passive mode, won't work for SelfContained Mode
 *   So make sure you listen to the right output depending on which mode you are using
 *
 *
 *  _Self-Contained Mode_: (Example in stock component in customer portal)
 *    This component would be able to retreive the file feeded in by the parent component via 'imageId',
 *    and would upload the image upon the confirmation of the image selection
 *
 * @param componentConfig Input for Self-Contained Mode:
 *   {
 *      config: { // must be a type of ISelfContainedInput
 *        imageId: xxx, // Image _id from MongoDB, the image component would use this id to do a get image API call
 *        locationId: xxx, // locationId that used to store against image object
 *        organisationId: xxx, // organisationId that used to store against image object
 *        description: xxx,
 *        objectType: 'xxx, // object type that linked to this image e.g. StockItem
 *        objectId: xxx, // objectId of the linked object e.g. 97 StockId
 *        publishStatus: xxx, // 'PRIVATE' (by default) or 'PUBLIC'
 *      },
 *      showToolBarOnHoverOnly: xxx,
 *      editable: xxx,
 *   };
 * @returns {fileUploaded} @type {IImgUploadResponse} Output for Self-Contained Mode
 *   will return the reponse of Post image route for parent component to use e.g. get image _id or url etc
 *   If upon save there is no image being uploaded or an image is being removed, will emit 'null' to notify parent component
 *   only will emit in Self-Contained Mode, won't work for Passive Mode
 *   So make sure you listen to the right output depending on which mode you are using
 *
 */
export class ImageComponent implements OnInit, OnDestroy {
  private static readonly imageButton = {
    UPLOAD: {
      label: 'add_photo_alternate',
      tooltip: 'Upload Image'
    },
    CHANGE: {
      label: 'photo_library',
      tooltip: 'Change Image'
    }
  };

  public $clear: Subscription;

  public selfContainedMode;
  public popUp = false;
  public loading = true;
  public editMode = true; // set to true for now, can set to false for using a main edit control in the future

  // # confirmBeforeSave #
  // when set to true: the user need to click the tick icon to confirm the selected image
  // when set to false: the image would be confirmed directly on file select
  // SelfContained mode: always set to true
  // Passive Mode: depending on the value set to (this.componentConfig.config as IPassiveInput).confirmBeforeSave
  public confirmBeforeSave;
  public originalImageSource: string | ArrayBuffer;
  public currentImgSrc: string | ArrayBuffer;
  public selectedFile: File;
  public imgBtn; // would be one of the options in imageButton
  public btnDebounced = false; // disabled when true, clicable when false

  @Input() public componentConfig: IImageComponentInput;
  @Input() public clear: Observable<void>;
  /**
   * @description Output for Passive Mode ONLY for parent component to use e.g. upload etc
   *   only will emit in Passive mode, won't work for SelfContained Mode
   *   So make sure you listen to the right output depending on which mode you are using
   */
  @Output() public readonly fileToUpload = new EventEmitter<File>(); // will emit in Passive mode
  /**
   * @description Output for Self-Contained Mode ONLY
   *   will return the reponse of Post image route for parent component to use e.g. get image _id or url etc
   *   If upon save there is no image being uploaded or an image is being removed, will emit 'null' to notify parent component
   *   only will emit in Self Contained mode, won't work for Passive Mode
   *   So make sure you listen to the right output depending on which mode you are using
   */
  @Output() public readonly fileUploaded = new EventEmitter<IImgUploadResponse>(); // will emit in Self Contained mode
  constructor(
    private utils: UtilityService,
    private imagesService: ImagesService,
    private globals: GlobalsService,
    private http: HttpClient
  ) { }

  /**
   * isSelfContained
   * @description check which mode the input is configured with
   */
  public isSelfContained(config: IPassiveInput | ISelfContainedInput): config is ISelfContainedInput {
    // if the config is a type of ISelfContainedInput then it must has orgId or locId provided
    return ((config as ISelfContainedInput).organisationId !== undefined || (config as ISelfContainedInput).locationId !== undefined);
  }

  public async ngOnInit(): Promise<void> {
    this.selfContainedMode = this.isSelfContained(this.componentConfig.config);
    this.confirmBeforeSave = this.isSelfContained(this.componentConfig.config) || this.componentConfig.config.confirmBeforeSave;
    if (this.selfContainedMode) {
      if ((this.componentConfig.config as ISelfContainedInput).imageId) {
        // use imageId to get the base64encode src
        try {
          this.originalImageSource = await this.imagesService.retrieveImage((this.componentConfig.config as ISelfContainedInput).imageId);
        } catch (error) {
          this.utils.openSnackbar('An error occur while loading the image, please try refreshing the page.', 'OK', 10000);
          logger.debug('Failed to get image, id: ' + (this.componentConfig.config as ISelfContainedInput).imageId + ' Error: ' + JSON.stringify(error));
        }
      }
    } else {
      // imageSource will be feed in directly
      this.originalImageSource = (this.componentConfig.config as IPassiveInput).imageSource;
    }
    this.currentImgSrc = this.originalImageSource;
    this.imgBtn = this.currentImgSrc ? ImageComponent.imageButton.CHANGE : ImageComponent.imageButton.UPLOAD;
    this.loading = false;
    if (this.clear) { this.$clear = this.clear.subscribe(() => { this.reset(); }); }
  }

  public ngOnDestroy(): void {
    if (this.$clear) { this.$clear.unsubscribe(); }
  }

 /**
  * onFileChange
  * @description This function will be trigger everytime user select a file (after clicking the confirm button on the pop up)
  *   In Passive Mode, if confirmBeforeSave set to false, then the fileToUpload will be emit directly, if set to true nothing will happen at this stage
  *   In Self-Contained Mode, confirmBeforeSave are forced to be true so nothing will happen at this stage
  */
  public onFileChange(event): void {
    if (event.target.files.length > 0) {
      this.loading = true;
      this.selectedFile = event.target.files[0];
      if (this.selectedFile.size > 5 * 1024 * 1024) {
        this.utils.openSnackbar('Please select an image under 5MB.', 'OK', 10000);
        this.loading = false;

        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = () => {
        this.currentImgSrc = reader.result;
        this.imgBtn = ImageComponent.imageButton.CHANGE;
        this.loading = false;
        if (!this.confirmBeforeSave) {
          this.fileToUpload.emit(this.selectedFile);
        }
      };
    }
  }

 /**
  * discard
  * @description when user click discard icon
  */
  public discard(): void {
    this.selectedFile = undefined;
    this.currentImgSrc = this.originalImageSource;
    this.imgBtn = this.currentImgSrc ? ImageComponent.imageButton.CHANGE : ImageComponent.imageButton.UPLOAD;
  }

/**
 * remove
 * @description when user click remove icon, will remove image
 *   In Passive Mode, if confirmBeforeSave set to false, then the fileToUpload (undefined as no file will require upload) will be emit directly,
 *                    if set to true nothing will happen at this stage
 *   In Self-Contained Mode, confirmBeforeSave are forced to be true so nothing will happen at this stage
 */
  public remove(): void {
    this.selectedFile = undefined;
    this.currentImgSrc = undefined;
    this.imgBtn = ImageComponent.imageButton.UPLOAD;
    if (!this.confirmBeforeSave) {
      this.fileToUpload.emit(this.selectedFile);
    }
  }

/**
 * save
 * @description when user click save icon
 *   In Passive Mode, the fileToUpload with selected will be emit directly
 *   In Self-Contained Mode, if an image is selected, then will upload the image and emit fileUploaded with response
 *                           if no image is selected/ the image is removed,
 *                              then will update the image record in mongoDB setting linked obejctId and objectType to null
 *                              and emit fileUploaded with null to parent component, so the parent component can action accordingly (e.g. removed linked imageId from the StockItem)
 */
  public async save(): Promise<void> {
    this.btnDebounced = true;
    if (this.selfContainedMode) {
      try {
        if (this.selectedFile) {
          // upload the file
          const image = {
            // imageName: (this.componentConfig.config as ISelfContainedInput).imageName, // comment it out for now, doesn't need it at this stage
            locationId: (this.componentConfig.config as ISelfContainedInput).locationId,
            organisationId: (this.componentConfig.config as ISelfContainedInput).organisationId,
            description: (this.componentConfig.config as ISelfContainedInput).description,
            objectType: (this.componentConfig.config as ISelfContainedInput).objectType,
            objectId: (this.componentConfig.config as ISelfContainedInput).objectId,
            publishStatus: (this.componentConfig.config as ISelfContainedInput).publishStatus
          };

          const res = await this.uploadImg(this.selectedFile, image);
          if (this.originalImageSource) { // the file is being changed
            // patch image record, remove objectId and objectType
            await this.removeImageReference((this.componentConfig.config as ISelfContainedInput).imageId);
          }
          this.originalImageSource = this.currentImgSrc; // now the selected image becomes the orginal image
          this.fileUploaded.emit(res);
        } else {
          // the file is being removed
          // patch image record, remove objectId and objectType
          await this.removeImageReference((this.componentConfig.config as ISelfContainedInput).imageId);
          // emit fileUploaded as null, to indicate parent component to remove imageId link
          this.originalImageSource = this.currentImgSrc; // now the the orginal image becomes null
          this.fileUploaded.emit(null);
        }
      } catch (error) {
        this.btnDebounced = false;
        this.utils.openSnackbar('An error occur while uploading the stock item image, please try again.', 'OK', 10000);
        logger.debug('ERROR UPLOADING STOCK ITEM IMAGE:');
        logger.debug(error);

        return;
      }
    } else {
      // output selected file on confirm
      this.fileToUpload.emit(this.selectedFile);
    }
    this.btnDebounced = false;
  }

/**
 * removeImageReference
 * @description update the image record in mongoDB setting linked obejctId and objectType to null
 */
  public async removeImageReference(imgId: string): Promise<void> {
    try {
      const currentState = this.globals.getState();
      const user = currentState ? currentState.user : null;
      const fileService = new PortalAPIFilesService(this.http, this.globals.getPortalBaseUrl(), this.globals.getPortalConfiguration());
      await fileService.filesPatch({
        criteria: {
          id: imgId
        },
        data: [
          { op: 'replace', path: '/objectId', value: null },
          { op: 'replace', path: '/objectType', value: null }
        ]
      }).toPromise();
    } catch (error) {
      logger.error('ERROR removing image reference:');
      logger.error(error);
    }
  }

/**
 * uploadImg
 * @description upload selected Image
 */
  public uploadImg(fileToUpload: File, image: IImage): Promise<IImgUploadResponse> {
    const formData = new FormData();
    formData.append('file', fileToUpload);
    // if (image.imageName) {
    //   formData.append('imageName', image.imageName); // comment it out for now, doesn't need it at this stage
    // }
    if (image.locationId) {
      formData.append('locationId', image.locationId);
    }
    if (image.organisationId) {
      formData.append('organisationId', image.organisationId);
    }
    if (image.description) {
      formData.append('description', image.description);
    }
    if (image.objectType) {
      formData.append('objectType', image.objectType);
    }
    if (image.objectId) {
      formData.append('objectId', image.objectId);
    }
    if (image.publishStatus) {
      formData.append('publishStatus', image.publishStatus);
    }

    return this.imagesService.uploadImage(formData);
  }

  /**
   * reset
   * @description reset the whole component
   */
  public reset(): void {
    this.selectedFile = undefined;
    this.currentImgSrc = undefined;
    this.originalImageSource = undefined;
    this.imgBtn = ImageComponent.imageButton.UPLOAD;
  }

}
