import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tour-box',
  templateUrl: './tour-box.component.html',
  styleUrls: ['./tour-box.component.scss']
})

export class TourBoxComponent implements OnInit {
  public visible = false;
  public cookie: string;
  @Input() public elementId: string;
  @Input() public position: string;
  @Input() public tabChangedFromStockNotification: Subject<boolean> = new Subject<boolean>();

  public ngOnInit(): void {
    this.tabChangedFromStockNotification.subscribe((response) => {
      if (this.getCookieIndex() === -1) {
        this.toggleNeverShow(true);
        this.confirm();
      }
    });

    this.cookie = document.cookie;
    if (this.getCookieIndex() !== -1) {
      const isNeverShow = this.cookie.substring(this.getCookieIndex()).split('=')[1].split(';')[0];
      if (isNeverShow === 'true') {
        return null;
      }
    }
    setTimeout(() => {
      this.visible = true;
    }, 0);
  }

  public confirm(): void {
    this.visible = false;
  }

  public toggleNeverShow(value: boolean): void {
    if (value) {
      document.cookie = `${this.elementId}=true; SameSite=Strict; max-age=34560000; Secure`;
    } else {
      document.cookie = `${this.elementId}=false; SameSite=Strict; Secure`;
    }
  }

  public getCookieIndex(): number {
    return this.cookie.indexOf(this.elementId);
  }

}

export const CSS_CONSTANT = {
  SYSNET_COMMON_HEADER_TITLE_RIGHT_MINWIDTH: 650
};
