/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UnitType } from './unitType';

export interface CalculatedLineCostsRequest { 
    unitType: UnitType;
    /**
     * The number of items we are looking to receive.
     */
    unitQtyReceived: number;
    /**
     * The supplier inventory code we are looking to calculate costs for.
     */
    supplierInventoryCode?: string;
    /**
     * The supplierId of the supplier you are using for this invoice. Used in conjunction with the supplierInventoryCode to determine the invoice item.
     */
    supplierId?: number;
    /**
     * The factorId of the factor we would like to calculate costs for. Only required when unit type is Factor
     */
    factorId?: number;
    /**
     * The stock id we are looking to receive.
     */
    stockId: number;
    /**
     * If the locationId is set then will use stockInLocation.lastCost in preference of stock.lastCost for estimating costs.
     */
    locationId?: number;
    /**
     * Whether sales tax should/is included in the line total.
     */
    salesTaxIncluded?: boolean;
    /**
     * Whether wholesale tax should/is included in the line total.
     */
    wholesaleTaxIncluded?: boolean;
    /**
     * The line total from which tax is calculated from. Optional.
     */
    lineTotal?: number;
    /**
     * Whether freight is included in LineTotal. Defaults to false.
     */
    freightIncluded?: boolean;
    /**
     * The cost of the freight. Default to zero.
     */
    freight?: number;
    /**
     * Not currently used for calculations.
     */
    depositIncluded?: boolean;
    /**
     * The cost of the deposit. Default to zero. Not currently used for calculations.
     */
    deposit?: number;
    /**
     * Extra costs that are associated with the line item (e.g packaging) that include a GST component. Defaults to zero.
     */
    taxableSundries?: number;
    /**
     * Extra costs that are associated with the line item that do not have a GST component. Defaults to zero.
     */
    nonTaxableSundries?: number;
}