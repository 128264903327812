/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

export interface StockAuditTrailView { 
    /**
     * The unique identifier of this record.
     */
    stockAuditTrailId?: number;
    /**
     * A reference to the primary key in the Stock Table
     */
    stockId?: number;
    /**
     * A reference to the primary key in the StockAuditTrailTransactionTypes Table
     */
    transactionType?: string;
    totalQty?: number;
    totalValue?: number;
    /**
     * A reference to the primary key in the VenueLocations Table
     */
    locationId?: number;
    comment?: string;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    transactionDate?: Date;
    dayPeriodId?: number;
    reason?: string;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    userId?: string;
    userName?: string;
    /**
     * A reference to the primary key in the Organisations Table
     */
    organisationId?: string;
    /**
     * A reference to the primary key in the Venues Table
     */
    venueId?: string;
}