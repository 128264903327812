import { Injectable } from '@angular/core';
import { GlobalsService } from '@services/global.service';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { HttpResponse } from '@angular/common/http';
import { StocktakeItemsDataEntryService as SDKStocktakeItemsDataEntryService } from '@modules/SDKs/sysnetApi/api/stocktakeItemsDataEntry.service';
import { GroupQuery, GroupSummaryQuery, StocktakeItemsDataEntryInsert, StocktakeItemsDataEntryView } from '@modules/SDKs/sysnetApi';
import { ServicesWrappingSysnetSDK } from './ServicesWrappingSysnetSDK';

@Injectable({
    providedIn: 'root'
})
export class StocktakeItemsDataEntryService extends ServicesWrappingSysnetSDK {
    private orgId: string;

    constructor(private sdkStocktakeItemsDataEntryService: SDKStocktakeItemsDataEntryService, private globals: GlobalsService) {
        super([sdkStocktakeItemsDataEntryService], globals);
        this.globals.$state.subscribe((newState) => {
            if (newState) { this.orgId = newState.selectedOrganisation?._id; }
        });
    }

    // Get StocktakeItemsDataEntry
    public getStocktakeItemsDataEntry(stocktakeItemsDataEntryId: number): Promise<HttpResponse<StocktakeItemsDataEntryView>> {
        return this.sdkStocktakeItemsDataEntryService.stocktakeItemsDataEntryIdGet(stocktakeItemsDataEntryId, AppConfiguration.rsh.getStrVal('rid'), null, 'response').toPromise();
    }

    // Search StocktakeItemsDataEntry
    public searchStocktakeItemsDataEntry(
        group?: GroupQuery,
        groupSummary?: GroupSummaryQuery,
        totalSummary?: GroupSummaryQuery,
        sort?: string,
        limit?: number,
        offset?: number,
        search?: object,
        searchDistinct?: string): Promise<HttpResponse<StocktakeItemsDataEntryView[]>> {
        return this.sdkStocktakeItemsDataEntryService.stocktakeItemsDataEntryGet(AppConfiguration.rsh.getStrVal('rid'), null,
            this.stringify(group), this.stringify(groupSummary), this.stringify(totalSummary), searchDistinct, sort, limit, offset, this.stringify(search),
            null, null, null, null, null, null, null, null, null, null, null, null, null, this.orgId, null, 'response').toPromise();
    }

    // Insert StocktakeItemsDataEntry
    public insertStocktakeItemsDataEntry(stocktakeItemsDataEntry: StocktakeItemsDataEntryInsert): Promise<HttpResponse<StocktakeItemsDataEntryView>> {
        return this.sdkStocktakeItemsDataEntryService.stocktakeItemsDataEntryPost(stocktakeItemsDataEntry, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Delete StocktakeItemsDataEntry
    public deleteStocktakeItemsDataEntry(stocktakeItemsDataEntryId: number): Promise<HttpResponse<StocktakeItemsDataEntryView>> {
        return this.sdkStocktakeItemsDataEntryService.stocktakeItemsDataEntryIdDelete(stocktakeItemsDataEntryId, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

}
