import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseGuard } from '@guards/base.guard';
import { GlobalsService } from '@services/global.service';
import { FactorsDataGridService } from '@services/grid/factors-data-grid.service';
import { BarcodesService } from '@services/sysnet/barcodes.service';
import { StocktakeItemsDataEntryService } from '@services/sysnet/stocktake-items-data-entry.service';
import { StocktakeItemsService } from '@services/sysnet/stocktake-items.service';
import { UtilityService } from '@services/utility.service';
import { DxFormComponent, DxLoadPanelComponent, DxLookupComponent, DxTabPanelComponent, DxTextBoxComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data';
import { Properties as dxLookupOptions } from 'devextreme/ui/lookup';
import { Properties as dxPopupOptions } from 'devextreme/ui/popup';
import { Item as dxTabsItem } from 'devextreme/ui/tabs';
import { DEFAULT_HOME_ROUTE, DxConfig, ILookupDataSourceConfig, IValidationRules, SysnetCloudConstants, ValidationFn } from 'src/app/Constants';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Item as dxAccordionItem } from 'devextreme/ui/accordion';
import { BarcodeView, StocktakeItemsDataEntryInsert, StocktakeItemView } from '@modules/SDKs/sysnetApi';
import { RangeRule } from 'devextreme/ui/validation_rules';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { StocktakeService } from '@services/sysnet/stocktake.service';
import { CustomDialogOptions } from 'devextreme/ui/dialog';
import { StockDataGridService } from '@services/grid/stock-data-grid.service';
import { ErrorMessages } from 'src/app/utilities/ErrorMessages';
import { BarcodeScannerComponent } from '@components/generic/barcode-scanner/barcode-scanner.component';
import { DefaultSettingsService } from '@services/sysnet/default-settings.service';
import { BarcodesDataGridService, IBarcode } from '@services/grid/barcodes-data-grid.service';
import { StocktakeGroupsService } from '@services/sysnet/stocktake-groups.service';
import { BarcodesHelper } from 'src/app/utilities/barcodesHelper';
import { IExtendedTabItem, IGroupQueryResultBody, ILookupDataSourceSetupReturnObj } from 'src/app/types/interfaces/GeneralService';
import { IStocktake } from 'src/app/types/interfaces/StockService';
import { MultiItemBarcodeSelectionListComponent } from '../multi-item-barcode-selection-list/multi-item-barcode-selection-list.component';
import { StocktakeEntryCountListComponent } from '../stocktake-entry-count-list/stocktake-entry-count-list.component';
import { StocktakeItemsListComponent } from '../stocktake-items-list/stocktake-items-list.component';

@Component({
  selector: 'app-count-stocktake',
  templateUrl: './count-stocktake.component.html',
  styleUrls: ['./count-stocktake.component.scss']
})
export class CountStocktakeComponent implements OnInit, OnDestroy {
  public stocktakeId: number;
  public smallScreen = false;
  public countedFactors = null;
  public isStocktakeItemLoaded = false;
  public isFactorsLoadedFromStocktakeItemSelection = false;
  public loadPanelVisible = false;
  public enableItemSearchBtn = false;
  public stocktakeItem;
  public barcode;
  public stock;
  public $searchTerm = new Subject<string>();
  public barcodeInput: string;
  public stocktake: IStocktake = { Location: { Venue: {} } };
  public factorId: number;
  public types = [];
  public selectedTab = 'Stocktake Count';
  public dxLookupConfig: dxLookupOptions = DxConfig.lookup;
  public currentDevice: MediaDeviceInfo = null;
  public availableFactors = [];
  public barcodeInputValidationStatus = 'valid';
  public barcodeInputValidationErrors = [{ message: 'The barcode entered does not belong to this stocktake' }];
  public showBarcodeScanner = false;
  public showQtyAtStocktake = false;

  public editing = {
    allowUpdating: false
  };
  public readonly countStocktakeTabs: IExtendedTabItem[] = [
    { title: 'Stocktake Count', template: 'stocktakeCountTempl' },
    { title: 'Stocktake (List)', template: 'stocktakeListTempl', disabled: true }
  ];
  public validationRules: IValidationRules = {};
  public countsEntered = 0;
  public initialExpanding = true;
  public readonly detailGroups: dxAccordionItem[] = [
    { template: 'stocktakeEntryDetailsTempl' }
  ];

  public barcodeLibLoaded = false;
  private $inputChanges: Subscription;
  @ViewChild('stocktakeItemsList', { static: false }) public stocktakeItemsList: StocktakeItemsListComponent;
  @ViewChild('factorsLookup', { static: false }) public factorsLookup: DxLookupComponent;
  @ViewChild('scanBarcodeInput', { static: false }) public scanBarcodeInput: DxTextBoxComponent;
  @ViewChild('stocktakeEntryCountList', { static: false }) public stocktakeEntryCountList: StocktakeEntryCountListComponent;
  @ViewChild('enterCount', { static: false }) public enterCount: DxTextBoxComponent;
  @ViewChild('assignBarcodeFactorsLookup', { static: false }) public assignBarcodeFactorsLookup: DxLookupComponent;
  @ViewChild('assignBarcodeStockLookup', { static: false }) public assignBarcodeStockLookup: DxLookupComponent;
  @ViewChild('countStocktakeTabPanel', { static: false }) public countStocktakeTabPanel: DxTabPanelComponent;
  @ViewChild('loadPanel', { static: false }) public loadPanel: DxLoadPanelComponent;
  @ViewChild('barcodeScanner', { static: false }) public barcodeScanner: BarcodeScannerComponent;
  @ViewChild('multiItemBarcodeSelectionList', { static: false }) public multiItemBarcodeSelectionList: MultiItemBarcodeSelectionListComponent;
  @ViewChild('countStocktakeForm', { static: false }) public countStocktakeForm: DxFormComponent;
  @Input() public isStockMobile = false;
  constructor(
    public globalsService: GlobalsService,
    private factorsDataGridService: FactorsDataGridService,
    private barcodesService: BarcodesService,
    private stocktakeItemsDataEntryService: StocktakeItemsDataEntryService,
    private stocktakeItemsService: StocktakeItemsService,
    private utils: UtilityService,
    private baseGuard: BaseGuard,
    private breakpointObserver: BreakpointObserver,
    private stocktakeService: StocktakeService,
    private route: ActivatedRoute,
    private router: Router,
    private stockDataGridService: StockDataGridService,
    private defaultSettingsService: DefaultSettingsService,
    private barcodesDataGridService: BarcodesDataGridService,
    private stocktakeGroupsService: StocktakeGroupsService
  ) {
    this.breakpointObserver.observe(['(max-width: 770px)']).subscribe((result) => {
      this.smallScreen = result.matches;
    });
    this.validationRules = {
      qtyStockEntered: [this.getMaxRuleForQtyEntered(9999999), ...ValidationFn.getDecimalNumRule(11, 4)],
      factorId: [ValidationFn.getRequiredRule()]
    };
    this.$inputChanges = this.$searchTerm.pipe(
      debounceTime(100)
    ).subscribe(async (data) => {
      if (data !== '') {
        this.barcodeInput = data;
        this.loadPanelVisible = true;
        const tempBarcodes = await this.getBarcodes(this.barcodeInput);
        this.barcodeInputValidationStatus = 'valid';
        if (tempBarcodes.length === 0) {
          const customDialogConfigs: CustomDialogOptions = {
            showTitle: false,
            messageHtml: `<br><div class="barcode-prompt-heading">Barcode not found</div><br><div class="barcode-prompt-barcode">${this.barcodeInput}</div> <br>
            <div style="margin: 0px 20px;"><center>Assign this barcode to existing stock item?</center></div>`,
            buttons: [
              { text: 'Yes', onClick: () => true, stylingMode: 'contained', type: 'default', elementAttr: { class: 'pop-up' } },
              { text: 'No', onClick: () => false, stylingMode: 'outlined', type: 'default', elementAttr: { class: 'pop-up' } }
            ]
          };
          this.utils.openDxCustomDialog(customDialogConfigs)
            .then((dialogResult) => {
              if (dialogResult) {
                this.barcodeScanner.showAssignBarcode(this.barcodeInput);
              }
            });
          this.reset();
          this.loadPanelVisible = false;
          return;
        }
        if (tempBarcodes.length === 1) {
          this.loadBarcodeItem(tempBarcodes[0] as IBarcode);
          return;
        }
        this.loadPanelVisible = false;
        const stockAndFactorUniqueBarcodes = BarcodesHelper.getStockAndFactorUniqueBarcodes(tempBarcodes as IBarcode[]);
        this.multiItemBarcodeSelectionList.openPopup(stockAndFactorUniqueBarcodes);
      } else {
        this.reset();
      }
    });
  }

  public async loadAvailableFactors(stockId: number, factorIdSelected?: number): Promise<void> {
    try {
      this.factorId = undefined;
      const res = await this.factorsDataGridService.searchFactorsByStockId({ take: 200 }, 'FactorsList', stockId);
      this.availableFactors = res.data;
      if (this.availableFactors.length > 0) {

        // need to set the selected value for the dropdown for sometime after the control has registered the new options available.
        if (factorIdSelected) {
          this.factorId = factorIdSelected;
          return;
        } else if (this.availableFactors.length === 1) {
          this.factorId = this.availableFactors[0].factorId;
        }

      }
    } catch (err) {
      this.availableFactors = [];
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} loading factors. `, 'OK', 10000);
    }
  }

  public async ngOnInit(): Promise<void> {
    this.globalsService.hideHeader();
    await this.checkCrudPermission();
    this.route.params.subscribe((params: Params) => {
      const id = parseInt(params['id'], 10);
      if (!isNaN(id)) {
        this.stocktakeId = id;
        this.getStocktakeById(this.stocktakeId);
        this.scanBarcodeInput?.instance?.focus();
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.$inputChanges) { this.$inputChanges.unsubscribe(); }
  }

  public async getStocktakeById(stocktakeId: number): Promise<void> {
    try {
      const stocktakeRes = await this.stocktakeService.getStocktake(stocktakeId, 'StocktakeDetails');
      this.stocktake = stocktakeRes.body;
      if (this.stocktake) {
        this.disableStocktakeCountTabIfProcessed();
        this.getVenueSettingShowQuantities(this.stocktake.Location.Venue.venueId);
      }
    } catch (error) {
      const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} retrieving stocktake details. ` + instruction, 'OK', 10000);
    }
  }

  public disableStocktakeCountTabIfProcessed(): void {
    const stocktakeCountTab = this.countStocktakeTabs.find((i) => i.template === 'stocktakeCountTempl');
    if (this.stocktake.dateProcessed && stocktakeCountTab) {
      stocktakeCountTab.disabled = true;
      this.countStocktakeTabPanel.selectedIndex = 1;
    }
  }

  public async getVenueSettingShowQuantities(venueId: string): Promise<void> {
    try {
      const venueSettingsRes = await this.defaultSettingsService.getVenueSettings(venueId);
      if (this.defaultSettingsService.getVenueSettingValue(SysnetCloudConstants.SYSNET_SETTINGS.SHOW_QUANTITY_IN_STOCKTAKE, venueSettingsRes) === 'true') {
        this.showQtyAtStocktake = true;
      }
    } catch (error) {
      const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} retrieving venue settings. ` + instruction, 'OK', 10000);
    }
  }

  public async checkCrudPermission(): Promise<void> {
    const checkProms = [
      { hasAccess: false, dataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_STOCKTAKEITEMS },
      { hasAccess: false, dataAccessName: SysnetCloudConstants.DATA_ACCESS.UPDATE_STOCKTAKEITEMS }
    ];
    await Promise.all(checkProms.map(async (check) => {
      check.hasAccess = (await this.baseGuard.checkPermissionAtCurrentOrg(check.dataAccessName, DEFAULT_HOME_ROUTE) === true);
    }));
    this.editing = {
      allowUpdating: checkProms[1].hasAccess
    };
    this.enableItemSearchBtn = checkProms[0].hasAccess;
    const stocktakeListTab = this.countStocktakeTabs.find((i) => i.template === 'stocktakeListTempl');
    if (checkProms[0].hasAccess && stocktakeListTab) { stocktakeListTab.disabled = false; }
  }

  public setupLookupDataSource(lookupConfig): ILookupDataSourceSetupReturnObj {
    return {
      store: new CustomStore({
        key: lookupConfig.primaryKey,
        loadMode: lookupConfig.dataSourceLoadMode,
        load: async (loadOptions: LoadOptions) => {
          try {
            const view = lookupConfig.dataSourceServiceSearchFnView;
            const routeId = lookupConfig.dataSourceServiceSearchFnRouteId;
            return await lookupConfig.dataSourceService[lookupConfig.dataSourceServiceSearchFn](loadOptions, view, routeId);
          } catch (error) { /* empty */ }
        },
        byKey: async (key) => {
          try {
            if (!key) { return null; }
            return await lookupConfig.dataSourceService[lookupConfig.dataSourceServiceGetFn](key);
          } catch (error) { /* empty */ }
        }
      }),
      sort: lookupConfig.dataSortBy,
      pageSize: 10,
      paginate: true
    };
  }

  public openStocktakeItemPopup(): void {
    this.countStocktakeTabPanel.instance.focus();
    setTimeout(() => {
      this.stocktakeItemsList.openPopup(this.stocktake.stocktakeId);
    }, 1000);
  }

  public openScanPopup(visibility = true): void {
    this.countStocktakeTabPanel.instance.focus();
    setTimeout(() => {
      this.showBarcodeScanner = visibility;
    }, 1000);
  }

  public reset(): void {
    this.isStocktakeItemLoaded = false;
    this.countedFactors = null;
    this.stocktakeItem = undefined;
    this.barcode = undefined;
    this.stock = undefined;
    this.factorId = undefined;
    this.factorsLookup.isValid = true;
  }

  public onStocktakeItemSelected(event): void {
    this.stocktakeItem = event;
    this.isFactorsLoadedFromStocktakeItemSelection = true;
    this.loadAvailableFactors(this.stocktakeItem.Stock.stockId);
    this.stock = { ...this.stocktakeItem.Stock };
    this.factorsLookup.instance.reset();
    this.isStocktakeItemLoaded = true;
    this.barcode = undefined;
    this.scanBarcodeInput.value = '';
  }

  public displayExprFactors(item): string {
    return item && item.Container?.description;
  }

  public async onFactorSelected(e): Promise<void> {
    if (e.value === null || !this.factorId || !this.isFactorsLoadedFromStocktakeItemSelection) { return; }
    const search = { factorId: { $eq: this.factorId } };
    this.barcode = await this.getBarcode(search);
  }

  public async getBarcode(search: object): Promise<BarcodeView> {
    try {
      const barcodesRes = await this.barcodesService.searchBarcodes(null, null, null, null, 1, null, 'BarcodesWithStockDetails', search);
      return barcodesRes.body[0] ?? undefined;
    } catch (error) {
      const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} retrieving barcode. ` + instruction, 'OK', 10000);
      return undefined;
    }
  }

  public async getStocktakeItem(search: object): Promise<StocktakeItemView> {
    try {
      const stocktakeItemsRes = await this.stocktakeItemsService.searchStocktakeItems(null, null, null, null, 1, null, 'StocktakeItemsList', search);
      return stocktakeItemsRes?.body[0];
    } catch (error) {
      const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} retrieving stocktake item. ` + instruction, 'OK', 10000);
      return undefined;
    }
  }

  public async addToStockCount(event): Promise<void> {

    if (this.countedFactors === null || !this.countStocktakeForm.instance.validate().isValid) {
      return null;
    }
    try {
      this.loadPanelVisible = true;
      const obj = { stocktakeItemsId: this.stocktakeItem.stocktakeItemsId, type: 'Factor', qtyCounted: this.countedFactors, factorId: this.factorId } as StocktakeItemsDataEntryInsert;
      await this.stocktakeItemsDataEntryService.insertStocktakeItemsDataEntry(obj);

      // get the stocktake itme to refresh the count
      await this.refreshStocktakeItem();
      this.countedFactors = null;
      this.loadPanelVisible = false;
      this.stocktakeEntryCountList.refreshGrid();
    } catch (error) {
      this.loadPanelVisible = false;
      const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} adding stock count. ` + instruction, 'OK', 10000);
    }
  }

  public scanSuccessHandler(barcode): void {
    this.showBarcodeScanner = false;
    this.barcodeInputValidationStatus = 'valid';
    this.scanBarcodeInput.value = barcode;
    this.$searchTerm.next(barcode);
  }

  public async refreshStocktakeItem(): Promise<void> {
    const search = { stocktakeItemsId: { $eq: this.stocktakeItem.stocktakeItemsId } };
    this.stocktakeItem = await this.getStocktakeItem(search);
    return;
  }

  public onTitleClick(e): void {
    this.selectedTab = e.itemData.title;
  }

  public async updateTotalStockEntryCount(totalEntryCount: number): Promise<void> {
    this.countsEntered = totalEntryCount;
    await this.refreshStocktakeItem();
  }

  public goBackToList(): void {
    this.router.navigate(['sysnet', 'stock', 'stocktakes']);
    this.globalsService.showHeader();
  }

  public scannerCloseHandler(): void {
    this.showBarcodeScanner = false;
  }

  public async getBarcodes(barcodeInput: string): Promise<BarcodeView[] | IGroupQueryResultBody['data']> {
    try {
      const barcodeSearch = {
        $and: [
          { barcode: { $eq: barcodeInput } },
          {
            $or: [
              {
                $and: [
                  { factorId: { $not: null } }
                ]
              },
              {
                $and: [
                  { 'Product.factorId': { $not: null } }
                ]
              }
            ]
          }
        ]
      };
      const barcodesRes = await this.barcodesDataGridService.searchBarcodesByBarcodeAndReturnAllDataIfNoPagination({},
        'BarcodesWithStockAndProductDetails', barcodeInput, barcodeSearch);
      return barcodesRes.data;
    } catch (error) {
      const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} retrieving barcodes. ` + instruction, 'OK', 10000);
    }
  }

  public async loadBarcodeItem(tempBarcode: IBarcode): Promise<void> {
    let factorId;
    this.stock = { ...tempBarcode.Product?.Factor?.Stock };
    factorId = tempBarcode.Product?.factorId;
    if (tempBarcode.factorId) {
      this.stock = { ...tempBarcode.Factor?.Stock };
      factorId = tempBarcode.factorId;
    }
    const stocktakeItemSearch = { $and: [{ stocktakeId: { $eq: this.stocktake.stocktakeId } }, { stockId: { $eq: this.stock.stockId } }] };
    const tempStocktakeItem = await this.getStocktakeItem(stocktakeItemSearch);
    if (!tempStocktakeItem) {
      this.loadPanelVisible = false;
      this.scanBarcodeInput.instance.focus();
      this.barcodeInputValidationStatus = 'invalid';
      this.reset();
      return;
    }
    this.isStocktakeItemLoaded = true;
    this.stocktakeItem = tempStocktakeItem;
    this.barcode = tempBarcode;

    // this.factorId = this.barcode.factorId;
    this.isFactorsLoadedFromStocktakeItemSelection = false;
    this.loadPanel.instance.toggle(false);
    await this.loadAvailableFactors(this.stock.stockId, factorId);
    this.countStocktakeTabPanel.instance.focus();
    this.barcodeInput = '';
    this.scanBarcodeInput?.instance.blur();
    this.enterCount?.instance?.focus();
  }

  public onBarcodeItemSelected(barcodeItem: IBarcode): void {
    this.loadBarcodeItem(barcodeItem);
  }

  private getMaxRuleForQtyEntered(max: number): RangeRule {
    return { type: 'range', max, message: 'Count cannot exceed 9,999,999' };
  }

}
