/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Specifies the purchase requirements of the Mix and Match promotions. For example a user might have to buy 1 item from one group of products and 2 from another group of products to recieve a discount.
 */
export interface MixAndMatchPurchaseRequirementUpdate { 
    /**
     * The description of the requirement.
     */
    description?: string;
    /**
     * The purchase quantity required for this requirement.
     */
    purchaseQuantityRequired?: number;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
}