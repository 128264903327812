/**
 * Portal API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FilePostMultiPartBody { 
    /**
     * the file to upload
     */
    file: Blob;
    /**
     * the venue/locationid that this image belongs to.
     */
    locationId?: string;
    /**
     * the organisationId that this image belongs to.
     */
    orgainsationId?: string;
    /**
     * The name of the image to upload.
     */
    imageName: string;
    /**
     * A description of the image.
     */
    description?: string;
    /**
     * Used to associate to an object. Eg to associate with Sysnet API  - Stock Object you might set the value='stock'
     */
    objectType?: string;
    /**
     * The object id that this image is assocated with. Eg this could be a stockId for sysnet API /stock route.
     */
    objectId?: string;
    /**
     * Images that are public will be availabe on a public accessible url. Images that are private will only be accessable via the GET /files/image/:id route
     */
    status: FilePostMultiPartBody.StatusEnum;
}
export namespace FilePostMultiPartBody {
    export type StatusEnum = 'PUBLIC' | 'PRIVATE';
    export const StatusEnum = {
        PUBLIC: 'PUBLIC' as StatusEnum,
        PRIVATE: 'PRIVATE' as StatusEnum
    };
}