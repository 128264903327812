/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * AuditLogs store information about insert, updates, patches and deletes. So that all user actions can be traced.
 */
export interface AuditLogView { 
    /**
     * The unique primary Key, used to identify a record
     */
    auditLogId?: number;
    message?: string;
    loggedInUserId?: string;
    /**
     * This is the table/object affected.  Example Value: Items
     */
    tableName?: string;
    tableId?: string;
    ipAddress?: string;
    /**
     * In the case of an update, insert or patches this field contains the HTTP body of the request. For deletes, this field contains the deleted object.
     */
    details?: string;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    logDate?: Date;
    operation?: string;
    /**
     * This is the unique Request Id for the current request generated by the menu management api. It can be used by H&L to trace requests made on this api.
     */
    rid?: string;
    /**
     * A reference to the primary key in the Venues Table
     */
    venueId?: string;
    organisationId?: string;
}