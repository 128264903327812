import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

export interface IOption {
  value: string;
  name: string;
}

@Component({
  selector: 'app-multiple-lookup-selection',
  templateUrl: './multiple-lookup-selection.component.html',
  styleUrls: ['./multiple-lookup-selection.component.scss']
})
export class MultipleLookupSelectionComponent implements OnInit, OnChanges {
  public options: IOption[];
  public selectedOptions: IOption[] = [];
  @Input() public rawOptions: IOption[];
  @Input() public excludeOptions: IOption[];
  @Input() public selectedAll = false;
  @Input() public disabled?: boolean;
  @Output() public readonly valueChange = new EventEmitter<IOption[]>();

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.rawOptions.currentValue) {
      this.setUpOptions();
      this.selectedOptions = [];
    }
  }

  public setUpOptions(): void {
    if (this.excludeOptions?.length > 0) {
      this.options = this.rawOptions.filter((option) => !(this.excludeOptions.findIndex((exOption) => (exOption.value === option.value)) !== -1));
    } else {
      this.options = this.rawOptions;
    }
    if (this.selectedAll && this.rawOptions?.length > 0) {
      this.options.unshift({
        name: 'Select All',
        value: 'selectAll'
      });
    }
  }

  public checkboxChange(item: IOption, event: MatCheckboxChange): void {
    if (item.value === 'selectAll') {
      if (event.checked) {
        // kick out selectAll option
        this.selectedOptions = this.options.slice(1);
      } else {
        this.selectedOptions = [];
      }
    } else {
      if (event.checked) {
        this.selectedOptions.push(item);
      } else {
        this.selectedOptions = this.selectedOptions.filter((option) => option.value !== item.value);
      }
    }
  }

  public onConfirm(): void {
    this.valueChange.emit(this.selectedOptions);
  }

  public checked(item: IOption): boolean {
    if (this.selectedOptions.find((option) => option.value === item.value)) {
      return true;
    }
    return false;
  }
  /* eslint-disable-next-line @typescript-eslint/explicit-member-accessibility */
  get fieldText(): string {
    return this.selectedOptions.map((option) => option.name).join(', ');
  }
}
