<dx-data-grid #dataGridComponent
    [keyExpr]="dataGridConfig.key"
    [dataSource]="overrideData"
    [columns]="dataGridConfig.columns"
    [editing]="dataGridConfig.editing"
    [columnChooser]="dataGridConfig.columnChooser"
    [filterRow]="dataGridConfig.filterRow"
    [showBorders]="true"
    [syncLookupFilterValues]="false"
    (onEditorPreparing)="dataGridConfig.onEditorPreparing($event)"
    (onToolbarPreparing)="dataGridConfig.onToolbarPreparing($event)"
    (onRowValidating)="dataGridConfig.onRowValidating($event)"
    (onSaving)="dataGridConfig.onSaving($event)">
    <ng-container>
        <div *dxTemplate="let cellInfo of 'actionColumnTemplate'">
            <ng-container *ngIf="cellInfo.row?.isEditing then editing else notEditing"></ng-container>
            <ng-template #editing>
                <a href="javascript:void(0)" (click)="onSaveItem()" i18n="save|@@save">Save</a>&nbsp;
                <a href="#" (click)="onCancelClick()" i18n="cancel|@@cancel">Cancel</a>&nbsp;
            </ng-template>
            <ng-template #notEditing>
                <a *ngIf="dataGridConfig.editing.allowUpdating" (click)="onEditItem(cellInfo)" href="javascript:void(0)" i18n="edit|@@edit">Edit</a>&nbsp;
                <a *ngIf="dataGridConfig.editing.allowDeleting" (click)="deleteItem(cellInfo)" href="javascript:void(0)" i18n="delete|@@delete" class="delete">Delete</a>&nbsp;
            </ng-template>
        </div>
    </ng-container>
</dx-data-grid>