/**
 * Portal API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Organisation } from './organisation';
import { UserResponseAccessLocationLocationType } from './userResponseAccessLocationLocationType';

export interface UserResponseAccessLocation { 
    active?: boolean;
    _id?: string;
    name?: string;
    code?: string;
    locationTypeId?: string;
    state?: string;
    locationType?: UserResponseAccessLocationLocationType;
    ownerUserId?: string;
    parentLocationId?: string;
    addressLine1?: string;
    addressLine2?: string;
    suburb?: string;
    countryCode?: string;
    postcode?: string;
    organisationId?: string;
    organisation?: Organisation;
    sysnetVenueId?: string;
    sysnetDebtorId?: string;
}