import { Injectable } from '@angular/core';
import { GlobalsService } from '@services/global.service';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { HttpResponse } from '@angular/common/http';
import { FactorsService as SDKFactorsService } from '@modules/SDKs/sysnetApi/api/factors.service';
import { GroupQuery, GroupSummaryQuery, FactorInsert, FactorUpdate, FactorView } from '@modules/SDKs/sysnetApi';
import { ServicesWrappingSysnetSDK } from './ServicesWrappingSysnetSDK';

@Injectable({
    providedIn: 'root'
})
export class FactorsService extends ServicesWrappingSysnetSDK {
    private orgId: string;

    constructor(private sdkFactorsService: SDKFactorsService, private globals: GlobalsService) {
        super([sdkFactorsService], globals);
        this.globals.$state.subscribe((newState) => {
            if (newState) { this.orgId = newState.selectedOrganisation?._id; }
        });
    }

    // Get Factor
    public getFactor(factorId: number, view?: string): Promise<HttpResponse<FactorView>> {
        return this.sdkFactorsService.factorsIdGet(factorId, AppConfiguration.rsh.getStrVal('rid'), view, 'response').toPromise();
    }

    // Search Factors
    public searchFactors(group?: GroupQuery, groupSummary?: GroupSummaryQuery, totalSummary?: GroupSummaryQuery,
                         sort?: string, limit?: number, offset?: number, view?: string, search?: object, searchDistinct?: string): Promise<HttpResponse<FactorView[]>> {
        return this.sdkFactorsService.factorsGet(AppConfiguration.rsh.getStrVal('rid'), view,
        this.stringify(group), this.stringify(groupSummary), this.stringify(totalSummary), searchDistinct, sort, limit, offset, this.stringify(search),
        null, null, null, null, null, null, null, null, null, null, null, null, null, this.orgId, 'response').toPromise();
    }

    // Insert Factor
    public insertFactor(factor: FactorInsert): Promise<HttpResponse<FactorView>> {
        return this.sdkFactorsService.factorsPost(factor, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Update Factor
    public updateFactor(factorId: number, factor: FactorUpdate): Promise<HttpResponse<FactorView[]>> {
        return this.sdkFactorsService.factorsIdPut(factorId, factor, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Delete Factor
    public deleteFactor(factorId: number): Promise<HttpResponse<FactorView>> {
        return this.sdkFactorsService.factorsIdDelete(factorId, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

}
