/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

export interface OfferRuleBulkInsertViewInsertedObject { 
    /**
     * The unique identifier of this record.
     */
    offerRuleId?: number;
    /**
     * The id of the offer
     */
    offerId?: number;
    /**
     * The type of rule. 'issuing' describes the rules that apply when issuing an offer. 'redemption' describes the products that must be bought when redeeming an offer. 'giveaway' describes which products can be givenaway or discounted.
     */
    ruleType?: OfferRuleBulkInsertViewInsertedObject.RuleTypeEnum;
    /**
     * The description of the rule e.g Buy 2 bottles of wine.
     */
    description?: string;
    /**
     * For a rule type of 'issuing' or 'redeeming' the quantity is the quantity required to be purchased. For a rule type of 'giveaway' it describes the maximum quantity that can be given away or discounted. 
     */
    quantity?: number;
    /**
     * The discount percent they will receive. Has minumum of 0 and a maximum of 100. Only used for rule type 'giveaway', otherwise should be null.
     */
    discountPercent?: number;
    /**
     * The discount amount they will recieve. Only used for rule type 'giveaway', otherwise should be null.
     */
    discountAmount?: number;
    /**
     * The unit price the customer will pay for the products they receive. 
     */
    sellUnitPrice?: number;
    /**
     * The minimum spend in order to receive this giveway rule. Only used for rule type 'giveaway', otherwise should be null.
     */
    minimumSpend?: number;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    lastUpdateByUserId?: LastUpdateByUserId;
}
export namespace OfferRuleBulkInsertViewInsertedObject {
    export type RuleTypeEnum = 'issuing' | 'redemption' | 'giveaway';
    export const RuleTypeEnum = {
        Issuing: 'issuing' as RuleTypeEnum,
        Redemption: 'redemption' as RuleTypeEnum,
        Giveaway: 'giveaway' as RuleTypeEnum
    };
}