import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, HostListener } from '@angular/core';
import { Item as dxTabsItem } from 'devextreme/ui/tabs';
import { Subject, Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import * as _ from 'lodash';
import { BreakpointsTriggers, DEFAULT_HOME_ROUTE } from 'src/app/Constants';
import { BaseGuard } from '@guards/base.guard';
import { NavigationEnd, Router } from '@angular/router';
import { CSS_CONSTANT } from '@components/generic/tour-box/tour-box.component';
import { pages } from 'src/app/utilities/Pages';
import { IExtendedTabItem } from 'src/app/types/interfaces/GeneralService';

@Component({
  selector: 'app-sysnet-common-header',
  templateUrl: './sysnet-common-header.component.html',
  styleUrls: ['./sysnet-common-header.component.scss']
})
export class SysnetCommonHeaderComponent implements OnInit, OnDestroy {
  public $page: Subscription;
  public smallScreen = true;
  public accessChecked = false;
  public selectedTitle: string;
  public isTitleFocused = false;
  public commonHeaderToolBarTourId = 'commonHeaderToolBarTourId';
  public toolBarTourPosition = 'right';
  public tabChangedFromStock: Subject<boolean> = new Subject<boolean>();

  @Input() public componentTitle: string;
  @Input() public tabs: (IExtendedTabItem & { url: string })[] = [];
  @Output() public readonly selectedItem = new EventEmitter<string>();
  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private baseGuard: BaseGuard
  ) { }

  public async ngOnInit(): Promise<void> {
    this.breakpointObserver.observe(BreakpointsTriggers.isMobileTrigger).subscribe((result) => {
      this.smallScreen = result.matches;
    });

    if (this.tabs.length > 0) {
      this.router.events.pipe().subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.setSelectedTitle();
        }
      });
      try {
        await this.checkTabPermission(this.tabs);
        this.setSelectedTitle();
      } catch (err) { /* empty */ }
      this.accessChecked = true;
    }
    if (window.innerWidth < CSS_CONSTANT.SYSNET_COMMON_HEADER_TITLE_RIGHT_MINWIDTH) {
      this.toolBarTourPosition = 'bottom';
    }
  }

  @HostListener('window:resize', ['$event']) public onResize(): void {
    if (window.innerWidth < CSS_CONSTANT.SYSNET_COMMON_HEADER_TITLE_RIGHT_MINWIDTH) {
      this.toolBarTourPosition = 'bottom';
    } else {
      this.toolBarTourPosition = 'right';
    }
  }

  public ngOnDestroy(): void {
    if (this.$page) { this.$page.unsubscribe(); }
  }
  public async checkTabPermission(tabs: IExtendedTabItem[]): Promise<void> {
    if (!tabs) { return; }
    await Promise.all(tabs.map(async (tab) => {
      if (!tab.dataAccessName) { tab.visible = true; return; }
      const hasAccess = await this.baseGuard.checkPermissionAtCurrentOrg(tab.dataAccessName, DEFAULT_HOME_ROUTE);
      tab.visible = (hasAccess === true);
      return;
    }));
  }
  public selectTab(tab: IExtendedTabItem & { url: string }): void {
    this.selectedTitle = tab.text;
    this.router.navigateByUrl(tab.url);

    if (tab.url !== pages.SYSNET.sub.STOCK.sub.UPDATE_STOCK.sub.STOCK_LIST.url) {
      this.tabChangedFromStock.next(true);
    }
  }

  public toggleMenuFocus(): void {
    this.isTitleFocused = !this.isTitleFocused;
  }

  public setSelectedTitle(): void {
    const currentTab = this.tabs.find((tab) => tab.url === this.router.url);
    this.selectedTitle = currentTab ? currentTab.text : this.tabs[0].text;
  }
}
