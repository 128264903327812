import { Injectable } from '@angular/core';
import { GlobalsService } from '@services/global.service';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { HttpResponse } from '@angular/common/http';
import { SupplierInventoryCodesService as SDKSupplierInventoryCodesService } from '@modules/SDKs/sysnetApi/api/supplierInventoryCodes.service';
import { GroupQuery, GroupSummaryQuery, SupplierInventoryCodeInsert, SupplierInventoryCodeUpdate, SupplierInventoryCodeView } from '@modules/SDKs/sysnetApi';
import { ServicesWrappingSysnetSDK } from './ServicesWrappingSysnetSDK';

@Injectable({
    providedIn: 'root'
})
export class SupplierInventoryCodesService extends ServicesWrappingSysnetSDK {
    private orgId: string;

    constructor(private sdkSupplierInventoryCodesService: SDKSupplierInventoryCodesService, private globals: GlobalsService) {
        super([sdkSupplierInventoryCodesService], globals);
        this.globals.$state.subscribe((newState) => {
            if (newState) { this.orgId = newState.selectedOrganisation?._id; }
        });
    }

    // Get SupplierInventoryCode
    public getSupplierInventoryCode(supplierInventoryCodeId: number): Promise<HttpResponse<SupplierInventoryCodeView>> {
        return this.sdkSupplierInventoryCodesService.supplierInventoryCodesIdGet(supplierInventoryCodeId, AppConfiguration.rsh.getStrVal('rid'), null, 'response').toPromise();
    }

    // Search SupplierInventoryCodes
    public searchSupplierInventoryCodes(group?: GroupQuery, groupSummary?: GroupSummaryQuery, totalSummary?: GroupSummaryQuery,
                                        sort?: string, limit?: number, offset?: number, view?: string, search?: object, searchDistinct?: string): Promise<HttpResponse<SupplierInventoryCodeView[]>> {
        return this.sdkSupplierInventoryCodesService.supplierInventoryCodesGet(AppConfiguration.rsh.getStrVal('rid'), view,
        this.stringify(group), this.stringify(groupSummary), this.stringify(totalSummary), searchDistinct, sort, limit, offset, this.stringify(search),
        null, null, null, null, null, null, null, null, null, null, null, null, null, null, this.orgId, 'response').toPromise();
    }

    // Insert SupplierInventoryCode
    public insertSupplierInventoryCode(supplierInventoryCode: SupplierInventoryCodeInsert): Promise<HttpResponse<SupplierInventoryCodeView>> {
        return this.sdkSupplierInventoryCodesService.supplierInventoryCodesPost(supplierInventoryCode, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Update SupplierInventoryCode
    public updateSupplierInventoryCode(supplierInventoryCodeId: number, supplierInventoryCode: SupplierInventoryCodeUpdate): Promise<HttpResponse<SupplierInventoryCodeView[]>> {
        return this.sdkSupplierInventoryCodesService.supplierInventoryCodesIdPut(supplierInventoryCodeId, supplierInventoryCode, AppConfiguration.rsh.getStrVal('rid'), 'response')
        .toPromise();
    }

    // Delete SupplierInventoryCode
    public deleteSupplierInventoryCode(supplierInventoryCodeId: number): Promise<HttpResponse<SupplierInventoryCodeView>> {
        return this.sdkSupplierInventoryCodesService.supplierInventoryCodesIdDelete(supplierInventoryCodeId, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

}
