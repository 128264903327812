const UI_COMMON_CONFIG = {
    DEV: {
        // auth0 details
        AUTH0_DOMAIN : 'develop-auth.hlcloud.com.au',

        // config for single page app (ClientID & Client Secret)
        AUTH0_APP_CLIENT_ID: 'ggj1xCS78lbeCzIMgjW1xfamTo3LfKgL',
    },
    DEVELOP: {
        // auth0 details
        AUTH0_DOMAIN : 'develop-auth.hlcloud.com.au',
       // config for single page app (ClientID & Client Secret)
        AUTH0_APP_CLIENT_ID: 'ggj1xCS78lbeCzIMgjW1xfamTo3LfKgL',
        PORTAL_API_URI: 'https://develop-portal.hlcloud.com.au:3000',
    },
    SANDBOX: {

        PORTAL_API_URI: 'https://sandbox-portal.hlcloud.com.au:3000',
        AUTH0_DOMAIN : 'sandbox-auth.hlcloud.com.au',
        AUTH0_APP_CLIENT_ID: 'HxyZJLkIze0rHprw4Wi0A5DAiU3zVMlC',

    },
    STAGING: {
        // auth0 details
        AUTH0_DOMAIN : 'staging-auth.hlcloud.com.au',

        // config for single page app (ClientID & Client Secret)
        AUTH0_APP_CLIENT_ID: 'BMzGvIHE7RWBp44iIieurdnq604y4GT3',
        PORTAL_API_URI: 'https://staging-portal.hlcloud.com.au:3000',

    },
    DEMO: {

        AUTH0_DOMAIN : 'auth.hlcloud.com.au',
        AUTH0_APP_CLIENT_ID: 'rz0Ej31Woq4AVO8BkyMx2yDzfS2WGUkQ',

    },
    PRODUCTION: {
        // application details
        AUTH0_DOMAIN : 'auth.hlcloud.com.au',

        // config for single page app (ClientID & Client Secret)
        AUTH0_APP_CLIENT_ID: 'm0pQML86HmS63IrDyRdoOgTxV3uFbE3s',
        PORTAL_API_URI: 'https://portal.hlcloud.com.au:3000',
    },
};

export default UI_COMMON_CONFIG;
