﻿// tslint:disable-next-line: align
export default class Constants {

    public static DATA_ACCESS = class {
        public static GET_LOCATION = 'get_location';
        public static UPDATE_LOCATION = 'update_location';
        public static INSERT_LOCATION = 'insert_location';
        public static INSERT_USER = 'insert_user';
        public static UPDATE_USER = 'update_user';
        public static UPDATE_USERS_LOCATION_WIDE = 'update_users_location_wide';
        public static UPDATE_USER_ADMIN = 'update_user_admin';
        public static GET_USER = 'get_user';
        public static GET_USER_LOCATION_WIDE = 'get_user_location_wide';
        public static GET_ALL_USERS_ADMIN = 'get_all_users_admin';
        public static ADD_USER_DATA_ACCESS = 'add_user_data_access';
        public static REMOVE_USER_DATA_ACCESS = 'remove_user_data_access';
        public static INSERT_ROLE = 'insert_role';
        public static UPDATE_ROLE = 'update_role';
        public static GET_ROLE = 'get_role';
        public static GET_ROLES_LOCATION_WIDE = 'get_roles_location_wide';
        public static GET_ALL_ROLES = 'get_all_roles';
        public static GET_STRIPE_CUSTOMERS = 'get_stripe_customers';
        public static UPDATE_STRIPE_CUSTOMER = 'update_stripe_customer';
        public static INSERT_STRIPE_CUSTOMER = 'insert_stripe_customer';
        public static SEND_SMS = 'send_sms';
        public static GET_MESSAGE_TEMPLATE = 'get_message_template';
        public static UPDATE_MESSAGE_TEMPLATE = 'update_message_template';
        public static INSERT_MESSAGE_TEMPLATE = 'insert_message_template';
        public static SEND_EMAIL = 'send_email';
        public static GET_APPLICATIONS = 'get_applications';
        public static GET_STRIPE_PLAN = 'get_stripe_plan';
        public static SEND_PUSH_NOTIFICATION = 'send_push_notification';
        public static UPDATE_NOTIFICATION = 'update_notification';
        public static INSERT_NOTIFICATION = 'insert_notification';
        public static GET_NOTIFICATION = 'get_notification';
        public static WFMC_EXAMPLE_APPLICATION_ROUTE = 'WFMC_EXAMPLE_APPLICATION_ROUTE';
        public static KM_DEFAULT_ROUTE = 'KM_DEFAULT_ROUTE';
        public static UPDATE_SUBSCRIPTION = 'update_subscription';
        public static GET_SUBSCRIPTION = 'get_subscription';
        public static INSERT_SUBSCRIPTION = 'insert_subscription';
        public static GET_CONNECTION = 'get_connection';
        public static INSERT_CONNECTION = 'insert_connection';
        public static UPDATE_CONNECTION = 'update_connection';
        public static INSERT_AUDIT = 'insert_audit';
        public static GET_AUDIT = 'get_audit';
        public static INSERT_ORGANISATION = 'insert_organisation';
        public static GET_ORGANISATION = 'get_organisation';
        public static UPDATE_ORGANISATION = 'update_organisation';
        public static GET_LOCATION_TYPE = 'get_location_type';
        public static GET_PAYMENT_METHOD = 'get_payment_method';
        public static INSERT_PAYMENT_METHOD = 'insert_payment_method';
        public static UPDATE_PAYMENT_METHOD = 'update_payment_method';
        public static DELETE_PAYMENT_METHOD = 'delete_payment_method';
        public static GET_INVOICE = 'get_invoice';
        public static GET_PAYMENT = 'get_payment';
        public static GET_USERS_BY_ROLE = 'get_users_by_role';
        public static GET_INVOICE_PAYMENT = 'get_invoice_payment';
        public static ONBOARDING_SEND_INVITE = 'onboarding_send_invite';
        public static UPDATE_LOCATION_ORGANISATION = 'update_location_organisation';
        public static UPDATE_LOCATION_DEACTIVATE = 'update_location_deactivate';
        public static UPDATE_LOCATION_REACTIVATE = 'update_location_reactivate';
        public static UPDATE_ORGANISATION_DEACTIVATE = 'update_organisation_deactivate';
        public static UPDATE_ORGANISATION_REACTIVATE = 'update_organisation_reactivate';
        public static KIBANA_INDEX_TRANSACTION_ITEMS = 'kibana_index_transaction_items';
        public static KIBANA_INDEX_TRANSACTION_TENDERS = 'kibana_index_transaction_tenders';
        public static ACCESS_STOCK = 'access_stock';
        public static UPLOAD_FILES = 'upload_files';
        public static UPLOAD_PUBLIC_FILES = 'upload_public_files';
        public static GET_FILES = 'get_files';
        public static GET_SUPPORT = 'get_support';
        public static GET_SUBSCRIPTIONS = 'esapi_get_subscriptions';
    };

    public static MESSAGE_TEMPLATES = class {
        public static INVITE_NEW_USER_EMAIL = 'INVITE_NEW_USER_EMAIL';
        public static INVITE_EXISTING_USER_EMAIL = 'INVITE_EXISTING_USER_EMAIL';
    };

    public static APPLICATION_CODES = class {
        public static ODM = 'ODM';
        public static WFMC = 'WFMC';
        public static BBC = 'BBC';
        public static ORDER_NOW = 'OAT';
        public static MENU_MANAGEMENT = 'mm';
        public static ADMIN_PORTAL = 'AdminPortal';
        public static CLOUD_PORTAL = 'CloudPortal';
        public static REPORTS = 'Reports';
        public static SYSNET_CLOUD = 'sc';
        public static WEB_HOOKS = 'esapi';
        public static USER_MANAGEMENT = 'um';
    };

}
