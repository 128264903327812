/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

export interface StockReceivedDetailView { 
    /**
     * The unique identifier of this record.
     */
    stockReceivedDetailId?: number;
    /**
     * A reference to the primary key in the StockReceivedHeaders Table
     */
    stockReceivedHeaderId?: number;
    /**
     * A reference to the primary key in the Stock Table
     */
    stockId?: number;
    /**
     * A reference to the primary key in the SupplierInventoryCodes Table
     */
    supplierInventoryCodeId?: number;
    /**
     * A reference to the primary key in the VenueLocations Table
     */
    locationId?: number;
    /**
     * A reference to the primary key in the Factors Table
     */
    factorId?: number;
    invoiceLineTotal?: number;
    total?: number;
    totalInnersReceived?: number;
    oldCost?: number;
    freight?: number;
    deposit?: number;
    discount?: number;
    taxableSundries?: number;
    nonTaxableSundries?: number;
    wholesaleTax0?: number;
    salesTax0?: number;
    salesTax1?: number;
    salesTax2?: number;
    salesTax3?: number;
    comment?: string;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    unitQtyReceived?: number;
    wholesaleTax1?: number;
    wholesaleTax2?: number;
    wholesaleTax3?: number;
    unitType?: StockReceivedDetailView.UnitTypeEnum;
}
export namespace StockReceivedDetailView {
    export type UnitTypeEnum = 'Factor' | 'Inner' | 'Outer';
    export const UnitTypeEnum = {
        Factor: 'Factor' as UnitTypeEnum,
        Inner: 'Inner' as UnitTypeEnum,
        Outer: 'Outer' as UnitTypeEnum
    };
}