/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HttpError { 
    /**
     * This is a one line summary of the error that has occurred.
     */
    summary?: string;
    /**
     * This is a string array detailing each individual problem we found.
     */
    details?: Array<string>;
    /**
     * This is an array of fieldLevelErrors which describes input errors found in url_parameters, the http body or http headers.
     */
    fieldLevelErrors?: Array<Array<any>>;
    code?: number;
}