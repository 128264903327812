import { Pipe, PipeTransform } from '@angular/core';
import { UnitType } from '@modules/SDKs/sysnetApi';
import { FormatFn } from '../Constants';
import { IItemTransform } from '../types/interfaces/FactorService';

export const factorTypes = ['Inner', 'Outer', 'Factor'];

export type UnitTypeEnum = 'Factor' | 'Inner' | 'Outer';

export interface IItem {
    factorId: string;
    Container: { description: string };
    divider: number;
}
export interface ISelectedStock {
    inners?: string;
    innersPerOuter?: number;
}

export const formatFactorLabel = (item: IItem, selectedStock: ISelectedStock): string => {
    switch (item.factorId) {
        case 'Inner':
            return `Inner | 1 x ${(selectedStock.inners ?? 'Single')}`;
        case 'Outer':
            return `Outer | ${selectedStock.innersPerOuter} x ${(selectedStock.inners ?? 'Single')}`;
        default:
            const innerPerOuterByDivider = (selectedStock.innersPerOuter / item.divider).toString();
            return `${item.Container.description} | ${FormatFn.decimalPointFormat(parseFloat(innerPerOuterByDivider), 2, true)} x ${(selectedStock.inners ?? 'Single')}`;
    }
};

export const getUnitTypeByFactorId = (reqFactorId: string | number): { unitType: UnitType, factorId: number } => {
    let unitType;
    let factorId = 0;
    if (typeof reqFactorId === 'number') {
        unitType = 'Factor';
        factorId = Number(reqFactorId);
    } else {
        unitType = reqFactorId;
    }
    return { unitType, factorId };
};

export const validateUnitQty = (value: string | number, factorId: string | number): boolean => {
    const { unitType } = getUnitTypeByFactorId(factorId);
    if ((unitType === 'Factor' || unitType === 'Outer') && !Number.isInteger(value)) { return false; }
    return true;
};

@Pipe({
    name: 'formatFactorLabel'
  })
  export class FormatFactorLabelPipe implements PipeTransform {
    public transform(item: IItemTransform, selectedStock: ISelectedStock): string {
        const factorId = item.factorId ?? item.unitType;
        const objToFormat = {
            factorId
        } as IItem;

        if (typeof factorId === 'number') {
            objToFormat.Container = { ...item.factorCalculations.Factor.Container };
            objToFormat.divider = item.factorCalculations.Factor.divider;
        }

        return formatFactorLabel(objToFormat, selectedStock);
    }
  }
