/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StockDiscrepancyDetailInsert { 
    /**
     * A reference to the primary key in the StockDiscrepancyHeaders Table
     */
    discrepancyId: number;
    /**
     * A reference to the primary key in the Stock Table
     */
    stockId: number;
    reason?: string;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
    unitType: StockDiscrepancyDetailInsert.UnitTypeEnum;
    /**
     * A reference to the primary key in the Factors Table. Required when 'unitType' is 'Factor'
     */
    factorId?: number;
    /**
     * Required only when doing Wastage
     */
    unitAdjustmentQty?: number;
    /**
     * Required only when doing Discrepancy
     */
    adjustmentQty?: number;
}
export namespace StockDiscrepancyDetailInsert {
    export type UnitTypeEnum = 'Factor' | 'Inner' | 'Outer';
    export const UnitTypeEnum = {
        Factor: 'Factor' as UnitTypeEnum,
        Inner: 'Inner' as UnitTypeEnum,
        Outer: 'Outer' as UnitTypeEnum
    };
}