import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonGroupModule, DxButtonModule, DxDataGridModule, DxFormModule, DxLookupModule, DxMenuModule, DxNumberBoxModule, DxPopupModule, DxRadioGroupModule,
  DxSwitchModule, DxTabPanelModule, DxTabsModule, DxTextBoxModule, DxTreeViewModule, DxDateBoxModule, DxToolbarModule, DxAccordionModule, DxLoadPanelModule, DxChartModule,
  DxTextAreaModule, DxValidatorModule, DxHtmlEditorModule, DxTagBoxModule, DxTemplateModule, DxDrawerModule, DxLoadIndicatorModule, DxCheckBoxModule, DxResponsiveBoxModule,
  DxAutocompleteModule, DxScrollViewModule, DxSelectBoxModule, DxDropDownButtonModule, DxContextMenuModule } from 'devextreme-angular';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxButtonGroupModule,
    DxMenuModule,
    DxTabsModule,
    DxPopupModule,
    DxTabPanelModule,
    DxTreeViewModule,
    DxRadioGroupModule,
    DxSwitchModule,
    DxTextBoxModule,
    DxLookupModule,
    DxDateBoxModule,
    DxFormModule,
    DxNumberBoxModule,
    DxToolbarModule,
    DxAccordionModule,
    DxLoadPanelModule,
    DxChartModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxHtmlEditorModule,
    DxTagBoxModule,
    DxTemplateModule,
    DxDrawerModule,
    DxLoadIndicatorModule,
    DxCheckBoxModule,
    DxResponsiveBoxModule,
    DxAutocompleteModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxDropDownButtonModule,
    DxContextMenuModule
  ],
  exports: [
    DxDataGridModule,
    DxButtonModule,
    DxButtonGroupModule,
    DxMenuModule,
    DxTabsModule,
    DxPopupModule,
    DxTabPanelModule,
    DxTreeViewModule,
    DxRadioGroupModule,
    DxSwitchModule,
    DxTextBoxModule,
    DxLookupModule,
    DxDateBoxModule,
    DxFormModule,
    DxNumberBoxModule,
    DxToolbarModule,
    DxAccordionModule,
    DxLoadPanelModule,
    DxChartModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxHtmlEditorModule,
    DxTagBoxModule,
    DxTemplateModule,
    DxDrawerModule,
    DxLoadIndicatorModule,
    DxCheckBoxModule,
    DxResponsiveBoxModule,
    DxAutocompleteModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxDropDownButtonModule,
    DxContextMenuModule
  ]
})
export class DevExtremeModule {}
