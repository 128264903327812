import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { PageNotFoundComponent } from './components/generic/page-not-found/page-not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersService } from '@services/users.service';
import { UsersService as PortalSDKUsersService } from '@modules/SDKs/portalApi/api/users.service';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '@modules/shared.module';
import { GlobalsService } from '@services/global.service';
import { UtilityService } from '@services/utility.service';
import { DashboardService } from '@services/dashboard.service';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ImagesService } from '@services/images.service';
import { BASE_PATH, DefaultSettingsService } from '@modules/SDKs/sysnetApi';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { FrameworkService } from '@services/framework.service';
import { EventsService } from '@services/events.service';
import UiAppConfig from '../AppConfigurationOptions';
import { environment } from '../environments/environment';
import { Auth0Service } from './auth0/auth0.service';
import { ProtectedRoute } from './auth0/auth.guard';
import { Auth0Component } from './auth0/auth0.component';
import { AngularMaterialModule } from './modules/angular-material.module';
import { SignupComponent } from './components/public/signup/signup.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DynamsoftConfig } from './dbr';
AppConfiguration.LoadConfiguration(UiAppConfig.AppConfigurationOptions, environment); // Configure the application.
DynamsoftConfig.LoadConfiguration();

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    Auth0Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    SharedModule,
    AngularMaterialModule,
    InlineSVGModule.forRoot()
  ],
  providers: [
    { provide: BASE_PATH, useValue: AppConfiguration.GetConfiguration('hal.sysnet').REST_API_HOST },
    UsersService,
    ProtectedRoute,
    Auth0Service,
    EventsService,
    GlobalsService,
    UsersService,
    PortalSDKUsersService,
    UtilityService,
    DashboardService,
    ImagesService,
    DatePipe,
    CurrencyPipe,
    DecimalPipe,
    FrameworkService,
    DefaultSettingsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
