// tslint:disable: max-line-length
import { UsersResponse, UsersResponseRoleDataAccesses
    , Organisation, UsersResponseAccess
    , LocationResponse} from '../modules/portalSDK/api';
export class User {
    private userResponse: UsersResponse;
    constructor(userResponse: UsersResponse) {
        this.userResponse = userResponse;
    }
    /**
     * Checks that a user has at least one of the dataAccessCodes passed in. Optionally will a specified permission and at a location
     * @param dataAccessCodes - mandatory - string - The names of the dataaccess code you are looking for, in comma separated format
     * @permission - optional - string - the name of permission assigned to the dataAccessCode, use null if you don't want this checked.
     * @location - Location Object - optional
     *               - Use null to specify a wildcard match against locations (could be org specific)
     *               - Use undefined to not require a specific location
     *               - Use a location object if you want to make sure the user has permissions at the specified location
     * @organisation - Organisation Object - optional
     *               - use undefined if you dont want to check the organisation
     *               - use null if you want specify all organisations
     *               - use Organisation Object if you want to specify which organisation the dataaccess has to belong to
     * Generally a users access should only look like the following: scenarios:
     *
     * { organisationId: "the org id", locationId": "*", roleId: "the roleId"} // user has access to all locations for an organisation
     * OR
     * {locationId: "*", roleId: "the roleID"} // user has all location access - only allowed on trusted ip ranges
     * OR
     * {locationId: "the locationId", roleId: "the roleId"} // user has access to one location
     *
     * @example
     * // to check that a user has org level privileges, ie user has a data access like { organisationId: "anOrgs id", locationId": "*", roleId: "the roleId"}, use:
     * user.hasDataAccesCode('code', 'permission', null, anOrg);
     * // to check if the user has access to any location in an organisation:
     * user.hasDataAccesCode('code', 'permission', undefined, anOrg);
     */
    public hasDataAccesCodesPermissionLocation(dataAccessCodes: string, permission: string, location: LocationResponse, organisation?: Organisation): boolean {
        if (dataAccessCodes) {
            const dataAccessCodesArray = dataAccessCodes.split(',');
            for (const dataAccessCode of dataAccessCodesArray) {
                if (this.hasDataAccesCode(dataAccessCode, permission, location, organisation)) {
                    return true;
                }
            }
        }
        return false;

    }
    public hasDataAccesCodes(dataAccessCodes: string): boolean {
        return this.hasDataAccesCodesPermission(dataAccessCodes, null);
    }

    public hasDataAccesCodesPermission(dataAccessCodes: string, permission: string): boolean {
        return this.hasDataAccesCodesPermissionLocation(dataAccessCodes, permission, undefined);
    }
    /**
     * Checks that a user has a dataAccessCode. Optionally will a specified permission and at a location
     * @param dataAccessCode - mandatory - string - The name of the dataaccess code you are looking for
     * @permission - optional - string - the name of permission assigned to the dataAccessCode, use null if you don't want this checked.
     * @location - Location Object - optional
     *               - Use null to specify a wildcard match against locations (could be org specific)
     *               - Use undefined to not require a specific location
     *               - Use a location object if you want to make sure the user has permissions at the specified location
     * @organisation - Organisation Object - optional, if set, checks for data access directly against the organisation or a location that belongs to that org
     *               - use undefined if you dont want to check the organisation
     *               - use Organisation Object if you want to specify which organisation the dataaccess has to belong to
     * Generally a users access should only look like the following: scenarios:
     *
     * { organisationId: "the org id", locationId": "*", roleId: "the roleId"} // user has access to all locations for an organisation
     * OR
     * {locationId: "*", roleId: "the roleID"} // user has all location access - only allowed on trusted ip ranges
     * OR
     * {locationId: "the locationId", roleId: "the roleId"} // user has access to one location
     *
     * @example
     * // to check that a user has org level privileges, ie user has a data access like { organisationId: "anOrgs id", locationId": "*", roleId: "the roleId"}, use:
     * user.hasDataAccesCode('code', 'permission', null, anOrg);
     * // to check if the user has access to any location in an organisation:
     * user.hasDataAccesCode('code', 'permission', undefined, anOrg);
     */
    public hasDataAccesCode(dataAccessCode: string, permission: string, location?: LocationResponse, organisation?: Organisation): boolean {
        if (dataAccessCode === '' || dataAccessCode == null) {   // IF THE ACCESS CODE IS NOT SET THERE IS NOTHING TO DETECT.
            return true;
        }
        for (let i = 0; i < this.userResponse.access.length; i++) {

            // if organisation is not checked for AND ( location is checked for all locations OR location is not checked for)
            if ( organisation === undefined && (location === undefined || location === null) ) {

                if (this.hasGivenDataAccessInDataAccesses(dataAccessCode, this.userResponse.access[i].role.dataAccesses, permission)) {
                    return true;
                }
            // if we are not checking for organisation and we are checking for a specific location
            } else if (organisation === undefined && typeof location === 'object') {

                // make sure that the location belongs to any organisation defined against the access
                if (this.userResponse.access[i].organisationId === undefined ||  location.organisationId === this.userResponse.access[i].organisationId) {
                    // check that the access is wildcard or that the location passed in matches the access location
                    if (this.userResponse.access[i].locationId === '*' || location._id === this.userResponse.access[i].locationId ) {
                        if (this.hasGivenDataAccessInDataAccesses(dataAccessCode, this.userResponse.access[i].role.dataAccesses, permission)) {
                            return true;
                        }
                    }
                }
            // checking that user has org level privileges
            } else if (typeof organisation === 'object' && location === null ) {

                if (
                    (this.userResponse.access[i].organisationId && this.userResponse.access[i].organisationId === organisation._id && this.userResponse.access[i].locationId === '*') ||
                    (this.userResponse.access[i].organisationId === undefined && this.userResponse.access[i].locationId === '*') ) {

                    if (this.hasGivenDataAccessInDataAccesses(dataAccessCode, this.userResponse.access[i].role.dataAccesses, permission)) {
                        return true;
                    }
                }

            } // checking that a user has privileges at that organisation
            else if (typeof organisation === 'object' && location === undefined ) {

                if (
                    (this.userResponse.access[i].organisationId && this.userResponse.access[i].organisationId === organisation._id && this.userResponse.access[i].locationId === '*') ||
                    (this.userResponse.access[i].organisationId === undefined && this.userResponse.access[i].locationId === '*') ||
                    (this.userResponse.access[i].location && this.userResponse.access[i].location.organisationId === organisation._id) ) {

                    if (this.hasGivenDataAccessInDataAccesses(dataAccessCode, this.userResponse.access[i].role.dataAccesses, permission)) {
                        return true;
                    }
                }

            }
            else if (typeof organisation === 'object' && typeof location === 'object') {
                if (
                    (this.userResponse.access[i].locationId === '*' || location._id === this.userResponse.access[i].locationId) &&
                    (
                        (this.userResponse.access[i].organisationId === organisation._id && this.userResponse.access[i].locationId === '*') ||
                        (this.userResponse.access[i].organisationId === undefined && this.userResponse.access[i].locationId === '*') ||
                        (this.userResponse.access[i].location && this.userResponse.access[i].location.organisationId === organisation._id)
                    )
                ) {

                    if (this.hasGivenDataAccessInDataAccesses(dataAccessCode, this.userResponse.access[i].role.dataAccesses, permission)) {
                        return true;
                    }

                }
            }
        }
        return false;
    }
    public hasGivenDataAccessInDataAccesses(dataAccessCode: string, dataAccesses: UsersResponseRoleDataAccesses[],  permission: string): boolean {
        for (let j = 0; j < dataAccesses.length; j++) {
            if (dataAccesses[j].name === dataAccessCode) {
                if (this.hasPermission(dataAccesses[j], permission)) {
                    return true;
                }
            }
        }
        return false;
    }
    /**
     * If a permission has been specified check that the data access has the approperate permission.
     */
    private hasPermission(dataAccess: UsersResponseRoleDataAccesses, permission: string) {
        if (permission == null) { return true; }
        for (let i = 0; i < dataAccess.dataAccessPermissions.length; i++) {
            if (dataAccess.dataAccessPermissions[i].name === permission) { return true; }
        }
        return false;
    }

    public hasAppAccessWithCode(appCode: string) {
        if (appCode && appCode.trim() === '') {
            return false;
        }

        if (this.userResponse.access && this.userResponse.access.find((acc: UsersResponseAccess) => acc.role && acc.role.application && acc.role.application.code === appCode)) {
            return true;
        }
        else {
            return false;
        }

    }
}
