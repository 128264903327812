import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GlobalsService } from '@services/global.service';
import { RowTemplateData } from 'devextreme/ui/data_grid';
import { GridConstants } from 'src/app/Constants';
import { IStandardDataGridConfig } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { Properties as dxPopupOptions } from 'devextreme/ui/popup';
import { DxDataGridComponent } from 'devextreme-angular';
import { BarcodeView } from '@modules/SDKs/sysnetApi';
import { IBarcode } from '@services/grid/barcodes-data-grid.service';

@Component({
  selector: 'app-multi-item-barcode-selection-list',
  templateUrl: './multi-item-barcode-selection-list.component.html',
  styleUrls: ['./multi-item-barcode-selection-list.component.scss']
})
export class MultiItemBarcodeSelectionListComponent implements OnInit {
  public popupConfig: dxPopupOptions = {
    title: 'Barcode Items',
    visible: false,
    onShowing: (e) => {
      this.multiItemBarcodeSelectionListDataGrid?.instance.refresh();
      this.selectedRowKey = null;
      this.multiItemBarcodeSelectionListDataGrid?.instance.clearSelection();
    }
  };
  public selectedRowKey;
  public barcodeGridData: BarcodeView[] = [];
  public multiItemBarcodeSelectionListDataGridConfig: IStandardDataGridConfig = {};
  public isMultiItemBarcodeFromProduct = false;
  @ViewChild('multiItemBarcodeSelectionListDataGrid', { static: false }) public multiItemBarcodeSelectionListDataGrid: DxDataGridComponent;
  @Output() public readonly barcodeItemSelected = new EventEmitter<BarcodeView & IBarcode>();
  @Input() public hidePopupAfterSelection = true;
  constructor(
    public globalsService: GlobalsService
  ) { this.onDataGridSetup(); }

  public ngOnInit(): void {
  }

  public openPopup(barcodes: IBarcode[], isMultiItemBarcodeFromProduct = false): void {
    this.isMultiItemBarcodeFromProduct = isMultiItemBarcodeFromProduct;
    if (this.isMultiItemBarcodeFromProduct) {
      this.setupDataGridForMultiItemBarcodesProduct();
    }
    this.barcodeGridData = barcodes; // provide grid data at popup open
    this.popupConfig.visible = true;
  }

  public onDataGridSetup(): void { // custom config
    this.multiItemBarcodeSelectionListDataGridConfig.columns = [
      {
        cellTemplate: 'singleSelectCellTemplate',
        width: 50,
        allowReordering: false,
        allowResizing: false,
        allowExporting: false,
        allowEditing: false,
        allowSorting: false,
        allowHiding: false,
        fixed: true,
        fixedPosition: 'left',
        alignment: 'center',
        showInColumnChooser: false
      },
      {
        dataType: 'number',
        minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
        width: 102,
        caption: 'Stock Id',
        allowSearch: false,
        allowHeaderFiltering: false,
        allowGrouping: false,
        allowSorting: false,
        allowFiltering: false,
        visible: false,
        calculateDisplayValue: (data) => data.factorId ? data.Factor?.Stock?.stockId : data.Product?.Factor?.Stock?.stockId
      },
      {
        dataType: 'number',
        minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
        width: 102,
        caption: 'Inv. Code',
        allowSearch: true,
        allowHeaderFiltering: false,
        allowGrouping: false,
        allowSorting: false,
        allowFiltering: false,
        calculateDisplayValue: (data) => data.factorId ? data.Factor?.Stock?.inventoryCode : data.Product?.Factor?.Stock?.inventoryCode
      },
      {
        dataType: 'string',
        minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
        caption: 'Stock Description',
        allowSearch: true,
        allowHeaderFiltering: false,
        allowGrouping: false,
        allowSorting: false,
        allowFiltering: false,
        calculateDisplayValue: (data) => data.factorId ? data.Factor?.Stock?.name : data.Product?.Factor?.Stock?.name
      },
      {
        dataType: 'string',
        minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
        caption: 'Factor Name',
        allowSearch: false,
        allowHeaderFiltering: false,
        allowGrouping: false,
        allowSorting: false,
        allowFiltering: false,
        calculateDisplayValue: (data) => data.factorId ? data.Factor?.Container?.description : data.Product?.Factor?.Container?.description
      }
    ];

    this.multiItemBarcodeSelectionListDataGridConfig.searchPanel = {
      visible: true,
      placeholder: 'Search...',
      searchVisibleColumnsOnly: true
    };

    this.multiItemBarcodeSelectionListDataGridConfig.selection = {
      mode: 'single'
    };

    this.multiItemBarcodeSelectionListDataGridConfig.scrolling = {
      columnRenderingMode: 'standard'
    };

    this.multiItemBarcodeSelectionListDataGridConfig.columnChooser = {
      enabled: true,
      mode: 'select'
    };

    this.multiItemBarcodeSelectionListDataGridConfig.onSelectionChanged = (e) => {
      const selectedRowKeys = e.component.getSelectedRowKeys();
      if (selectedRowKeys.length > 0) {
        this.selectedRowKey = selectedRowKeys[0];
      }
    };

    this.multiItemBarcodeSelectionListDataGridConfig.remoteOperations = false;
  }

  public hidePopup(): void {
    this.popupConfig.visible = false;
  }

  public async select(): Promise<void> {
    const selectedItem = await this.multiItemBarcodeSelectionListDataGrid?.instance?.getSelectedRowsData()[0];
    if (!selectedItem) { return; }
    this.barcodeItemSelected.emit(selectedItem);
    if (this.hidePopupAfterSelection) { this.hidePopup(); }
  }

  public setupDataGridForMultiItemBarcodesProduct(): void {
    this.multiItemBarcodeSelectionListDataGridConfig.columns = [
      {
        cellTemplate: 'singleSelectCellTemplate',
        width: 50,
        allowReordering: false,
        allowResizing: false,
        allowExporting: false,
        allowEditing: false,
        allowSorting: false,
        allowHiding: false,
        fixed: true,
        fixedPosition: 'left',
        alignment: 'center',
        showInColumnChooser: false
      },
      {
        dataField: 'Product.fullName',
        dataType: 'string',
        minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
        caption: 'Product Name',
        allowSearch: true,
        allowHeaderFiltering: false,
        allowGrouping: false,
        allowSorting: false,
        allowFiltering: false
      }
    ];
  }

}
