import { Injectable } from '@angular/core';
import { GlobalsService } from '@services/global.service';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { HttpResponse } from '@angular/common/http';
import { StockCreationTemplatesService as SDKStockCreationTemplatesService } from '@modules/SDKs/sysnetApi/api/stockCreationTemplates.service';
import { GroupQuery, GroupSummaryQuery } from '@modules/SDKs/sysnetApi';
import { StockCreationTemplateInsert } from '@modules/SDKs/sysnetApi/model/stockCreationTemplateInsert';
import { StockCreationTemplateUpdate } from '@modules/SDKs/sysnetApi/model/stockCreationTemplateUpdate';
import { StockCreationTemplateView } from '@modules/SDKs/sysnetApi/model/stockCreationTemplateView';
import { ServicesWrappingSysnetSDK } from './ServicesWrappingSysnetSDK';

@Injectable({
    providedIn: 'root'
})
export class StockCreationTemplatesService extends ServicesWrappingSysnetSDK {
    private orgId: string;
    constructor(private sdkStockCreationTemplatesService: SDKStockCreationTemplatesService, private globals: GlobalsService) {
        super([sdkStockCreationTemplatesService], globals);

        this.globals.$state.subscribe((newState) => {
            if (newState) { this.orgId = newState.selectedOrganisation?._id; }
        });
    }

    // Get StockCreationTemplate
    public getStockCreationTemplate(stockCreationTemplateId: number, view?: string): Promise<HttpResponse<StockCreationTemplateView>> {
        return this.sdkStockCreationTemplatesService.stockCreationTemplatesIdGet(stockCreationTemplateId, AppConfiguration.rsh.getStrVal('rid'), view, 'response').toPromise();
    }

    // Search StockCreationTemplates
    public searchStockCreationTemplates(group?: GroupQuery, groupSummary?: GroupSummaryQuery, totalSummary?: GroupSummaryQuery,
                                        sort?: string, limit?: number, offset?: number, view?: string, search?: object,
                                        searchDistinct?: string): Promise<HttpResponse<StockCreationTemplateView[]>> {
        return this.sdkStockCreationTemplatesService.stockCreationTemplatesGet(AppConfiguration.rsh.getStrVal('rid'), view,
        this.stringify(group), this.stringify(groupSummary), this.stringify(totalSummary), searchDistinct, sort, limit, offset, this.stringify(search),
        null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null
        , null, null, this.orgId, 'response').toPromise();
    }

    // Insert StockCreationTemplate
    public insertStockCreationTemplate(stockCreationTemplate: StockCreationTemplateInsert): Promise<HttpResponse<StockCreationTemplateView>> {
        return this.sdkStockCreationTemplatesService.stockCreationTemplatesPost(stockCreationTemplate, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Update StockCreationTemplate
    public updateStockCreationTemplate(stockCreationTemplateId: number, stockCreationTemplate: StockCreationTemplateUpdate): Promise<HttpResponse<StockCreationTemplateView[]>> {
        return this.sdkStockCreationTemplatesService.stockCreationTemplatesIdPut(stockCreationTemplateId, stockCreationTemplate,
            AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Delete StockCreationTemplate
    public deleteStockCreationTemplate(stockCreationTemplateId: number): Promise<HttpResponse<StockCreationTemplateView>> {
        return this.sdkStockCreationTemplatesService.stockCreationTemplatesIdDelete(stockCreationTemplateId, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

}
