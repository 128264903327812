import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ITextDialogData {
  title?: string;
  content?: string;
  defaultValue?: string;
  maxLength?: number;
  displayLabel?: string;
  yesBtnLabel?: string;
  noBtnLabel?: string;
  showClearBtn?: boolean;
  fnYes?: (e: string) => void;
  fnNo?: () => void;
  required?: boolean;
}

@Component({
  selector: 'app-text-dialog',
  templateUrl: './text-dialog.component.html',
  styleUrls: ['./text-dialog.component.scss']
})
export class TextDialogComponent implements OnInit {

  public title = '';
  public content = '';
  public val = '';
  public maxLength = 40;
  public yesBtnLabel = 'Confirm';
  public noBtnLabel = 'Cancel';
  public showClearBtn = true;
  public displayLabel = '';
  public required = true;
  public fnYes: (e: string) => void;
  public fnNo: () => void;
  constructor(public dialogRef: MatDialogRef<TextDialogComponent, string>, @Inject(MAT_DIALOG_DATA) public data: ITextDialogData) {
      this.init(data);
  }

  public init(data: ITextDialogData): void {
    this.title = data.title;
    this.content = data.content;
    this.val = data.defaultValue ? data.defaultValue : this.val;
    this.maxLength = data.maxLength ? data.maxLength : this.maxLength;
    this.yesBtnLabel = data.yesBtnLabel ? data.yesBtnLabel : this.yesBtnLabel;
    this.noBtnLabel = data.noBtnLabel ? data.noBtnLabel : this.noBtnLabel;
    this.displayLabel = data.hasOwnProperty('displayLabel') ? data.displayLabel : this.displayLabel;
    this.showClearBtn = data.hasOwnProperty('showClearBtn') ? data.showClearBtn : this.showClearBtn;
    this.required = data.hasOwnProperty('required') ? data.required : this.required;
    if (data.fnYes) {
      this.fnYes = data.fnYes;
    }
    if (data.fnNo) {
      this.fnNo = data.fnNo;
    }
  }

  public ngOnInit(): void {
    const doc = document.getElementById('dialogContent');
    if (doc) { doc.innerHTML = this.content; }
  }

  public onYesClick(): void {
    if (this.fnYes) {
      if ((!this.val) && (this.required)) { return; }
      this.fnYes(this.val);
    }
    this.dialogRef.close(this.val);
  }

  public onNoClick(): void {
    if (this.fnNo) { this.fnNo(); }
    this.dialogRef.close(undefined);
  }

  public clear($event: Event): void {
    this.stop($event);
    this.val = '';
  }

  public stop(event: Event): void {
    if (event) { event.stopImmediatePropagation(); }
  }

}
