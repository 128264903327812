/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { FactorIdFKObject } from './factorIdFKObject';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { LinkedPluGrpIdFKObject } from './linkedPluGrpIdFKObject';
import { LocationIdFKObject } from './locationIdFKObject';
import { PluGroupIdFKObject } from './pluGroupIdFKObject';
import { PriceAPriceNameIdFKObject } from './priceAPriceNameIdFKObject';
import { PriceBPriceNameIdFKObject } from './priceBPriceNameIdFKObject';
import { PriceCPriceNameIdFKObject } from './priceCPriceNameIdFKObject';
import { PriceDPriceNameIdFKObject } from './priceDPriceNameIdFKObject';
import { PriceEPriceNameIdFKObject } from './priceEPriceNameIdFKObject';
import { PrintGroupIdFKObject } from './printGroupIdFKObject';
import { RevenueGroupIdFKObject } from './revenueGroupIdFKObject';
import { SalesStockView } from './salesStockView';
import { StockIdFKObject } from './stockIdFKObject';
import { TaxRateIdFKObject } from './taxRateIdFKObject';
import { UpdateDateTime } from './updateDateTime';

export interface SalesStockFKExternalKeysView extends SalesStockView { 
    locationIdFKObject?: LocationIdFKObject;
    stockIdFKObject?: StockIdFKObject;
    factorIdFKObject?: FactorIdFKObject;
    pluGroupIdFKObject?: PluGroupIdFKObject;
    revenueGroupIdFKObject?: RevenueGroupIdFKObject;
    priceAPriceNameIdFKObject?: PriceAPriceNameIdFKObject;
    priceBPriceNameIdFKObject?: PriceBPriceNameIdFKObject;
    priceCPriceNameIdFKObject?: PriceCPriceNameIdFKObject;
    priceDPriceNameIdFKObject?: PriceDPriceNameIdFKObject;
    priceEPriceNameIdFKObject?: PriceEPriceNameIdFKObject;
    linkedPluGrpIdFKObject?: LinkedPluGrpIdFKObject;
    taxRateIdFKObject?: TaxRateIdFKObject;
    printGroupIdFKObject?: PrintGroupIdFKObject;
}
export namespace SalesStockFKExternalKeysView {
}