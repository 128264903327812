import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UtilityService } from '@services/utility.service';
import { pages } from '../redux/store';

@Injectable()
export class ProtectedRoute implements CanActivate {
    constructor(private router: Router, public utils: UtilityService) { }

    public canActivate(): boolean {
        const jwtHelper = new JwtHelperService();
        if (!jwtHelper.isTokenExpired(localStorage.getItem('id_token'))) {
            return true;
        }
        this.utils.openSnackbar('Your session has expired, please log in again.', 'OK', 10000);
        // eslint-disable-next-line no-console
        console.warn('Session token has expired, log in again. If issues persist please confirm system time is correct.');
        this.router.navigate([pages.LOGIN.url]);

        return false;
    }
}
