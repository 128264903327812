/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PackageItemBulkInsertInner { 
    /**
     * The productId of the package.
     */
    packageProductId?: number;
    /**
     * The productId of the packageItem.
     */
    itemProductId?: number;
    /**
     * When ranging the parent package product this will create the packageItem at the location.
     */
    alwaysRangeToLocation?: boolean;
    /**
     * The quantity of the packageItem that is included in the package
     */
    quantity?: number;
    /**
     * If the package products revenueAttributeMethod is set to 'manual percentage', this field is used to determine how revenue is attributed for a sale. This should be a number between 0 and 100.
     */
    revenueAttributionPercentage?: number;
    /**
     * This fields determine whether the product can be removed at the point of sale.
     */
    isRequired?: boolean;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
}