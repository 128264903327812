/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { StockReceivedHeaderDetailsViewLocation } from './stockReceivedHeaderDetailsViewLocation';
import { StockReceivedHeaderDetailsViewPurchaseOrderHeader } from './stockReceivedHeaderDetailsViewPurchaseOrderHeader';
import { StockReceivedHeaderDetailsViewSupplier } from './stockReceivedHeaderDetailsViewSupplier';
import { UpdateDateTime } from './updateDateTime';

export interface StockReceivedHeadersDetailsView { 
    /**
     * The unique identifier of this record.
     */
    stockReceivedHeaderId?: number;
    referenceNo?: string;
    /**
     * A reference to the primary key in the Suppliers Table
     */
    supplierId?: number;
    /**
     * A reference to the primary key in the VenueLocations Table
     */
    locationId?: number;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    dateReceived?: Date;
    total?: number;
    amountPaid?: number;
    discount?: number;
    sundries?: number;
    sundriesExcludeSalesTax?: number;
    freight?: number;
    wholesaleTaxIncluded?: boolean;
    salesTaxIncluded?: boolean;
    freightIncluded?: boolean;
    depositIncluded?: boolean;
    excludeFreeStockFromLastCostCalculation?: boolean;
    nonSalesTaxSupplier?: boolean;
    isCreditClaim?: boolean;
    processed?: boolean;
    comment?: string;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    dateProcessed?: Date;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    /**
     * A reference to the primary key in the PurchaseOrderHeaders Table
     */
    purchaseOrderHeaderId?: number;
    freightOnInners?: boolean;
    sundriesOnInners?: boolean;
    supplier?: StockReceivedHeaderDetailsViewSupplier;
    location?: StockReceivedHeaderDetailsViewLocation;
    purchaseOrderHeader?: StockReceivedHeaderDetailsViewPurchaseOrderHeader;
}