﻿export class HttpStatus<T> {

    public static OK           = 200;
    public static NO_CONTENT   = 204;
    public static MOVED_PERMANENTLY = 301;
    public static TEMPORARY_REDIRECT = 307;
    public static NOT_FOUND    = 404;
    public static UNAUTHORISED = 401;
    public static SERVER_ERROR = 500;
    public static NOT_ACCEPTABLE = 406;
    public static CONFLICT = 409;
    public static PRECONDITION_FAILED = 412;
    public static PAYLOAD_TOO_LARGE = 413;
    public static BAD_REQUEST: number = 400;
    public static NON_AUTHENTICATION_INFO = 203;
    public static SERVICE_UNAVAILABLE = 503;
    public static FORBIDDEN = 403;

}
