<mat-toolbar *ngIf="componentTitle" class="sysnet-common-header-toolbar">
    <mat-toolbar-row>
        <span class="component-title">{{componentTitle}}</span>
    </mat-toolbar-row>
</mat-toolbar>
<mat-toolbar *ngIf="accessChecked && tabs.length > 0" class="sysnet-common-header-toolbar tabs-toolbar"
  [ngClass]="{'tabs-toolbar-focus': isTitleFocused}">
  <mat-toolbar-row [matMenuTriggerFor]="menu" (click)="toggleMenuFocus()" [id]="commonHeaderToolBarTourId">
      <span class="component-title">{{selectedTitle}}</span>
      <mat-icon i18n="arrow_drop_down|@@arrow_drop_down" class="tabs-button">arrow_drop_down</mat-icon>
      <mat-menu #menu="matMenu" xPosition="before" (closed)="toggleMenuFocus()">
        <button *ngFor="let tab of tabs" mat-menu-item (click)="selectTab(tab)">{{tab.text}}</button>
      </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
<app-tour-box [elementId]="commonHeaderToolBarTourId" [position]="toolBarTourPosition" [tabChangedFromStockNotification]="tabChangedFromStock.asObservable()">
  <p class="tour-text" i18n="Click on the dropdown to access SALES STOCK and STOCK IN LOCATION|@@Click_on_the_dropdown_to_access_SALES_STOCK_and_STOCK_IN_LOCATION">Click on the dropdown to access <span class="tour-text">SALES STOCK</span><br> and <span class="tour-text">STOCK IN LOCATION</span></p>
</app-tour-box>
