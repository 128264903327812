/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FactorDetailsViewContainer } from './factorDetailsViewContainer';
import { ProductDetailViewPluGroup } from './productDetailViewPluGroup';
import { ProductDetailViewTaxRate } from './productDetailViewTaxRate';
import { ProductListViewFactor } from './productListViewFactor';
import { ProductListViewPrintGroup } from './productListViewPrintGroup';
import { ProductsInLocationWithProductDetailsViewProductRevenueGroup } from './productsInLocationWithProductDetailsViewProductRevenueGroup';
import { StockListViewRange } from './stockListViewRange';

export interface ProductsInLocationWithProductDetailsViewProduct { 
    fullName?: string;
    shortName?: string;
    /**
     * A reference to the primary key in the PluGroups Table
     */
    pluGroupId?: number;
    /**
     * A reference to the primary key in the RevenueGroups Table
     */
    revenueGroupId?: number;
    averageCost?: number;
    lastCost?: number;
    allowDecimalQtys?: boolean;
    /**
     * A reference to the primary key in the PluGroups Table
     */
    linkedPluGroupId?: number;
    /**
     * A reference to the primary key in the TaxRates Table
     */
    taxRateId?: number;
    /**
     * A reference to the primary key in the Ranges Table
     */
    rangeId?: number;
    active?: boolean;
    flaggedForDeletion?: boolean;
    includeInExport?: boolean;
    openPriceItem?: boolean;
    /**
     * A reference to the primary key in the PrintGroups Table
     */
    printGroupId?: number;
    modifier?: ProductsInLocationWithProductDetailsViewProduct.ModifierEnum;
    /**
     * A reference to the primary key in the Factors Table
     */
    factorId?: number;
    plu?: number;
    productGroup?: ProductDetailViewPluGroup;
    linkedProductGroup?: ProductDetailViewPluGroup;
    revenueGroup?: ProductsInLocationWithProductDetailsViewProductRevenueGroup;
    factor?: ProductListViewFactor;
    printGroup?: ProductListViewPrintGroup;
    taxRate?: ProductDetailViewTaxRate;
    container?: FactorDetailsViewContainer;
    range?: StockListViewRange;
}
export namespace ProductsInLocationWithProductDetailsViewProduct {
    export type ModifierEnum = 'No modifier' | 'Normal modifier' | 'Group modifier' | 'Position modifier' | 'Printer change modifier' | 'Order Number modifier' | 'Reason Code modifier' | 'Member Fees' | 'Ticket modifier' | 'Package' | 'Timer' | 'Non-kitchen printed';
    export const ModifierEnum = {
        NoModifier: 'No modifier' as ModifierEnum,
        NormalModifier: 'Normal modifier' as ModifierEnum,
        GroupModifier: 'Group modifier' as ModifierEnum,
        PositionModifier: 'Position modifier' as ModifierEnum,
        PrinterChangeModifier: 'Printer change modifier' as ModifierEnum,
        OrderNumberModifier: 'Order Number modifier' as ModifierEnum,
        ReasonCodeModifier: 'Reason Code modifier' as ModifierEnum,
        MemberFees: 'Member Fees' as ModifierEnum,
        TicketModifier: 'Ticket modifier' as ModifierEnum,
        Package: 'Package' as ModifierEnum,
        Timer: 'Timer' as ModifierEnum,
        NonKitchenPrinted: 'Non-kitchen printed' as ModifierEnum
    };
}