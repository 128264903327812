/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

export interface ProductsInLocationView { 
    /**
     * The unique identifier of this record.
     */
    productInLocationId?: number;
    /**
     * A reference to the primary key in the VenueLocations Table
     */
    locationId?: number;
    /**
     * A reference to the primary key in the Products Table
     */
    productId?: number;
    fullName?: string;
    shortName?: string;
    /**
     * A reference to the primary key in the PluGroups Table
     */
    productGroupId?: number;
    /**
     * A reference to the primary key in the RevenueGroups Table
     */
    revenueGroupId?: number;
    averageCost?: number;
    allowDecimalQtys?: boolean;
    pointsOverride?: boolean;
    /**
     * calculated field - if pointsOverride is false, equals to priceA, otherwise returns the valuePoints stored.
     */
    valuePoints?: number;
    /**
     * Calculated field - if pointsOverride is false, equals to (priceA - cost), otherwise returns the profitPoints stored.
     */
    profitPoints?: number;
    redeemPoints?: number;
    externalReferenceCode?: string;
    /**
     * A reference to the primary key in the PluGroups Table
     */
    linkedProductGroupId?: number;
    /**
     * A reference to the primary key in the TaxRates Table
     */
    taxRateId?: number;
    active?: boolean;
    flaggedForDeletion?: boolean;
    includeInExport?: boolean;
    openPriceItem?: boolean;
    /**
     * A reference to the primary key in the PrintGroups Table
     */
    printGroupId?: number;
    modifier?: ProductsInLocationView.ModifierEnum;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    priceA?: number;
    useProductPriceA?: boolean;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    priceAPriceNameId?: number;
    priceB?: number;
    useProductPriceB?: boolean;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    priceBPriceNameId?: number;
    priceC?: number;
    useProductPriceC?: boolean;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    priceCPriceNameId?: number;
    priceD?: number;
    useProductPriceD?: boolean;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    priceDPriceNameId?: number;
    priceE?: number;
    useProductPriceE?: boolean;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    priceEPriceNameId?: number;
    lastCost?: number;
    /**
     * A reference to the primary key in the Ranges Table
     */
    rangeId?: number;
}
export namespace ProductsInLocationView {
    export type ModifierEnum = 'No modifier' | 'Normal modifier' | 'Group modifier' | 'Position modifier' | 'Printer change modifier' | 'Order Number modifier' | 'Reason Code modifier' | 'Member Fees' | 'Ticket modifier' | 'Package' | 'Timer' | 'Non-kitchen printed' | 'null';
    export const ModifierEnum = {
        NoModifier: 'No modifier' as ModifierEnum,
        NormalModifier: 'Normal modifier' as ModifierEnum,
        GroupModifier: 'Group modifier' as ModifierEnum,
        PositionModifier: 'Position modifier' as ModifierEnum,
        PrinterChangeModifier: 'Printer change modifier' as ModifierEnum,
        OrderNumberModifier: 'Order Number modifier' as ModifierEnum,
        ReasonCodeModifier: 'Reason Code modifier' as ModifierEnum,
        MemberFees: 'Member Fees' as ModifierEnum,
        TicketModifier: 'Ticket modifier' as ModifierEnum,
        Package: 'Package' as ModifierEnum,
        Timer: 'Timer' as ModifierEnum,
        NonKitchenPrinted: 'Non-kitchen printed' as ModifierEnum,
        Null: 'null' as ModifierEnum
    };
}