import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { IImageComponentInput } from '@components/generic/image/image.component';

export enum ImagePublishStatus { PUBLIC = 'PUBLIC', PRIVATE = 'PRIVATE' }
export interface IImgUploadResponse {
  _id: string;
  imageName: string;
  publicUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(private http: HttpClient) { }

  public uploadImage(formData: FormData): Promise <IImgUploadResponse> {
    const url = this.getBaseUrl() + '/files';
    const httpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('id_token')
    });

    return new Promise((resolve, reject) => {
      this.http.post(url, formData, { headers: httpHeaders }).toPromise()
        .then((res: IImgUploadResponse) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

  public retrieveImage(imageId: string): Promise<string | ArrayBuffer> {
    const url = this.getBaseUrl() + '/files/image/' + imageId;
    const httpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('id_token')
    });

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: httpHeaders, responseType: 'text' }).toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private getBaseUrl(): string {
    return AppConfiguration.GetChildConfiguration('hal.common').REST_API_HOST;
  }
}
