/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { PackageItemSubstituteDetailViewPackageItem } from './packageItemSubstituteDetailViewPackageItem';
import { SalesStocksWithDetailsViewRevenueGroups } from './salesStocksWithDetailsViewRevenueGroups';
import { UpdateDateTime } from './updateDateTime';

export interface PackageItemSubstituteProductGroupDetailView { 
    /**
     * The unique identifier of this record.
     */
    packageItemSubstituteProductGroupId?: number;
    /**
     * The id of packageItem that we are providing a substitute for.
     */
    packageItemId?: number;
    /**
     * The productGroupId of the productGroup. All products in this product group will be allowed to be substituted.
     */
    productGroupId?: number;
    /**
     * The quantity of the product selected.
     */
    quantity?: number;
    /**
     * The amount the price is adjusted when selecting this a product from this productGroup.
     */
    priceAdjustmentAmount?: number;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    packageItem?: PackageItemSubstituteDetailViewPackageItem;
    productGroup?: SalesStocksWithDetailsViewRevenueGroups;
}