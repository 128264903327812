/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface IntegrationStockSalesView { 
    /**
     * System generated unique identifier for the sales sales stock (product) item
     */
    salesStockId?: number;
    /**
     * User defined number used to identify the product at POS
     */
    plu?: number;
    /**
     * The name of the product that will be display on a POS device
     */
    shortName?: string;
    /**
     * The full name of the product
     */
    longName?: string;
    /**
     * The name of the price list
     */
    priceAName?: string;
    /**
     * The selling price of the item at price level A (ex GST)
     */
    priceA?: number;
    /**
     * The name of the price list
     */
    priceBName?: string;
    /**
     * The selling price of the item at price level B (ex GST)
     */
    priceB?: number;
    /**
     * The name of the price list
     */
    priceCName?: string;
    /**
     * The selling price of the item at price level C (ex GST)
     */
    priceC?: number;
    /**
     * The name of the price list
     */
    priceDName?: string;
    /**
     * The selling price of the item at price level D (ex GST)
     */
    priceD?: number;
    /**
     * The name of the price list
     */
    priceEName?: string;
    /**
     * The selling price of the item at price level E (ex GST)
     */
    priceE?: number;
    /**
     * The date and time of the last price change for the item
     */
    lastPriceChange?: string;
    pluGroup?: string;
    /**
     * Describes the type of modifier the item is for use at POS.
     */
    modifier?: string;
    /**
     * The url of the item's label image used for printing product lables
     */
    labelImageUrl?: string;
    /**
     * The url of the display image used for POS display
     */
    displayImageUrl?: string;
    /**
     * The user defined number of the location
     */
    locationNumber?: number;
    /**
     * The name of the location
     */
    locationName?: string;
    /**
     * The vintage year of the item. Usually reserved for wines.
     */
    vintage?: number;
    /**
     * Describes the size of the item.
     */
    size?: number;
    /**
     * The region the item originates from. Usually reserved for wines.
     */
    region?: string;
    /**
     * The variety of the item. Usually reserved for wines.
     */
    variety?: string;
    /**
     * Status of the item
     */
    active?: boolean;
    /**
     * Describes the unit of the item
     */
    containerDescription?: string;
    /**
     * The unique identifier of the PLU group the item belongs to
     */
    pluGroupId?: number;
    /**
     * The linked plu group is used to indicate to the POS system to display items in the linked group following the selection of this item. Eg: a steak linked to a group of cooking styles.
     */
    linkedPluGroupId?: number;
    linkedPluGroup?: string;
}