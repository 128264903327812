/**
 * Portal API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FilePatchBodyData { 
    /**
     * the path to the field you want to update.
     */
    path: FilePatchBodyData.PathEnum;
    op: FilePatchBodyData.OpEnum;
    value: string;
}
export namespace FilePatchBodyData {
    export type PathEnum = '/objectId' | '/objectType' | '/description';
    export const PathEnum = {
        ObjectId: '/objectId' as PathEnum,
        ObjectType: '/objectType' as PathEnum,
        Description: '/description' as PathEnum
    };
    export type OpEnum = 'replace';
    export const OpEnum = {
        Replace: 'replace' as OpEnum
    };
}