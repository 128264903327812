/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { SalesStockWithDetailsViewLocation } from './salesStockWithDetailsViewLocation';
import { SalesStockWithDetailsViewStock } from './salesStockWithDetailsViewStock';
import { SalesStockWithDetailsViewTaxRate } from './salesStockWithDetailsViewTaxRate';
import { SalesStocksWithDetailsViewFactor } from './salesStocksWithDetailsViewFactor';
import { SalesStocksWithDetailsViewRevenueGroups } from './salesStocksWithDetailsViewRevenueGroups';
import { UpdateDateTime } from './updateDateTime';

export interface SalesStockWithDetailsView { 
    /**
     * System generated unique identifier for the sales sales stock (product) item
     */
    salesStockId?: number;
    /**
     * A reference to the primary key in the VenueLocations Table
     */
    locationId?: number;
    /**
     * User defined number used to identify the product at POS
     */
    plu?: number;
    /**
     * A reference to the primary key in the Stock Table
     */
    stockId?: number;
    /**
     * The name of the product that will be display on a POS device
     */
    shortName?: string;
    /**
     * The full name of the product
     */
    longName?: string;
    /**
     * A reference to the primary key in the Factors Table
     */
    factorId?: number;
    /**
     * The unique identifier of the PLU group the item belongs to
     */
    pluGroupId?: number;
    /**
     * A reference to the primary key in the RevenueGroups Table
     */
    revenueGroupId?: number;
    decimalQty?: boolean;
    promotional?: boolean;
    cost?: number;
    pointsOverride?: boolean;
    valuePoints?: number;
    profitPoints?: number;
    redeemPoints?: number;
    /**
     * The selling price of the item at price level A (ex GST)
     */
    priceA?: number;
    useInventoryPriceA?: boolean;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    priceAPriceNameId?: number;
    /**
     * The selling price of the item at price level B (ex GST)
     */
    priceB?: number;
    useInventoryPriceB?: boolean;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    priceBPriceNameId?: number;
    /**
     * The selling price of the item at price level C (ex GST)
     */
    priceC?: number;
    useInventoryPriceC?: boolean;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    priceCPriceNameId?: number;
    /**
     * The selling price of the item at price level D (ex GST)
     */
    priceD?: number;
    useInventoryPriceD?: boolean;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    priceDPriceNameId?: number;
    /**
     * The selling price of the item at price level E (ex GST)
     */
    priceE?: number;
    useInventoryPriceE?: boolean;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    priceEPriceNameId?: number;
    standardPriceA?: number;
    standardPriceB?: number;
    standardPriceC?: number;
    standardPriceD?: number;
    standardPriceE?: number;
    standardPriceAPriceNameId?: boolean;
    standardPriceBPriceNameId?: boolean;
    standardPriceCPriceNameId?: boolean;
    standardPriceDPriceNameId?: boolean;
    standardPriceEPriceNameId?: boolean;
    externalReferenceCode?: string;
    quickMenuPage?: number;
    /**
     * The linked plu group is used to indicate to the POS system to display items in the linked group following the selection of this item. Eg: a steak linked to a group of cooking styles.
     */
    linkedPluGrpId?: number;
    /**
     * A reference to the primary key in the TaxRates Table
     */
    taxRateId?: number;
    /**
     * The date and time of the last price change for the item
     */
    lastPriceChange?: Date;
    active?: boolean;
    flaggedForDeletion?: boolean;
    includeInExport?: boolean;
    openPriceItem?: boolean;
    /**
     * A reference to the primary key in the PrintGroups Table
     */
    printGroupId?: number;
    ticketExpiryPeriodType?: number;
    ticketExpiryDuration?: number;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    ticketValidDateTime?: Date;
    binNo?: string;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    /**
     * The url of the item's label image used for printing product lables
     */
    labelImageUrl?: string;
    /**
     * The url of the display image used for POS display
     */
    displayImageUrl?: string;
    /**
     * Describes the type of modifier the item is for use at POS.
     */
    modifier?: SalesStockWithDetailsView.ModifierEnum;
    /**
     * The average cost of relevant StockInLocation by dividing divider value from Factor
     */
    lastCostPerInner?: string;
    /**
     * The last cost of relevant StockInLocation by dividing divider value from Factor
     */
    avgCostPerInner?: string;
    /**
     * The average cost of relevant StockInLocation
     */
    avgCost?: string;
    /**
     * The last cost of relevant StockInLocation
     */
    lastCost?: string;
    pluGroup?: SalesStocksWithDetailsViewRevenueGroups;
    revenueGroup?: SalesStocksWithDetailsViewRevenueGroups;
    stock?: SalesStockWithDetailsViewStock;
    location?: SalesStockWithDetailsViewLocation;
    factor?: SalesStocksWithDetailsViewFactor;
    taxRate?: SalesStockWithDetailsViewTaxRate;
}
export namespace SalesStockWithDetailsView {
    export type ModifierEnum = 'No modifier' | 'Normal modifier' | 'Group modifier' | 'Position modifier' | 'Printer change modifier' | 'Order Number modifier' | 'Reason Code modifier' | 'Member Fees' | 'Ticket modifier' | 'Package' | 'Timer' | 'Non-kitchen printed';
    export const ModifierEnum = {
        NoModifier: 'No modifier' as ModifierEnum,
        NormalModifier: 'Normal modifier' as ModifierEnum,
        GroupModifier: 'Group modifier' as ModifierEnum,
        PositionModifier: 'Position modifier' as ModifierEnum,
        PrinterChangeModifier: 'Printer change modifier' as ModifierEnum,
        OrderNumberModifier: 'Order Number modifier' as ModifierEnum,
        ReasonCodeModifier: 'Reason Code modifier' as ModifierEnum,
        MemberFees: 'Member Fees' as ModifierEnum,
        TicketModifier: 'Ticket modifier' as ModifierEnum,
        Package: 'Package' as ModifierEnum,
        Timer: 'Timer' as ModifierEnum,
        NonKitchenPrinted: 'Non-kitchen printed' as ModifierEnum
    };
}