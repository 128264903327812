<dx-popup #stocktakeItemsPopup [(visible)]="popupConfig.visible" [title]="popupConfig.title" [showCloseButton]="true"
    [hideOnOutsideClick]="false" [shading]="true" shadingColor="rgba(0, 0, 0, 0.3)"
    [fullScreen]="globalsService?.isMobile" (onShowing)="popupConfig.onShowing($event)" (onHiding)="popupConfig.onHiding($event)">

    <div class="stocktake-items-grid">
        <app-generic-data-grid #stocktakeItemsListDataGrid [dataSourceConfig]="stocktakeItemsListDataSourceConfig"
            [joinTableColumnConfig]="stocktakeItemsJoinTableColumnConfig"
            [dataGridConfig]="stocktakeItemsListDataGridConfig"
            [dataGridActionColumn]="stocktakeItemsListDataGridActionColumn">
        </app-generic-data-grid>
    </div>

    <div class="form-btn-container">
        <dx-button class="form-save" stylingMode="contained" text="Select" (onClick)="select()" [disabled]="!isSelectBtnEnabled">
        </dx-button>
        <dx-button class="form-cancel" stylingMode="outlined" text="Cancel" (onClick)="hidePopup()">
        </dx-button>
    </div>

</dx-popup>