/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Offers is a promotional tool that allows users to configure special offers that are generally issued through a voucher system. The offers may have issuing and redeeming criteria and run for a specified period of time.
 */
export interface OfferInsert { 
    organisationId: string;
    /**
     * A description of the Offer
     */
    description?: string;
    /**
     * The type of promotion. 'type' means the offer is issued on a 'transaction' or sale. 'Buy x over x' is designed for offers like buy 4 coffees in one month and get the 5th free. 'batch issued' is used to generate a batch of offers that can be delivered to customers via printed coupons, email campaigns etc.
     */
    promotionType?: OfferInsert.PromotionTypeEnum;
    /**
     * The type of giveaway, either a voucher or instant.
     */
    giveawayType: OfferInsert.GiveawayTypeEnum;
    /**
     * When the offer starts to be valid
     */
    startDatetime: Date;
    /**
     * When the offer finishes
     */
    endDatetime: Date;
    /**
     * The start date that an offer can be redeemed.
     */
    redeemStartDatetime?: Date;
    /**
     * The number of days from the date of issue that an offer can be redeemed.
     */
    redeemNumberValidDays?: number;
    /**
     * This allows recipients of a voucher to redeem an offer after the offer end datetime. The redeem valid from and the redeem number valid days will still determine the expiry date.
     */
    allowExpiryAfterEndDate?: boolean;
    /**
     * The maximum number of times the offer can be issued.
     */
    totalOffersAvailable: number;
    /**
     * The maximum number of times the offer can be issued per day.
     */
    offersAvailablePerDay: number;
    /**
     * The number of offers issued that are reserved for members. This must be less or equal to the available count.
     */
    totalOffersReservedForMembers?: number;
    /**
     * The total number of offers issued that are reserved daily for members.
     */
    offersReservedForMembersPerDay?: number;
    /**
     * The maxium discount in dollars that the redeemer can receive.
     */
    maximumDiscount: number;
    /**
     * The amount that must be spent by a voucher recipient in order to qualify to receive an offer.
     */
    issuingRequiredSpendAmount: number;
    /**
     * The period type in which the required spend must occur in order to receive an offer
     */
    issuingRequiredSpendPeriod?: OfferInsert.IssuingRequiredSpendPeriodEnum;
    /**
     * The number of period types the required spend must occure within. Must be set to 1 if the period is 'transaction' or 'daily'
     */
    issuingRequiredSpendPeriodCount: number;
    /**
     * Whether the offer is only available to members
     */
    issueToMembersOnly?: boolean;
    /**
     * Is this offer issueable on redemption of another voucher.
     */
    issueOnRedemption?: boolean;
    /**
     * Determines whether a voucher is issued for each qualifying item in a qualifying transaction that the recipient purchases.
     */
    issuedPerItem?: boolean;
    /**
     * The amount that must be spent while redeeming to reedem a voucher generated by this offer.
     */
    redeemRequiredSpend: number;
    /**
     * Determines whether a voucher can be redeemed more than once.
     */
    redeemOnceOnly?: boolean;
    /**
     * When redeeming, determines how other discounts are treated on the sale. 'no further discount' means that no further discounts can be applied. 'overrides other discount' means that other discounts are overrided with this discount. 'further discounts' means that this offer can be redeemed with other discounts.
     */
    redeemOtherDiscountsBehaviour?: OfferInsert.RedeemOtherDiscountsBehaviourEnum;
    /**
     * Whether the offer is only redeemable by members
     */
    redeemByMembersOnly?: boolean;
    /**
     * Setup a scheduled recurring rule for when offer is issued.
     */
    issueScheduledRecurringRule?: string;
    /**
     * Used inconjunction with issue schedule recurring rule. When a the recurring rule triggers this field indicates how many seconds the rule is available for.
     */
    issueDurationSeconds?: number;
    /**
     * Setup a scheduled recurring rule for when offer can be redeemed.
     */
    redemptionScheduledRecurringRule?: string;
    /**
     * Used inconjunction with redemption scheduled recurring rule. When a the recurring rule triggers this field indicates how many seconds the rule is available for.
     */
    redemptionDurationSeconds?: number;
    /**
     * The header for the promotion
     */
    displayHeader?: string;
    /**
     * The body of the promotion
     */
    displayBody?: string;
    /**
     * The footer of the promotion
     */
    displayFooter?: string;
    /**
     * The type of barcode that is generated when an offer  is issued.
     */
    barcodeType?: OfferInsert.BarcodeTypeEnum;
    /**
     * The layout of a the receipt
     */
    layout?: OfferInsert.LayoutEnum;
    /**
     * An image you want to print out with this voucher
     */
    imageUrl?: string;
    /**
     * An image you want to print out with this voucher
     */
    image2Url?: string;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
    /**
     * A reference to the primary key in the Products Table
     */
    discountProductId: number;
}
export namespace OfferInsert {
    export type PromotionTypeEnum = 'transaction' | 'buy x over x' | 'batch issued';
    export const PromotionTypeEnum = {
        Transaction: 'transaction' as PromotionTypeEnum,
        BuyXOverX: 'buy x over x' as PromotionTypeEnum,
        BatchIssued: 'batch issued' as PromotionTypeEnum
    };
    export type GiveawayTypeEnum = 'voucher' | 'instant';
    export const GiveawayTypeEnum = {
        Voucher: 'voucher' as GiveawayTypeEnum,
        Instant: 'instant' as GiveawayTypeEnum
    };
    export type IssuingRequiredSpendPeriodEnum = 'daily' | 'transaction' | 'days' | 'months';
    export const IssuingRequiredSpendPeriodEnum = {
        Daily: 'daily' as IssuingRequiredSpendPeriodEnum,
        PerTransaction: 'transaction' as IssuingRequiredSpendPeriodEnum,
        Days: 'days' as IssuingRequiredSpendPeriodEnum,
        Months: 'months' as IssuingRequiredSpendPeriodEnum
    };
    export type RedeemOtherDiscountsBehaviourEnum = 'no further discount' | 'overrides other discount' | 'further discounts';
    export const RedeemOtherDiscountsBehaviourEnum = {
        NoFurtherDiscount: 'no further discount' as RedeemOtherDiscountsBehaviourEnum,
        OverridesOtherDiscount: 'overrides other discount' as RedeemOtherDiscountsBehaviourEnum,
        FurtherDiscounts: 'further discounts' as RedeemOtherDiscountsBehaviourEnum
    };
    export type BarcodeTypeEnum = 'regular' | 'qr' | 'null';
    export const BarcodeTypeEnum = {
        Regular: 'regular' as BarcodeTypeEnum,
        Qr: 'qr' as BarcodeTypeEnum,
        Null: 'null' as BarcodeTypeEnum
    };
    export type LayoutEnum = 'standard' | 'side by side' | 'null';
    export const LayoutEnum = {
        Standard: 'standard' as LayoutEnum,
        SideBySide: 'side by side' as LayoutEnum,
        Null: 'null' as LayoutEnum
    };
}