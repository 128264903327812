/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StockGroupUpdate { 
    number?: number;
    name?: string;
    /**
     * A reference to the primary key in the ExpenseGroups Table
     */
    expenseGroupId?: number;
    /**
     * A reference to the primary key in the StockCategories Table
     */
    stockGroupTypeId?: number;
    daysStockRequired?: number;
    purchaseTriggerPercentage?: number;
    recommendedGrossProfit?: number;
    /**
     * A reference to the primary key in the TaxRates Table
     */
    taxRateId?: number;
    oldExcise?: number;
    newExcise?: number;
    averageValue?: number;
    /**
     * A reference to the primary key in the StockCategories Table
     */
    stockCategoryId?: number;
    expectedCostTriggerPercentage?: number;
    rangeStart?: number;
    rangeEnd?: number;
    incrementValue?: number;
    useLiquorfileNo?: boolean;
    daysToWarn?: number;
    organisationId?: string;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
}