import { Injectable } from '@angular/core';
import { ICustomStoreLoadResult } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { StockCreationTemplateView } from '@modules/SDKs/sysnetApi/model/stockCreationTemplateView';
import { StockCreationTemplatesService } from '@services/sysnet/stock-creation-templates.service';
import { LoadOptions } from 'devextreme/data';
import { IGroupQueryResultBody } from 'src/app/types/interfaces/GeneralService';
import { LoadOptionsHelper } from 'src/app/utilities/LoadOptionsHelper';

@Injectable({
  providedIn: 'root'
})
export class StockCreationTemplatesDataGridService {

  constructor(
    private stockCreationTemplatesService: StockCreationTemplatesService
  ) {
  }

  // Get StockCreationTemplate
  public async getStockCreationTemplate(key, view?: string): Promise<StockCreationTemplateView> {
    if (key) {
      const stockCreationTemplateRes = await this.stockCreationTemplatesService.getStockCreationTemplate(key, view);
      return stockCreationTemplateRes.body;
    }
  }

  // Search StockCreationTemplates
  public async searchStockCreationTemplates(loadOptions: LoadOptions, view?: string): Promise<ICustomStoreLoadResult<StockCreationTemplateView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const stockCreationTemplatesRes = await this.stockCreationTemplatesService.searchStockCreationTemplates(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort,
      loadOptions.take, loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<StockCreationTemplateView> = {
      data: parsedLoadOptions.group ? (stockCreationTemplatesRes.body as unknown as IGroupQueryResultBody).data : stockCreationTemplatesRes.body,
      totalCount: +stockCreationTemplatesRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  // Insert StockCreationTemplate
  public async insertStockCreationTemplate(values): Promise<StockCreationTemplateView> {
    const stockCreationTemplateResult = await this.stockCreationTemplatesService.insertStockCreationTemplate(values);
    return stockCreationTemplateResult.body;
  }

  // Update StockCreationTemplate
  public async updateStockCreationTemplate(key, values): Promise<void> {
    if (Object.keys(values).length !== 0) {
      await this.stockCreationTemplatesService.updateStockCreationTemplate(key, values);
    }
  }

  // Delete StockCreationTemplate
  public async deleteStockCreationTemplate(key): Promise<void> {
    if (key) {
      await this.stockCreationTemplatesService.deleteStockCreationTemplate(key);
    }
  }
}
