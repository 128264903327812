import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { StocktakeItemsDataGridService } from '@services/grid/stocktake-items-data-grid.service';
import { GenericDataGridComponent, IDataGridActionColumn, IDataSourceConfig, IJoinTableColumnConfig, IStandardDataGridConfig } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { StockDataGridService } from '@services/grid/stock-data-grid.service';
import { ActivatedRoute, Params } from '@angular/router';
import { FormatFn, GridConstants } from 'src/app/Constants';
import { IExtendedStateStoring } from 'src/app/types/interfaces/GeneralService';

@Component({
  selector: 'app-stocktake-items-list-tabbed',
  templateUrl: './stocktake-items-list-tabbed.component.html',
  styleUrls: ['./stocktake-items-list-tabbed.component.scss']
})
export class StocktakeItemsListTabbedComponent implements OnInit, OnChanges {
  public stocktakeItemsListDataSourceConfig: IDataSourceConfig<StocktakeItemsDataGridService> = {
    dataSourceName: 'stocktakeItemsListTabbed',
    dataSourcePrimaryKey: 'stocktakeItemsId',
    dataSourceLoadMode: 'processed',
    dataSourceService: this.stocktakeItemsDataGridService,
    dataSourceServiceSearchFnView: 'StocktakeItemsList',
    dataSourceServiceSearchFnRouteId: undefined,
    dataSourceServiceSearchFn: 'searchStocktakeItemsByStocktakeId',
    dataSourceServiceDeleteFn: 'deleteStocktakeItem'
  };
  public stocktakeItemsJoinTableColumnConfig: IJoinTableColumnConfig<StockDataGridService> = {
    stockId: {
      joinModelName: 'Stock',
      joinTableDataSource: {},
      joinTableDataSourceLoadMode: 'processed',
      joinTableDataSourceService: this.stockDataGridService,
      joinTableDataSourceServiceSearchFn: 'searchStock',
      joinTableDataSourceServiceGetFn: 'getStock',
      joinTablePrimaryKey: 'stockId',
      joinTableDataSortBy: 'inventoryCode'
    }
  };
  public stocktakeItemsListDataGridConfig: IStandardDataGridConfig = {};
  public stocktakeItemsListDataGridActionColumn: IDataGridActionColumn = { enabled: false };
  @ViewChild('stocktakeItemsListTabbedDataGrid', { static: false }) public stocktakeItemsListTabbedDataGrid: GenericDataGridComponent;
  @Input() public selectedTab;
  @Input() public showQtyAtStocktake = false;
  @Input() public isStockMobile = false;
  constructor(
    private stocktakeItemsDataGridService: StocktakeItemsDataGridService,
    private stockDataGridService: StockDataGridService,
    private route: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    this.onDataGridSetup();
    this.route.params.subscribe((params: Params) => {
      const id = parseInt(params['id'], 10);
      if (!isNaN(id)) {
        this.stocktakeItemsListDataSourceConfig.dataSourceServiceSearchFnRouteId = id;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.selectedTab.firstChange && this.selectedTab === 'Stocktake (List)') {
      this.stocktakeItemsListTabbedDataGrid?.refresh();
    }
  }

  public onDataGridSetup(): void { // custom config
    if (!this.isStockMobile) {
      this.stocktakeItemsListDataGridConfig.columns = [
        {
          dataField: 'stockId',
          name: 'stockName',
          dataType: 'number',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Description',
          calculateDisplayValue: 'Stock.name',
          lookup: {
            dataSource: this.stocktakeItemsJoinTableColumnConfig.stockId.joinTableDataSource,
            displayExpr: 'name',
            valueExpr: 'stockId',
            allowClearing: false
          }
        },
        {
          dataField: 'qtyStockEntered',
          dataType: 'number',
          minWidth: 90,
          caption: 'Counted',
          allowHeaderFiltering: false
        }
      ];

      if (this.showQtyAtStocktake) {
        this.stocktakeItemsListDataGridConfig.columns.splice(1, 0, {
          dataField: 'qtyAtStocktake',
          dataType: 'number',
          minWidth: 90,
          caption: 'On Hand',
          visibleIndex: 1,
          allowHeaderFiltering: false
        })
      }
    } else {
      this.stocktakeItemsListDataGridConfig.columns = [
        {
          dataField: 'Stock.inventoryCode',
          dataType: 'number',
          caption: 'InvCode',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          allowSearch: true,
          allowFiltering: false,
          allowHeaderFiltering: false,
          visible: false
        },
        {
          dataField: 'stockId',
          name: 'stockName',
          dataType: 'number',
          minWidth: 120,
          caption: 'Description',
          allowSearch: true,
          allowFiltering: false,
          allowHeaderFiltering: false,
          calculateDisplayValue: 'Stock.name',
          lookup: {
            dataSource: this.stocktakeItemsJoinTableColumnConfig.stockId.joinTableDataSource,
            displayExpr: 'name',
            valueExpr: 'stockId',
            allowClearing: false
          }
        },
        {
          dataField: 'Stock.inners',
          dataType: 'string',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Inners Unit',
          allowSearch: false,
          allowSorting: false,
          allowFiltering: false,
          allowHeaderFiltering: false,
          visible: false
        },
        {
          dataField: 'qtyStockEntered',
          dataType: 'number',
          minWidth: 140,
          caption: 'Qty Counted',
          allowSearch: false,
          allowFiltering: false,
          allowHeaderFiltering: false
        },
        {
          dataField: 'counted',
          dataType: 'boolean',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          width: 110,
          caption: 'Counted',
          allowSearch: false,
          allowHeaderFiltering: false,
          visible: false
        }
      ];

      if (this.showQtyAtStocktake) {
        this.stocktakeItemsListDataGridConfig.columns.splice(3, 0, {
          dataField: 'qtyAtStocktake',
          dataType: 'number',
          minWidth: 140,
          caption: 'Qty At Stocktake',
          visibleIndex: 3,
          allowSearch: false,
          allowFiltering: false,
          allowHeaderFiltering: false
        })

        this.stocktakeItemsListDataGridConfig.columns.push({
          name: 'qtyVariance',
          dataType: 'number',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: 'Qty Variance',
          allowSearch: false,
          allowSorting: false,
          allowFiltering: false,
          allowHeaderFiltering: false,
          visible: false,
          calculateCellValue: (data) => (data.qtyStockEntered - data.qtyAtStocktake)
        },
        {
          name: 'varianceValue',
          dataType: 'number',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: '$ Variance',
          allowSearch: false,
          allowSorting: false,
          allowFiltering: false,
          allowHeaderFiltering: false,
          visible: false,
          format: FormatFn.currencyFormat,
          calculateCellValue: (row) => {
            const qtyVariance = (row.qtyStockEntered - row.qtyAtStocktake);
            if (row?.Stock.innersPerOuter > 0) {
              const varianceValue = qtyVariance * (row.avgCost / row.Stock.innersPerOuter);
              return FormatFn.decimalPointFormat(varianceValue, 4);
            }
            return qtyVariance * row.avgCost;
          }
        },
        {
          name: 'variancePercent',
          dataType: 'number',
          minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
          caption: '% Variance',
          allowSearch: false,
          allowSorting: false,
          allowFiltering: false,
          allowHeaderFiltering: false,
          visible: false,
          calculateCellValue: (row) => {
            const qtyVariance = (row.qtyStockEntered - row.qtyAtStocktake);
            if (row?.qtyAtStocktake > 0) {
              const variancePercent = Math.abs(qtyVariance) / row?.qtyAtStocktake * 100;
              return FormatFn.decimalPointFormat(variancePercent, 2);
            }
            return 0;
          }
        })
      }
    }

    this.stocktakeItemsListDataGridConfig.columnChooser = {
      enabled: this.isStockMobile ?? false
    };

    const settingName = !this.isStockMobile ? 'GridSettings' : 'StockMobileGridSettings';
    this.stocktakeItemsListDataGridConfig.stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: this.stocktakeItemsListDataSourceConfig.dataSourceName + settingName,
      ignoreColumnOptionNames: []
    } as IExtendedStateStoring;

    this.stocktakeItemsListDataGridConfig.remoteOperations = {
      filtering: true,
      paging: true,
      sorting: true,
      summary: false,
      grouping: true,
      groupPaging: false
    };
  }

}
