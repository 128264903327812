export * from './files.service';
import { FilesService } from './files.service';
export * from './locations.service';
import { LocationsService } from './locations.service';
export * from './organisations.service';
import { OrganisationsService } from './organisations.service';
export * from './reports.service';
import { ReportsService } from './reports.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './usersprovision.service';
import { UsersprovisionService } from './usersprovision.service';
export const APIS = [FilesService, LocationsService, OrganisationsService, ReportsService, UsersService, UsersprovisionService];
