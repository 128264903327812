/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { StockDetailViewRecipeIngredients } from './stockDetailViewRecipeIngredients';
import { StockDetailViewRecipeMethods } from './stockDetailViewRecipeMethods';
import { UpdateDateTime } from './updateDateTime';

export interface RecipesView { 
    /**
     * The unique identifier of this record.
     */
    stockId?: number;
    name?: string;
    /**
     * A reference to the primary key in the StockGroups Table
     */
    stockGroupId?: number;
    organisationId?: string;
    /**
     * A reference to the primary key in the Measures Table
     */
    measureId?: number;
    quantity?: number;
    active?: boolean;
    yieldQty?: number;
    mappingUnitsQty?: number;
    mappingVolumeQty?: number;
    mappingVolumeUom?: number;
    mappingWeightQty?: number;
    mappingWeightUom?: number;
    measureCustomId?: number;
    /**
     * 0 = stock, 1 = recipe (for now)
     */
    type?: number;
    preparationTime?: number;
    cookingTime?: number;
    outers?: string;
    inners?: string;
    innersPerOuter?: number;
    outersOnly?: boolean;
    minReorderQty?: number;
    freight?: number;
    deposit?: number;
    mls?: number;
    /**
     * A reference to the primary key in the Suppliers Table
     */
    preferredSupplierId?: number;
    preferredSupplierInventoryCode?: string;
    /**
     * A reference to the primary key in the Suppliers Table
     */
    manufacturerDistributorId?: number;
    alternativeInventoryCode?: string;
    averageCost?: number;
    lastCost?: number;
    liquorfileStockNo?: number;
    cut?: number;
    /**
     * The variety of the item. Usually reserved for wines.
     */
    variety?: string;
    /**
     * The vintage year of the item. Usually reserved for wines.
     */
    vintage?: string;
    /**
     * Describes the size of the item.
     */
    size?: string;
    /**
     * The region the item originates from. Usually reserved for wines.
     */
    region?: string;
    expectedCostPerOuter?: number;
    includeInExport?: boolean;
    discretionaryStatus?: number;
    comment?: string;
    /**
     * A reference to the primary key in the CookingDepartments Table
     */
    cookingDepartmentId?: number;
    producer?: string;
    brand?: string;
    cutweight?: number;
    /**
     * A reference to the primary key in the ReportGroups Table
     */
    reportGroupId?: number;
    divideByCut?: boolean;
    alcoholPercentage?: number;
    sweetness?: number;
    /**
     * A reference to the primary key in the StockStatuses Table
     */
    stockStatusId?: number;
    minimumVariables?: number;
    allowPackageDisc?: boolean;
    allowPriceReduction?: boolean;
    innerGrossWeight?: number;
    innerTareWeight?: number;
    outerGrossWeight?: number;
    /**
     * A reference to the primary key in the Countries Table
     */
    countryId?: number;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    dateCostsChanged?: Date;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    dateExpectedCostChanged?: Date;
    leadTime?: number;
    trackByMethod?: number;
    daysValidFor?: number;
    warnNoOfDays?: number;
    packageRevenueSplitType?: number;
    allowPriceIncrease?: boolean;
    packageLabelImageId?: number;
    validDaysDesc?: number;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    inventoryCode?: number;
    /**
     * A reference to the primary key in the Ranges Table
     */
    rangeId?: number;
    imageUrl?: string;
    imageId?: string;
    recipeIngredients?: Array<StockDetailViewRecipeIngredients>;
    recipeMethods?: Array<StockDetailViewRecipeMethods>;
}