import { Injectable } from '@angular/core';
import { GlobalsService } from '@services/global.service';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { HttpResponse } from '@angular/common/http';
import { BarcodesService as SDKBarcodesService } from '@modules/SDKs/sysnetApi/api/barcodes.service';
import { GroupQuery, GroupSummaryQuery, BarcodeInsert, BarcodeUpdate, BarcodeView } from '@modules/SDKs/sysnetApi';
import { ServicesWrappingSysnetSDK } from './ServicesWrappingSysnetSDK';

@Injectable({
    providedIn: 'root'
})
export class BarcodesService extends ServicesWrappingSysnetSDK {
    private orgId: string;

    constructor(private sdkBarcodesService: SDKBarcodesService, private globals: GlobalsService) {
        super([sdkBarcodesService], globals);
        this.globals.$state.subscribe((newState) => {
            if (newState) { this.orgId = newState.selectedOrganisation?._id; }
        });
    }

    // Get Barcode
    public getBarcode(barcodeId: number): Promise<HttpResponse<BarcodeView>> {
        return this.sdkBarcodesService.barcodesIdGet(barcodeId, AppConfiguration.rsh.getStrVal('rid'), null, 'response').toPromise();
    }

    // Search Barcodes
    public searchBarcodes(group?: GroupQuery, groupSummary?: GroupSummaryQuery, totalSummary?: GroupSummaryQuery,
                          sort?: string, limit?: number, offset?: number, view?: string, search?: object, searchDistinct?: string): Promise<HttpResponse<BarcodeView[]>> {
        return this.sdkBarcodesService.barcodesGet(AppConfiguration.rsh.getStrVal('rid'), view,
        this.stringify(group), this.stringify(groupSummary), this.stringify(totalSummary), searchDistinct, sort, limit, offset, this.stringify(search),
        null, null, null, null, null, null, null, this.orgId, null, 'response').toPromise();
    }

    // Insert Barcode
    public insertBarcode(barcode: BarcodeInsert): Promise<HttpResponse<BarcodeView>> {
        return this.sdkBarcodesService.barcodesPost(barcode, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Update Barcode
    public updateBarcode(barcodeId: number, barcode: BarcodeUpdate): Promise<HttpResponse<BarcodeView[]>> {
        return this.sdkBarcodesService.barcodesIdPut(barcodeId, barcode, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Delete Barcode
    public deleteBarcode(barcodeId: number): Promise<HttpResponse<BarcodeView>> {
        return this.sdkBarcodesService.barcodesIdDelete(barcodeId, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

}
