/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VenueLocationBulkInsertInner { 
    /**
     * The user defined number of the location
     */
    number?: number;
    /**
     * The name of the location
     */
    name?: string;
    venueId?: string;
    venuePoints?: number;
    specialVenuePoints?: number;
    generalLedgerPrefix?: string;
    restockReport?: boolean;
    leasedSpace?: number;
    salesTaxOnHeadOffice?: boolean;
    glPrefix?: string;
    masterStationId?: number;
    /**
     * A reference to the primary key in the VenueLocations Table
     */
    stockKeepingLocationId?: number;
    allowTransfersTo?: boolean;
    payrollAreaId?: string;
    birthdayPointsMultiplier?: number;
    pointsMultiplier?: number;
    specialPointsMultiplier?: number;
    discretionaryStatus?: number;
    allowInvoiceEntry?: boolean;
    xRefCode?: string;
    royaltyPercentage?: number;
    advertisingLevyPercentage?: number;
    allowTransfersFrom?: boolean;
    /**
     * A reference to the primary key in the TaxNames Table
     */
    taxNameId?: number;
    /**
     * A reference to the primary key in the LocationTypes Table
     */
    locationTypeId?: number;
    nextOrderNo?: number;
    orderNoPrefix?: number;
    kitchenPrinterSequenceNo?: number;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    mostRecentEndOfShift?: Date;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    lastDownloadScheduled?: Date;
    tradingName?: string;
    addressLine1?: string;
    addressLine2?: string;
    suburbName?: string;
    postCode?: string;
    state?: string;
    country?: string;
    workPhone?: string;
    fax?: string;
    email?: string;
    www?: string;
    isAutoTableClearEnabled?: boolean;
    minStockHoldingValue?: number;
    maxStockHoldingValue?: number;
    /**
     * A reference to the primary key in the LocationSegments Table
     */
    locationSegmentId?: number;
    noMemberSurcharge?: boolean;
    surchargePercent?: number;
    surchargeDollar?: number;
    resdiaryRestaurantId?: number;
    serviceCharge?: number;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    aPriceNameId?: number;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    bPriceNameId?: number;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    cPriceNameId?: number;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    dPriceNameId?: number;
    /**
     * A reference to the primary key in the PriceNames Table
     */
    ePriceNameId?: number;
}