import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { HalEventsClient, IEventMessage } from 'hal.events.client';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { filter } from 'rxjs/operators';
import { Auth0Service } from '../auth0/auth0.service';

@Injectable({
  providedIn: 'root'
})

export class EventsService {

  public readonly event$: Observable<IEventMessage>;
  private halClient: HalEventsClient = new HalEventsClient();

  constructor(public readonly authService: Auth0Service) {
    this.authService.$isAuthenticated.subscribe((authenticated: boolean) => {
      if (authenticated) {
        this.connect();
      } else {
        this.halClient.disconnect();
      }
      return {
        unsubscribe: () => {
          this.halClient.disconnect();
        }
      };
    });

    this.event$ = new Observable((observer: Subscriber<IEventMessage>) => {
      const fn = (event: IEventMessage): void => {
        observer.next(event);
      };
      this.halClient.addListener('EventMessage', fn);
      return () => {
        this.halClient.removeListener('EventMessage', fn);
      };
    }).pipe(filter((event: IEventMessage) => !event.isMe));

  }

  /**
   * tries to connect to events web socket via halClient, if the client isn't connected.
   */
  public connect(): void {
    if (!localStorage.getItem('id_token')) { return; }
    if (this.halClient.isConnected()) { return; }

    const getBearerToken = (): string => localStorage.getItem('id_token');
    this.halClient.connectWithBearerToken(
      getBearerToken,
      AppConfiguration.rsh.getStrVal('rid_chain'),
      false,
      [],
      4000,
      AppConfiguration.GetConfiguration('EVENTS_ENVIRONMENT')
    ).catch(
      (err: Error) => {
        // eslint-disable-next-line no-console
        console.log('Failed to connect to events with bearer token. Error: ' + err.toString())
      }
    );

  }
}
