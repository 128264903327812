export enum Page {
  LOGIN = 'Login',
  NOT_FOUND = 'Not Found',
  SIGNUP = 'Sign Up',
  DASHBOARD = 'Dashboard',
  HOME = 'Home',
  SYSNET = 'Sysnet',
  REPORTS = 'Reports',
}

/**
 * Of the form { title: string, url: string}. Url will always start and end with a /
 */
export const pages = {
  LOGIN: {
    id: Page.LOGIN,
    title: 'Login',
    url: '/login'
  },
  NOT_FOUND: {
    id: Page.NOT_FOUND,
    title: 'Not Found',
    url: '**'
  },
  SIGNUP: {
    id: Page.SIGNUP,
    title: 'Sign Up',
    url: '/signup/'
  },
  DASHBOARD: {
    id: Page.DASHBOARD,
    title: 'Home',
    url: ''
  },
  HOME: {
    id: Page.HOME,
    title: 'Home',
    url: '/home'
  },
  SYSNET: {
    id: Page.SYSNET,
    title: 'Home',
    url: '/sysnet'
  },
  REPORTS: {
    id: Page.REPORTS,
    title: 'Home',
    url: '/dashboards'
  }
};
// export enum UniqueCheckState {NOT_TRIGGERED = '0', CHECKING = '1', UNIQUE = '2', NOT_UNIQUE = '3'}

export interface IPageInfo {
  id: string;
  title: string;
  url: string;
}

export enum Store {
  user = 'user',
  currentpage = 'currentpage',
}
