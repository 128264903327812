<dx-popup #assignSupplierCode [(visible)]="popupConfig.visible" [title]="popupConfig.title" [showCloseButton]="false"
    [hideOnOutsideClick]="false" [shading]="true" [shadingColor]="popupConfig.shadingColor" (onHiding)="popUpClose($event)"
    [width]="popupConfig.width" [height]="popupConfig.height" [fullScreen]="globalsService?.isMobile"
    [wrapperAttr]="{id:'assignSupplierCodePopUp'}" >



    <div *ngIf="globalsService?.isMobile; else fullHeadings" class="supplier-inv-code-detail">
        <span class="supplier-inv-code-text" i18n="The mobile label for the supplier code being added@@supplierInvCodeMobile">Code: <span class="detail">{{supplierInvCode}}</span></span>
        <span class="supplier-name-text" i18n="The mobile label for the supplier name the code is being added for@@supplierNameMobile">Name: <span class="detail">{{supplierName}}</span></span>
    </div>
    <ng-template #fullHeadings> 
        <div class="supplier-inv-code-detail">
        <span class="supplier-inv-code-text" i18n="The label for the supplier code being added@@supplierInvCode">Supplier Inv Code: <span class="detail">{{supplierInvCode}}</span></span>
        <span class="supplier-name-text" i18n="The label for the supplier name the code is being added for@@supplierName">Supplier Name: <span class="detail">{{supplierName}}</span></span>
        </div>
    </ng-template>

    <div class="stock-item-heading" i18n="Heading asking for stock item selection@@stockSelectionHeading">Which stock item
        does this code refer to?</div>

    <div id="assignSupplierCodeStockGrid"
        [ngClass]="globalsService?.isMobile ? 'stock-data-grid-container-mobile':'stock-data-grid-container'">
        <app-generic-data-grid #stockDataGrid [dataSourceConfig]="stockListDataSourceConfig"
            [dataGridConfig]="stockListDataGridConfig"></app-generic-data-grid>
    </div>

    <dx-form [colCount]="1" validationGroup="assignSupplierInvCodeGroup" id="assignSupplierCodeForm">
        <dxi-item itemType="group" [colCount]="3" caption="Which factor does this code refer to?"
            cssClass="factor-selection">
            <dxi-item dataField="factorId" [colSpan]="1" [template]="'factorTemplate'"
                [label]="{text: 'Factor Name', location: 'top', showColon: false}"
                [validationRules]="validationRules.factorId" cssClass="factor-item">
            </dxi-item>
            <dxi-item itemType="empty" [visible]="!globalsService?.isMobile"></dxi-item>
            <dxi-item itemType="empty" [visible]="!globalsService?.isMobile"></dxi-item>
        </dxi-item>

        <dxi-item [colSpan]="1" [template]="'buttonsTemplate'" cssClass="button-section"></dxi-item>


        <div *dxTemplate="let data of 'factorTemplate'">
            <dx-lookup #factorsLookup [items]="availableFactors" [deferRendering]="false"
                [displayExpr]="displayExprFactors" valueExpr="factorId" placeholder="Select Factor Name"
                searchPlaceholder="Search Factor" [showClearButton]="false"
                [dropDownOptions]="dxLookupConfig.dropDownOptions" searchExpr="Container.description">
            </dx-lookup>
        </div>

        <div *dxTemplate="let data of 'buttonsTemplate'" class="form-btn-container">
            <dx-button class="form-cancel" stylingMode="outlined" text="Cancel" (onClick)="popUpClose($event)"
                validationGroup="assignSupplierInvCodeGroup">
            </dx-button>
            <dx-button [elementAttr]="{id:'save'}" class="form-save" stylingMode="contained" text="Save"
                (onClick)="popUpClose($event)" validationGroup="assignSupplierInvCodeGroup">
            </dx-button>
        </div>
    </dx-form>
</dx-popup>



<dx-load-panel [(visible)]="loadingVisible" [message]="'Saving...'" [showIndicator]="true" [showPane]="true"
    [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>