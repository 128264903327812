/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

/**
 * Product Groups group similar products (PLUs) together for reporting on like items as well as for product management purposes by setting default values that are inherited by products created in the group.
 */
export interface ProductGroupsView { 
    /**
     * The unique identifier of this record.
     */
    pluGroupId?: number;
    number?: number;
    name?: string;
    pointsMultiplier?: number;
    specialPointsMultiplier?: number;
    recommendedGrossProfit?: number;
    /**
     * A reference to the primary key in the TaxRates Table
     */
    taxId?: number;
    linkedPluGroupNo?: number;
    superPluGroupId?: number;
    decimalQty?: boolean;
    courseType?: string;
    cookGroupNo?: number;
    condimentGroupNo?: number;
    alphaSort?: boolean;
    superPluGroupIdReports?: number;
    /**
     * A reference to the primary key in the PrintGroups Table
     */
    printGroupId?: number;
    rangeStart?: number;
    rangeEnd?: number;
    incrementValue?: number;
    incrementFromInvCode?: boolean;
    tableStatusId?: number;
    maximumSelection?: number;
    /**
     * A reference to the primary key in the RevenueGroups Table
     */
    revenueGroupId?: number;
    externalReferenceNo?: number;
    /**
     * A reference to the primary key in the StockGroups Table
     */
    defaultStockGroupId?: number;
    /**
     * A reference to the primary key in the Containers Table
     */
    defaultContainerId?: number;
    forcePosition?: boolean;
    promptTableOrOrder?: boolean;
    noMemberSurcharge?: boolean;
    surchargePercent?: number;
    organisationId?: string;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    promoQtyRequired?: number;
    /**
     * A reference to the primary key in the PluGroups Table
     */
    promoFreePluGroupId?: number;
    defaultModifierType?: ProductGroupsView.DefaultModifierTypeEnum;
}
export namespace ProductGroupsView {
    export type DefaultModifierTypeEnum = 'No modifier' | 'Normal modifier' | 'Group modifier' | 'Position modifier' | 'Printer change modifier' | 'Order Number modifier' | 'Reason Code modifier' | 'Member Fees' | 'Ticket modifier' | 'Package' | 'Timer' | 'Non-kitchen printed' | 'null';
    export const DefaultModifierTypeEnum = {
        NoModifier: 'No modifier' as DefaultModifierTypeEnum,
        NormalModifier: 'Normal modifier' as DefaultModifierTypeEnum,
        GroupModifier: 'Group modifier' as DefaultModifierTypeEnum,
        PositionModifier: 'Position modifier' as DefaultModifierTypeEnum,
        PrinterChangeModifier: 'Printer change modifier' as DefaultModifierTypeEnum,
        OrderNumberModifier: 'Order Number modifier' as DefaultModifierTypeEnum,
        ReasonCodeModifier: 'Reason Code modifier' as DefaultModifierTypeEnum,
        MemberFees: 'Member Fees' as DefaultModifierTypeEnum,
        TicketModifier: 'Ticket modifier' as DefaultModifierTypeEnum,
        Package: 'Package' as DefaultModifierTypeEnum,
        Timer: 'Timer' as DefaultModifierTypeEnum,
        NonKitchenPrinted: 'Non-kitchen printed' as DefaultModifierTypeEnum,
        Null: 'null' as DefaultModifierTypeEnum
    };
}