/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { PackageItemIdFKObject } from './packageItemIdFKObject';
import { PackageItemSubstituteView } from './packageItemSubstituteView';
import { ProductIdFKObject } from './productIdFKObject';
import { UpdateDateTime } from './updateDateTime';

export interface PackageItemSubstitutesFKExternalKeysView extends PackageItemSubstituteView { 
    packageItemIdFKObject?: PackageItemIdFKObject;
    productIdFKObject?: ProductIdFKObject;
}