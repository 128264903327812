import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IDialogData {
  title: string;
  content: string;
  iconPath?: IconPaths;
  confirmLbl?: string;
  cancelLbl?: string;
  fnYes: () => void;
  fnNo: () => void;
}

export enum IconPaths {
 bin = 'assets/img/dialog-popup-icons/bin.svg',
 cross = 'assets/img/dialog-popup-icons/cross.svg',
  exclamation = 'assets/img/dialog-popup-icons/exclamation.svg'
}

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss'],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None
})
export class YesNoDialogComponent implements OnInit {

  public title = '';
  public content = '';
  public cancelLbl = 'Cancel';
  public confirmLbl = 'Confirm';
  public fnYes: () => void;
  public fnNo: () => void;
  public iconPath: IconPaths;

  constructor(public dialogRef: MatDialogRef<YesNoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IDialogData) {
      this.title = data.title;
      this.content = data.content;
      this.iconPath = data.iconPath;
      if (data.fnYes) {
        this.fnYes = data.fnYes;
      }
      if (data.fnNo) {
        this.fnNo = data.fnNo;
      }
      if (data.cancelLbl) { this.cancelLbl = data.cancelLbl; }
      if (data.confirmLbl) { this.confirmLbl = data.confirmLbl; }
  }

  public ngOnInit(): void {
    document.getElementById('dialogContent').innerHTML = this.content;
  }

  public onYesClick(): void {
    if (this.fnYes) {
      this.fnYes();
    }
  }

  public onNoClick(): void {
    if (this.fnNo) {
      this.fnNo();
    }
  }

}
