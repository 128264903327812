import { Injectable } from '@angular/core';
import { ICustomStoreLoadResult } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { SupplierInventoryCodeView } from '@modules/SDKs/sysnetApi';
import { SupplierInventoryCodesService } from '@services/sysnet/supplier-inventory-codes.service';
import { UtilityService } from '@services/utility.service';
import { LoadOptions } from 'devextreme/data';
import { IGroupQueryResultBody } from 'src/app/types/interfaces/GeneralService';
import { ErrorMessages } from 'src/app/utilities/ErrorMessages';
import { LoadOptionsHelper } from 'src/app/utilities/LoadOptionsHelper';

@Injectable({
  providedIn: 'root'
})
export class SupplierInventoryCodesDataGridService {

  constructor(
    private supplierInventoryCodesService: SupplierInventoryCodesService,
    private utils: UtilityService
  ) {
  }

  // Get SupplierInventoryCode
  public async getSupplierInventoryCode(key): Promise<SupplierInventoryCodeView> {
    if (key) {
      const supplierInventoryCodeRes = await this.supplierInventoryCodesService.getSupplierInventoryCode(key);
      return supplierInventoryCodeRes.body;
    }
  }

  // Search SupplierInventoryCodes
  public async searchSupplierInventoryCodes(loadOptions: LoadOptions, view?: string): Promise<ICustomStoreLoadResult<SupplierInventoryCodeView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const supplierInventoryCodesRes = await this.supplierInventoryCodesService.searchSupplierInventoryCodes(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort,
      loadOptions.take, loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<SupplierInventoryCodeView> = {
      data: parsedLoadOptions.group ? (supplierInventoryCodesRes.body as unknown as IGroupQueryResultBody).data : supplierInventoryCodesRes.body,
      totalCount: +supplierInventoryCodesRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  // Insert SupplierInventoryCode
  public async insertSupplierInventoryCode(values): Promise<SupplierInventoryCodeView> {
    const supplierInventoryCodeResult = await this.supplierInventoryCodesService.insertSupplierInventoryCode(values);
    return supplierInventoryCodeResult.body;
  }

  // Update SupplierInventoryCode
  public async updateSupplierInventoryCode(key, values): Promise<void> {
    if (Object.keys(values).length !== 0) {
      await this.supplierInventoryCodesService.updateSupplierInventoryCode(key, values);
    }
  }

  // Delete SupplierInventoryCode
  public async deleteSupplierInventoryCode(key): Promise<void> {
    if (key) {
      await this.supplierInventoryCodesService.deleteSupplierInventoryCode(key);
    }
  }

  public async searchSupplierInventoryCodesByStockId(loadOptions: LoadOptions, view?: string, stockId?: number): Promise<ICustomStoreLoadResult<SupplierInventoryCodeView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    if (stockId === undefined) {
      return { data: [], totalCount: 0, summary: null, groupCount: null };
    }
    const stockIdSearchExpr = { stockId: { $eq: stockId } };
    if (parsedLoadOptions.search) {
      parsedLoadOptions.search = {
        $and: [parsedLoadOptions.search, stockIdSearchExpr]
      };
    } else {
      parsedLoadOptions.search = stockIdSearchExpr;
    }
    const supplierInventoryCodesRes = await this.supplierInventoryCodesService.searchSupplierInventoryCodes(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort,
      loadOptions.take, loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<SupplierInventoryCodeView> = {
      data: parsedLoadOptions.group ? (supplierInventoryCodesRes.body as unknown as IGroupQueryResultBody).data : supplierInventoryCodesRes.body,
      totalCount: +supplierInventoryCodesRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  public async isInvCodeUniqueForTheSupplier(supplierId: number, invCode: string | number, supplierInventoryCodeId: number): Promise<boolean | object> {
    if (invCode === null || invCode === undefined) { return true; }
    const search = {
      $and: [
        { supplierId: { $eq: supplierId } },
        { supplierInventoryCode: { $eq: invCode } }
      ]
    };
    try {
      const result = await this.supplierInventoryCodesService.searchSupplierInventoryCodes(null, null, null, null, null, null, null, search);
      if (result?.body?.length === 0) {
        return true;
      } else if (supplierInventoryCodeId && result?.body?.length === 1 && result?.body[0]?.supplierInventoryCodeId === supplierInventoryCodeId) {
        return true;
      }
      return false;
    } catch (error) {
      const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} validating supplier inv code. ` + instruction, 'OK', 10000);
      return {};
    }
  }

  public async isDefaultCodeUniqueForTheSupplierAndStock(supplierId: number, stockId: number, supplierInventoryCodeId: number): Promise<SupplierInventoryCodeView> {
    const search = {
      $and: [
        { stockId: { $eq: stockId } },
        { supplierId: { $eq: supplierId } },
        { defaultCode: { $is: true } }
      ]
    };
    try {
      const result = await this.supplierInventoryCodesService.searchSupplierInventoryCodes(null, null, null, null, null, null, null, search);
      if (result?.body?.length === 0) {
        return null;
      } else if (supplierInventoryCodeId && result?.body?.length === 1 && result?.body[0]?.supplierInventoryCodeId === supplierInventoryCodeId) {
        return null;
      }
      return result?.body[0];
    } catch (error) {
      const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} validating supplier default code. ` + instruction, 'OK', 10000);
      return {}
    }
  }
}
