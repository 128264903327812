import { Injectable } from '@angular/core';
import { ICustomStoreLoadResult } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { BarcodesWithStockDetailsView, BarcodeView } from '@modules/SDKs/sysnetApi';
import { BarcodesService } from '@services/sysnet/barcodes.service';
import { UtilityService } from '@services/utility.service';
import { LoadOptions } from 'devextreme/data';
import { ErrorMessages } from 'src/app/utilities/ErrorMessages';
import { LoadOptionsHelper } from 'src/app/utilities/LoadOptionsHelper';
import { IGroupQueryResultBody } from 'src/app/types/interfaces/GeneralService';
import { IQueryFilter } from 'src/app/types/GeneralTypes';
import { IFactor } from './factors-data-grid.service';
import { IProduct } from './products-data-grid.service';

export interface IBarcode {
  barcodeId?: number;
  barcode?: string;
  factorId?: number;
  productId?: number;
  Factor?: IFactor;
  Product?: IProduct;
}

@Injectable({
  providedIn: 'root'
})
export class BarcodesDataGridService {

  constructor(
    private barcodesService: BarcodesService,
    private utils: UtilityService
  ) {
  }

  // Get Barcode
  public async getBarcode(key): Promise<BarcodeView> {
    if (key) {
      const barcodeRes = await this.barcodesService.getBarcode(key);
      return barcodeRes.body;
    }
  }

  // Search Barcodes
  public async searchBarcodes(loadOptions: LoadOptions, view?: string): Promise<ICustomStoreLoadResult<BarcodeView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const barcodesRes = await this.barcodesService.searchBarcodes(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take,
      loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<BarcodeView> = {
      data: parsedLoadOptions.group ? (barcodesRes.body as unknown as IGroupQueryResultBody).data : barcodesRes.body,
      totalCount: +barcodesRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  // Insert Barcode
  public async insertBarcode(values): Promise<BarcodeView> {
    const barcodeResult = await this.barcodesService.insertBarcode(values);
    return barcodeResult.body;
  }

  // Update Barcode
  public async updateBarcode(key, values): Promise<void> {
    if (Object.keys(values).length !== 0) {
      await this.barcodesService.updateBarcode(key, values);
    }
  }

  // Delete Barcode
  public async deleteBarcode(key): Promise<void> {
    if (key) {
      await this.barcodesService.deleteBarcode(key);
    }
  }

  public async searchBarcodesByFactorId(loadOptions: LoadOptions, view?: string, factorId?: number): Promise<ICustomStoreLoadResult<BarcodeView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    if (factorId === undefined) {
      return { data: [], totalCount: 0, summary: null, groupCount: null };
    }
    const factorIdSearchExpr = { factorId: { $eq: factorId } };
    if (parsedLoadOptions.search) {
      parsedLoadOptions.search = {
        $and: [parsedLoadOptions.search, factorIdSearchExpr]
      };
    } else {
      parsedLoadOptions.search = factorIdSearchExpr;
    }
    const barcodesRes = await this.barcodesService.searchBarcodes(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take,
      loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<BarcodeView> = {
      data: parsedLoadOptions.group ? (barcodesRes.body as unknown as IGroupQueryResultBody).data : barcodesRes.body,
      totalCount: +barcodesRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  public async searchBarcodesByProductId(loadOptions: LoadOptions, view?: string, productId?: number): Promise<ICustomStoreLoadResult<BarcodeView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    if (productId === undefined) {
      return { data: [], totalCount: 0, summary: null, groupCount: null };
    }
    const productsIdSearchExpr = { productId: { $eq: productId } };
    if (parsedLoadOptions.search) {
      parsedLoadOptions.search = {
        $and: [parsedLoadOptions.search, productsIdSearchExpr]
      };
    } else {
      parsedLoadOptions.search = productsIdSearchExpr;
    }
    const barcodesRes = await this.barcodesService.searchBarcodes(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take,
      loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<BarcodeView> = {
      data: parsedLoadOptions.group ? (barcodesRes.body as unknown as IGroupQueryResultBody).data : barcodesRes.body,
      totalCount: +barcodesRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  public async searchBarcodesByBarcodeAndReturnAllDataIfNoPagination(loadOptions: LoadOptions, view?: string,
                                                                     barcode?: string, customFilter?: IQueryFilter): Promise<ICustomStoreLoadResult<BarcodeView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    if (barcode === undefined) {
      return { data: [], totalCount: 0, summary: null, groupCount: null };
    }
    if (customFilter) {
      if (parsedLoadOptions.search) {
        parsedLoadOptions.search = {
          $and: [parsedLoadOptions.search, customFilter]
        };
      } else {
        parsedLoadOptions.search = customFilter;
      }
    }
    const take = loadOptions.take || 100;

    let skip = loadOptions.skip || 0;
    const hasPagination = loadOptions.hasOwnProperty('take') && loadOptions.hasOwnProperty('skip');
    let barcodes = [];
    let barcodesRes;
    do {
      barcodesRes = await this.barcodesService.searchBarcodes(
        parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, take,
        skip, view, parsedLoadOptions.search);
      if (barcodesRes?.body?.length > 0) {
        barcodes = barcodes.concat(barcodesRes.body);
        skip += take;
      }
    } while ((!hasPagination && barcodes.length < +barcodesRes.headers.get('total_available_results_count')));
    const value: ICustomStoreLoadResult<BarcodeView> = {
      data: parsedLoadOptions.group ? (barcodes as unknown as IGroupQueryResultBody).data : barcodes,
      totalCount: +barcodesRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  public async isBarcodeUnique(barcode: string | number, view: string): Promise<BarcodesWithStockDetailsView[] | object> {
    const search = { barcode };
    try {
      const result = await this.barcodesService.searchBarcodes(null, null, null, null, null, null, view, search);
      if (result?.body?.length === 0) {
        return null; // no duplicate => is unique
      }
      return result?.body; // all other cases, not unique
    } catch (error) {
      const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} validating barcode. ` + instruction, 'OK', 10000);
      return {};
    }
  }
}
