/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BulkInsertError } from './bulkInsertError';

export interface BulkUpdate { 
    /**
     * Corresponds to the index of the posted array item in the HTTP request body JSON.
     */
    index?: number;
    /**
     * Returns true if the item has been updated successfully and will also return the updatedObject attribute. Returns false if the item failed to update, the reasons for the failure will be available in the ‘errors’ attribute.
     */
    success?: boolean;
    error?: BulkInsertError;
}