/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StocktakeUpdate { 
    status?: StocktakeUpdate.StatusEnum;
    /**
     * Update stock on hand quantities to 0 for all uncounted stocktakeItems in stocktake.
     */
    zeroUncounted?: boolean;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
    /**
     * Can be set to true when preparing a stocktake.
     */
    prepared?: boolean;
    /**
     * Can be set to true when processing a stocktake.
     */
    processed?: boolean;
    /**
     * Can be set to true when printing a stocktake sheet.
     */
    sheetsPrinted?: boolean;
    /**
     * Can be set to true when printing a stocktake report.
     */
    reportPrinted?: boolean;
}
export namespace StocktakeUpdate {
    export type StatusEnum = 'IN_PROGRESS' | 'COMPLETED' | 'DISCARDED';
    export const StatusEnum = {
        INPROGRESS: 'IN_PROGRESS' as StatusEnum,
        COMPLETED: 'COMPLETED' as StatusEnum,
        DISCARDED: 'DISCARDED' as StatusEnum
    };
}