/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CookingDepartmentIdFKObject } from './cookingDepartmentIdFKObject';
import { CountryIdFKObject } from './countryIdFKObject';
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { ManufacturerDistributorIdFKObject } from './manufacturerDistributorIdFKObject';
import { MeasureIdFKObject } from './measureIdFKObject';
import { PreferredSupplierIdFKObject } from './preferredSupplierIdFKObject';
import { RangeIdFKObject } from './rangeIdFKObject';
import { ReportGroupIdFKObject } from './reportGroupIdFKObject';
import { StockGroupIdFKObject } from './stockGroupIdFKObject';
import { StockStatusIdFKObject } from './stockStatusIdFKObject';
import { StockView } from './stockView';
import { UpdateDateTime } from './updateDateTime';

export interface StockFKExternalKeysView extends StockView { 
    stockGroupIdFKObject?: StockGroupIdFKObject;
    measureIdFKObject?: MeasureIdFKObject;
    preferredSupplierIdFKObject?: PreferredSupplierIdFKObject;
    manufacturerDistributorIdFKObject?: ManufacturerDistributorIdFKObject;
    cookingDepartmentIdFKObject?: CookingDepartmentIdFKObject;
    reportGroupIdFKObject?: ReportGroupIdFKObject;
    stockStatusIdFKObject?: StockStatusIdFKObject;
    countryIdFKObject?: CountryIdFKObject;
    rangeIdFKObject?: RangeIdFKObject;
}