<dx-data-grid class="quick-entry-data-grid"
    [dataSource]="dataGridConfig.dataSource"
    [keyExpr]="dataGridConfig.keyExpr"
    [columns] = "dataGridConfig.columns"
    [repaintChangesOnly]="dataGridConfig.repaintChangesOnly"
    [allowColumnReordering]="dataGridConfig.allowColumnReordering"
    [allowColumnResizing]="dataGridConfig.allowColumnResizing"
    [columnFixing]="dataGridConfig.columnFixing"
    [columnResizingMode]="dataGridConfig.columnResizingMode"
    [showBorders]="dataGridConfig.showBorders"
    [showColumnLines]="dataGridConfig.showColumnLines"
    [showRowLines]="dataGridConfig.showRowLines"
    [rowAlternationEnabled]="dataGridConfig.rowAlternationEnabled"
    [columnHidingEnabled]="dataGridConfig.columnHidingEnabled"
    [syncLookupFilterValues]="dataGridConfig.syncLookupFilterValues"
    [columnChooser]="dataGridConfig.columnChooser"
    [selection]="dataGridConfig.selection"
    [pager]="dataGridConfig.pager"
    [paging]="dataGridConfig.paging"
    [sorting]="dataGridConfig.sorting"
    [editing]="dataGridConfig.editing"
    [export]="dataGridConfig.export"
    [searchPanel]="dataGridConfig.searchPanel"
    [filterRow]="dataGridConfig.filterRow"
    [filterPanel]="dataGridConfig.filterPanel"
    [headerFilter]="dataGridConfig.headerFilter"
    [groupPanel]="dataGridConfig.groupPanel"
    [grouping]="dataGridConfig.grouping"
    [remoteOperations]="dataGridConfig.remoteOperations"
    [summary]="dataGridConfig.summary"
    [scrolling]="dataGridConfig.scrolling"
    [stateStoring]="dataGridConfig.stateStoring"
    [errorRowEnabled]="dataGridConfig.errorRowEnabled"
    [keyboardNavigation]="dataGridConfig.keyboardNavigation"
    (onSelectionChanged)="dataGridConfig.onSelectionChanged($event)"
    (onContextMenuPreparing)="dataGridConfig.onContextMenuPreparing($event)"
    (onEditorPreparing)="dataGridConfig.onEditorPreparing($event)"
    (onEditorPrepared)="dataGridConfig.onEditorPrepared($event)"
    (onToolbarPreparing)="dataGridConfig.onToolbarPreparing($event)"
    (onSaving)="dataGridConfig.onSaving($event)"
    (onRowPrepared)="dataGridConfig.onRowPrepared($event)"
    (onRowValidating)="dataGridConfig.onRowValidating($event)"
    (onRowRemoved)="dataGridConfig.onRowRemoved($event)"
    (onContentReady)="dataGridConfig.onContentReady($event)"
    (onInitNewRow)="dataGridConfig.onInitNewRow($event)"
    (onEditingStart)="dataGridConfig.onEditingStart($event)"
    (onCellPrepared)="dataGridConfig.onCellPrepared($event)"
    (onCellDblClick)="dataGridConfig.onCellDblClick($event)"
    (onFocusedCellChanged)="dataGridConfig.onFocusedCellChanged($event)"
    (onFocusedCellChanging)="dataGridConfig.onFocusedCellChanging($event)"
    (onKeyDown)="dataGridConfig.onKeyDown($event)"
    (onSaved)="dataGridConfig.onSaved($event)"
    (onOptionChanged)="dataGridConfig.onOptionChanged($event)"
>
<dxo-filter-row [visible]="filterRowVisible"></dxo-filter-row>

<div *dxTemplate="let cellInfo of 'refreshTemplate'">
    <div class="refresh-button-container">
      <dx-button icon="refresh" (click)="refresh()" [hint]="gridUpdateRequired && refreshRequiredTooltip !== '' ? refreshRequiredTooltip : 'Refresh'"></dx-button>
      <img *ngIf="gridUpdateRequired" src="../../../../../../../../assets/img/icons/circle-exclamation.svg" class="exclamation-icon"/>
    </div>
</div>

<ng-container *ngIf="dataGridConfig.selection?.mode === 'single'">
    <div *dxTemplate="let data of 'singleSelectCellTemplate'">
        <dx-radio-group [value]="selectedRowKey" [valueExpr]="dataSourceConfig.dataSourcePrimaryKey" [items]="[data.data]">
        </dx-radio-group>
    </div>
</ng-container>
<div *dxTemplate="let cellInfo of 'actionColumnTemplate'">
    <a *ngIf="cellInfo.row?.isNewRow && dataGridConfig.editing.allowAdding" href="javascript:void(0)" (click)="dataGridActionColumn.onSaveClick(cellInfo)" i18n="add|@@add">Add</a>&nbsp;
    <a *ngIf="cellInfo.row?.isNewRow || cellInfo.row?.modified" href="#" (click)="undo($event, cellInfo)" i18n="Cancel|@@Cancel" class="cancel" (keydown)="onKeyDown($event, cellInfo)">Cancel</a>&nbsp;
    <a *ngIf="!cellInfo.row?.isNewRow && !cellInfo.row?.modified && dataGridConfig.editing.allowDeleting" href="#" (click)="deleteItem($event, cellInfo)" (keydown)="onKeyDown($event, cellInfo)" i18n="Delete|@@Delete" class="delete">Delete</a>&nbsp;
</div>

<ng-container *ngFor="let ct of customTemplates">
    <ng-container *dxTemplate="let cellInfo of ct.name">
        <ng-template [ngTemplateOutlet]="ct.template" [ngTemplateOutletContext]="{ cellInfo: cellInfo }"></ng-template>
    </ng-container>
</ng-container>
</dx-data-grid>