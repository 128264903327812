/* eslint-disable */

import UI_COMMON_CONFIG from '../../hal.common.ui/config/config';

const PRODUCTION = {
    'PERSISTANCE_DEFAULT': 'REST',   // OPTIONS ARE "MONGO OR REST"
    'hal.common': {
        'REST_API_HOST': 'https://portal.hlcloud.com.au:3000', // remove this and add the line above after it has been updated in hal.common
    },
    'hal.odm': {
        'REST_API_HOST': 'https://odm.hlcloud.com.au:3003',
    },
    'hal.sysnet': {
        'REST_API_HOST': 'https://sysnet-api.hlcloud.com.au',
    },
    'hal.subscription': {
        'REST_API_HOST': 'https://events-subscription-api.hlcloud.com.au',
    },
    'KIBANA_CONFIG': {
        host: 'https://dash.hlcloud.com.au:5601',
        hostUTC: 'https://utc-dash.hlcloud.com.au:5601'
    },
    'FIREBASE_CONFIG' : {
        apiKey: 'AIzaSyDjK8AsKPqDF7kmtUMYWixfXO0TU0PdqWM',
        authDomain: 'notifcations-56539.firebaseapp.com',
        databaseURL: 'https://notifcations-56539.firebaseio.com',
        projectId: 'notifcations-56539',
        storageBucket: 'notifcations-56539.appspot.com',
        messagingSenderId: '308082605266'
    },
    'SOCKET_API_HOST' : 'wss:' + '//' + window.location.hostname + ':5003',
    'STRIPE_CONNECT_CLIENT_ID': 'pk_test_XiPhyJki43YxwSBuss72sh7j',
    'DOSHII_LOCATION_ID' : '5EOdLLp4',

    'AUTH0_APP_CLIENT_ID': UI_COMMON_CONFIG.PRODUCTION.AUTH0_APP_CLIENT_ID,
    'AUTH0_DOMAIN': UI_COMMON_CONFIG.PRODUCTION.AUTH0_DOMAIN,
    'DYNAMSOFT_CONFIG': {
        license: 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxMTM4ODkyLVRYbFhaV0pRY205cSIsIm9yZ2FuaXphdGlvbklEIjoiMTAxMTM4ODkyIn0=',
        engineResourcePath: 'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.2.13/dist/',
    },
};

export default class UiAppConfig {
    constructor() {

    }

    public static AppConfigurationOptions: any =
    {
        'dev':
        {
            'PERSISTANCE_DEFAULT': 'REST',   // OPTIONS ARE "MONGO OR REST"

            'hal.common': {
                'REST_API_HOST': UI_COMMON_CONFIG.SANDBOX.PORTAL_API_URI,
            },

            'hal.odm': {
                'REST_API_HOST': 'https://staging-odm.hlcloud.com.au:3003',
            },
            'hal.sysnet': {
                'REST_API_HOST': 'https://develop-sysnet-api.hlcloud.com.au',
            },
            'hal.subscription': {
                'REST_API_HOST': 'https://develop-events-subscription-api.hlcloud.com.au',
            },
            'KIBANA_CONFIG': {
                host: 'https://dev-dash.hlcloud.com.au:5601',
                hostUTC: 'https://develop-utc-dash.hlcloud.com.au',
            },
            'FIREBASE_CONFIG' : {
                apiKey: 'AIzaSyDjK8AsKPqDF7kmtUMYWixfXO0TU0PdqWM',
                authDomain: 'notifcations-56539.firebaseapp.com',
                databaseURL: 'https://notifcations-56539.firebaseio.com',
                projectId: 'notifcations-56539',
                storageBucket: 'notifcations-56539.appspot.com',
                messagingSenderId: '308082605266'
            },
            'SOCKET_API_HOST' : 'ws:' + '//' + window.location.hostname + ':5003',
            'STRIPE_CONNECT_CLIENT_ID': 'pk_test_XiPhyJki43YxwSBuss72sh7j',
            // DEV
            'AUTH0_APP_CLIENT_ID': UI_COMMON_CONFIG.SANDBOX.AUTH0_APP_CLIENT_ID,
            'AUTH0_DOMAIN': UI_COMMON_CONFIG.SANDBOX.AUTH0_DOMAIN,
            'LOG_TO_CONSOLE': true,
            'LOG_LEVEL': 'debug',
            'DYNAMSOFT_CONFIG': {
                license: 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxMTM4ODkyLVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMTEzODg5MiIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOi05Nzk0ODEwNjB9',
                engineResourcePath: 'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.2.13/dist/',
            },
            'DATASYMBOL_CONFIG': {
                license: '',
            },
            'GOOGLE_PLACES_API_KEY': 'AIzaSyBG69DbHejBIaP7-ZPYtbX8w-hxFTgi_W0',
            'EVENTS_ENVIRONMENT': 'develop'
        },
        'branch_mobiletest':
        {
            'PERSISTANCE_DEFAULT': 'REST',   // OPTIONS ARE "MONGO OR REST"

            'hal.common': {
                'REST_API_HOST': UI_COMMON_CONFIG.SANDBOX.PORTAL_API_URI,
            },
            'hal.odm': {
                'REST_API_HOST': 'https://staging-odm.hlcloud.com.au:3003',
            },
            'hal.sysnet': {
                'REST_API_HOST': 'https://develop-sysnet-api.hlcloud.com.au',
            },
            'hal.subscription': {
                'REST_API_HOST': 'https://develop-events-subscription-api.hlcloud.com.au',
            },
            'KIBANA_CONFIG': {
                host: 'https://dev-dash.hlcloud.com.au:5601',
                hostUTC: 'https://develop-utc-dash.hlcloud.com.au',
            },
            'FIREBASE_CONFIG' : {
                apiKey: 'AIzaSyDjK8AsKPqDF7kmtUMYWixfXO0TU0PdqWM',
                authDomain: 'notifcations-56539.firebaseapp.com',
                databaseURL: 'https://notifcations-56539.firebaseio.com',
                projectId: 'notifcations-56539',
                storageBucket: 'notifcations-56539.appspot.com',
                messagingSenderId: '308082605266'
            },
            'SOCKET_API_HOST' : 'wss:' + '//' + window.location.hostname + ':5003',
            'STRIPE_CONNECT_CLIENT_ID': 'pk_test_XiPhyJki43YxwSBuss72sh7j',
            'AUTH0_APP_CLIENT_ID': UI_COMMON_CONFIG.SANDBOX.AUTH0_APP_CLIENT_ID,
            'AUTH0_DOMAIN': UI_COMMON_CONFIG.SANDBOX.AUTH0_DOMAIN,
            'LOG_TO_CONSOLE': true,
            'LOG_LEVEL': 'debug',
            'DYNAMSOFT_CONFIG': {
                license: 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxMTM4ODkyLVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMTEzODg5MiIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOi05Nzk0ODEwNjB9',
                engineResourcePath: 'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.2.13/dist/',
            },
            'DATASYMBOL_CONFIG': {
                license: 'RYyrrunbQG/fEgeyzn0gOMIs60M1PPbIfBBgwgXCI8jciYJN8+fTpS5+yDfbd2gf0v7Bc/VE/Inp67x+OvFhyzSuNiOLowj5Tk7ible1ZaU9Aay/vohkQgBYidj+tQoVLNAWBH7HNTYxVReQ9P9VTYWMeeBgYvKxmK0XXOM6uBFIYpNx17YOtlUCRQwS6Bj9ZS3hAc/mYL+b7px1Wsb1O6tRPPW6K7LHwODe7E0wvNAjTmcZlqc6YDZ8vARodqr8dcqYMsfgooiuLUdEF4/L4VEDsv9Linebg9B+/Apryzlyl6oH7Q8EdpOdejB9UiObRqeFN6dr0/kZrXr+FcQQFQ=='
            },
            'EVENTS_ENVIRONMENT': 'develop'
        },
        'branch_develop':
        {
            'PERSISTANCE_DEFAULT': 'REST',   // OPTIONS ARE "MONGO OR REST"

            'hal.common': {
                'REST_API_HOST': UI_COMMON_CONFIG.SANDBOX.PORTAL_API_URI,
            },
            'hal.odm': {
                'REST_API_HOST': 'https://staging-odm.hlcloud.com.au:3003',
            },
            'hal.sysnet': {
                'REST_API_HOST': 'https://develop-sysnet-api.hlcloud.com.au',
            },
            'hal.subscription': {
                'REST_API_HOST': 'https://develop-events-subscription-api.hlcloud.com.au',
            },
            'KIBANA_CONFIG': {
                host: 'https://dev-dash.hlcloud.com.au:5601',
                hostUTC: 'https://develop-utc-dash.hlcloud.com.au',
            },
            'FIREBASE_CONFIG' : {
                apiKey: 'AIzaSyDjK8AsKPqDF7kmtUMYWixfXO0TU0PdqWM',
                authDomain: 'notifcations-56539.firebaseapp.com',
                databaseURL: 'https://notifcations-56539.firebaseio.com',
                projectId: 'notifcations-56539',
                storageBucket: 'notifcations-56539.appspot.com',
                messagingSenderId: '308082605266'
            },
            'SOCKET_API_HOST' : 'wss:' + '//' + window.location.hostname + ':5003',
            'STRIPE_CONNECT_CLIENT_ID': 'pk_test_XiPhyJki43YxwSBuss72sh7j',
            'AUTH0_APP_CLIENT_ID': UI_COMMON_CONFIG.SANDBOX.AUTH0_APP_CLIENT_ID,
            'AUTH0_DOMAIN': UI_COMMON_CONFIG.SANDBOX.AUTH0_DOMAIN,
            'LOG_TO_CONSOLE': true,
            'LOG_LEVEL': 'debug',
            'DYNAMSOFT_CONFIG': {
                license: 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxMTM4ODkyLVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMTEzODg5MiIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOi05Nzk0ODEwNjB9',
                engineResourcePath: 'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.2.13/dist/',
            },
            'DATASYMBOL_CONFIG': {
                license: 'pw5O/nRYyQtQplF3dFewJ3WRKTYHxrNK03dqUocjr/Cr8VUchCJ2TMnchbs0Ljh8YmEMs4lI6ogcJB8sQgFuGlsxiXfLc2xY4UbmAeDUGX9buvLD4F9mYZpEDizz9yQkRMKXL0888abV/ej2N1rLo/wPEiQwSnzZA4rD2x+BUAqI/NngJNAFgmjQ7DCQrpEF7v98SZ4VTYYBuRAayHr5+jJJTHUa5ky/XNnjRy7fkupwHMSyft+lrQDLfcuLWVUf0CisGllRcjf/D3kIGvxQevJE0NJWAcOyD4W+03gRXh8AFBbiz4FzhaAoEtviYqwSniA0eIT++Z0qQFsTJq2mAQ=='
            },
            'GOOGLE_PLACES_API_KEY': 'AIzaSyBG69DbHejBIaP7-ZPYtbX8w-hxFTgi_W0',
            'EVENTS_ENVIRONMENT': 'develop'
        },
        'branch_staging':
        {
            'PERSISTANCE_DEFAULT': 'REST',   // OPTIONS ARE "MONGO OR REST"
            'hal.common': {
                'REST_API_HOST': UI_COMMON_CONFIG.SANDBOX.PORTAL_API_URI,
            },
            'hal.odm': {
                'REST_API_HOST': 'https://staging-odm.hlcloud.com.au:3003',
            },
            'hal.sysnet': {
                'REST_API_HOST': 'https://staging-sysnet-api.hlcloud.com.au',
            },
            'hal.subscription': {
                'REST_API_HOST': 'https://staging-events-subscription-api.hlcloud.com.au',
            },
            'KIBANA_CONFIG': {
                host: 'https://dev-dash.hlcloud.com.au:5601',
                hostUTC: 'https://develop-utc-dash.hlcloud.com.au',
            },
            'FIREBASE_CONFIG' : {
                apiKey: 'AIzaSyDjK8AsKPqDF7kmtUMYWixfXO0TU0PdqWM',
                authDomain: 'notifcations-56539.firebaseapp.com',
                databaseURL: 'https://notifcations-56539.firebaseio.com',
                projectId: 'notifcations-56539',
                storageBucket: 'notifcations-56539.appspot.com',
                messagingSenderId: '308082605266'
            },
            'SOCKET_API_HOST' : 'wss:' + '//' + window.location.hostname + ':5003',
            'STRIPE_CONNECT_CLIENT_ID': 'pk_test_XiPhyJki43YxwSBuss72sh7j',
            'DOSHII_LOCATION_ID' : '8AMaoLM8',

            'AUTH0_APP_CLIENT_ID': UI_COMMON_CONFIG.SANDBOX.AUTH0_APP_CLIENT_ID,
            'AUTH0_DOMAIN': UI_COMMON_CONFIG.SANDBOX.AUTH0_DOMAIN,
            'LOG_TO_CONSOLE': true,
            'LOG_LEVEL': 'info',
            'DYNAMSOFT_CONFIG': {
                license: 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxMTM4ODkyLVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMTEzODg5MiIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOi05Nzk0ODEwNjB9',
                engineResourcePath: 'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.2.13/dist/',
            },
            'DATASYMBOL_CONFIG': {
                license: 'VQfsS6hO78q1IXxJU4pWVpRnk6lhFu9BNvh77dxmMbSlJaEkUS6UPWgTlHdg9fK2xQDk6mXhu0TWHeQRv6wh+kocgbKBI49A/YUE/alrcbACnjF208LTotpkpE915lnRD3NH8TRkRBoEnah2F6qanwkSRyJDHWN5bqtvD4dmOwyI/NngJNAFgmjQ7DCQrpEF7v98SZ4VTYYBuRAayHr5+jJJTHUa5ky/XNnjRy7fkupwHMSyft+lrQDLfcuLWVUf0CisGllRcjf/D3kIGvxQevJE0NJWAcOyD4W+03gRXh8AFBbiz4FzhaAoEtviYqwSniA0eIT++Z0qQFsTJq2mAQ=='
            },
            'GOOGLE_PLACES_API_KEY': 'AIzaSyBG69DbHejBIaP7-ZPYtbX8w-hxFTgi_W0',
            'EVENTS_ENVIRONMENT': 'staging'
        },
        'branch_sandbox':
        {
            'PERSISTANCE_DEFAULT': 'REST',   // OPTIONS ARE "MONGO OR REST"
            'hal.common': {
                'REST_API_HOST': UI_COMMON_CONFIG.SANDBOX.PORTAL_API_URI,
            },
            'hal.odm': {
                'REST_API_HOST': 'https://sandbox-odm.hlcloud.com.au:3003'
            },
            'hal.sysnet': {
                'REST_API_HOST': 'https://sandbox-sysnet-api.hlcloud.com.au',
            },
            'hal.subscription': {
                'REST_API_HOST': 'https://sandbox-events-subscription-api.hlcloud.com.au',
            },
            'KIBANA_CONFIG': {
                host: 'https://dev-dash.hlcloud.com.au:5601',
                hostUTC: 'https://develop-utc-dash.hlcloud.com.au',
            },
            'FIREBASE_CONFIG' : {
                apiKey: 'AIzaSyDjK8AsKPqDF7kmtUMYWixfXO0TU0PdqWM',
                authDomain: 'notifcations-56539.firebaseapp.com',
                databaseURL: 'https://notifcations-56539.firebaseio.com',
                projectId: 'notifcations-56539',
                storageBucket: 'notifcations-56539.appspot.com',
                messagingSenderId: '308082605266'
            },
            'SOCKET_API_HOST' : 'wss:' + '//' + window.location.hostname + ':5003',
            'STRIPE_CONNECT_CLIENT_ID': 'pk_test_XiPhyJki43YxwSBuss72sh7j',
            'DOSHII_LOCATION_ID' : '8AMaoLM8',

            'AUTH0_APP_CLIENT_ID': UI_COMMON_CONFIG.SANDBOX.AUTH0_APP_CLIENT_ID,
            'AUTH0_DOMAIN': UI_COMMON_CONFIG.SANDBOX.AUTH0_DOMAIN,
            'LOG_TO_CONSOLE': true,
            'LOG_LEVEL': 'info',
            'DYNAMSOFT_CONFIG': {
                license: 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxMTM4ODkyLVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMTEzODg5MiIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOi05Nzk0ODEwNjB9',
                engineResourcePath: 'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.2.13/dist/',
            },
            'DATASYMBOL_CONFIG': {
                license: '',
            },
            'GOOGLE_PLACES_API_KEY': 'AIzaSyBG69DbHejBIaP7-ZPYtbX8w-hxFTgi_W0',
            'EVENTS_ENVIRONMENT': 'sandbox'
        },
        'branch_preproductionstage1':
        {
            'PERSISTANCE_DEFAULT': PRODUCTION['PERSISTANCE_DEFAULT'],   // OPTIONS ARE "MONGO OR REST"
            /* REST CONFIG  */
            'hal.common': {
                'REST_API_HOST': 'https://pre-portal.hlcloud.com.au:3000',
            },
            'hal.odm': {
                'REST_API_HOST': 'https://pre-odm.hlcloud.com.au:3003'
            },
            'hal.sysnet': {
                'REST_API_HOST': 'https://pre-sysnet-api.hlcloud.com.au',
            },
            'hal.subscription': {
                'REST_API_HOST': 'https://events-subscription-api.hlcloud.com.au',
            },
            'KIBANA_CONFIG': PRODUCTION['KIBANA_CONFIG'],
            'FIREBASE_CONFIG' : PRODUCTION['FIREBASE_CONFIG'],
            'SOCKET_API_HOST' : PRODUCTION['SOCKET_API_HOST'],
            'STRIPE_CONNECT_CLIENT_ID': PRODUCTION['STRIPE_CONNECT_CLIENT_ID'],
            'DOSHII_LOCATION_ID' : PRODUCTION['DOSHII_LOCATION_ID'],

            'AUTH0_APP_CLIENT_ID': PRODUCTION['AUTH0_APP_CLIENT_ID'],
            'AUTH0_DOMAIN': PRODUCTION['AUTH0_DOMAIN'],
            'DYNAMSOFT_CONFIG': PRODUCTION['DYNAMSOFT_CONFIG'],
            'DATASYMBOL_CONFIG': {
                license: 'aIXvxVkInVnGG3DkwLQeYbEBy8Ocv+SJF4LbOWkC3xeQ6n5pVDMLPsditJ4tTzSRnDoz1lG/2os3FqLBVsjGBP61xsTUFTtB5K+HRd7iRRxDQP8Dn81DnR9ruQZorJp6MLjZfBUZPb+tm+gg3Tq8y5vydzVSpBGRKdzOgOmePQJdKBjgNdDDgTAE409N/z5dg4DpXVU3SJy4Cc7+n9i60CEli89Uvy27XGZ/GYNs3pYe/7YwReX8VKu4FHFgtNtYyLOG38SZzHSqzhlp0S2rXTsEDoJZvhJII9Urcd+XRtMOd00QkbAZOzBTjlKygF9Bjnmj9Ccs+TSUXCbc4y6PEg=='
            },
            'EVENTS_ENVIRONMENT': 'production'
        },
        'branch_production':
        {
            'PERSISTANCE_DEFAULT': PRODUCTION['PERSISTANCE_DEFAULT'],   // OPTIONS ARE "MONGO OR REST"
            /* REST CONFIG  */
            'REST_API_HOST': PRODUCTION['REST_API_HOST'],
            'hal.common': PRODUCTION['hal.common'],
            'hal.sysnet': PRODUCTION['hal.sysnet'],
            'hal.subscription': PRODUCTION['hal.subscription'],
            'KIBANA_CONFIG': PRODUCTION['KIBANA_CONFIG'],
            'FIREBASE_CONFIG' : PRODUCTION['FIREBASE_CONFIG'],
            'SOCKET_API_HOST' : PRODUCTION['SOCKET_API_HOST'],
            'STRIPE_CONNECT_CLIENT_ID': PRODUCTION['STRIPE_CONNECT_CLIENT_ID'],
            'DOSHII_LOCATION_ID' : PRODUCTION['DOSHII_LOCATION_ID'],

            'AUTH0_APP_CLIENT_ID': PRODUCTION['AUTH0_APP_CLIENT_ID'],
            'AUTH0_DOMAIN': PRODUCTION['AUTH0_DOMAIN'],
            'DYNAMSOFT_CONFIG': PRODUCTION['DYNAMSOFT_CONFIG'],
            'DATASYMBOL_CONFIG': {
// 2023-24 licence                license: 'yEZzpHsbdMze2YmA4cTsLUtY+cUkK8eTXFBmE1nx415Q8I2lHEdSyIlqX9if+Pf7Xq7PmTtLulDF5JetlRrfH6fsQxKSgQLz3vRVYpHfvR59MwmX2t1MvbLNXgUa0QDYcfm/8HpktEhN/XsXUK6NlSvd0e8mToi2lF0H2FANNAj6mpgmmVI3o+YVSqBuxgR+yXrvaF2sX+CNVBuLdCK1UpzUto9DMdE2neY6K8SNxU9WHGj/RuDqlSgQ8jMqC0EoIcMxw9UYXiPk05D/rQutmR8ZiFQvVex4gq2ZBl5M5DXcsA/t/eBus6UNkB7HXtd58mmUZOdyZzCqP4O6ekDYAw=='
                license: 'FjKvv2BRekswW7UgVzw2sL7wszDRbHzrgRhf9qktRMArw+3kQADgVqBpjTsdjZs3w/XccW0wYc3OpiXXiYtdaq/DtNycu0IwjKTYDmJTM7pnQ6ogQ7LpRf//m1ulwAV/tfywqEAJfYWqZYyVxf4aWoSnF0m9nRz2RDEePMCPVhFdKBjgNdDDgTAE409N/z5dg4DpXVU3SJy4Cc7+n9i60CEli89Uvy27XGZ/GYNs3pYe/7YwReX8VKu4FHFgtNtYyLOG38SZzHSqzhlp0S2rXTsEDoJZvhJII9Urcd+XRtMOd00QkbAZOzBTjlKygF9Bjnmj9Ccs+TSUXCbc4y6PEg=='
            },
            'EVENTS_ENVIRONMENT': 'production'

        },
        'e2e':
        {
            'PERSISTANCE_DEFAULT': 'REST',   // OPTIONS ARE "MONGO OR REST"

            'hal.common': {
                'REST_API_HOST': 'http://localhost:3000',
            },
            'hal.odm': {
                'REST_API_HOST': 'https://staging-odm.hlcloud.com.au:3003',
            },
            'hal.sysnet': {
                'REST_API_HOST': 'http://localhost:3004',
            },
            'KIBANA_CONFIG': {
                host: 'https://dev-dash.hlcloud.com.au:5601',
                dashboardURI: '/app/dashboards#/view/',
                salesDashboard: {
                    id:'AV7HJYZRhOuXvaEmE0Zv',
                    globalRison:'(refreshInterval:(pause:!t,value:0),time:(from:now/d,to:now/d))',
                    appFilterRison:`(description:Transaction+based+reporting+dashboard,filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:c6ed3b00-3c7b-11e8-924e-41fd05b380d0,key:venue_id,negate:!f,params:!(XXXXX),type:phrases,value:XXXXX),query:(bool:(minimum_should_match:1,should:!((match_phrase:(venue_id:XXXXX))))))))`,
                    space: 'sales-dashboards',
                },
                tenderDashboard: {
                    id:'e7f276a0-80d0-11e8-a53c-ff6f5e99126c',
                    globalRison:'(refreshInterval:(pause:!t,value:0),time:(from:now/d,to:now/d))',
                    appFilterRison:`(description:'',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:f91905e0-7ff2-11e8-a53c-ff6f5e99126c,key:venue_id,negate:!f,params:!(XXXXX),type:phrases,value:XXXXX),query:(bool:(minimum_should_match:1,should:!((match_phrase:(venue_id:XXXXX))))))))`,
                    space: 'sales-dashboards',
                },
                odmDashboard: {
                    id:'',
                    globalRison: '',
                    appFilterRison: '',
                }
            },
            'FIREBASE_CONFIG' : {
                apiKey: 'AIzaSyDjK8AsKPqDF7kmtUMYWixfXO0TU0PdqWM',
                authDomain: 'notifcations-56539.firebaseapp.com',
                databaseURL: 'https://notifcations-56539.firebaseio.com',
                projectId: 'notifcations-56539',
                storageBucket: 'notifcations-56539.appspot.com',
                messagingSenderId: '308082605266'
            },
            'SOCKET_API_HOST' : 'ws:' + '//' + window.location.hostname + ':5003',
            'STRIPE_CONNECT_CLIENT_ID': 'pk_test_XiPhyJki43YxwSBuss72sh7j',
            // DEV
            'AUTH0_APP_CLIENT_ID': UI_COMMON_CONFIG.SANDBOX.AUTH0_APP_CLIENT_ID,
            'AUTH0_DOMAIN': UI_COMMON_CONFIG.SANDBOX.AUTH0_DOMAIN,
            'LOG_TO_CONSOLE': true,
            'LOG_LEVEL': 'debug',
            'DYNAMSOFT_CONFIG': {
                license: 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxMTM4ODkyLVRYbFhaV0pRY205cSIsIm9yZ2FuaXphdGlvbklEIjoiMTAxMTM4ODkyIn0=',
                engineResourcePath: 'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.2.13/dist/',
            },
            'DATASYMBOL_CONFIG': {
                license: '',
            },
            'EVENTS_ENVIRONMENT': 'develop'
        }
    };
}