/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * This table is used to determine subsitute products for items within a package.
 */
export interface PackageItemSubstituteUpdate { 
    /**
     * The quantity of the substitute item that will be received when substituting a package item.
     */
    quantity?: number;
    /**
     * This number will adjust the package price up or down by the amount recorded here.
     */
    priceAdjustmentAmount?: number;
    /**
     * When ranging the parent package product this will create this packageItem substite product at the location being ranged to.
     */
    alwaysRangeToLocation?: boolean;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
}