/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { APriceNameIdFKObject } from './aPriceNameIdFKObject';
import { BPriceNameIdFKObject } from './bPriceNameIdFKObject';
import { CPriceNameIdFKObject } from './cPriceNameIdFKObject';
import { CreateDateTime } from './createDateTime';
import { DPriceNameIdFKObject } from './dPriceNameIdFKObject';
import { EPriceNameIdFKObject } from './ePriceNameIdFKObject';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { LocationSegmentIdFKObject } from './locationSegmentIdFKObject';
import { LocationTypeIdFKObject } from './locationTypeIdFKObject';
import { StockKeepingLocationIdFKObject } from './stockKeepingLocationIdFKObject';
import { TaxNameIdFKObject } from './taxNameIdFKObject';
import { UpdateDateTime } from './updateDateTime';
import { VenueLocationView } from './venueLocationView';
import { VenueRefIdFKObject } from './venueRefIdFKObject';

export interface VenueLocationsFKExternalKeysView extends VenueLocationView { 
    venueRefIdFKObject?: VenueRefIdFKObject;
    stockKeepingLocationIdFKObject?: StockKeepingLocationIdFKObject;
    taxNameIdFKObject?: TaxNameIdFKObject;
    locationTypeIdFKObject?: LocationTypeIdFKObject;
    locationSegmentIdFKObject?: LocationSegmentIdFKObject;
    aPriceNameIdFKObject?: APriceNameIdFKObject;
    bPriceNameIdFKObject?: BPriceNameIdFKObject;
    cPriceNameIdFKObject?: CPriceNameIdFKObject;
    dPriceNameIdFKObject?: DPriceNameIdFKObject;
    ePriceNameIdFKObject?: EPriceNameIdFKObject;
}