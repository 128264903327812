import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DashboardService {

  public sidebarDisplayedSource = new Subject<boolean>();
  public headerDisplayedSource = new Subject<boolean>();

  public sidebarDisplayed$ = this.sidebarDisplayedSource.asObservable();
  public headerDisplayed$ = this.headerDisplayedSource.asObservable();

  /**
   * Open or close the sidebar.
   * @param open true for open, false for close.
   */
  public openSidebar(open: boolean): void {
    this.sidebarDisplayedSource.next(open);
  }

  /**
   * Open or close the header.
   * @param open true for open, false for close.
   */
  public openHeader(open: boolean): void {
    this.headerDisplayedSource.next(open);
  }
}
