import { Injectable } from '@angular/core';
import { GlobalsService } from '@services/global.service';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { HttpResponse } from '@angular/common/http';
import { StockService as SDKStockService } from '@modules/SDKs/sysnetApi/api/stock.service';
import { GroupQuery, GroupSummaryQuery, StockBulkUpdateInner, StockBulkUpdateView, StockCloneInsert, StockInsert, StockUpdate, StockView } from '@modules/SDKs/sysnetApi';
import { TemplateStockCreationRequest } from '@modules/SDKs/sysnetApi/model/templateStockCreationRequest';
import { TemplateStockCreationResponse } from '@modules/SDKs/sysnetApi/model/templateStockCreationResponse';
import { ServicesWrappingSysnetSDK } from './ServicesWrappingSysnetSDK';

@Injectable({
    providedIn: 'root'
})
export class StockService extends ServicesWrappingSysnetSDK {
    public selectedInvCodeFinal: number;
    private orgId: string;

    constructor(private sdkStockService: SDKStockService, private globals: GlobalsService) {
        super([sdkStockService], globals);

        this.globals.$state.subscribe((newState) => {
            if (newState) { this.orgId = newState.selectedOrganisation?._id; }
        });
    }

    // store selected inv code for use in sales stock and stock in location
    public setSelectedInventoryCode(selectedInvCode: number): void {
        this.selectedInvCodeFinal = selectedInvCode;
    }

    // Get Stock
    public async getStock(stockId: number, view?: string): Promise<HttpResponse<StockView>> {
        return this.sdkStockService.stockIdGet(stockId, AppConfiguration.rsh.getStrVal('rid'), view, 'response').toPromise();
    }

    // Search Stock
    public async searchStock(group?: GroupQuery, groupSummary?: GroupSummaryQuery, totalSummary?: GroupSummaryQuery,
                             sort?: string, limit?: number, offset?: number, view?: string, search?: object, searchDistinct?: string): Promise<HttpResponse<StockView[]>> {
        return this.sdkStockService.stockGet(AppConfiguration.rsh.getStrVal('rid'), view,
            this.stringify(group), this.stringify(groupSummary), this.stringify(totalSummary), searchDistinct, sort, limit, offset,
            this.stringify(search), null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,
            null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,
            null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,
            null, null, null, null, null, null, null, null, null, null, null, null, null, null, this.orgId, null, 'response').toPromise();
    }

    // Insert Stock
    public async insertStock(stock: StockInsert): Promise<HttpResponse<StockView>> {
        return this.sdkStockService.stockPost(stock, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Update Stock
    public async updateStock(stockId: number, stock: StockUpdate): Promise<HttpResponse<StockView[]>> {
        return this.sdkStockService.stockIdPut(stockId, stock, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Delete Stock
    public async deleteStock(stockId: number): Promise<HttpResponse<StockView>> {
        return this.sdkStockService.stockIdDelete(stockId, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Clone stock
    public async cloneStock(stockId: number, stock: StockCloneInsert): Promise<HttpResponse<StockView[]>> {
        return this.sdkStockService.stockCloneIdPut(stockId, stock, 'response').toPromise();
    }

    // Bulk Update Stock
    public bulkUpdateStock(stockList: StockBulkUpdateInner[]): Promise<HttpResponse<StockBulkUpdateView[]>> {
        return this.sdkStockService.stockBulkUpdatePut(stockList, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Get From Template
    public async getFromTemplate(name: string, stockCreationTemplateId: number): Promise<HttpResponse<TemplateStockCreationResponse[]>> {
        return this.sdkStockService.stockFromTemplateGet(name, stockCreationTemplateId, 'response').toPromise();
    }

    // Insert From Template
    public async insertFromTemplate(body: TemplateStockCreationRequest): Promise<HttpResponse<TemplateStockCreationResponse>> {
        return this.sdkStockService.stockFromTemplatePost(body, 'response').toPromise();
    }

}
