/**
 * Portal API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { OrganisationsGetRolesResponse } from '../model/organisationsGetRolesResponse';
import { OrganisationsGetUserResponse } from '../model/organisationsGetUserResponse';
import { ResendInvitePostBody } from '../model/resendInvitePostBody';
import { UserAccessPostBody } from '../model/userAccessPostBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OrganisationsService {

    protected basePath = 'https://sandbox-portal.hlcloud.com.au';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * This route allows user to resend invitation link.
     * @param body Invitation data to be resend.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organisationsResendInvitePost(body: ResendInvitePostBody, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public organisationsResendInvitePost(body: ResendInvitePostBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public organisationsResendInvitePost(body: ResendInvitePostBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public organisationsResendInvitePost(body: ResendInvitePostBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organisationsResendInvitePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (BearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/organisations/resendInvite`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Provides a list of roles available to be assigned at an organisation.
     * @param organisationId Unique organisation id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organisationsRolesGet(organisationId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OrganisationsGetRolesResponse>>;
    public organisationsRolesGet(organisationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganisationsGetRolesResponse>>>;
    public organisationsRolesGet(organisationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganisationsGetRolesResponse>>>;
    public organisationsRolesGet(organisationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling organisationsRolesGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (organisationId !== undefined && organisationId !== null) {
            queryParameters = queryParameters.set('organisationId', <any>organisationId);
        }

        let headers = this.defaultHeaders;

        // authentication (BearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OrganisationsGetRolesResponse>>('get',`${this.basePath}/organisations/roles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * This route handles removing access for a user for an organisation.
     * @param organisationId 
     * @param email 
     * @param venueId 
     * @param roleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organisationsUserAccessDelete(organisationId: string, email: string, venueId: string, roleId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public organisationsUserAccessDelete(organisationId: string, email: string, venueId: string, roleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public organisationsUserAccessDelete(organisationId: string, email: string, venueId: string, roleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public organisationsUserAccessDelete(organisationId: string, email: string, venueId: string, roleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling organisationsUserAccessDelete.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling organisationsUserAccessDelete.');
        }

        if (venueId === null || venueId === undefined) {
            throw new Error('Required parameter venueId was null or undefined when calling organisationsUserAccessDelete.');
        }

        if (roleId === null || roleId === undefined) {
            throw new Error('Required parameter roleId was null or undefined when calling organisationsUserAccessDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (organisationId !== undefined && organisationId !== null) {
            queryParameters = queryParameters.set('organisationId', <any>organisationId);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (venueId !== undefined && venueId !== null) {
            queryParameters = queryParameters.set('venueId', <any>venueId);
        }
        if (roleId !== undefined && roleId !== null) {
            queryParameters = queryParameters.set('roleId', <any>roleId);
        }

        let headers = this.defaultHeaders;

        // authentication (BearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/organisations/userAccess`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * This route allows you to add access to a user for an organisation.
     * @param body Add access to user for an organisation.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organisationsUserAccessPost(body: UserAccessPostBody, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public organisationsUserAccessPost(body: UserAccessPostBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public organisationsUserAccessPost(body: UserAccessPostBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public organisationsUserAccessPost(body: UserAccessPostBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organisationsUserAccessPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (BearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/organisations/userAccess`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * This route handles the user acceptance of an invite to use an organisations applications on H&amp;L. It also handles workflow to setup a new user.
     * @param secret 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organisationsUserInviteAcceptGet(secret: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public organisationsUserInviteAcceptGet(secret: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public organisationsUserInviteAcceptGet(secret: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public organisationsUserInviteAcceptGet(secret: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (secret === null || secret === undefined) {
            throw new Error('Required parameter secret was null or undefined when calling organisationsUserInviteAcceptGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (secret !== undefined && secret !== null) {
            queryParameters = queryParameters.set('secret', <any>secret);
        }

        let headers = this.defaultHeaders;

        // authentication (BearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/organisations/userInviteAccept`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * This route allows a user to remove their access to an organisation. The route also allows an administrator to remove a user from an organisation by removing their invite.
     * @param organisationId Allows an api user to delete invite by specific organisationId.
     * @param userId Removes a user from an organisation by deleting the invite.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organisationsUserInviteDelete(organisationId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public organisationsUserInviteDelete(organisationId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public organisationsUserInviteDelete(organisationId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public organisationsUserInviteDelete(organisationId: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling organisationsUserInviteDelete.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling organisationsUserInviteDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (organisationId !== undefined && organisationId !== null) {
            queryParameters = queryParameters.set('organisationId', <any>organisationId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (BearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/organisations/userInvite`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Provides a list of users and their access for an organisation.
     * @param organisationId Unique organisation id.
     * @param paging Paging Query
     * @param sortBy This parameter is used to change the sorting of results returned.
     * @param venueId Unique venue id.
     * @param showPending By default it is set to false, if set to true only shows pending users.
     * @param email Only searches for the whole email address provided.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organisationsUsersGet(organisationId: string, paging?: string, sortBy?: string, venueId?: string, showPending?: boolean, email?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OrganisationsGetUserResponse>>;
    public organisationsUsersGet(organisationId: string, paging?: string, sortBy?: string, venueId?: string, showPending?: boolean, email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganisationsGetUserResponse>>>;
    public organisationsUsersGet(organisationId: string, paging?: string, sortBy?: string, venueId?: string, showPending?: boolean, email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganisationsGetUserResponse>>>;
    public organisationsUsersGet(organisationId: string, paging?: string, sortBy?: string, venueId?: string, showPending?: boolean, email?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling organisationsUsersGet.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (paging !== undefined && paging !== null) {
            queryParameters = queryParameters.set('paging', <any>paging);
        }
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = queryParameters.set('sortBy', <any>sortBy);
        }
        if (organisationId !== undefined && organisationId !== null) {
            queryParameters = queryParameters.set('organisationId', <any>organisationId);
        }
        if (venueId !== undefined && venueId !== null) {
            queryParameters = queryParameters.set('venueId', <any>venueId);
        }
        if (showPending !== undefined && showPending !== null) {
            queryParameters = queryParameters.set('showPending', <any>showPending);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // authentication (BearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OrganisationsGetUserResponse>>('get',`${this.basePath}/organisations/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
