/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

/**
 * Dietary Requirements including Allergerns, Nutritional or General Dietary information that can be linked to Stock (Inventory) items.  Examples of Allergens: Contains Nuts, Contains Fish, Gluten Free  Examples of Nutritional: High in fibre, Low in Sugar  Examples of General Dietary: Vegetarian, Vegan, Paleo
 */
export interface DietaryInformationView { 
    /**
     * The unique identifier of this record.
     */
    dietaryId?: number;
    description?: string;
    imageId?: number;
    organisationId?: string;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    isAllergen?: boolean;
}