/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { LocationIdFKObject } from './locationIdFKObject';
import { StockAuditTrailView } from './stockAuditTrailView';
import { StockIdFKObject } from './stockIdFKObject';
import { TransactionTypeFKObject } from './transactionTypeFKObject';
import { UpdateDateTime } from './updateDateTime';
import { VenueIdFKObject } from './venueIdFKObject';

export interface StockAuditTrailFKExternalKeysView extends StockAuditTrailView { 
    stockIdFKObject?: StockIdFKObject;
    transactionTypeFKObject?: TransactionTypeFKObject;
    locationIdFKObject?: LocationIdFKObject;
    venueIdFKObject?: VenueIdFKObject;
}