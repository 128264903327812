import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GlobalsService } from '@services/global.service';
import { Properties as dxPopupOptions } from 'devextreme/ui/popup';
import { IStandardDataGridConfig, GenericDataGridComponent, IDataGridActionColumn, IDataSourceConfig, IJoinTableColumnConfig } from '../generic-data-grid/generic-data-grid.component';

@Component({
  selector: 'app-generic-pop-up-with-data-grid',
  templateUrl: './generic-pop-up-with-data-grid.component.html',
  styleUrls: ['./generic-pop-up-with-data-grid.component.scss']
})
export class GenericPopUpWithDataGridComponent implements OnInit {
  public fullScreen = false;
  @Input() public popupConfig: dxPopupOptions = {};
  @Input() public genericDataSourceConfig: IDataSourceConfig;
  @Input() public genericDataGridConfig: IStandardDataGridConfig = {};
  @Input() public genericJoinTableColumnConfig: IJoinTableColumnConfig = {};
  @Input() public genericDataGridActionColumn: IDataGridActionColumn = { enabled: false };

  @ViewChild('genericDataGrid', { static: false }) public genericDataGrid: GenericDataGridComponent;
  @Output() public readonly popupClosed = new EventEmitter<CloseEvent>();
  constructor(
    public globalsService: GlobalsService
  ) { }
  public ngOnInit(): void {
    this.popupConfig.onShowing = (e) => {
      this.genericDataGrid?.refresh(); // reload data grid
    };
    this.popupConfig.onHiding = (e) => {
      if (!this.genericDataGridConfig.hasEditMode) {
        this.genericDataGrid?.cancelEditData();
      }
      this.genericDataGrid?.hideColumnChooser();
      this.popupClosed.emit();
    };
  }

}
