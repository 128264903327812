import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from '@services/utility.service';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventsService } from '@services/events.service';
import { Auth0Service } from './auth0.service';

@Component({
  selector: 'app-auth0',
  templateUrl: './auth0.component.html',
  styleUrls: ['./auth0.component.scss']
})

export class Auth0Component implements OnInit {
  private static readonly loadId = 'lock-loader';
  public logoutReportSrc = '';
  public logoutUTCReportSrc = '';
  public stopTimeout = false;

  constructor(
    @Inject(Auth0Service) private auth,
    private spin2win: NgxSpinnerService,
    private router: ActivatedRoute,
    public utils: UtilityService
  ) {
    // nothing to do here
  }

  public ngOnInit(): void {
    this.spin2win.show(Auth0Component.loadId);
    this.logoutKibana();
    setTimeout(() => {
      if (!this.stopTimeout) {
        // DANNY: If Kibana doesn't respond in 5 seconds it must be down, so show the login, we can go on without reports functioning.
        this.stopTimeout = true;
        this.init();
      }
    }, 5000);
  }

  public init(): void {
    if (this.router.snapshot?.queryParams?.message) {
      this.utils.openSnackbar(this.router.snapshot?.queryParams?.message, 'OK', 10000, {
        horizontalPosition: 'end',
        verticalPosition: 'top'
      });
    }
    this.spin2win.hide(Auth0Component.loadId);
    this.auth.login();
  }

  public logoutKibana(): void {
    this.logoutReportSrc = AppConfiguration.GetConfiguration('KIBANA_CONFIG').host + '/logout';
    this.logoutUTCReportSrc = AppConfiguration.GetConfiguration('KIBANA_CONFIG').hostUTC + '/logout';
  }

}
