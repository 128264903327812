import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import logger from '@hal.common.ui/utilities/Logger';
import AppConfig from '../../hal.common.ui/utilities/AppConfig';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import UiAppConfig from './AppConfigurationOptions';
import 'hammerjs';
AppConfig.LoadConfiguration(UiAppConfig.AppConfigurationOptions, environment); // Configure the application.

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((err) => {
    logger.error(err);
  }
);
