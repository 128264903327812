import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FactorDetailsView } from '@modules/SDKs/sysnetApi';
import { StockCreationTemplateFactorInsert } from '@modules/SDKs/sysnetApi/model/stockCreationTemplateFactorInsert';
import { StockCreationTemplateFactorView } from '@modules/SDKs/sysnetApi/model/stockCreationTemplateFactorView';
import { TemplateStockCreationResponse } from '@modules/SDKs/sysnetApi/model/templateStockCreationResponse';
import { GlobalsService } from '@services/global.service';
import { UtilityService } from '@services/utility.service';
import { DxDataGridComponent } from 'devextreme-angular';
import dxDataGrid, { Column, Row, SavingEvent, ToolbarPreparingEvent } from 'devextreme/ui/data_grid';
import { CustomDialogOptions } from 'devextreme/ui/dialog';
import _ from 'lodash';
import { IExtendedRow } from 'src/app/types/interfaces/GeneralService';
import { IStockCreationTemplateFactor } from 'src/app/types/interfaces/StockService';
import { StockFromTemplateData } from 'src/app/types/StockTypes';
@Component({
  selector: 'app-add-stock-from-template-data-grid',
  templateUrl: './add-stock-from-template-data-grid.component.html',
  styleUrls: ['./add-stock-from-template-data-grid.component.scss']
})
export class AddStockFromTemplateDataGridComponent implements OnInit, OnChanges {

  public overrideData = [];
  public removedRows: number[] = [];
  public updatedRows: IStockCreationTemplateFactor[]= [];
  @Input() public data: StockFromTemplateData;
  @Input() public dataGridConfig;
  @Input() public isFactor = false;
  @Input() public isProduct = false;
  @Input() public associatedItemsToRemove: (number|string)[] | number = -1;
  @Output() public readonly factorRemoved = new EventEmitter<boolean>();
  @ViewChild('dataGridComponent', { static: false }) public dataGridComponent: DxDataGridComponent;
  constructor(public globalsService: GlobalsService,
              private utils: UtilityService) {}

  public ngOnInit(): void {
    this.setupGrid();
    this.resetGridData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.associatedItemsToRemove) {
      this.overrideData = _.cloneDeep(this.data);
      const associatedItems = this.associatedItemsToRemove !== -1 ? this.associatedItemsToRemove : [];
      const rowsToRemove = [...this.removedRows, ...associatedItems as (string|number)[]];
      this.overrideData = this.overrideData.filter((row) => !rowsToRemove.includes(Number(row.templateFactorId)));

      if (this.updatedRows.length > 0) {
        this.overrideData = this.overrideData.map((row) => {
          const updatedRow = this.updatedRows.find((uRow) => uRow.stockCreationTemplateFactorId === Number(row.templateFactorId));
          if (updatedRow) {
            row.shortName = updatedRow.shortDescription;
            row.fullName = updatedRow.longDescription;
          }
          return row;
        });
      }
    }
  }

  public setupGrid(): void {
    this.dataGridConfig.filterRow = {
      visible: true
    };
    this.dataGridConfig.columnChooser = {
      enabled: true,
      mode: 'select'
    };

    this.dataGridConfig.onToolbarPreparing = (e: ToolbarPreparingEvent) => {
      e.toolbarOptions.visible = true;
      const toolbarItems = e.toolbarOptions.items;
      toolbarItems.push({
        locateInMenu: 'auto',
        location: 'after',
        showText: 'always',
        widget: 'dxButton',
        cssClass: 'reset-grid-btn',
        options: {
          text: 'Reset Grid',
          hint: 'Reset Grid',
          icon: 'undo',
          onClick: () => { this.clearStates(); }
        }
      });
      toolbarItems.push({
        name: 'clearAllButton',
        locateInMenu: 'auto',
        location: 'after',
        showText: 'inMenu',
        widget: 'dxButton',
        options: {
          icon: 'close',
          text: 'Clear All',
          hint: 'Clear All',
          onClick: () => { this.resetGridData(); }
        }
      });
    };

    this.dataGridConfig.onEditorPreparing = (e) => {
      if (e.parentType === 'dataRow') {
        e.editorOptions.onValueChanged = (args) => {
          e.setValue(args?.value);
        };
      }
    };

    this.dataGridConfig.onRowValidating = (e) => {
      const rowIndex = this.dataGridComponent.instance.getRowIndexByKey(e.key);
      const rows: IExtendedRow[] = this.dataGridComponent.instance.getVisibleRows();
      rows[rowIndex].isValid = e.isValid;
      if (this.dataGridConfig.editing.allowAdding) {
        const editingRows = this.dataGridComponent.instance.getVisibleRows().filter((row: Row & { modified: boolean }) => row.isNewRow || row.modified);
        if (rowIndex === 0 && editingRows.length > 1) {
          // ignore entry row
          e.isValid = true;
          return;
        }
      }
      if (e.isValid) { return; }
      if (e.brokenRules.length === 0) { return; }

      const visibleColumnIndex = (e.brokenRules[0]).column.visibleIndex;
      const columnIndex = rows[rowIndex].cells.findIndex((cell) => cell.column.visibleIndex === visibleColumnIndex);
      setTimeout(() => {
        this.navigateToCell(rowIndex, columnIndex);
      }, 0);
    };

    this.dataGridConfig.onSaving = (e: SavingEvent) => {
      if (e.changes[0].type === 'remove') {
        this.removedRows.push(Number(e.changes[0].key));
        if (this.isFactor) {
          this.factorRemoved.emit(true);
        }
        if (this.updatedRows.length > 0) {
          this.updatedRows = this.updatedRows.filter((row) => row.stockCreationTemplateFactorId !== Number(e.changes[0].key));
        }
      }

      if (e.changes[0].type === 'update') {
        const selectedRow = this.overrideData.find((row) => row.templateFactorId === e.changes[0].key);
        const updateRow = this.updatedRows.find((row) => row.stockCreationTemplateFactorId === Number(e.changes[0].key));
        const shortDescription = e.changes[0].data.shortName ? e.changes[0].data.shortName : selectedRow.shortName;
        const longDescription = e.changes[0].data.fullName ? e.changes[0].data.fullName : selectedRow.fullName;
        if (updateRow) {
          updateRow.shortDescription = shortDescription;
          updateRow.longDescription = longDescription;
        } else {
          this.updatedRows.push({
            stockCreationTemplateFactorId: Number(e.changes[0].key),
            shortDescription,
            longDescription
          });
        }
      }
    };
  }

  public navigateToCell(rowIndex: number, columnIndex: number, editState = true): void {
    if (!this.dataGridConfig.editing.allowUpdating) { return; }
    this.dataGridComponent.instance.editCell(rowIndex, columnIndex);
    const cellElement = this.dataGridComponent.instance.getCellElement(rowIndex, columnIndex);
    this.dataGridComponent.instance.focus(cellElement);
    if (!editState) {
      this.dataGridComponent.instance.closeEditCell();
    }
  }

  public clearStates(): void {
    this.dataGridComponent?.instance.clearFilter();
    this.dataGridComponent?.instance.cancelEditData();
    this.dataGridComponent?.instance.repaint();
    this.dataGridComponent?.instance.state(null);
    this.dataGridComponent?.instance.clearSelection();
    this.dataGridComponent?.instance.refresh();
  }

  public resetGridData(): void {
    this.overrideData = _.cloneDeep(this.data);
    if (this.associatedItemsToRemove !== -1) {
      this.overrideData = this.overrideData.filter((row) => !(this.associatedItemsToRemove as (number|string)[]).includes(Number(row.templateFactorId)));
    }
    this.removedRows = [];
    this.updatedRows = [];
    if (this.isProduct) {
      this.overrideData.forEach((row) => {
        this.updatedRows.push({
          stockCreationTemplateFactorId: Number(row.templateFactorId),
          shortDescription: row.shortName,
          longDescription: row.fullName
        });
      });
    }
    this.factorRemoved.emit(true);
  }

  public onCancelClick(): boolean {
    this.dataGridComponent?.instance.cancelEditData();
    return false;
  }

  public onSaveItem(): void {
    this.dataGridComponent?.instance.saveEditData();
  }

  public onEditItem(cellInfo): void {
    const firstEditableDataField = this.dataGridComponent?.instance.getVisibleColumns().filter((column: Column) => column.allowEditing)[0]?.dataField;
    this.dataGridComponent?.instance.editRow(cellInfo.rowIndex);
    const cellElement = this.dataGridComponent.instance.getCellElement(cellInfo.rowIndex, firstEditableDataField);
    this.dataGridComponent.instance.focus(cellElement);
  }

  public deleteItem(cellInfo): void {
    let factorName;
    if (this.isFactor) {
      factorName = cellInfo.data.name;
    }
    const customDialogConfigs: CustomDialogOptions = {
      showTitle: false,
      messageHtml: this.isFactor ?
        `This will prevent any ${factorName} products from being created. Are you sure you want to continue?` : 'Are you sure you want to delete this item?',
      buttons: [
        { text: 'Yes', onClick: () => true, stylingMode: 'contained', type: 'default', elementAttr: { class: 'pop-up' } },
        { text: 'No', onClick: () => false, stylingMode: 'outlined', type: 'default', elementAttr: { class: 'pop-up' } }
      ]
    };
    this.utils.openDxCustomDialog(customDialogConfigs)
      .then((dialogResult) => {
        if (dialogResult) {
          this.dataGridComponent?.instance.deleteRow(cellInfo.rowIndex);
        }
      });
  }
}
