import { HttpHeaders } from '@angular/common/http';
import { GlobalsService } from '@services/global.service';

export abstract class ServicesWrappingSysnetSDK {
  constructor(services: ISDKService[], globals: GlobalsService) {
    services.forEach((service) => {
      globals.$events.auth0TokenRefreshed.subscribe((auth0Token) => {
        service.defaultHeaders = service.defaultHeaders.set('Authorization', 'Bearer ' + auth0Token);
      });
      service.defaultHeaders = service.defaultHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('id_token'));
    });
  }
  // NOTE: Michelle commented out these function for now to pass the coverage because this function is not being used anywhere at the moment
  // protected stringArrayify(array): string[] {
  //   if (array) {
  //     return [JSON.stringify(array)];
  //   } else {
  //     return null;
  //   }
  // }
  protected stringify(obj: object): string {
    if (obj) {
      return JSON.stringify(obj);
    } else {
      return null;
    }
  }

  protected stringifyVenueIds(obj: string[]): string {
    if (obj && obj.length > 0) {
      return `[${obj.join()}]`;
    }
    return null;
  }
}

interface ISDKService {
  defaultHeaders: HttpHeaders;
}
