import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import logger from '@hal.common.ui/utilities/Logger';
import { ITextDialogData, TextDialogComponent } from '@components/generic/text-dialog/text-dialog.component';
import { confirm, custom, alert, CustomDialogOptions } from 'devextreme/ui/dialog';

import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { IDialogData, YesNoDialogComponent } from '../components/generic/yes-no-dialog/yes-no-dialog.component';
import { IPDFDocumentTemplate } from '../types/interfaces/GeneralService';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  public static createLimitedInterval(everyMs: number, maxRunnableMs: number, fn: (stop: () => void) => void): void {
    let elapsedMS = 0;
    const interval = setInterval(() => {
      fn(() => {
        clearInterval(interval);
      });
      elapsedMS += everyMs;
      if (elapsedMS > maxRunnableMs) {
        clearInterval(interval);
      }
    }, everyMs);
  }

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private spin2win: NgxSpinnerService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe

  ) {}

  /**
   * Opens a snackbar at the bottom of the screen. Default action label is OK and default duration is 5 seconds.
   * @param message Your message to display on the snackbar.
   * @param action The confirmation button text.
   * @param duration How long to display the snack bar for.
   */
  public openSnackbar(message: string, action = 'OK', duration = 5000, config?: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {
    const snackBarRef = this.snackBar.open(message, action, {
      duration,
      ...config
    });

    return snackBarRef;
  }

  /**
   * Create a form group.
   * @param opts Additional form options.
   */
  public createForm(opts?): UntypedFormGroup {
    const lopts = opts ? opts : {};

    return this.formBuilder.group(lopts);
  }

  /**
   * Opens up a YesNoDialogComponent. Width is constrainted to between 100 and 1000 (px), and defaults to 549px.
   * Returns a reference to the opened dialog, null if failed to open or dialogData was null.
   * @param dialogData title, content, and function handlers for yes and no click.
   * @param width How wide to make the dialog.
   */
  public openYesNoDialog(dialogData: IDialogData, width = 549): MatDialogRef<YesNoDialogComponent> {
    if (!dialogData) { return null; }
    const realWidth = Math.min(Math.max(100, width), 1000).toString() + 'px';

    return this.dialog.open(YesNoDialogComponent, {
      width: realWidth,
      data: dialogData,
      panelClass: 'yes-no-dialog-panel'
    });
  }

  public openTextDialog(dialogData: ITextDialogData, width = 500): MatDialogRef<TextDialogComponent, string> {
    if (!dialogData) { return null; }
    const realWidth = Math.min(Math.max(100, width), 1000).toString() + 'px';

    return this.dialog.open(TextDialogComponent, {
      width: realWidth,
      data: dialogData
    });
  }

  public openSpinner(loadingId?: string): void {
    this.spin2win.show(loadingId);
  }

  public closeSpinner(loadingId?: string): void {
    this.spin2win.hide(loadingId);
  }

  /**
   * @author Danny Csaky <danny.csaky@hlaustralia.com.au>
   * @method log
   * @description
   * I like using the console.log() because looks neater and lets me drill down.
   * This allows me to switch and get past the linting rules. simply by un/commenting between the 2 log methods
   */
  public logInfo(str1: string, str2?: string | number | object): void {
    // console.log(str1, str2);
    logger.info(str1 + JSON.stringify(str2));
  }

  public logError(str1: string, str2?: string | number | object): void {
    // console.error(str1, str2);
    logger.error(str1 + JSON.stringify(str2));
  }

  public escapeRegExp(str: string): string {
    // need to escapte \ _ %
    return str ? str.replace(/[\\_%]/g, '\\$&') : ''; // $& means the whole matched string
  }
  public titleCase(str: string): string {
    return str ? str.replace(/\b[a-z]/g, (t) => t.toUpperCase()) : '';
  }

  public openDxConfirmDialog(messageHtml: string, title: string): Promise<boolean> {
    return confirm(messageHtml, title);
  }

  public openDxAlertDialog(message: string, title: string): Promise<void> {
    return alert(message, title);
  }

  public openDxCustomDialog(configs: CustomDialogOptions): Promise<boolean> {
    return custom(configs).show();
  }

  public formatReportDate(date: Date): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy @ hh:mm a');
  }
  public formatReportCurrency(value: number): string {
    if (isNaN(value)) { return ''; }
    return this.currencyPipe.transform(value);
  }
  public formatReportDecimal(value: number, digitsInfo = '1.2-2'): string {
    if (isNaN(value)) { return ''; }
    return this.decimalPipe.transform(value, digitsInfo);
  }
  public downloadPDFReport(document: IPDFDocumentTemplate, docName: string): void {
    pdfMake.createPdf(document).download(docName + '.pdf');
    // pdfMake.createPdf(document).open();
    // pdfMake.createPdf(document).print();
  }

  public printReport(document: IPDFDocumentTemplate): void {
    pdfMake.createPdf(document).print();
  }

}
