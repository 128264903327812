import { Injectable } from '@angular/core';
import { GlobalsService } from '@services/global.service';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { HttpResponse } from '@angular/common/http';
import { StocktakeGroupsService as SDKStocktakeGroupsService } from '@modules/SDKs/sysnetApi/api/stocktakeGroups.service';
import { GroupQuery, GroupSummaryQuery, StocktakeGroupInsert, StocktakeGroupUpdate, StocktakeGroupView } from '@modules/SDKs/sysnetApi';
import { ServicesWrappingSysnetSDK } from './ServicesWrappingSysnetSDK';

@Injectable({
    providedIn: 'root'
})
export class StocktakeGroupsService extends ServicesWrappingSysnetSDK {
    private orgId: string;
    constructor(private sdkStocktakeGroupsService: SDKStocktakeGroupsService, private globals: GlobalsService) {
        super([sdkStocktakeGroupsService], globals);
        this.globals.$state.subscribe((newState) => {
            if (newState) { this.orgId = newState.selectedOrganisation?._id; }
        });
    }

    // Get StocktakeGroup
    public getStocktakeGroup(stocktakeGroupId: number): Promise<HttpResponse<StocktakeGroupView>> {
        return this.sdkStocktakeGroupsService.stocktakeGroupsIdGet(stocktakeGroupId, AppConfiguration.rsh.getStrVal('rid'), null, 'response').toPromise();
    }

    // Search StocktakeGroups
    public searchStocktakeGroups(
        group?: GroupQuery,
        groupSummary?: GroupSummaryQuery,
        totalSummary?: GroupSummaryQuery,
        sort?: string,
        limit?: number,
        offset?: number,
        view?: string,
        search?: object,
        searchDistinct?: string): Promise<HttpResponse<StocktakeGroupView[]>> {
        return this.sdkStocktakeGroupsService.stocktakeGroupsGet(AppConfiguration.rsh.getStrVal('rid'), view,
            this.stringify(group), this.stringify(groupSummary), this.stringify(totalSummary), searchDistinct, sort, limit, offset, this.stringify(search),
            null, null, null, null, null, null, null, this.orgId, 'response').toPromise();
    }

    // Insert StocktakeGroup
    public insertStocktakeGroup(stocktakeGroup: StocktakeGroupInsert): Promise<HttpResponse<StocktakeGroupView>> {
        return this.sdkStocktakeGroupsService.stocktakeGroupsPost(stocktakeGroup, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Update StocktakeGroup
    public updateStocktakeGroup(stocktakeGroupId: number, stocktakeGroup: StocktakeGroupUpdate): Promise<HttpResponse<StocktakeGroupView[]>> {
        return this.sdkStocktakeGroupsService.stocktakeGroupsIdPut(stocktakeGroupId, stocktakeGroup, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Delete StocktakeGroup
    public deleteStocktakeGroup(stocktakeGroupId: number): Promise<HttpResponse<StocktakeGroupView>> {
        return this.sdkStocktakeGroupsService.stocktakeGroupsIdDelete(stocktakeGroupId, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

}
