import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sysnet-common',
  templateUrl: './sysnet-common.component.html',
  styleUrls: ['./sysnet-common.component.scss']
})
export class SysnetCommonComponent implements OnInit {

  public ngOnInit(): void {
  }

  public selectedItem(popupName: string): void {
    if (this.hasOwnProperty(popupName) && this[popupName].popupConfig) {
      this[popupName].popupConfig.visible = true;
    }
  }

}
