/**
 * Portal API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DataAccessPermissions } from './dataAccessPermissions';
import { UserResponseRoleDataAccessesDataAccessType } from './userResponseRoleDataAccessesDataAccessType';

export interface UserResponseRoleDataAccesses { 
    _id?: string;
    dataAccessType?: UserResponseRoleDataAccessesDataAccessType;
    isRestricted?: boolean;
    name?: string;
    generatesEvents?: boolean;
    eventObjectName?: string;
    dataAccessTypeId?: string;
    dataAccessPermissionIds?: Array<string>;
    dataAccessPermissions?: DataAccessPermissions;
}