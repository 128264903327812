// import IAppAttachments from '../base/interfaces/IAppAttachments';
import { IRequestStorageHandler } from './AppConfig/base/interfaces/IRequestStorageHandler';
import { rsh, RequestStorageHandler } from './AppConfig/helpers/RequestStorageHandler';
import ILogger from './ILogger';
import logger from './Logger';
import requestPromise from '../shim/RequestPromise';

export interface ICryptLib {
    getHashSha256(secretKey: string, bytes: number): string;
    encrypt(textToEncrypt: string, key: string, vector: string): string;
    decrypt(textToEncrypt: string, key: string, vector: string): string;
}

export interface IHeaders {
    [key: string]: any;
}

export interface IRequestPromiseOptions {
    method?: 'POST' | 'PUT' | 'GET' | 'OPTIONS' | 'DELETE' | 'PATCH';
    uri: string;
    headers: IHeaders;
    body?: any;
    json?: boolean;
    resolveWithFullResponse?: boolean;
    auth?: {
        user: string,
        pass: string
    };
}

export interface IRequestPromiseResponse {
    statusCode: string;
    headers: IHeaders;
    body: any;
    error?: string;
}

export class AppConfig {
    private static app: Array<string> = null;
    // public static appAttachments: IAppAttachments = null;
    public requestPromiseHandler: (options: IRequestPromiseOptions) => Promise<IRequestPromiseResponse | any> = null;
    public cryptLib: ICryptLib = null;
    public trustedIPs: Array<string> = [];
    // Request Storage Handler needs to be able persist data for an API call, not needed for UI really
    // this is setup from RequestLogger
    public rsh: IRequestStorageHandler = rsh;

    public logger: ILogger = logger;

    // cloudwatch transport is only useful for api projects, cause it only works on cloudwatch projects
    // this is setup from hal.common.api/utilities/AppConfig
    public externalConfigurations;

    /* GENERAL */
    public readonly DEFAULT_NAMESPACE = 'DEFAULT_NAMESPACE';
    public readonly TIMEOUT = 'TIMEOUT';

    /* HOST CONFIG  */
    public readonly PORTAL_HOST = 'PORTAL_HOST';
    public readonly BOOKBOOK_HOST = 'BOOKBOOK_HOST';
    public readonly CLOUDPORTAL_HOST = 'CLOUDPORTAL_HOST';

    /* KIBANA CONFIG  */
    public readonly KIBANA_CONFIG = 'KIBANA_CONFIG';

    /* REST CONFIG  */
    public readonly HAL_COMMON = 'hal.common';
    public readonly HAL_SYSNET = 'hal.sysnet';

    public readonly AUTH0_APP_CLIENT_ID = 'AUTH0_APP_CLIENT_ID';
    public readonly AUTH0_DOMAIN = 'AUTH0_DOMAIN';

    public readonly LOGIN_URL = 'LOGIN_URL';

    /** level of logs - default 'info' */
    public readonly LOG_LEVEL = 'LOG_LEVEL';
    public readonly REST_API_HOST = 'REST_API_HOST';

    // Sentry Logging
    public readonly SENTRY_DSN = 'SENTRY_DSN';

    // default is false
    public readonly LOG_TO_CONSOLE = 'LOG_TO_CONSOLE'; // default is false
    public readonly LOG_TO_CONSOLE_LEVEL = 'LOG_TO_CONSOLE_LEVEL'; // defaults to LOG_LEVEL

    /** WEB_SOCKET */
    public readonly WEB_SOCKET_HOST = 'WEB_SOCKET_HOST';
    public readonly WEB_SOCKET_PORT = 'WEB_SOCKET_PORT';

    /* FIREBASE */
    public readonly FIREBASE_CONFIG = 'FIREBASE_CONFIG';

    // short link
    public readonly SHORT_LINK_SHORTNER_SERVICE_URI = 'SHORT_LINK_SHORTNER_SERVICE_URI';
    public readonly SHORT_LINK_DOMAIN_NAME = 'SHORT_LINK_DOMAIN_NAME';

    // Ordering api
    public readonly ORDERING_API_URI = 'ORDERING_API_URI';
    // Booking api
    public readonly BOOKBOOK_API_URI = 'BOOKBOOK_API_URI';
    // Sysnet api
    public readonly SYSNET_API_URI = 'SYSNET_API_URI';

    constructor() {
        if (AppConfig.app == null) {
            AppConfig.app = new Array<string>();
        }
    }

    private onChangeFns = new Array<() => void>();
    private onStopFns = new Array<() => Promise<void>>();

    public onChange(fn): {unsubscribe: () => void} {
        this.onChangeFns.push(fn);
        return({unsubscribe: () => {
            const index = this.onChangeFns.indexOf(fn);
            if (index > -1) {
                this.onChangeFns.splice(index, 1);
            }
        }});
    }

    public GetChildConfiguration(key: string): AppConfig {
        const childAppConfig = new AppConfig();
        childAppConfig.LoadValues(childAppConfig, AppConfig.app[key]);
        childAppConfig.GetConfiguration = childAppConfig.GetChildConfig;
        return childAppConfig;
    }

    private tellListeners_onChange() {
        for (const fn of this.onChangeFns) {
            fn();
        }
    }
    public onStop(fn: () => Promise<void>): {unsubscribe: () => void} {
        this.onStopFns.push(fn);
        return({unsubscribe: () => {
            const index = this.onStopFns.indexOf(fn);
            if (index > -1) {
                this.onStopFns.splice(index, 1);
            }
        }});
    }
    private tellListeners_onStop(): Promise<void> {
        const promise = new Promise<void>((resolve, reject) => {
            const promiseAll = [];
            for (const fn of this.onStopFns) {
                promiseAll.push(new Promise<void>((resolve2, reject2) => {
                    const p = fn();
                    if (p && p.then) {
                        p.then(() => {
                            resolve2();
                        }).catch((err) => {
                            this.logger.warn(err);
                            resolve2();
                        });
                    } else {
                        resolve2();
                    }
                }));
            }
            Promise.all(promiseAll).then(() => {
                this.onStopFns = new Array<() => Promise<void>>();
                resolve();
            });
        });
        return promise;
    }
    public stop(): Promise<void> {
        const promise = new Promise<void>( async (resolve, reject) => {
            try {
                await this.tellListeners_onStop();
                resolve();
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    // public LoadAttachments(appAttachments: IAppAttachments) {
    //     AppConfig.appAttachments = appAttachments;
    //     const appDispatcher = appAttachments.getAppDispatcher();
    //     if (appDispatcher) {
    //         appDispatcher.attachSocketEvents();
    //     }
    // }

    private LoadValues(obj: any, configValues: any) {
      if (!configValues) {
        return;
      }

      Object.keys(configValues).forEach((key) => {
        obj[key] = configValues[key];
      });
    }

    public getEnvironmentName(environment: any = null): string {
        const environmentNameDefault = 'dev';
        let environmentName: string;
        if (environment !== null) {
            // UI
            environmentName = (environment && environment.name) ? environment.name : environmentNameDefault;
        }
        return (environmentName);
    }
    /**
     * Load the configuration options for the environment set in environment.name
     * @param appConfigurationOptions - the AppConfigurationOptions for your project (keyed by environment, then config key)
     * @param environment - Object (use the name field to specify the environment you want to load out of the AppConfiguraitonOptions,
     *                      Allows other parameters to be set against he object. These are not used.
     */
    public LoadConfiguration(appConfigurationOptions: any, environment: { name?: string } = null) {
        const environmentName = this.getEnvironmentName(environment);
        let configValues;
        // if (environmentName !== 'unit_tests' && this.logger) {
        //     // this.logger.info('environmentName: ' + environmentName);
        // }
        if (this.externalConfigurations) {
            configValues =  this.externalConfigurations;
        } else {
            configValues = appConfigurationOptions[environmentName];
        }
        this.LoadValues(AppConfig, configValues);
        AppConfig.app = configValues;

        // SHARED OPTIONS THAT ARE THE SAME REGARDLESS OF ENVIRONMENT BUT ARE NOT CONSTANTS.
        const sharedConfiguration = appConfigurationOptions[`shared_configuration`];
        if (sharedConfiguration) {
          this.LoadValues(AppConfig, sharedConfiguration);
        }
        this.tellListeners_onChange();
    }

    public GetConfiguration(key: string): any {
        return AppConfig[key];
    }
    public SetConfiguration(key: string, value: any): any {
        if (key === null || key === undefined) {
          throw new Error('Key is not defined');
        }
        AppConfig[key] = value;
    }

    public GetChildConfig(key: string): any {
        return this[key];
    }
}

// tslint:disable-next-line:variable-name
const AppConfiguration = new AppConfig();

AppConfiguration.onChange(() => {
    logger.configureLogging(AppConfiguration);
});

(() => {
    // LOAD EXTERNAL CONFIG JSON FILE
    let configRAW;
    const path = './config.json';
    try {
        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState === 4 && this.status === 200) {
                console.log('EXTERNAL CONFIG: ', this.response);
            }
        };
        xhttp.open('GET', path, false);
        xhttp.send();
        configRAW = xhttp.response;
        AppConfiguration.externalConfigurations = JSON.parse(configRAW);
    } catch (err) {
        console.log('External Config Not Loaded.');
    }
})();

AppConfiguration.requestPromiseHandler = requestPromise;

export default AppConfiguration;
