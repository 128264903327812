/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

/**
 * This table is used to determine subsitute products for items within a package.
 */
export interface PackageItemSubstituteView { 
    /**
     * The unique identifier of this record.
     */
    packageItemSubstituteId?: number;
    /**
     * The id of packageItem that we are providing a substitute for.
     */
    packageItemId?: number;
    /**
     * The productId of the product that we are allowing to substitute.
     */
    productId?: number;
    /**
     * The quantity of the substitute item that will be received when substituting a package item.
     */
    quantity?: number;
    /**
     * This number will adjust the package price up or down by the amount recorded here.
     */
    priceAdjustmentAmount?: number;
    /**
     * When ranging the parent package product this will create this packageItem substite product at the location being ranged to.
     */
    alwaysRangeToLocation?: boolean;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
}