import { Injectable } from '@angular/core';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsersService as PortalAPIUsersService, UserResponse as User } from '@modules/SDKs/portalApi/index';
import { Organisation } from '@modules/SDKs/portalApi/model/organisation';
import { OrganisationsService } from '@modules/SDKs/sysnetApi';
import Navigation, { SideNavTabs } from '@components/dashboard/sidebar/Navigation';
import { IEncryptedInviteData, IInviteResponse } from '../types/interfaces/UserModel';
import { ServicesWrappingSysnetSDK } from './sysnet/ServicesWrappingSysnetSDK';
import { GlobalsService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends ServicesWrappingSysnetSDK {

  private currentUser: User;

  constructor(private http: HttpClient, private globals: GlobalsService) {
    super([], globals);
  }

  public decodeValidateInviteDetails(inviteKey: string): Promise<IInviteResponse> {
    const url = this.getBaseUrl() + '/users/appOnboarding/decodeValidateInviteDetails';
    const getParams = { inviteKey: encodeURIComponent(inviteKey) };

    return new Promise((resolve, reject) => {
      this.http.get(url, { params: getParams }).toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

  }
  public setupAuth0(encryptedInviteData: IEncryptedInviteData | string): Promise<object> {
    const url = this.getBaseUrl() + '/users/appOnboarding/inviteSetupAuth0';

    return new Promise((resolve, reject) => {
      this.http.post(url, encryptedInviteData).toPromise()
        .then((user) => {
          resolve(user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get the user that is currently logged in from the API. We do this by grabbing their email from localStorage. Returns null if no user is set,
   * and undefined if an occured.
   */
  public async getLoggedInUser(): Promise<User> {

    const profile = JSON.parse(localStorage.getItem('profile'));
    if (!profile || !profile.email) {
      return null;
    }

    if (!this.currentUser || (profile.email !== this.currentUser?.email)) {
      const _state = this.globals.$state?.getValue();
      if (_state?.user) {
        this.currentUser = _state.user;
      } else {
        this.currentUser = await this.getUser(profile.email);
      }
    }

    return this.currentUser;
  }

  /**
   * The user with this email.
   * @param email the email of the user to fetch from the API.
   */
  public async getUser(email: string): Promise<User> {
    const sdkUsersService: PortalAPIUsersService = new PortalAPIUsersService(this.http, this.globals.getPortalBaseUrl(), this.globals.getPortalConfiguration());

    return sdkUsersService.usersEmailGet(email).toPromise();
  }

  public async getHelp(): Promise<string> {
    const url = Navigation.supportOptions.find((item) => item.id === SideNavTabs.HELP_CENTRE).routeUrl;
    return Promise.resolve(url);
  }

  public async getUserOrganisations(): Promise<Organisation[]> {

    try {
      const user = await this.getLoggedInUser();
      let organisations = user.access.map((currAccess) => currAccess?.location?.organisation);
      organisations = organisations.filter((value, index, self) => value && self.indexOf(value) === index);
      return organisations;
    } catch (err) {
      return [];
    }
  }
  public async getUserOrganisationsForApp(appCode: string): Promise<Organisation[]> {

    try {
      const user = await this.getLoggedInUser();
      const appAccess = user.access.filter((currAccess) =>
        currAccess.role.application.code === appCode && currAccess?.location?.organisation);
      let organisations = appAccess.map((access) => access.location.organisation);
      organisations = organisations.filter((org, index, self) => {

        for (let i = 0; i < index; i++) {
          if (self[i]._id === org._id) {
              return false;
            }
        }
        return true;
      });
      return organisations;

    } catch (err) {
      return [];
    }

  }

  private getBaseUrl(): string {
    return AppConfiguration.GetChildConfiguration('hal.common').REST_API_HOST;
  }
}
