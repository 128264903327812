import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { DEFAULT_STOCK_MOBILE_ROUTE } from 'src/app/Constants';
import { StockMobileService } from '@services/sysnet/stock-mobile.service';

@Component({
  selector: 'app-stock-mobile-common-header',
  templateUrl: './stock-mobile-common-header.component.html',
  styleUrls: ['./stock-mobile-common-header.component.scss']
})
export class StockMobileCommonHeaderComponent implements OnInit {

  @Input() public previousUrl: string[];
  constructor(
    private location: Location,
    private router: Router,
    public stockMobileService: StockMobileService
    ) { }

  public ngOnInit(): void {
  }

  public goBack(): void {
    if (this.previousUrl) {
      this.router.navigate(this.previousUrl);
      return;
    }

    this.location.back();
  }

  public goToHome(): void {
    this.router.navigate(DEFAULT_STOCK_MOBILE_ROUTE);
  }

}
