<body class="login-bg">
  <img src="../../assets/img/Customer-Background-Colour.jpeg" class="bg-img">
  <ngx-spinner
    bdOpacity = 0
    bdColor = "rgba(255,255,255,0)"
    size = "large"
    color="#fff"
    type = "ball-atom"
    [fullScreen] = "true"
    [name]="'lock-loader'">
    <p style="font-size: 20px; color: white" i18n="Loading |@@loading">Loading...</p>
  </ngx-spinner>
  <div id="login-container" class="lock">
  </div>
  <img src="../../assets/img/branding/hl/&.png" class="logo-auth">
  <iframe style="display: none" [src]="logoutReportSrc | bypassSanitizer" id="logout-iframe"></iframe>
  <iframe style="display: none" [src]="logoutUTCReportSrc | bypassSanitizer" id="logout-iframe-utc"></iframe>
</body>