/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { SearchStockAndFactorDetailsViewFactor } from './searchStockAndFactorDetailsViewFactor';
import { SearchStockAndFactorDetailsViewStock } from './searchStockAndFactorDetailsViewStock';
import { UpdateDateTime } from './updateDateTime';

export interface SearchStockAndFactorDetailsView { 
    /**
     * The unique identifier of this record.
     */
    recipeIngredientId?: number;
    /**
     * A reference to the primary key in the Stock Table
     */
    recipeStockId?: number;
    /**
     * A reference to the primary key in the Stock Table
     */
    ingredientStockId?: number;
    /**
     * A reference to the primary key in the Factors Table
     */
    ingredientFactorId?: number;
    factorQty?: number;
    weightRequired?: boolean;
    displayOrder?: number;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    stock?: SearchStockAndFactorDetailsViewStock;
    factor?: SearchStockAndFactorDetailsViewFactor;
}