import { Injectable } from '@angular/core';
import { StockView } from '@modules/SDKs/sysnetApi/model/stockView';

@Injectable()
export class StockMobileService {

  public commonHeaderTitle = '';

  private stockDetails: StockView;

  public setLastSelectedLocation(locationId: number): void {
    localStorage.setItem('stockMobileSelectedLocationId', locationId.toString());
  }

  public getLastSelectedLocation(): number {
    return Number(localStorage.getItem('stockMobileSelectedLocationId'));
  }

  public setLastStockDetails(stockDetails: StockView): void {
    this.stockDetails = stockDetails;
  }

  public getLastStockDetails(): StockView {
    return this.stockDetails;
  }

}
