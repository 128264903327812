<dx-tooltip
  [id]="elementId + 'tooltip'"
  [target]="'#'+elementId"
  [(visible)]="visible"
  [position]="position"
  [hideOnOutsideClick]="false"
>
  <div class="tour-body">
    <div class="tour-title">
      <mat-icon class="tour-icon" i18n="info_outline|@@info_outline">info_outline</mat-icon>
      <ng-content></ng-content>
    </div>
    <div class="tour-action">
      <mat-checkbox class="tour-checkbox" i18n="Never show this again|@@Never_show_this_again" (change)="toggleNeverShow($event.checked)">Never show this again</mat-checkbox>
      <button mat-raised-button color="primary" (click)="confirm()" i18n="OK|@@OK">OK</button>
    </div>
  </div>
</dx-tooltip>
