import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GenericDataGridComponent, IDataGridActionColumn, IDataSourceConfig, IStandardDataGridConfig } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { Properties as dxPopupOptions } from 'devextreme/ui/popup';
import { DxConfig, ValidationFn, GridConstants, POPUP_SHADING_COLOR } from 'src/app/Constants';
import { StockDataGridService, StockTypes } from '@services/grid/stock-data-grid.service';
import { DxLookupComponent, DxPopupComponent, DxPopupModule } from 'devextreme-angular';
import { GlobalsService } from '@services/global.service';
import { Properties as dxLookupOptions } from 'devextreme/ui/lookup';
import { FactorsDataGridService } from '@services/grid/factors-data-grid.service';
import { SupplierInventoryCodeInsert } from '@modules/SDKs/sysnetApi';
import { SupplierInventoryCodesDataGridService } from '@services/grid/supplier-inventory-codes-data-grid.service';
import { RequiredRule } from 'devextreme/ui/validation_rules';
import { UtilityService } from '@services/utility.service';
import { ErrorMessages } from 'src/app/utilities/ErrorMessages';

interface IValidationRules { [key: string]: RequiredRule[]; }
interface IStockInfo { stockId: number; invCode: number; description: string; StockStatus: { markForDeath: boolean }; }
@Component({
  selector: 'app-assign-supplier-inv-code',
  templateUrl: './assign-supplier-inv-code.component.html',
  styleUrls: ['./assign-supplier-inv-code.component.scss']
})
export class AssignSupplierInvCodeComponent implements OnInit {
  public supplierInventoryCodeDetails: SupplierInventoryCodeInsert;
  public loadingVisible = false;
  public dxLookupConfig: dxLookupOptions = DxConfig.lookup;
  public stockListDataSourceConfig: IDataSourceConfig<StockDataGridService> = {
    dataSourceName: 'stockList',
    dataSourcePrimaryKey: 'stockId',
    dataSourceLoadMode: 'processed',
    dataSourceService: this.stockDataGridService,
    dataSourceServiceSearchFnView: 'StockList',
    dataSourceServiceSearchFnArgs: { $and: [{ 'StockStatus.disallowStock': { $is: false } },
                                            { type: { $not: StockTypes['Recipe(SalesBased)'] } }, { type: { $not: StockTypes.Package } }] },
    dataSourceServiceSearchFn: 'searchStock'
  };
  public stockListDataGridConfig: IStandardDataGridConfig = {};
  public popupConfig: dxPopupOptions = {
    title: 'Add Supplier Inventory Code',
    visible: false,
    width: '50vw',
    height: '75vh',
    minHeight: '500px',
    shadingColor: POPUP_SHADING_COLOR
  };

  public stockListDataGridActionColumn: IDataGridActionColumn = {
    enabled: false
  };

  public selectedStockDetails: IStockInfo = {
    stockId: undefined,
    invCode: undefined,
    description: undefined,
    StockStatus: {
      markForDeath: undefined
    }
  };

  public availableFactors = [];
  public validationRules: IValidationRules;
  private formSaved = false;

  @ViewChild('assignSupplierCode', { static: false }) public assignSupplierCode: DxPopupComponent;
  @ViewChild('factorsLookup', { static: false }) public factorsLookup: DxLookupComponent;
  @ViewChild('stockDataGrid', { static: false }) public stockDataGrid: GenericDataGridComponent;
  @Output() public readonly supplierInvCodeSaved = new EventEmitter<boolean>();
  @Input() public supplierId: number;
  @Input() public supplierInvCode: string;
  @Input() public supplierName: string;

  constructor(
    public stockDataGridService: StockDataGridService,
    private factorsDataGridService: FactorsDataGridService, // does this have to be a data grid service?
    private supplierInventoryCodesDataGridService: SupplierInventoryCodesDataGridService,
    public globalsService: GlobalsService,
    private utils: UtilityService
  ) {
    this.onDataGridSetup();
    this.defineValidationRules();
  }

  public ngOnInit(): void {
  }

  public defineValidationRules(): void {
    this.validationRules = {
      factorId: [ValidationFn.getRequiredRule()]
    };
  }

  public onDataGridSetup(): void { // custom config
    this.stockListDataGridConfig.columns = [
      {
        dataField: 'stockId',
        dataType: 'number',
        minWidth: 50,
        width: 80,
        caption: 'Stock ID',
        allowHeaderFiltering: false,
        allowSorting: true,
        visible: true
      },
      {
        dataField: 'inventoryCode',
        dataType: 'number',
        minWidth: 60,
        width: 80,
        caption: 'Inv Code',
        allowHeaderFiltering: false,
        allowSorting: true,
        visible: true
      },
      {
        dataField: 'name',
        dataType: 'string',
        minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
        caption: 'Stock Description',
        allowHeaderFiltering: false,
        allowSorting: true,
        visible: true
      }
    ];
    this.stockListDataGridConfig.remoteOperations = {
      filtering: true,
      paging: true,
      sorting: true,
      summary: true,
      grouping: false,
      groupPaging: false
    };

    this.stockListDataGridConfig.scrolling = {
      columnRenderingMode: 'virtual'
    };

    this.stockListDataGridConfig.selection = {
      mode: 'single'
    };

    this.stockListDataGridConfig.searchPanel = {
      visible: true,
      placeholder: 'Search here',
      searchVisibleColumnsOnly: true
    };

    this.stockListDataGridConfig.pager = {
      visible: true,
      displayMode: 'compact',
      showInfo: true,
      showPageSizeSelector: true,
      allowedPageSizes: GridConstants.ALLOWED_PAGE_SIZES
    };
    this.stockListDataGridConfig.paging = {
      pageSize: 25
    };
    this.stockListDataGridConfig.columnChooser = {
      enabled: false
    };
    this.stockListDataGridConfig.onSelectionChanged = (e) => {
      this.onStockSelected(e);
    };
    this.stockListDataGridConfig.stateStoring = {
      enabled: false
    };
    this.stockListDataGridConfig.filterPanel = {
      visible: false
    };
  }

  public async onStockSelected(e): Promise<void> {
    if (e.selectedRowsData[0]) {
      this.selectedStockDetails.stockId = e.selectedRowsData[0].stockId;
      this.selectedStockDetails.invCode = e.selectedRowsData[0].inventoryCode;
      this.selectedStockDetails.description = e.selectedRowsData[0].name;
      this.selectedStockDetails.StockStatus = e.selectedRowsData[0].StockStatus;
      const res = await this.factorsDataGridService.searchFactorsByStockId({ take: 50 }, 'FactorsList', e.selectedRowsData[0].stockId);
      this.availableFactors = res.data;
      this.factorsLookup.instance.reset();
    }
  }

  public openPopup(): void {
    this.stockDataGrid.refresh();
    this.formSaved = false;
    this.popupConfig.visible = true;
  }

  public displayExprFactors(item): string {
    return item && item.Container?.description;
  }

  public popUpClose(event): void {
    if (event.element.id === 'save') {
      this.save(event);
    } else {
      this.cancelled(event);
    }
  }

  public async save(event): Promise<void> {
    if (!event.validationGroup.validate().isValid) { return null; }
    const isChecksPassed = await this.checkStockStatuses();
    if (!isChecksPassed) { return null; }
    let makeAsDefaultCode = false;
    this.utils.openDxConfirmDialog(`Do you wish to make <span class='custom-pop-up-text-bold'>${this.supplierInvCode}</span>
      the default code for the supplier <span class='custom-pop-up-text-bold'>${this.supplierName}?</span>`, 'Warning')
      .then(async (dialogResult) => {
        makeAsDefaultCode = dialogResult;
        await this.saveSupplierInventoryCode(event, makeAsDefaultCode);
      });
  }

  public async saveSupplierInventoryCode(event, makeAsDefaultCode: boolean): Promise<void> {
    try {
      this.supplierInventoryCodeDetails = {
        supplierId: this.supplierId,
        supplierInventoryCode: this.supplierInvCode,
        stockId: this.selectedStockDetails.stockId,
        factorId: this.factorsLookup.value,
        defaultCode: makeAsDefaultCode
      };
      this.loadingVisible = true;
      const supplierInventoryCodeRes = await this.supplierInventoryCodesDataGridService.
            isDefaultCodeUniqueForTheSupplierAndStock(this.supplierId, this.selectedStockDetails.stockId, undefined);

      await this.supplierInventoryCodesDataGridService.insertSupplierInventoryCode(this.supplierInventoryCodeDetails);
      if (makeAsDefaultCode && supplierInventoryCodeRes && Object.keys(supplierInventoryCodeRes).length !== 0) {
        // Inactivate the default code of existing supplier inv code
        await this.supplierInventoryCodesDataGridService.updateSupplierInventoryCode(supplierInventoryCodeRes.supplierInventoryCodeId, { defaultCode: false });
      }
      this.loadingVisible = false;
      this.supplierInvCodeSaved.emit(true);
      this.formSaved = true;
      this.popupConfig.visible = false;
      event.validationGroup.reset();
    } catch (error) {
      this.loadingVisible = false;
      const instruction = error?.error?.summary
        ? 'Err: ' + (error.error.summary as string)
        : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(
        `${ErrorMessages.STANDARD_SNACK_BAR_MESSAGE} supplier inventory code. ` + instruction,
        'OK',
        10000
      );
    }
  }

  public cancelled(event): void {
    if (!this.formSaved) {
      this.supplierInvCodeSaved.emit(false);
    }
    this.popupConfig.visible = false;
    if (event?.validationGroup) {
      event.validationGroup.reset();
    }
    this.resetGrid();
  }

  public resetGrid(): void {
    this.stockDataGrid.clearStates();
    this.stockDataGrid.clearSelection();
    this.availableFactors = [];
    this.factorsLookup.instance.reset();
  }

  public async checkStockStatuses(): Promise<boolean> {
    let checkPassed = true;
    if (this.selectedStockDetails.StockStatus?.markForDeath) {
      await this.utils.openDxConfirmDialog(`The status of this item includes Mark for Death.
      Using it will increase this item's run down time. Do you really wish to assign to this item?`, 'Warning!')
        .then((dialogResult) => {
          checkPassed = dialogResult;
        });
    }
    return checkPassed;
  }

}
