import { IRequestStorageHandler } from '../base/interfaces/IRequestStorageHandler';

export class RequestStorageHandler implements IRequestStorageHandler {

    private static currentInstance;

    public static getInstance(): RequestStorageHandler {
        if (RequestStorageHandler.currentInstance === undefined) {
            RequestStorageHandler.currentInstance = new RequestStorageHandler();
        }
        return RequestStorageHandler.currentInstance;
    }

    private readonly nameSpace: string = 'storage';

    public readonly fieldNameRid: string = 'rid';

    /**
     * Gets a value from the storage for the fieldname passed in, returns an empty string if it is not available.
     * @param fieldName - the name of the field that you want information for.
     * @returns the data found in the storage if there is one or an empty string if nothing found.
     */
    public getVal(fieldName: string): any {
        const val = this[fieldName];
        if (val === undefined) {
            return '';
        }
        else {
            return val;
        }
    }

    /**
     * Gets a string value from the storage for the fieldName passed in. Returns and empty string
     * if the storage value is not defined. Prepends a value to the string if the storage value is found.
     * @param fieldName - the name of the field you to get data for.
     * @param prepend - the string you would like to prepend to the stored value.
     * @returns string - An empty string if no value is found or prepend + storagevalue
     */
    public getStrVal(fieldName: string, prepend?: string): string {
        const retVal = this.getVal(fieldName) as string;
        if (retVal === '') {
            return '';
        }
        else {
            if (prepend !== undefined) {
                return prepend + retVal;
            } else {
                return retVal;
            }
        }
    }

    /**
     *
     * @param fieldname the fieldname that the value will be retrieved by
     * @param fieldvalue the value that is to be stored
     * @returns void
     */
    public setVal(fieldName: string, fieldValue: any): void {
        try {
            this[fieldName] = fieldValue;
        }
        catch (err) {
            // doesn't actually matter if it fails. #TODO maybe log a message in future
        }
    }

    /**
     * Runs some code, enables that code and any functions called from that code to have
     * access to data set within that section of code (except for callbacks)
     * @param callback - runs some code and gives it access to the request handler.
     * @returns void
     */
    public run(callback: any): void {
        callback();
    }

    /**
     * Binds a callback so that the callback has access to this storage handler
     * @param callback The callback function that you want to be able to access this storage handler
     * @returns a function that can be executed.
     */
    public bind(callback: any): any {
        return callback;
    }

    /**
     * Binds an event emmitter so that emmited events have access to this storage handler.
     * @param emitter - a node eventemitter object
     * @returns void
     */
    public bindEmitter(emitter: any): void {
        return emitter;
    }

}

export const rsh = RequestStorageHandler.getInstance();
