import { ErrorMessages } from './ErrorMessages';

export const pages = {
  LOGIN: {
    title: 'Login',
    url: '/login'
  },
  NOT_FOUND: {
    title: 'Not Found',
    url: '**'
  },
  SIGNUP: {
    title: 'Sign Up',
    url: '/signup/'
  },
  DASHBOARD: {
    title: 'Home',
    url: ''
  },
  HOME: {
    title: 'Home',
    url: '/home'
  },
  SYSNET: {
    sub: {
      STOCK: {
        title: 'Stock',
        url: '/sysnet/stock',
        sub: {
          UPDATE_STOCK: {
            title: 'Update Stock',
            url: '/sysnet/stock/update-stock',
            sub: {
              STOCK_LIST: {
                title: 'Stock List',
                url: '/sysnet/stock/update-stock/stock-list'
              },
              SALES_STOCK_LIST: {
                title: 'Sales Stock List',
                url: '/sysnet/stock/update-stock/sales-stock-list'
              },
              STOCK_IN_LOCATION_LIST: {
                title: 'Stock in Location List',
                url: '/sysnet/stock/update-stock/stock-in-location-list'
              },
              STOCK_DETAILS: {
                title: 'Stock Details',
                url: '/sysnet/stock/update-stock/stock-details/:id'
              }
            }
          },
          DISCREPANCY: {
            title: 'Discrepancies',
            url: '/sysnet/stock/discrepancy',
            sub: {
              DISCREPANCY_LIST: {
                title: 'Discrepancy List',
                url: '/sysnet/stock/discrepancy/discrepancy-list'
              },
              DISCREPANCY_DETAILS: {
                title: 'Discrepancy Details',
                url: '/sysnet/stock/discrepancy/discrepancy-details/:id'
              }
            }
          },
          TRANSFER: {
            title: 'Transfers',
            url: '/sysnet/stock/transfers',
            sub: {
              TRANSFER_LIST: {
                title: 'Transfer List',
                url: '/sysnet/stock/transfers/transfer-list'
              },
              TRANSFER_DETAILS: {
                title: 'Transfer Details',
                url: '/sysnet/stock/transfers/transfer-details/:id'
              }
            }
          },
          STOCKTAKE: {
            title: 'Stocktakes',
            url: '/sysnet/stock/stocktakes',
            sub: {
              STOCKTAKE_LIST: {
                title: 'Stocktake List',
                url: '/sysnet/stock/stocktakes/stocktake-list'
              },
              COUNT_STOCKTAKE: {
                title: 'Stocktake Entry',
                url: '/sysnet/stock/stocktakes/count-stocktake/:id'
              }
            }
          },
          RECIPE: {
            title: 'Recipes',
            url: '/sysnet/stock/recipes',
            sub: {
              RECIPE_LIST: {
                title: 'Recipe List',
                url: '/sysnet/stock/recipes/recipes-list'
              },
              RECIPE_DETAILS: {
                title: 'Recipe Details',
                url: '/sysnet/stock/recipes/recipe-details/:id'
              }
            }
          },
          RECEIVE: {
            title: 'Receive',
            url: '/sysnet/stock/receive',
            sub: {
              RECIPE_LIST: {
                title: 'Receive List',
                url: '/sysnet/stock/receive/receive-list'
              }
            }
          },
          PURCHASE_ORDER: {
            title: 'Reorder',
            url: '/sysnet/stock/purchase-order',
            sub: {
              PURCHASE_ORDER_LIST: {
                title: 'Purchase Order List',
                url: '/sysnet/stock/purchase-order/purchase-order-list'
              }
            }
          }
        }
      },
      SUPPLIERS: {
        title: 'Suppliers',
        url: '/sysnet/suppliers',
        sub: {
          SUPPLIER_LIST: {
            title: 'Supplier List',
            url: '/sysnet/suppliers/supplier-list'
          }
        }
      },
      SETTINGS: {
        title: 'Settings & Configuration',
        url: '/sysnet/settings'
      },
      HOME: {
        title: 'Sysnet Home',
        url: '/sysnet/home'
      },
      STOCK_MOBILE: {
        title: 'Stock Mobile',
        url: '/sysnet/stock-mobile',
        sub: {
          HOME: {
            title: 'Stock Mobile Home',
            url: '/sysnet/stock-mobile/home'
          },
          STOCK_INFO: {
            title: 'Stock Info',
            url: '/sysnet/stock-mobile/stock-info',
            sub: {
              STOCK_ITEM_DETAILS: {
                title: 'Stock Item Details',
                url: '/sysnet/stock-mobile/stock-info/:id',
                sub: {
                  PRICES: {
                    title: 'Prices',
                    url: '/sysnet/stock-mobile/stock-info/:id/prices'
                  },
                  QUANTITY: {
                    title: 'Quantity in Stock',
                    url: '/sysnet/stock-mobile/stock-info/:id/quantity'
                  }
                }
              }
            }
          },
          DISCREPANCY: {
            title: 'Discrepancy',
            url: '/sysnet/stock-mobile/discrepancy',
            sub: {
              DISCREPANCY_LIST: {
                title: 'Discrepancy',
                url: '/sysnet/stock-mobile/discrepancy/discrepancy-list'
              },
              HISTORY: {
                title: 'Discrepancy History',
                url: '/sysnet/stock-mobile/discrepancy/history'
              },
              HISTORY_DETAILS: {
                title: 'Discrepancy History Details',
                url: '/sysnet/stock-mobile/discrepancy/details/:id/history'
              },
              CREATE_DISCREPANCY: {
                title: 'Create New Discrepancy',
                url: '/sysnet/stock-mobile/discrepancy/create'
              },
              DETAILS: {
                title: 'Discrepancy Details',
                url: '/sysnet/stock-mobile/discrepancy/details/:id'
              },
              FIND_STOCK: {
                title: 'Find Stock',
                url: '/sysnet/stock-mobile/discrepancy/details/:id/search-stock'
              },
              DETAIL_ITEM: {
                title: 'Discrepancy Detail Item',
                url: '/sysnet/stock-mobile/discrepancy/details/:id/item'
              }
            }
          },
          TRANSFER: {
            title: 'Transfer Stock',
            url: '/sysnet/stock-mobile/transfer',
            sub: {
              TRANSFER_LIST: {
                title: 'Transfer Stock',
                url: '/sysnet/stock-mobile/transfer/transfer-list'
              },
              HISTORY: {
                title: 'Transfer History',
                url: '/sysnet/stock-mobile/transfer/history'
              },
              CREATE_TRANSFER: {
                title: 'Transfer Stock',
                url: '/sysnet/stock-mobile/transfer/create'
              },
              FIND_STOCK: {
                title: 'Find Stock',
                url: '/sysnet/stock-mobile/transfer/details/:id/search-stock'
              },
              DETAIL_ITEM: {
                title: 'Stock Item List',
                url: '/sysnet/stock-mobile/transfer/details/:id/item'
              }
            }
          },
          COUNT_STOCKTAKE: {
            title: 'Count Stocktakes',
            url: '/sysnet/stock-mobile/count-stocktake',
            sub: {
              STOCKTAKE_ENTRY: {
                title: 'Stocktake Entry',
                url: '/sysnet/stock-mobile/count-stocktake/details/:id'
              },
              CREATE_STOCKTAKE: {
                title: 'Create Stocktake',
                url: '/sysnet/stock-mobile/count-stocktake/create'
              },
              HISTORY: {
                title: 'Stocktake History',
                url: '/sysnet/stock-mobile/count-stocktake/history'
              }
            }
          },
          WASTAGE: {
            title: 'Record Wastage',
            url: '/sysnet/stock-mobile/wastage',
            sub: {
              WASTAGE_LIST: {
                title: 'Record Wastage',
                url: '/sysnet/stock-mobile/wastage/wastage-list'
              },
              CREATE_WASTAGE: {
                title: 'Create New Wastage',
                url: '/sysnet/stock-mobile/wastage/create'
              },
              FIND_STOCK: {
                title: 'Find Stock',
                url: '/sysnet/stock-mobile/wastage/details/:id/search-stock'
              },
              DETAIL_ITEM: {
                title: 'Wastage Detail Item',
                url: '/sysnet/stock-mobile/wastage/details/:id/item'
              },
              DETAILS: {
                title: 'Wastage Details',
                url: '/sysnet/stock-mobile/wastage/details/:id'
              },
              HISTORY_LIST: {
                title: 'Wastage History List',
                url: '/sysnet/stock-mobile/wastage/wastage-list/history'
              },
              HISTORY_DETAILS: {
                title: 'Wastage hISTORY Details',
                url: '/sysnet/stock-mobile/wastage/details/:id/history'
              }
            }
          },
          MULTI_ITEM_BARCODE: {
            title: 'Multi Item Barcodes',
            url: '/sysnet/stock-mobile/multi-item-barcodes',
            sub: {
              FIND_BARCODE: {
                title: 'Assign Barcodes',
                url: '/sysnet/stock-mobile/multi-item-barcodes/find-barcode'
              },
              ASSIGN_BARCODE: {
                title: 'Multi-Item Barcode',
                url: '/sysnet/stock-mobile/multi-item-barcodes/assign-item'
              }
            }
          },
          RECEIVE: {
            title: 'Receive Stock',
            url: ''
          }
        }
      },
      PRODUCTS: {
        title: 'Products',
        url: '/sysnet/products',
        sub: {
          UPDATE_PRODUCTS: {
            title: 'Update Products',
            url: '/sysnet/products/update-products',
            sub: {
              PRODUCT_LIST: {
                title: 'Product List',
                url: '/sysnet/products/update-products/product-list'
              },
              PRODUCT_IN_LOCATION_LIST: {
                title: 'Product in Location List',
                url: '/sysnet/products/update-products/product-in-location-list'
              }
            }
          },
          DYNAMIC_PRICING: {
            title: 'Dynamic Pricing',
            url: '/sysnet/products/dynamic-pricing'
          },
          SALES_SPECIALS: {
            title: 'Sales Specials',
            url: '/sysnet/products/sales-special',
            sub: {
              SALES_SPECIALS_LIST: {
                title: 'Sales Specials',
                url: '/sysnet/products/sales-special/sales-special-list'
              }
            }
          },
          PACKAGES: {
            title: 'Packages',
            url: '/sysnet/products/packages'
          },
          OFFERS: {
            title: 'Offers',
            url: '/sysnet/products/offers',
            sub: {
              OFFERS_LIST: {
                title: 'Offers List',
                url: '/sysnet/products/offers/offer-list'
              }
            }
          },
          MIX_N_MATCH: {
            title: 'Mix\'n\'Match',
            url: '/sysnet/products/mix-n-match',
            sub: {
              MIX_N_MATCH_LIST: {
                title: 'Mix\'n\'Match List',
                url: '/sysnet/products/mix-and-match/mix-and-match-list'
              }
            }
          },
          PRICE_MANAGEMENT: {
            title: 'Price Management',
            url: '/sysnet/products/price-management',
            sub: {
              PRICE_MANAGEMENT_LIST: {
                title: 'Price Management',
                url: '/sysnet/products/price-management/price-management-list'
              }
            }
          }
        }
      },
      REPORTS: {
        title: 'Reports',
        url: '/sysnet/reports'
      }
    }
  },
  REPORTS: {
    title: 'Dashboards',
    url: '/Dashboards',
    sub: {
    }
  },
  WEBHOOKS: {
    title: 'Webhooks',
    url: '/Webhooks',
    sub: {
      WEBHOOKS_LIST: {
        title: 'Webhooks List',
        url: '/Webhooks/list'
      }
    }
  },
  USER_MANAGEMENT: {
    title: 'User Management',
    url: '/user-management',
    sub: {
      USER_MANAGEMENT: {
        title: 'User Management List',
        url: '/user-management/list'
      }
    }
  }
};

/**
 * Checks whether there are currently unsaved changes on your page. Does this by checking if you have the sticky sidebar or a modal currently open.
 * Returns an empty string if no unsaved changes, and a string of the error if you do have unsaved changes.
 * @param $event the event passed in from window.beforeunload.
 */
export const unsavedPageValidator = ($event): string => {
  if (!document.getElementById('edit-floating-menu-container') && document.getElementsByClassName('mat-dialog-container').length === 0) { return ''; }
  // some browsers use $event.returnValue, some use the return. Need both.
  $event.returnValue = ErrorMessages.LEAVE_PAGE_UNSAVED_CHANGES;

  return ErrorMessages.LEAVE_PAGE_UNSAVED_CHANGES;
};
