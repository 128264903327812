/* eslint-disable max-classes-per-file */
import Constants from '@hal.common.ui/utilities/Constants';
import { SysnetCloudConstants } from 'src/app/Constants';
import { pages } from 'src/app/utilities/Pages';
import { LocationResponse, Organisation, UserReport } from '@modules/SDKs/portalApi/index';
import { User } from '@hal.common.ui/utilities/User';
import { buildEnvironment } from '../../../.././../circleciBuildInfo';

export interface IAppSwitcherCategories {
  id?: string;
  displayName?: string;
  useCustomIcon?: boolean;
  iconPath?: string;
  routeUrl?: string;
  openInNewTab?: boolean;
  subTabs?: IAppSwitcherCategoryOptions[];
  hasAccess?: boolean;
  requiredAccessToAppName?: string;
  includedScreens?: string[];
  _coloured?: boolean; // do not use when setting up a tab, this is controlled by the navigation/sidebar code
  _expanded?: boolean; // do not use when setting up a tab, this is controlled by the navigation/sidebar code
}

export interface IAppSwitcherCategoryOptions {
  id: string;
  displayName: string;
  routeUrl: string;
  openInNewTab?: boolean;
  hasAccess: boolean;
  requiredDataAccessName?: string | string[];
  requiredApplicationCode?: string;
  subTabs?: IAppSwitcherCategoryOptions[];
  includedScreens?: string[];
  iconName?: string;
  selected?: boolean;
  _coloured?: boolean; // do not use when setting up a tab, this is controlled by the navigation/sidebar code
  _expanded?: boolean; // do not use when setting up a tab, this is controlled by the navigation/sidebar code
  requiredOrganisationSetting?: string;
}

export interface ISimpleNavItem {
  id: string;
  displayName: string;
  routeUrl: string;
  openInNewTab?: boolean;
  hasAccess: boolean;
  requiredDataAccessName?: string | string[];
  requiredOrganisationSetting?: string;
  subTabs?: IAppSwitcherCategoryOptions[];
  includedScreens?: string[];
  iconName?: string;
  selected?: boolean;
  disabled?: boolean;
  _coloured?: boolean; // do not use when setting up a tab, this is controlled by the navigation/sidebar code
  _expanded?: boolean; // do not use when setting up a tab, this is controlled by the navigation/sidebar code
}

export class SideNavTabs {
  public static SYSNET = 'Sysnet';
  public static MY_APPS = 'MyApps';
  public static STOCK = 'Stock';
  public static SUPPLIERS = 'Suppliers';
  public static SETTINGS = 'Settings';
  public static UPDATE_STOCK = 'UpdateStock';
  public static DISCREPANCIES = 'Discrepancies';
  public static REPORTS = 'Reports';
  public static SALES_REPORTS = 'SalesReport';
  public static TENDERS_REPORTS = 'TendersReport';
  public static SUPPORT = 'Support';
  public static HELP_CENTRE = 'HelpCentre';
  public static LIVE_CHAT = 'LiveChat';
  public static TRANSFERS = 'Transfers';
  public static STOCKTAKES = 'Stocktakes';
  public static RECIPES = 'Recipes';
  public static DASHBOARDS = 'Dashboards';
  public static ODM = 'ODM';
  public static MENU_MANAGEMENT = 'MenuManagement';
  public static BOOK_BOOK = 'BookBook';
  public static ORDER_NOW = 'OrderNow';
  public static IDEAS_PORTAL = 'IdeasPortal';
  public static RECEIVE = 'Receive';
  public static WEB_HOOKS = 'Webhooks';
  public static USER_MANAGEMENT = 'UserManagement';
  public static STOCK_MOBILE = 'StockMobile';
  public static STOCK_MOBILE_TRANSFER = 'StockMobileTransfer';
  public static STOCK_MOBILE_COUNT_STOCKTAKE = 'StockMobileCountStocktake';
  public static STOCK_MOBILE_STOCK_INFO = 'StockMobileStockInfo';
  public static STOCK_MOBILE_DISCREPANCY = 'StockMobileDiscrepancy';
  public static STOCK_MOBILE_RECEIVE = 'StockMobileReceive';
  public static STOCK_MOBILE_WASTAGE = 'StockMobileWastage';
  public static STOCK_MOBILE_MULTI_ITEM_BARCODE = 'StockMobileMultiItemBarcode';
  public static PURCHASE_ORDER = 'PurchaseOrder';
  public static PRODUCTS = 'Products';
  public static UPDATE_PRODUCTS = 'UpdateProducts';
  public static DYNAMIC_PRICING = 'DynamicPricing';
  public static SALES_SPECIALS = 'SalesSpecials';
  public static PACKAGES = 'Packages';
  public static MIX_N_MATCH = 'MixNMatch';
  public static OFFERS = 'Offers';
  public static PRICE_MANAGEMENT = 'PriceManagement';

}

export class Menus {
  public static APPLICATION_SWITCHER = 'ApplicationSwitcher';
  public static STOCK_SIDE_MENU = 'StockSideMenu';
  public static PRODUCT_SIDE_MENU = 'ProductSideMenu';
}

export default class Navigation {
  public static readonly sysnetOptions: IAppSwitcherCategoryOptions[] = [
    {
      id: SideNavTabs.STOCK,
      displayName: pages.SYSNET.sub.STOCK.title,
      routeUrl: pages.SYSNET.sub.STOCK.url,
      openInNewTab: false,
      iconName: 'stock-icon.png',
      selected: true,
      hasAccess: false,
      subTabs: Navigation.getStockSubSelections(),
      requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_STOCK_MODULE
    },
    {
      id: SideNavTabs.SUPPLIERS,
      displayName: pages.SYSNET.sub.SUPPLIERS.title,
      routeUrl: pages.SYSNET.sub.SUPPLIERS.url,
      openInNewTab: false,
      iconName: 'suppliers-icon.png',
      selected: false,
      hasAccess: false,
      requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_SUPPLIERS,
      includedScreens: [
        pages.SYSNET.sub.SUPPLIERS.sub.SUPPLIER_LIST.title
      ]
    },
    {
      id: SideNavTabs.SETTINGS,
      displayName: pages.SYSNET.sub.SETTINGS.title,
      routeUrl: pages.SYSNET.sub.SETTINGS.url,
      openInNewTab: false,
      iconName: 'settings-icon.svg',
      selected: false,
      hasAccess: false,
      requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_CONFIGURATION_AND_SETTINGS_MODULE,
      requiredOrganisationSetting: SysnetCloudConstants.SYSNET_SETTINGS.USE_SYSNET_SETTINGS
    },
    {
      id: SideNavTabs.STOCK_MOBILE,
      displayName: pages.SYSNET.sub.STOCK_MOBILE.title,
      routeUrl: pages.SYSNET.sub.STOCK_MOBILE.url,
      openInNewTab: false,
      iconName: 'stock-mobile.svg',
      selected: false,
      hasAccess: false,
      subTabs: Navigation.getStockMobileSubSelections(),
      requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_STOCK_MOBILE_MODULE
    },
    {
      id: SideNavTabs.PRODUCTS,
      displayName: pages.SYSNET.sub.PRODUCTS.title,
      routeUrl: pages.SYSNET.sub.PRODUCTS.url,
      openInNewTab: false,
      iconName: 'products-icon.svg',
      selected: false,
      hasAccess: false,
      subTabs: Navigation.getProductsSubSelections(),
      requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_PRODUCTS_MODULE,
      requiredOrganisationSetting: SysnetCloudConstants.SYSNET_SETTINGS.USE_PRODUCT_MANAGEMENT
    },
    {
      id: SideNavTabs.REPORTS,
      displayName: pages.SYSNET.sub.REPORTS.title,
      routeUrl: pages.SYSNET.sub.REPORTS.url,
      openInNewTab: false,
      iconName: 'reports-icon.svg',
      selected: false,
      hasAccess: false,
      requiredOrganisationSetting: SysnetCloudConstants.SYSNET_SETTINGS.USE_REPORTS
    }
  ];

  public static readonly myAppsOptions: IAppSwitcherCategoryOptions[] = [
    {
      id: SideNavTabs.DASHBOARDS,
      displayName: 'Dashboards',
      routeUrl: '/dashboards',
      openInNewTab: false,
      iconName: 'dashboard-icon.png',
      selected: false,
      hasAccess: false,
      requiredApplicationCode: Constants.APPLICATION_CODES.REPORTS
    },
    {
      id: SideNavTabs.SYSNET,
      displayName: 'Sysnet Cloud',
      routeUrl: '/sysnet',
      openInNewTab: false,
      iconName: 'Sysnet 3d.svg',
      selected: false,
      hasAccess: false,
      requiredApplicationCode: Constants.APPLICATION_CODES.SYSNET_CLOUD
    },
    {
      id: SideNavTabs.ODM,
      displayName: 'ODM',
      routeUrl: '/', // will be set in the ts file
      openInNewTab: true,
      iconName: 'order-display-monitor-icon.png',
      selected: false,
      hasAccess: false,
      requiredApplicationCode: Constants.APPLICATION_CODES.ODM
    },
    {
      id: SideNavTabs.MENU_MANAGEMENT,
      displayName: 'Menu Management',
      routeUrl: '/', // will be set in the ts file
      openInNewTab: true,
      iconName: 'menu-management-icon.png',
      selected: false,
      hasAccess: false,
      requiredApplicationCode: Constants.APPLICATION_CODES.MENU_MANAGEMENT
    },
    {
      id: SideNavTabs.BOOK_BOOK,
      displayName: 'BookBook',
      routeUrl: '/', // will be set in the ts file
      openInNewTab: true,
      iconName: 'book-book-icon.png',
      selected: false,
      hasAccess: false,
      requiredApplicationCode: Constants.APPLICATION_CODES.BBC
    },
    {
      id: SideNavTabs.ORDER_NOW,
      displayName: 'Order Now',
      routeUrl: '/', // will be set in the ts file
      openInNewTab: true,
      iconName: 'order-now-icon.png',
      selected: false,
      hasAccess: false,
      requiredApplicationCode: Constants.APPLICATION_CODES.ORDER_NOW
    },
    {
      id: SideNavTabs.WEB_HOOKS,
      displayName: 'Webhooks',
      routeUrl: '/webhooks',
      openInNewTab: false,
      iconName: 'web-hooks.svg',
      selected: false,
      hasAccess: false,
      requiredApplicationCode: Constants.APPLICATION_CODES.WEB_HOOKS
    },
    {
      id: SideNavTabs.USER_MANAGEMENT,
      displayName: 'User Management',
      routeUrl: '/user-management',
      openInNewTab: false,
      iconName: 'user-management.svg',
      selected: false,
      hasAccess: false,
      requiredApplicationCode: Constants.APPLICATION_CODES.USER_MANAGEMENT
    }
  ];

  public static readonly myApps: IAppSwitcherCategories = {
    id: SideNavTabs.MY_APPS,
    displayName: 'My Apps',
    routeUrl: null,
    subTabs: Navigation.myAppsOptions,
    hasAccess: true
  };

  public static readonly home: IAppSwitcherCategories = {
    id: 'Home',
    displayName: pages.HOME.title,
    subTabs: [ ],
    useCustomIcon: false,
    iconPath: 'home',
    routeUrl: pages.HOME.url,
    openInNewTab: false,
    hasAccess: true
  };

  public static readonly sysnet: IAppSwitcherCategories = {
    id: SideNavTabs.SYSNET,
    displayName: 'Sysnet',
    useCustomIcon: true,
    routeUrl: pages.SYSNET.sub.HOME.url,
    openInNewTab: false,
    subTabs: Navigation.sysnetOptions,
    hasAccess: false,
    requiredAccessToAppName: Constants.APPLICATION_CODES.SYSNET_CLOUD
  };

  public static readonly supportOptions: IAppSwitcherCategoryOptions[] = [
    {
      id: SideNavTabs.HELP_CENTRE,
      displayName: 'Help Centre',
      routeUrl: 'https://helpdesk.hlpos.com/support/home',
      openInNewTab: true,
      iconName: 'help-center-icon.png',
      selected: false,
      hasAccess: false,
      requiredDataAccessName: Constants.DATA_ACCESS.GET_SUPPORT
    },
    {
      id: SideNavTabs.LIVE_CHAT,
      displayName: 'Live Chat',
      routeUrl: 'https://livechatsignature.hlcloud.com.au/',
      openInNewTab: true,
      iconName: 'live-chat-icon.png',
      selected: false,
      hasAccess: true
    },
    {
      id: SideNavTabs.IDEAS_PORTAL,
      displayName: 'Ideas Portal',
      routeUrl: 'https://hlpos.ideas.aha.io/',
      openInNewTab: true,
      iconName: 'ideas-portal-icon.svg',
      selected: false,
      hasAccess: true
    }];

  public static readonly support: IAppSwitcherCategories = {
    id: SideNavTabs.SUPPORT,
    displayName: 'Support',
    useCustomIcon: false,
    routeUrl: null,
    openInNewTab: false,
    subTabs: Navigation.supportOptions,
    hasAccess: true
  };
  public static loadNavPermissions(user: User, organisationId: string,
                                   navArr: { requiredAccessToAppName?: string, hasAccess: boolean, requiredDataAccessName?: string | string[] }[]): void {
    navArr.forEach((item) => {
      if (item.requiredAccessToAppName) {
        item.hasAccess = user.hasAppAccessWithCode(item.requiredAccessToAppName);
      }
      if (item.requiredDataAccessName) {
        item.hasAccess = Navigation.hasDataAccesCode(user, item.requiredDataAccessName, null, undefined, { _id: organisationId });
      }
    });
  }

  public static hasDataAccesCode(user: User, dataAccessCode: string | string[], permission: string, location?: LocationResponse, organisation?: Organisation): boolean {
    if (Array.isArray(dataAccessCode)) {
      let hasAccess = false;
      dataAccessCode.forEach((accessCode) => {
        if (user.hasDataAccesCode(accessCode, permission, location, organisation)) { hasAccess = true; }
      });
      return hasAccess;
    }
    return user.hasDataAccesCode(dataAccessCode, permission, location, organisation);
  }

  public static getStockSubSelections(): ISimpleNavItem[] {

    const stockSubSelections: ISimpleNavItem[] = [
      {
        id: SideNavTabs.UPDATE_STOCK,
        displayName: pages.SYSNET.sub.STOCK.sub.UPDATE_STOCK.title,
        routeUrl: pages.SYSNET.sub.STOCK.sub.UPDATE_STOCK.url,
        selected: false,
        iconName: 'update-stock-icon.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_STOCK,
        includedScreens: [
          pages.SYSNET.sub.STOCK.sub.UPDATE_STOCK.sub.STOCK_LIST.title,
          pages.SYSNET.sub.STOCK.sub.UPDATE_STOCK.sub.SALES_STOCK_LIST.title,
          pages.SYSNET.sub.STOCK.sub.UPDATE_STOCK.sub.STOCK_IN_LOCATION_LIST.title,
          pages.SYSNET.sub.STOCK.sub.UPDATE_STOCK.sub.STOCK_DETAILS.title
        ]
      },
      {
        id: SideNavTabs.PURCHASE_ORDER,
        displayName: pages.SYSNET.sub.STOCK.sub.PURCHASE_ORDER.title,
        routeUrl: pages.SYSNET.sub.STOCK.sub.PURCHASE_ORDER.url,
        selected: false,
        iconName: 'reorder-icon.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_PURCHASE_ORDER_HEADERS,
        includedScreens: [
          pages.SYSNET.sub.STOCK.sub.PURCHASE_ORDER.sub.PURCHASE_ORDER_LIST.title
        ]
      },
      {
        id: SideNavTabs.RECEIVE,
        displayName: pages.SYSNET.sub.STOCK.sub.RECEIVE.title,
        routeUrl: pages.SYSNET.sub.STOCK.sub.RECEIVE.url,
        selected: false,
        iconName: 'receive-icon.svg',
        openInNewTab: false,
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_RECEIVE_HEADERS,
        includedScreens: [
          pages.SYSNET.sub.STOCK.sub.RECEIVE.sub.RECIPE_LIST.title
        ]
      },
      {
        id: SideNavTabs.TRANSFERS,
        displayName: pages.SYSNET.sub.STOCK.sub.TRANSFER.title,
        routeUrl: pages.SYSNET.sub.STOCK.sub.TRANSFER.url,
        selected: false,
        iconName: 'stock-transfer-icon.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_TRANSFER_HEADERS,
        includedScreens: [
          pages.SYSNET.sub.STOCK.sub.TRANSFER.sub.TRANSFER_LIST.title,
          pages.SYSNET.sub.STOCK.sub.TRANSFER.sub.TRANSFER_DETAILS.title
        ]
      },
      {
        id: SideNavTabs.DISCREPANCIES,
        displayName: pages.SYSNET.sub.STOCK.sub.DISCREPANCY.title,
        routeUrl: pages.SYSNET.sub.STOCK.sub.DISCREPANCY.url,
        selected: false,
        iconName: 'stock-discrepancy-icon.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_DISCREPANCY_HEADERS,
        includedScreens: [
          pages.SYSNET.sub.STOCK.sub.DISCREPANCY.sub.DISCREPANCY_LIST.title,
          pages.SYSNET.sub.STOCK.sub.DISCREPANCY.sub.DISCREPANCY_DETAILS.title
        ]
      },
      {
        id: SideNavTabs.STOCKTAKES,
        displayName: pages.SYSNET.sub.STOCK.sub.STOCKTAKE.title,
        routeUrl: pages.SYSNET.sub.STOCK.sub.STOCKTAKE.url,
        selected: false,
        iconName: 'stocktake-icon.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_STOCKTAKES,
        includedScreens: [
          pages.SYSNET.sub.STOCK.sub.STOCKTAKE.sub.STOCKTAKE_LIST.title
        ]
      },
      {
        id: SideNavTabs.RECIPES,
        displayName: pages.SYSNET.sub.STOCK.sub.RECIPE.title,
        routeUrl: pages.SYSNET.sub.STOCK.sub.RECIPE.url,
        selected: false,
        iconName: 'recipe-icon.svg',
        openInNewTab: false,
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_STOCK,
        includedScreens: [
          pages.SYSNET.sub.STOCK.sub.RECIPE.sub.RECIPE_LIST.title,
          pages.SYSNET.sub.STOCK.sub.RECIPE.sub.RECIPE_DETAILS.title
        ]
      }
    ];

    return stockSubSelections;
  }

  public static getStockMobileSubSelections(): ISimpleNavItem[] {

    const stockMobileSubSelections: ISimpleNavItem[] = [
      {
        id: SideNavTabs.STOCK_MOBILE_TRANSFER,
        displayName: pages.SYSNET.sub.STOCK_MOBILE.sub.TRANSFER.title,
        routeUrl: pages.SYSNET.sub.STOCK_MOBILE.sub.TRANSFER.url,
        selected: false,
        iconName: 'transfer.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_TRANSFER_HEADERS,
        requiredOrganisationSetting: SysnetCloudConstants.STOCK_MOBILE_SETTINGS.USE_MOBILE_STOCK_TRANSFER
      },
      {
        id: SideNavTabs.STOCK_MOBILE_COUNT_STOCKTAKE,
        displayName: pages.SYSNET.sub.STOCK_MOBILE.sub.COUNT_STOCKTAKE.title,
        routeUrl: pages.SYSNET.sub.STOCK_MOBILE.sub.COUNT_STOCKTAKE.url,
        selected: false,
        iconName: 'count-stocktake.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_STOCKTAKES,
        requiredOrganisationSetting: SysnetCloudConstants.STOCK_MOBILE_SETTINGS.USE_MOBILE_STOCK_STOCKTAKE
      },
      {
        id: SideNavTabs.STOCK_MOBILE_STOCK_INFO,
        displayName: pages.SYSNET.sub.STOCK_MOBILE.sub.STOCK_INFO.title,
        routeUrl: pages.SYSNET.sub.STOCK_MOBILE.sub.STOCK_INFO.url,
        selected: false,
        iconName: 'stock-info.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_STOCK,
        requiredOrganisationSetting: SysnetCloudConstants.STOCK_MOBILE_SETTINGS.USE_MOBILE_STOCK_INFO
      },
      {
        id: SideNavTabs.STOCK_MOBILE_DISCREPANCY,
        displayName: pages.SYSNET.sub.STOCK_MOBILE.sub.DISCREPANCY.title,
        routeUrl: pages.SYSNET.sub.STOCK_MOBILE.sub.DISCREPANCY.url,
        selected: false,
        iconName: 'discrepancy.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_DISCREPANCY_HEADERS,
        requiredOrganisationSetting: SysnetCloudConstants.STOCK_MOBILE_SETTINGS.USE_MOBILE_STOCK_DISCREPANCIES
      },
      {
        id: SideNavTabs.STOCK_MOBILE_RECEIVE,
        displayName: pages.SYSNET.sub.STOCK_MOBILE.sub.RECEIVE.title,
        routeUrl: pages.SYSNET.sub.STOCK_MOBILE.sub.RECEIVE.url,
        selected: false,
        iconName: 'receive.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_RECEIVE_HEADERS,
        disabled: true // TODO: Remove when implementing the component
      },
      {
        id: SideNavTabs.STOCK_MOBILE_WASTAGE,
        displayName: pages.SYSNET.sub.STOCK_MOBILE.sub.WASTAGE.title,
        routeUrl: pages.SYSNET.sub.STOCK_MOBILE.sub.WASTAGE.url,
        selected: false,
        iconName: 'wastage.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_DISCREPANCY_HEADERS,
        requiredOrganisationSetting: SysnetCloudConstants.STOCK_MOBILE_SETTINGS.USE_MOBILE_STOCK_WASTAGE
      },
      {
        id: SideNavTabs.STOCK_MOBILE_MULTI_ITEM_BARCODE,
        displayName: pages.SYSNET.sub.STOCK_MOBILE.sub.MULTI_ITEM_BARCODE.title,
        routeUrl: pages.SYSNET.sub.STOCK_MOBILE.sub.MULTI_ITEM_BARCODE.url,
        selected: false,
        iconName: 'barcode.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_BARCODES,
        requiredOrganisationSetting: SysnetCloudConstants.STOCK_MOBILE_SETTINGS.USE_MOBILE_STOCK_MULTI_ITEM_BARCODES
      }
    ];

    return stockMobileSubSelections;
  }

  public static getProductsSubSelections(): ISimpleNavItem[] {

    const productSubSelections: ISimpleNavItem[] = [
      {
        id: SideNavTabs.UPDATE_PRODUCTS,
        displayName: pages.SYSNET.sub.PRODUCTS.sub.UPDATE_PRODUCTS.title,
        routeUrl: pages.SYSNET.sub.PRODUCTS.sub.UPDATE_PRODUCTS.url,
        selected: false,
        iconName: 'update-products-icon.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_PRODUCTS
      },
      {
        id: SideNavTabs.SALES_SPECIALS,
        displayName: pages.SYSNET.sub.PRODUCTS.sub.SALES_SPECIALS.title,
        routeUrl: pages.SYSNET.sub.PRODUCTS.sub.SALES_SPECIALS.url,
        selected: false,
        iconName: 'sales-special-icon.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_SALES_SPECIALS
      },
      {
        id: SideNavTabs.DYNAMIC_PRICING,
        displayName: pages.SYSNET.sub.PRODUCTS.sub.DYNAMIC_PRICING.title,
        routeUrl: pages.SYSNET.sub.PRODUCTS.sub.DYNAMIC_PRICING.url,
        selected: false,
        iconName: 'dynamic-pricing-icon.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_DYNAMIC_PRICING
      },
      {
        id: SideNavTabs.OFFERS,
        displayName: pages.SYSNET.sub.PRODUCTS.sub.OFFERS.title,
        routeUrl: pages.SYSNET.sub.PRODUCTS.sub.OFFERS.url,
        selected: false,
        iconName: 'offers-icon.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_OFFERS
      },
      {
        id: SideNavTabs.MIX_N_MATCH,
        displayName: pages.SYSNET.sub.PRODUCTS.sub.MIX_N_MATCH.title,
        routeUrl: pages.SYSNET.sub.PRODUCTS.sub.MIX_N_MATCH.url,
        selected: false,
        iconName: 'mix-n-match-icon.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_MIX_N_MATCH
      },
      {
        id: SideNavTabs.PRICE_MANAGEMENT,
        displayName: pages.SYSNET.sub.PRODUCTS.sub.PRICE_MANAGEMENT.title,
        routeUrl: pages.SYSNET.sub.PRODUCTS.sub.PRICE_MANAGEMENT.url,
        selected: false,
        iconName: 'price-management-icon.svg',
        hasAccess: false,
        requiredDataAccessName: SysnetCloudConstants.DATA_ACCESS.GET_PRICE_CHANGES_HEADERS,
        requiredOrganisationSetting: SysnetCloudConstants.SYSNET_SETTINGS.USE_PRICE_MANAGEMENT
      }
    ];

    return productSubSelections;
  }

  public static getReportSelection(reports: UserReport[]): IAppSwitcherCategories {

    if (reports && reports.length > 0) {
      return {
        id: SideNavTabs.REPORTS,
        displayName: pages.REPORTS.title,
        useCustomIcon: true,
        iconPath: '../../../../assets/img/branding/icon-svg/Reports.svg',
        routeUrl: null,
        openInNewTab: false,
        subTabs: Navigation.getReportSubSelections(reports),
        hasAccess: true,
        requiredAccessToAppName: Constants.APPLICATION_CODES.REPORTS
      };
    }

    return undefined;
  }

  public static getReportSubSelections(reports: UserReport[]): IAppSwitcherCategoryOptions[] {

    const reportsSubSelections = reports.map((report) =>
      ({
        id: report.name,
        displayName: report.name,
        routeUrl: '/dashboards/' + report.name,
        openInNewTab: false,
        hasAccess: true
      }));
    // return Navigation.reportsSubSelections;
    return reportsSubSelections;
  }

  public static getNavOptions(reports: UserReport[]): IAppSwitcherCategories[] {

    const navOpts = [];
    navOpts.push(Navigation.home);
    navOpts.push(Navigation.sysnet);
    const reportSelection = Navigation.getReportSelection(reports);
    if (reportSelection) {
      navOpts.push(reportSelection);
    }
    navOpts.push(Navigation.support);

    return navOpts;
  }
}
