/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

/**
 * Containers are names that logically describe how stock items are packaged in various situations  Example: Beer may be purchased by the container named \"Carton\" and counted in a stocktake by a container named \"Single\" or \"Each\" and sold by \"Single\", \"4-Pack\", 6-Pack\" and \"Carton\"
 */
export interface ContainerView { 
    /**
     * The unique identifier of this record.
     */
    containerId?: number;
    /**
     * Describes the unit of the item
     */
    description?: string;
    organisationId?: string;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    status?: ContainerView.StatusEnum;
}
export namespace ContainerView {
    export type StatusEnum = 'active' | 'disabled';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Disabled: 'disabled' as StatusEnum
    };
}