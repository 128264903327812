import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormControl, ValidatorFn, ValidationErrors, AbstractControl, UntypedFormGroup } from '@angular/forms';
import { UsersService } from '@services/users.service';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from '@services/utility.service';
import logger from '@hal.common.ui/utilities/Logger';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpStatus } from '@hal.common.ui/utilities/HttpStatus';
import { IEncryptedInviteData } from 'src/app/types/interfaces/UserModel';
import { UserResponse as User } from '../../../modules/SDKs/portalApi/index';

export interface IUserObjSignUpFieldsToUpdate {
  firstName: string;
  lastName: string;
  name: string;
  // email: string;
  mobile: string;
}
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  /* Form Controls Name, must match the name used in html  */

  public static readonly FIRST_NAME = 'firstName';
  public static readonly LAST_NAME = 'lastName';
  public static readonly EMAIL = 'email';
  public static readonly MOBILE = 'mobile';
  public static readonly PASSWORD = 'password';
  public static readonly CONFIRM_PASSWORD = 'confirmPassword';

  // At least 8 characters in length_
  public static readonly MIN_LENGTH = /^[\s\S]{8,}$/;
  // Contain at least 3 of the following 4 types of characters:
  // Lower case letters (a-z)
  public static readonly UPPER = /[A-Z]/;
  // Upper case letters (A-Z)
  public static readonly LOWER = /[a-z]/;
  // Numbers (i.e. 0-9)
  public static readonly NUM = /[0-9]/;
  // Special characters (e.g. !@#$%^&*)
  public static readonly SPECIAL = /[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]/;
  public passCount = 0;
  public createAccountForm = this.formBuilder.group({});

  public failToLoadMsg = 'Oops! Failed to load user data, please try refreshing the page!';
  public failToLoad = false;
  public lengthFailed = true;
  public upperFailed = true;
  public lowerFailed = true;
  public numFailed = true;
  public specialFailed = true;
  public optFailed = true;
  public showPwdHint = false;
  public pwdVisible = false;
  public canSave = true;
  public inviteKey = '';
  public redirectURL = '';
  public user: User;
  public showRedirectOpt = false;
  public smallScreen = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private spin2win: NgxSpinnerService,
    private utils: UtilityService,
    private breakpointObserver: BreakpointObserver

  ) {
    this.createAccountForm.addControl(SignupComponent.FIRST_NAME, new UntypedFormControl('', [Validators.required]));
    this.createAccountForm.addControl(SignupComponent.LAST_NAME, new UntypedFormControl('', [Validators.required]));
    this.createAccountForm.addControl(SignupComponent.EMAIL, new UntypedFormControl('', [Validators.required, Validators.email]));
    this.createAccountForm.addControl(SignupComponent.MOBILE, new UntypedFormControl('',
      [Validators.required, Validators.pattern('^[0-9]{8,12}')]));
    // min length 8, max length 12, numbers only, matches the validtion on the User Object
    this.createAccountForm.addControl(SignupComponent.PASSWORD, new UntypedFormControl('',
      [Validators.required]));
    this.createAccountForm.addControl(SignupComponent.CONFIRM_PASSWORD, new UntypedFormControl('',
      [Validators.required]));

    this.createAccountForm.get(SignupComponent.EMAIL).disable();

    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape,
      Breakpoints.XSmall,
      '(max-width: 750px)'
    ]).subscribe((result) => {
      this.smallScreen = result.matches;
    });

  }

  public formErrorMessage(field: string): string {
    switch (field) {
      case SignupComponent.FIRST_NAME:
        return this.createAccountForm.get(SignupComponent.FIRST_NAME).hasError('required') ? 'You must enter a value' :
          '';
      case SignupComponent.LAST_NAME:
        return this.createAccountForm.get(SignupComponent.FIRST_NAME).hasError('required') ? 'You must enter a value' :
          '';
      case SignupComponent.EMAIL:
        return this.createAccountForm.get(SignupComponent.EMAIL).hasError('required') ? 'You must enter a value' :
          this.createAccountForm.get(SignupComponent.EMAIL).hasError('email') ? 'Not a valid email' :
            '';
      case SignupComponent.MOBILE:
        return this.createAccountForm.get(SignupComponent.MOBILE).hasError('required') ? 'You must enter a value' :
          this.createAccountForm.get(SignupComponent.MOBILE).hasError('pattern') ?
            'Not a valid mobile number, numbers only with length of 8 to 12' : '';
      case SignupComponent.PASSWORD:
        return this.createAccountForm.get(SignupComponent.PASSWORD).hasError('required') ? 'You must enter a password' :
          this.createAccountForm.get(SignupComponent.PASSWORD).hasError('invalid') ? 'Invalid password' :
            '';
      case SignupComponent.CONFIRM_PASSWORD:
        return this.createAccountForm.get(SignupComponent.CONFIRM_PASSWORD).hasError('required') ? 'You must confirm your password' :
          this.createAccountForm.get(SignupComponent.CONFIRM_PASSWORD).hasError('notMatch') ? 'Password does not match' :
            '';
      default:
        break;
    }
  }

  public checkPasswordMatch(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const notMatch = this.createAccountForm.get(SignupComponent.PASSWORD).value !==
        this.createAccountForm.get(SignupComponent.CONFIRM_PASSWORD).value;

      return notMatch ? { notMatch: control.value } : null;
    };
  }

  public validatePassword(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      this.passCount = 0;
      const password = this.createAccountForm.get(SignupComponent.PASSWORD).value;
      if (SignupComponent.MIN_LENGTH.test(password)) {
        if (SignupComponent.UPPER.test(password)) {
          this.passCount += 1;
        }
        if (SignupComponent.LOWER.test(password)) {
          this.passCount += 1;
        }
        if (SignupComponent.NUM.test(password)) {
          this.passCount += 1;
        }
        if (SignupComponent.SPECIAL.test(password)) {
          this.passCount += 1;
        }
      }

      return this.passCount < 3 ? { invalid: control.value } : null;
    };
  }

  public processPwdHint(): void {
    const password = this.createAccountForm.get(SignupComponent.PASSWORD).value;

    this.lengthFailed = SignupComponent.MIN_LENGTH.test(password) ? false : true;
    this.upperFailed = SignupComponent.UPPER.test(password) ? false : true;
    this.lowerFailed = SignupComponent.LOWER.test(password) ? false : true;
    this.numFailed = SignupComponent.NUM.test(password) ? false : true;
    this.specialFailed = SignupComponent.SPECIAL.test(password) ? false : true;
    const checkArry = [this.upperFailed, this.lowerFailed, this.numFailed, this.specialFailed];
    this.optFailed = checkArry.filter((check) => !check).length >= 3 ? false : true;

    this.showPwdHint = this.createAccountForm.get(SignupComponent.PASSWORD).dirty ?
      ((!this.lengthFailed && !this.optFailed) ? false : true) : false;

  }

  public togglePwdVisible(): void {
    if (this.pwdVisible) {
      // toggle to not visible
      (document.getElementById('password') as HTMLInputElement).type = 'password';
      this.pwdVisible = false;
    } else {
      // toggle to visible
      (document.getElementById('password') as HTMLInputElement).type = 'text';
      this.pwdVisible = true;
    }
  }

  public ngOnInit(): void {
    this.spin2win.show();
    this.route.queryParams
      .subscribe((params) => {
        this.inviteKey = params.inviteKey;
        if (!this.inviteKey) {
          this.spin2win.hide();
          this.router.navigate(['/dashboard']);
        } else {
          this.spin2win.hide();
          this.loadDetail()
            .then((result) => { /* do nothing*/ })
            .catch((error) => { /* do nothing*/ });
        }
      });
    this.createAccountForm.get(SignupComponent.PASSWORD).setValidators([
      this.createAccountForm.get(SignupComponent.PASSWORD).validator,
      this.validatePassword()
    ]);
    this.createAccountForm.get(SignupComponent.CONFIRM_PASSWORD).setValidators([
      this.createAccountForm.get(SignupComponent.CONFIRM_PASSWORD).validator,
      this.checkPasswordMatch()
    ]);
    this.createAccountForm.get(SignupComponent.PASSWORD).valueChanges.subscribe(() => {
      this.processPwdHint();
    });
  }

  public loadDetail(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.usersService.decodeValidateInviteDetails(this.inviteKey)
        .then((response) => {
          this.spin2win.hide();
          if (response && response.user) {
            this.user = response.user;
            this.setFields(response.user);
            if (response.redirectURL) {
              this.redirectURL = response.redirectURL;
            }
            resolve(null);
          } else {
            this.failToLoad = true;
            logger.warn('ERROR: Failed to load user info');
            reject('ERROR: Failed to load user info');
          }
        })
        .catch((error) => {
          this.spin2win.hide();

          if (error.status === HttpStatus.CONFLICT) {
            this.showRedirectOpt = true;
            if (error.error && error.error.error) {
              this.redirectURL = error.error.error;
            }
          } else if (error.status === HttpStatus.PRECONDITION_FAILED) {
            this.failToLoad = true;
            if (error.error && error.error.error) {
              if (error.error.error.includes('expired')) {
                this.failToLoadMsg = 'Oops! Looks like the invitation has expired! ' +
                  'Please contact H&L for further assistance 1300 797 638';
              } else if (error.error.error.includes('Invalid')) {
                this.failToLoadMsg = 'Oops! Looks like the application, user or venue is invalid in the invite! ' +
                  'Please try refreshing the page!';
              }
            }
          } else if (error.status === HttpStatus.BAD_REQUEST) {
            this.failToLoad = true;
            if (error.error && error.error.error) {
              if (error.error.error.includes('expired')) {
                this.failToLoadMsg = 'Oops! Looks like the invitation has expired! ' +
                  'Please contact H&L for further assistance 1300 797 638';
              }
            }
          } else {
            this.failToLoad = true;
          }
          logger.warn('ERROR: Failed to load user info: ' + JSON.stringify(error));
          reject(error);
        });
    });
  }
  public clickLink(): void {
    document.getElementById('redirectLink').setAttribute('href', this.redirectURL);
  }

  public setFields(user: User): void {
    this.createAccountForm.get(SignupComponent.FIRST_NAME).setValue(user.firstName);
    this.createAccountForm.get(SignupComponent.LAST_NAME).setValue(user.lastName);
    this.createAccountForm.get(SignupComponent.MOBILE).setValue(user.mobile);
    this.createAccountForm.get(SignupComponent.EMAIL).setValue(user.email);
  }
  public validData(form: UntypedFormGroup): boolean {
    let canBeSaved = true;
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (control instanceof UntypedFormControl) { // if the field is found in the form
        if (this.formErrorMessage(field) !== '') { // if there is an error in the field
          control.markAsTouched({ onlySelf: true }); // mark as touched to trigger displaying the error
          canBeSaved = false; // and the form cannot be saved
        }
      }
    });

    return canBeSaved;
  }

  public save(): Promise<void> {
    if (this.validData(this.createAccountForm) && this.canSave) {
      this.spin2win.show();
      this.canSave = false;

      const fieldsToUpdate = this.processFieldsToUpdate();
      logger.info('fieldsToUpdate' + JSON.stringify(fieldsToUpdate));

      const encryptedInviteData = {
        userId: this.user._id,
        email: this.user.email,
        password: this.createAccountForm.get(SignupComponent.PASSWORD).value,
        confirmPassword: this.createAccountForm.get(SignupComponent.CONFIRM_PASSWORD).value,
        encryptedInviteData: this.inviteKey,
        fieldsToUpdate // fieldsToUpdate: fieldsToUpdate
      };

      return this.setupAuth0(encryptedInviteData)
      .then(() => {
        window.location.href = this.redirectURL;
      })
      .catch((error) => {
        logger.warn('Failed to create account: ' + JSON.stringify(error));
      });
    }

    return null;
  }

  public processFieldsToUpdate(): IUserObjSignUpFieldsToUpdate {
    const fieldsToUpdate: IUserObjSignUpFieldsToUpdate = {
      firstName: null,
      lastName: null,
      name: null,
      mobile: null
    };
    // check first name changes
    if (this.createAccountForm.get(SignupComponent.FIRST_NAME).value !== this.user.firstName) {
      fieldsToUpdate.firstName = this.createAccountForm.get(SignupComponent.FIRST_NAME).value;
    }
    // check last name changes
    if (this.createAccountForm.get(SignupComponent.LAST_NAME).value !== this.user.lastName) {
      fieldsToUpdate.lastName = this.createAccountForm.get(SignupComponent.LAST_NAME).value;
    }
    // change name accordingly
    if (fieldsToUpdate.firstName && fieldsToUpdate.lastName) {
      fieldsToUpdate.name = fieldsToUpdate.firstName + ' ' + fieldsToUpdate.lastName;
    } else if (fieldsToUpdate.firstName) {
      fieldsToUpdate.name = fieldsToUpdate.firstName + ' ' + this.user.lastName;
    } else if (fieldsToUpdate.lastName) {
      fieldsToUpdate.name = this.user.firstName + ' ' + fieldsToUpdate.lastName;
    }
    // check mobile changes
    if (this.createAccountForm.get(SignupComponent.MOBILE).value !== this.user.mobile) {
      fieldsToUpdate.mobile = this.createAccountForm.get(SignupComponent.MOBILE).value;
    }
    let shouldUpdate = false;
    Object.keys(fieldsToUpdate).forEach((field) => {
      if (fieldsToUpdate[field] !== null) {
        return shouldUpdate = true;
      }
    });

    return shouldUpdate ? fieldsToUpdate : null;
  }

  public setupAuth0(encryptedInviteData: IEncryptedInviteData): Promise<void> {
    return new Promise((resolve, reject) => {
      this.usersService.setupAuth0(encryptedInviteData)
        .then((response) => {
          this.spin2win.hide();
          resolve();
        })
        .catch((error) => {
          this.spin2win.hide();
          if (error.status === HttpStatus.CONFLICT) {
            this.showRedirectOpt = true;
          } else {
            let errMsg = '';
            if (error.error && error.error.error) {
              errMsg = error.error.error;
            }
            const errorMsg = 'Oops! An error occurred when creating your account user. ' + errMsg;
            const actionLbl = 'OK';
            const snackBarRef = this.utils.openSnackbar(errorMsg, actionLbl, 10000);
          }
          logger.warn('An error occurred when creating account: ' + JSON.stringify(error));
          this.canSave = true;
          reject(error);
        });
    });
  }

}
