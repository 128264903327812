import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { GenericDataGridComponent, IDataGridActionColumn, IDataSourceConfig, IJoinTableColumnConfig, IStandardDataGridConfig } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { FormatFn, GridConstants } from 'src/app/Constants';
import { StocktakeItemsDataEntryDataGridService } from '@services/grid/stocktake-items-data-entry-data-grid.service';
import { UtilityService } from '@services/utility.service';
import { ErrorMessages } from 'src/app/utilities/ErrorMessages';
@Component({
  selector: 'app-stocktake-entry-count-list',
  templateUrl: './stocktake-entry-count-list.component.html',
  styleUrls: ['./stocktake-entry-count-list.component.scss']
})
export class StocktakeEntryCountListComponent implements OnInit, OnChanges {
  public stocktakeEntryCountListDataSourceConfig: IDataSourceConfig<StocktakeItemsDataEntryDataGridService> = {
    dataSourceName: 'stocktakeEntryCountList',
    dataSourcePrimaryKey: 'stocktakeDataEntryId',
    dataSourceLoadMode: 'processed',
    dataSourceService: this.stocktakeEntryCountDataGridService,
    dataSourceServiceSearchFnRouteId: undefined,
    dataSourceServiceSearchFn: 'searchStocktakeItemsDataEntryByStocktakeId'
  };
  public stocktakeEntryCountJoinTableColumnConfig: IJoinTableColumnConfig<StocktakeItemsDataEntryDataGridService> = {};
  public stocktakeEntryCountListDataGridConfig: IStandardDataGridConfig = {};
  public stocktakeEntryCountListDataGridActionColumn: IDataGridActionColumn = { enabled: false };
  @ViewChild('stocktakeEntryCountListDataGrid', { static: false }) public stocktakeEntryCountListDataGrid: GenericDataGridComponent;
  @Input() public selectedStocktakeId;
  @Output() public readonly totalStockEntryCount = new EventEmitter<number>();

  constructor(
    private stocktakeEntryCountDataGridService: StocktakeItemsDataEntryDataGridService,
    private utils: UtilityService
  ) { this.onDataGridSetup(); }

  public ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.stocktakeEntryCountListDataSourceConfig.dataSourceServiceSearchFnRouteId = this.selectedStocktakeId;
    if (!changes.selectedStocktakeId.firstChange) {
      this.refreshGrid();
    }
  }

  public refreshGrid(): void {
    this.stocktakeEntryCountListDataGrid?.refresh();
  }

  public revertStocktakeCount(currentRowData): void {
    this.utils.openDxConfirmDialog(`Are you sure you want to reverse this count of ${currentRowData.totalInners}?`, 'Confirm Reset')
      .then(async (dialogResult) => {
        if (dialogResult) {
          try {
            await this.stocktakeEntryCountDataGridService.deleteStocktakeItemDataEntry(currentRowData.stocktakeDataEntryId);
            this.stocktakeEntryCountListDataGrid?.refresh();
          } catch (error) {
            const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
            this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} resetting this stocktake count. ` + instruction, 'OK', 10000);
          }
        }
      });
  }

  public onDataGridSetup(): void { // custom config
    this.stocktakeEntryCountListDataGridConfig.columns = [
      {
        dataField: 'createDateTime',
        dataType: 'datetime',
        minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
        caption: 'Date Time',
        allowSearch: false,
        format: FormatFn.dateTimeShortFormat,
        allowEditing: false,
        allowFiltering: true,
        allowGrouping: false,
        allowHeaderFiltering: false,
        allowSorting: false
      },
      {
        dataField: 'userName',
        dataType: 'string',
        minWidth: GridConstants.STANDARD_COLUMN_MIN_WIDTH,
        caption: 'User',
        allowFiltering: false,
        allowGrouping: false,
        allowHeaderFiltering: false,
        allowSearch: false,
        allowSorting: false
      },
      {
        dataField: 'totalInners',
        dataType: 'number',
        alignment: 'left',
        width: 100,
        caption: 'Qty',
        allowFiltering: true,
        allowGrouping: false,
        allowHeaderFiltering: false,
        allowSearch: false,
        allowSorting: false,
        cellTemplate: (cellElement, cellInfo) => {
          cellElement.innerHTML = FormatFn.decimalPointFormat(cellInfo.value) as string;
          if (cellInfo.value > 0 && !cellInfo.data.reversed) {
            cellElement.innerHTML += "<button type='button' class='individual-button reset-entry-count' style='margin: 0px 10px; cursor: pointer; float: right; border:0px; border-radius:3px;'> <i class='material-icons' style='padding-top:2px;font-size: small;'>undo</i> </button>";
            cellElement.querySelector('.reset-entry-count').addEventListener('click', () => { this.revertStocktakeCount(cellInfo.data); return; });
          }
        }
      }
    ];

    this.stocktakeEntryCountListDataGridConfig.columnChooser = {
      enabled: false
    };

    this.stocktakeEntryCountListDataGridConfig.searchPanel = {
      visible: false
    };

    this.stocktakeEntryCountListDataGridConfig.filterPanel = {
      visible: false
    };

    this.stocktakeEntryCountListDataGridConfig.remoteOperations = {
      filtering: false,
      paging: true,
      sorting: false,
      summary: false,
      grouping: false,
      groupPaging: false
    };

    this.stocktakeEntryCountListDataGridConfig.onContentReady = (event) => {
      const count = this.stocktakeEntryCountListDataGrid?.dataGridComponent?.instance.totalCount();
      this.totalStockEntryCount.emit(count);
    };
  }

}
