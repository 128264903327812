/** *
 * @author Danny Csaky <danny.csaky@hlaustralia.com.au>
 * @file menu.component.ts
 * @example
   _____________________________________________________________________________________________
  |                          |
  | your-file.component.html |
  |__________________________|

  <app-edit-floating-menu [editFloatingMenuControl]="editFloatingMenuControl"></app-edit-floating-menu>

   _____________________________________________________________________________________________
  |                          |
  |  your-file.component.ts  |
  |__________________________|

  public ngOnInit(): void {
    this.editFloatingMenuControl.onCancel(() => {
      console.log("Cancel");
    });
    this.editFloatingMenuControl.onSave(() => {
      console.log("Save");
    });
    this.editFloatingMenuControl.visible = true;
  }

  ______________________________________________________________________________________________
 */

import { Component, OnInit, Inject, Input } from '@angular/core';
import { EditFloatingMenuControl } from './EditFloatingMenuControl';

@Component({
  selector: 'app-edit-floating-menu',
  templateUrl: './edit-floating-menu.component.html',
  styleUrls: ['./edit-floating-menu.component.scss']
})
export class EditFloatingMenuComponent implements OnInit {
  @Input() public editFloatingMenuControl: EditFloatingMenuControl;
  public ngOnInit(): void {

  }

  public cancelClicked(): void {
    if (this.editFloatingMenuControl && this.editFloatingMenuControl.onCancelFn) {
      this.editFloatingMenuControl.onCancelFn();
    }
  }

  public saveClicked(): void {
    if (this.editFloatingMenuControl && this.editFloatingMenuControl.onSaveFn) {
      this.editFloatingMenuControl.onSaveFn();
    }
  }
}
