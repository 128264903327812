/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FactorBulkInsertInner } from './factorBulkInsertInner';

/**
 * Factors describe all the various ways stock can be moved or sold.  Example: Whiskey  is purchased by the case or dozen but  sold by the single bottle, 30ml nip and 15ml nip
 */
export interface FactorBulkInsert extends Array<FactorBulkInsertInner> { 
}