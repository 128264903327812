/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StocktakeBulkInsertInner { 
    /**
     * A reference to the primary key in the VenueLocations Table
     */
    locationId?: number;
    status?: string;
    syswan?: number;
    eventId?: number;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
    prepareVerified?: StocktakeBulkInsertInner.PrepareVerifiedEnum;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    preparedDateTime?: Date;
    prepareFailedReason?: string;
}
export namespace StocktakeBulkInsertInner {
    export type PrepareVerifiedEnum = 'not prepared' | 'not verified' | 'verified' | 'failed';
    export const PrepareVerifiedEnum = {
        NotPrepared: 'not prepared' as PrepareVerifiedEnum,
        NotVerified: 'not verified' as PrepareVerifiedEnum,
        Verified: 'verified' as PrepareVerifiedEnum,
        Failed: 'failed' as PrepareVerifiedEnum
    };
}