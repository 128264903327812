/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

export interface SalesSpecialProductsInLocationView { 
    /**
     * The unique identifier of this record.
     */
    id?: number;
    /**
     * A reference to the primary key in the SalesSpecials Table
     */
    salesSpecialId?: number;
    /**
     * The product in location the price will be assigned to.
     */
    productInLocationId?: number;
    /**
     * The price level the price applies to.
     */
    priceLevel?: SalesSpecialProductsInLocationView.PriceLevelEnum;
    /**
     * The price that will be charged.
     */
    price?: number;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    lastUpdateByUserId?: LastUpdateByUserId;
}
export namespace SalesSpecialProductsInLocationView {
    export type PriceLevelEnum = 'a' | 'b' | 'c' | 'd' | 'e';
    export const PriceLevelEnum = {
        A: 'a' as PriceLevelEnum,
        B: 'b' as PriceLevelEnum,
        C: 'c' as PriceLevelEnum,
        D: 'd' as PriceLevelEnum,
        E: 'e' as PriceLevelEnum
    };
}