<dx-popup #addStockItemPopup
    [(visible)]="popupConfig.visible"
    [title]="popupConfig.title"
    [showCloseButton]="true"
    [hideOnOutsideClick]="false"
    [shading]="true"
    shadingColor="rgba(0, 0, 0, 0.3)"
    (onHiding)="popupConfig.onHiding($event)"
    [fullScreen]="globalsService?.isMobile"
    [height]="'auto'"
    [maxHeight]="800"
    [width]="925">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view width="100%" height="100%" class="add-stock-item-accordion-wrapper">
            <dx-form #stockForm
                *ngIf="type === addStockType.STOCK"
                [formData]="stock"
                labelLocation="top"
                [colCount]="4">
                    <dxi-item
                        dataField="name"
                        editorType="dxTextBox"
                        [colSpan]="2"
                        [label]="{text: stockNameInputTitle}"
                        [validationRules]="validationRules.name">
                    </dxi-item>
                    <dxi-item
                        dataField="stockGroupId"
                        [template]="'stockGroupTemplate'"
                        [colSpan]="2"
                        [label]="{text: 'Stock Group'}"
                        [validationRules]="validationRules.stockGroupId">
                    </dxi-item>
                    <dxi-item
                        [template]="'stockCreationTemplate'"
                        [colSpan]="2"
                        [label]="{text: 'Template Name'}">
                    </dxi-item>
                    <div [colSpan]="2"></div>
                    <dxi-item *ngIf="!globalsService?.isMobile"  itemType="empty" [colSpan]="2"></dxi-item>
                    <dxi-item
                        dataField="inventoryCode"
                        editorType="dxNumberBox"
                        [editorOptions]="{step: 0, disabled : isGenerateEditDetails}"
                        [colSpan]="2"
                        [validationRules]="validationRules.inventoryCode">
                    </dxi-item>
                    <dxi-item
                        dataField="lastCost"
                        editorType="dxNumberBox"
                        [editorOptions]="{step: 0, format: currencyFormat, disabled : isClone || isGenerateEditDetails}"
                        [colSpan]="2"
                        [label]="{text: 'Cost'}"
                        [validationRules]="validationRules.lastCost">
                    </dxi-item>
                    <div *dxTemplate="let data of 'stockGroupTemplate'">
                        <dx-lookup
                            [dataSource]="stockGroupDataSource"
                            [displayExpr]="displayExprStockGroup"
                            valueExpr="stockGroupId"
                            placeholder="Select"
                            searchPlaceholder="Search Stock Group"
                            [showClearButton]="false"
                            [dropDownOptions]="dxLookupConfig.dropDownOptions"
                            [(value)]="stock.stockGroupId"
                            [disabled]="isGenerateEditDetails"
                            (onValueChanged)="onStockGroupChanged($event)">
                        </dx-lookup>
                    </div>
                    <div *dxTemplate="let data of 'stockCreationTemplate'">
                        <dx-lookup
                            #stockCreationTemplateLookup
                            [dataSource]="stockCreationTemplateDataSource"
                            displayExpr="name"
                            valueExpr="stockCreationTemplateId"
                            searchPlaceholder="Search Stock Creation Template"
                            [showClearButton]="true"
                            [dropDownOptions]="dxLookupConfig.dropDownOptions"
                            [(value)]="selectedStockCreationTemplateId"
                            [disabled]="isGenerateEditDetails">
                        </dx-lookup>
                    </div>
            </dx-form>

            <dx-form #stockForm
                *ngIf="type !== addStockType.STOCK"
                [formData]="stock"
                labelLocation="top"
                [colCount]="6">
                    <dxi-item
                        dataField="name"
                        editorType="dxTextBox"
                        [colSpan]="3"
                        [label]="{text: stockNameInputTitle}"
                        [validationRules]="validationRules.name">
                    </dxi-item>
                    <dxi-item
                        dataField="inventoryCode"
                        editorType="dxNumberBox"
                        [editorOptions]="{step: 0}"
                        [colSpan]="3"
                        [validationRules]="validationRules.inventoryCode">
                    </dxi-item>
                    <dxi-item
                        dataField="lastCost"
                        editorType="dxNumberBox"
                        [editorOptions]="{step: 0, format: currencyFormat, disabled : isClone}"
                        [colSpan]="2"
                        [label]="{text: 'Cost'}"
                        [validationRules]="validationRules.lastCost">
                    </dxi-item>
                    <dxi-item
                        dataField="stockGroupId"
                        [template]="'stockGroupTemplate'"
                        [colSpan]="2"
                        [label]="{text: 'Stock Group'}"
                        [validationRules]="validationRules.stockGroupId">
                    </dxi-item>
                    <dxi-item
                        dataField="type"
                        [template]="'stockTypeTemplate'"
                        [colSpan]="2"
                        [disabled]="{disabled : isClone}"
                        [label]="{text: 'Stock Type'}"
                        [validationRules]="validationRules.type">
                    </dxi-item>
                    <div *dxTemplate="let data of 'stockGroupTemplate'">
                        <dx-lookup
                            [dataSource]="stockGroupDataSource"
                            [displayExpr]="displayExprStockGroup"
                            valueExpr="stockGroupId"
                            placeholder="Select"
                            searchPlaceholder="Search Stock Group"
                            [showClearButton]="false"
                            [dropDownOptions]="dxLookupConfig.dropDownOptions"
                            [(value)]="stock.stockGroupId">
                        </dx-lookup>
                    </div>
                    <div *dxTemplate="let data of 'stockTypeTemplate'">
                        <dx-lookup
                            [dataSource]="types"
                            displayExpr="label"
                            valueExpr="type"
                            placeholder="Select"
                            [searchEnabled]="false"
                            [showClearButton]="false"
                            [dropDownOptions]="dxLookupConfig.dropDownOptions"
                            [(value)]="stock.type">
                        </dx-lookup>
                    </div>
            </dx-form>

            <div class="add-stock-item-accordion">
                <dx-accordion *ngIf="isGenerateEditDetails" #addStockItemAccordion [items]="accordionItems" [collapsible]="true" [multiple]="true"
                    (onContentReady)="onContentReady($event)" [animationDuration]="300">
                    <div *dxTemplate="let title of 'factorTitleTemp'" i18n="factors|@@factors">Factors</div>
                    <div *dxTemplate="let content of 'factorTempl'">
                        <app-add-stock-from-template-data-grid
                            #fromTemplateOverrideGridFactors
                            *ngIf="selectedStockCreationTemplateId && type === addStockType.STOCK && isGenerateEditDetails"
                            [data]="stockFromTemplate.factors"
                            [dataGridConfig]="fromTemplateOverrideGrid.factors"
                            [isFactor]="true"
                            (factorRemoved)="onFactorRemoved($event)"
                        ></app-add-stock-from-template-data-grid>
                    </div>
    
                    <div *dxTemplate="let title of 'productTitleTemp'" i18n="products|@@products">Products</div>
                    <div *dxTemplate="let content of 'productTempl'">
                        <app-add-stock-from-template-data-grid
                            #fromTemplateOverrideGridProducts
                            *ngIf="selectedStockCreationTemplateId && type === addStockType.STOCK && isGenerateEditDetails"
                            [data]="stockFromTemplate.products"
                            [dataGridConfig]="fromTemplateOverrideGrid.products"
                            [associatedItemsToRemove]="productsToRemove"
                            [isProduct]="true"
                        ></app-add-stock-from-template-data-grid>
                    </div>
    
                    <div *dxTemplate="let title of 'sILTitleTemp'" i18n="rangeSIL|@@rangeSIL">Range Stock To Locations</div>
                    <div *dxTemplate="let content of 'sILTempl'">
                        <app-add-stock-from-template-data-grid
                            #fromTemplateOverrideGridSIL
                            *ngIf="selectedStockCreationTemplateId && type === addStockType.STOCK && isGenerateEditDetails"
                            [data]="stockFromTemplate.stockInLocations"
                            [dataGridConfig]="fromTemplateOverrideGrid.stockInLocations"
                        ></app-add-stock-from-template-data-grid>
                    </div>
                </dx-accordion>
            </div>
            <div class="form-btn-container">
                <dx-button
                    class="form-generate"
                    *ngIf="selectedStockCreationTemplateId && type === addStockType.STOCK && !isGenerateEditDetails && stockForm?.instance?.validate()?.isValid"
                    stylingMode="contained"
                    text="Generate / Edit Details"
                    (onClick)="generateEditDetails()">
                </dx-button>
                <dx-button
                    class="form-save-and-finish"
                    stylingMode="contained"
                    [text]="!isGenerateEditDetails ? 'Save' : 'Save and Finish'"
                    (onClick)="save()">
                </dx-button>
                <dx-button
                    class="form-cancel"
                    stylingMode="outlined"
                    text="Cancel"
                    (onClick)="hidePopup()">
                </dx-button>
            </div>

            <dx-load-panel [(visible)]="savePanelVisible" message="Saving ..." [showIndicator]="true" [showPane]="true"
                [shading]="false" [hideOnOutsideClick]="false">
            </dx-load-panel>
            <dx-load-panel [(visible)]="loadPanelVisible" message="Loading ..." [showIndicator]="true" [showPane]="true"
                [shading]="false" [hideOnOutsideClick]="false">
            </dx-load-panel>
        </dx-scroll-view>
    </div>
</dx-popup>