import { Injectable } from '@angular/core';
import { ICustomStoreLoadResult } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { StocktakeItemView } from '@modules/SDKs/sysnetApi';
import { StocktakeItemsService } from '@services/sysnet/stocktake-items.service';
import { LoadOptions } from 'devextreme/data';
import { IGroupQueryResultBody } from 'src/app/types/interfaces/GeneralService';
import { LoadOptionsHelper } from 'src/app/utilities/LoadOptionsHelper';

@Injectable({
  providedIn: 'root'
})
export class StocktakeItemsDataGridService {

  constructor(
    private stocktakeItemsService: StocktakeItemsService
  ) {
  }

  // Get StocktakeItem
  public async getStocktakeItem(key): Promise<StocktakeItemView> {
    if (key) {
      const stocktakeItemRes = await this.stocktakeItemsService.getStocktakeItem(key);
      return stocktakeItemRes.body;
    }
  }

  // Search StocktakeItems
  public async searchStocktakeItems(loadOptions: LoadOptions, view?: string): Promise<ICustomStoreLoadResult<StocktakeItemView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const stocktakeItemsRes = await this.stocktakeItemsService.searchStocktakeItems(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort,
      loadOptions.take, loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<StocktakeItemView> = {
      data: parsedLoadOptions.group ? (stocktakeItemsRes.body as unknown as IGroupQueryResultBody).data : stocktakeItemsRes.body,
      totalCount: +stocktakeItemsRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  // Delete StocktakeItem
  public async deleteStocktakeItem(key): Promise<void> {
    if (key) {
      await this.stocktakeItemsService.deleteStocktakeItem(key);
    }
  }

  public async searchStocktakeItemsByStocktakeId(loadOptions: LoadOptions, view?: string, stocktakeId?: number): Promise<ICustomStoreLoadResult<StocktakeItemView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    if (stocktakeId === undefined) {
      return { data: [], totalCount: 0, summary: null, groupCount: null };
    }
    const stocktakeIdSearchExpr = { stocktakeId: { $eq: stocktakeId } };
    if (parsedLoadOptions.search) {
      parsedLoadOptions.search = {
        $and: [parsedLoadOptions.search, stocktakeIdSearchExpr]
      };
    } else {
      parsedLoadOptions.search = stocktakeIdSearchExpr;
    }
    const stocktakeItemsRes = await this.stocktakeItemsService.searchStocktakeItems(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort,
      loadOptions.take, loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<StocktakeItemView> = {
      data: parsedLoadOptions.group ? (stocktakeItemsRes.body as unknown as IGroupQueryResultBody).data : stocktakeItemsRes.body,
      totalCount: +stocktakeItemsRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }
}
