/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StocktakeItemsDataEntryInsert { 
    /**
     * A reference to the primary key in the StocktakeItems Table
     */
    stocktakeItemsId: number;
    /**
     * The type of Stock Take Data Entry. Values are 'inners', 'outers', or 'factor'. If using factor a factorId must be specified.
     */
    type: StocktakeItemsDataEntryInsert.TypeEnum;
    /**
     * A reference to the primary key in the Factors Table, only required when type is Factor
     */
    factorId?: number;
    /**
     * When type is one of ‘Inners’, ‘Outers’, ‘Factor’, qtyCount should be a positive number or string type (the string will contain a valid positive number). When type is ‘InnersOutersTotalCount’, qtyCount should be a string in the format of <innersCount>,<outersCount> e.g. 10,2. Each number should be a positive number.
     */
    qtyCounted?: string;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
}
export namespace StocktakeItemsDataEntryInsert {
    export type TypeEnum = 'Factor' | 'Inners' | 'Outers' | 'InnersOutersTotalCount';
    export const TypeEnum = {
        Factor: 'Factor' as TypeEnum,
        Inners: 'Inners' as TypeEnum,
        Outers: 'Outers' as TypeEnum,
        InnersOutersTotalCount: 'InnersOutersTotalCount' as TypeEnum
    };
}