import { Injectable } from '@angular/core';
import { ICustomStoreLoadResult } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { ProductView } from '@modules/SDKs/sysnetApi/model/productView';
import { ProductsService } from '@services/sysnet/products.service';
import { LoadOptions } from 'devextreme/data';
import { LoadOptionsHelper } from 'src/app/utilities/LoadOptionsHelper';
import { IGroupQueryResultBody } from 'src/app/types/interfaces/GeneralService';
import { IFactor } from './factors-data-grid.service';

export enum Modifiers {
  NO_MODIFIER = 'No modifier', NORMAL_MODIFIER = 'Normal modifier', GROUP_MODIFIER = 'Group modifier', POSITION_MODIFIER = 'Position modifier',
  PRINTER_CHANGE_MODIFIER = 'Printer change modifier', ORDER_NUMBER_MODIFIER = 'Order Number modifier', REASON_CODE_MODIFIER = 'Reason Code modifier',
  MEMBER_FEES = 'Member Fees', TICKET_MODIFIER = 'Ticket modifier', PACKAGE = 'Package', TIMER = 'Timer', NON_KITCHEN_PRINTED = 'Non-kitchen printed'
}

export interface IProduct {
  productId: number;
  fullName: string;
  shortName?: string;
  factorId?: number;
  Factor?: IFactor;
}

@Injectable({
  providedIn: 'root'
})
export class ProductsDataGridService {

  constructor(
    private productsService: ProductsService
  ) {
  }

  // Get Product
  public async getProduct(key, view?: string): Promise<ProductView> {
    if (key) {
      const productRes = await this.productsService.getProduct(key, view);
      return productRes.body;
    }
  }

  // Search Products
  public async searchProducts(loadOptions: LoadOptions, view?: string): Promise<ICustomStoreLoadResult<ProductView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const productsRes = await this.productsService.searchProducts(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort,
      loadOptions.take, loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<ProductView> = {
      data: parsedLoadOptions.group ? (productsRes.body as unknown as IGroupQueryResultBody).data : productsRes.body,
      totalCount: +productsRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  // Insert Product
  public async insertProduct(values): Promise<ProductView> {
    const productResult = await this.productsService.insertProduct(values);
    return productResult.body;
  }

  // Update Product
  public async updateProduct(key, values): Promise<void> {
    if (Object.keys(values).length !== 0) {
      await this.productsService.updateProduct(key, values);
    }
  }

  // Delete Product
  public async deleteProduct(key): Promise<void> {
    if (key) {
      await this.productsService.deleteProduct(key);
    }
  }

  public async searchProductByMultipleSearchExprWithProductId(loadOptions: LoadOptions): Promise<ICustomStoreLoadResult<ProductView>> {
    let search;
    if (loadOptions.searchValue) {
      search = {
        $or: [{ shortName: { $iLike: '*' + (loadOptions.searchValue as string) + '*' } }]
      };
      if (!isNaN(loadOptions.searchValue)) {
        search.$or.push({ productId: { $eq: Number(loadOptions.searchValue) } } as object);
      }
    }
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const productsRes = await this.productsService.searchProducts(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort,
      loadOptions.take, loadOptions.skip, null, search);
    const value: ICustomStoreLoadResult<ProductView> = {
      data: parsedLoadOptions.group ? (productsRes.body as unknown as IGroupQueryResultBody).data : productsRes.body,
      totalCount: +productsRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  // Search Products for Find and Set Prices
  public async searchProductsWithPriceListPricesOnly(loadOptions: LoadOptions, view?: string, routeId?: number, customFilter?: {
    deferred: boolean; priceNameId?: number, priceChangesHeaderId?: number
}): Promise<ICustomStoreLoadResult<ProductView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);

    const { priceNameId, priceChangesHeaderId } = customFilter;

    if (!priceNameId || !priceChangesHeaderId) {
      return { data: [], totalCount: 0, summary: null, groupCount: null };
    }
    const take = loadOptions.take || 200;
    // tslint:disable-next-line:strict-boolean-expressions
    const skip = loadOptions.skip || 0;
    const hasPagination = loadOptions.hasOwnProperty('take') && loadOptions.hasOwnProperty('skip');

    let products = [];
    let productsRes;

    if (customFilter.deferred) {
      let currentSkip = skip;
      do {
        productsRes = await this.productsService.searchProductsWithPriceListPricesOnly(priceNameId, priceChangesHeaderId,
          parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort,
          take, currentSkip, view, parsedLoadOptions.search
        );
        if (productsRes?.body?.length > 0) {
          products = products.concat(productsRes.body);
          currentSkip += take;
        }
      } while (!hasPagination && products.length < +productsRes.headers.get('total_available_results_count'));
    } else {
      productsRes = await this.productsService.searchProductsWithPriceListPricesOnly(priceNameId, priceChangesHeaderId,
        parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort,
        take, skip, view, parsedLoadOptions.search
      );
      products = productsRes.body;
    }
    const value: ICustomStoreLoadResult<ProductView> = {
      data: parsedLoadOptions.group ? (products as unknown as IGroupQueryResultBody).data : products,
      totalCount: +productsRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }
}
