<div class="stock-mobile-common-header">
    <dx-toolbar class="dx-toolbar-common">
        <dxi-item location="before" locateInMenu="auto">
            <a href="javascript:void(0)" (click)="goBack()">
                <img src="../../../../assets/img/icons/back-arrow.svg" />
            </a>
        </dxi-item>
        <dxi-item location="center" locateInMenu="auto" text="{{stockMobileService?.commonHeaderTitle}}" cssClass="toolbar-title">
        </dxi-item>
        <dxi-item location="after" locateInMenu="auto">
            <a href="javascript:void(0)" (click)="goToHome()">
                <img src="../../../../assets/img/icons/home-black.svg" />
            </a>
        </dxi-item>
    </dx-toolbar>
</div>