import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class FrameworkService {

  public menuOpenedEvent = new Subject<string>();
  public menuClosedEvent = new Subject<string>();

  public menuOpened$: Observable<string> = this.menuOpenedEvent.asObservable();
  public menuClosed$: Observable<string> = this.menuClosedEvent.asObservable();

  public menuOpened(menuName: string): void {
    this.menuOpenedEvent.next(menuName);
  }

  public menuClosed(menuName: string): void {
    this.menuClosedEvent.next(menuName);
  }

}
