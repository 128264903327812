
<div mat-dialog-content class="yes-no-dialog">
  <div class="icon-holder" *ngIf="iconPath">
    <img [src]="iconPath">
  </div>
  <h1 id="dialogTitle" mat-dialog-title i18n="yes no dialog title|@@ynDialog_title">{{title}}</h1>
<!-- <mat-icon>{{iconPath}}</mat-icon> -->
  <p id="dialogContent" class="responsive-font-size" i18n="yes no dialog content|@@ynDialog_content"></p>
</div>
<div mat-dialog-actions class="btn-footer">
  <button type="button" class="individual-button responsive-font-size" i18n="confirm|@@confirm" id="dialogConfirm" mat-button (click)="onYesClick()" mat-dialog-close >{{confirmLbl}}</button>
  <button type="button" class="secondary-button responsive-font-size" i18n="cancel|@@cancel" id="dialogCancel" mat-button (click)="onNoClick()" mat-dialog-close cdkFocusInitial>{{cancelLbl}}</button>
</div>