/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

export interface TaxNameView { 
    /**
     * The unique identifier of this record.
     */
    taxNameId?: number;
    salesTax3Name?: string;
    salesTax3Code?: string;
    salesTax0Name?: string;
    salesTax0Code?: string;
    salesTax1Name?: string;
    salesTax1Code?: string;
    salesTax2Name?: string;
    salesTax2Code?: string;
    organisationId?: string;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    wholesaleTax0Name?: string;
    wholesaleTax0Code?: string;
    wholesaleTax1Name?: string;
    wholesaleTax1Code?: string;
    wholesaleTax2Name?: string;
    wholesaleTax2Code?: string;
    wholesaleTax3Name?: string;
    wholesaleTax3Code?: string;
}