/**
 * Portal API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { UserResponse } from '../model/userResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UsersprovisionService {

    protected basePath = 'https://sandbox-portal.hlcloud.com.au';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Gets a the user object from portal for the current bearerToken. Provisions the user with appropriate access if necessary
     * @param rid_chain The rid_chain is used to track the current users session. It should be a unique value that identifies a users particular session in a particular device. When events are received through H&amp;L websocket events, the current user&#x27;s session can be identified with this value. As such this value can help you determine which device a user was on when they made a request on this API.
     * @param x_hal_forwarded_for This will only be accepted if coming from a trusted ip address. Use this when making a request from another server. This is mainly used by apps
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersProvisionGet(rid_chain?: string, x_hal_forwarded_for?: string, observe?: 'body', reportProgress?: boolean): Observable<UserResponse>;
    public usersProvisionGet(rid_chain?: string, x_hal_forwarded_for?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserResponse>>;
    public usersProvisionGet(rid_chain?: string, x_hal_forwarded_for?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserResponse>>;
    public usersProvisionGet(rid_chain?: string, x_hal_forwarded_for?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (rid_chain !== undefined && rid_chain !== null) {
            headers = headers.set('rid_chain', String(rid_chain));
        }
        if (x_hal_forwarded_for !== undefined && x_hal_forwarded_for !== null) {
            headers = headers.set('x-hal-forwarded-for', String(x_hal_forwarded_for));
        }

        // authentication (BearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserResponse>('get',`${this.basePath}/users/provision`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
