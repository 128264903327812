import { Injectable, OnDestroy } from '@angular/core';
import { ICustomStoreLoadResult } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { StockCloneInsert, StockView } from '@modules/SDKs/sysnetApi';
import { TemplateStockCreationRequest } from '@modules/SDKs/sysnetApi/model/templateStockCreationRequest';
import { TemplateStockCreationResponse } from '@modules/SDKs/sysnetApi/model/templateStockCreationResponse';
import { GlobalsService } from '@services/global.service';
import { StockService } from '@services/sysnet/stock.service';
import { UtilityService } from '@services/utility.service';
import { LoadOptions } from 'devextreme/data';
import { Subscription } from 'rxjs';
import { IGroupQueryResultBody } from 'src/app/types/interfaces/GeneralService';
import { ErrorMessages } from 'src/app/utilities/ErrorMessages';
import { LoadOptionsHelper } from 'src/app/utilities/LoadOptionsHelper';
import { IQueryFilter } from 'src/app/types/GeneralTypes';

export enum StockTypes { 'Stock', 'Recipe(SalesBased)', 'NonStockKeepingItem', 'Recipe(ProductionBased)', 'Package' }
export enum StockTypesId { STOCK = 0, RECIPE_SB = 1, NON_STOCK = 2, RECIPE_PB = 3, PACKAGE = 4 }

export interface IStock {
  stockId: number;
  name: string;
  inventoryCode: string;
  inners: string;
  innersPerOuter: number;
  StockStatus: {
    stockStatusId: number;
    description: string;
    markForDeath: boolean;
    disallowStock: boolean;
  };
}
@Injectable({
  providedIn: 'root'
})
export class StockDataGridService implements OnDestroy {

  public readonly types = [
    { type: 0, label: 'Stock' },
    { type: 1, label: 'Recipe (Sales Based)' },
    { type: 2, label: 'Non Stock Keeping Item' },
    { type: 3, label: 'Recipe (Production Based)' },
    { type: 4, label: 'Package' }
  ];
  public readonly discretionaryStatuses = [
    { discretionaryStatus: 0, label: 'Non Discretionary' },
    { discretionaryStatus: 1, label: 'Partial Discretionary' },
    { discretionaryStatus: 2, label: 'Full Discretionary' },
    { discretionaryStatus: 3, label: 'Semi Non Discretionary' }
  ];
  public readonly trackByMethods = [
    { trackByMethod: 0, label: 'Not Tracked' },
    { trackByMethod: 1, label: 'Serial Number' },
    { trackByMethod: 2, label: 'Batch Number' }
  ];
  public readonly packageRevenueSplitTypes = [
    { packageRevenueSplitType: 0, label: 'None' },
    { packageRevenueSplitType: 1, label: 'Cost Percentage' },
    { packageRevenueSplitType: 2, label: 'Retail Percentage' },
    { packageRevenueSplitType: 3, label: 'Manual Percentage' }
  ];
  private $state: Subscription;
  private orgId: string;

  constructor(
    private globals: GlobalsService,
    private stockService: StockService,
    private utils: UtilityService
  ) {
    this.$state = this.globals.$state.subscribe((newState) => {
      if (newState) { this.orgId = newState.selectedOrganisation?._id; }
    });
  }

  public ngOnDestroy(): void {
    if (this.$state) { this.$state.unsubscribe(); }
  }

  // Get Stock
  public async getStock(key, view?: string): Promise<StockView> {
    if (key) {
      const stockRes = await this.stockService.getStock(key, view);
      return stockRes.body;
    }
  }

  // Search Stock
  public async searchStock(loadOptions: LoadOptions, view?: string, routeId?: number, customFilter?: IQueryFilter): Promise<ICustomStoreLoadResult<StockView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    if (customFilter) {
      if (parsedLoadOptions.search) {
        parsedLoadOptions.search = {
          $and: [parsedLoadOptions.search, customFilter]
        };
      } else {
        parsedLoadOptions.search = customFilter;
      }
    }
    const stockRes = await this.stockService.searchStock(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take,
      loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<StockView> = {
      data: parsedLoadOptions.group ? (stockRes.body as unknown as IGroupQueryResultBody).data : stockRes.body,
      totalCount: +stockRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  // Insert Stock
  public async insertStock(values): Promise<StockView> {
    const body = {
      ...values,
      organisationId: this.orgId
    };
    const stockResult = await this.stockService.insertStock(body);
    return stockResult.body;
  }

  // Clone Stock
  public async cloneStock(stockId: number, values: StockCloneInsert): Promise<StockView[]> {
    const stockResult = await this.stockService.cloneStock(stockId, values);
    return stockResult.body;
  }

  // Get From Template
  public async getFromTemplate(name: string, stockCreationTemplateId: number): Promise<TemplateStockCreationResponse[]> {
    const stockResult = await this.stockService.getFromTemplate(name, stockCreationTemplateId);
    return stockResult.body;
  }

  // Insert From Template
  public async insertFromTemplate(body: TemplateStockCreationRequest): Promise<TemplateStockCreationResponse> {
    const stockResult = await this.stockService.insertFromTemplate(body);
    return stockResult.body;
  }

  // Update Stock
  public async updateStock(key, values): Promise<void> {
    if (Object.keys(values).length !== 0) {
      await this.stockService.updateStock(key, values);
    }
  }

  // Delete Stock
  public async deleteStock(key): Promise<void> {
    if (key) {
      await this.stockService.deleteStock(key);
    }
  }

  public async isInventoryCodeUnique(inventoryCode: string | number, stockId: number = null): Promise<boolean> {
    const invCode = inventoryCode;
    if (invCode === null || invCode === undefined) { return true; }
    const search = { inventoryCode: invCode };
    try {
      const result = await this.stockService.searchStock(null, null, null, null, null, null, null, search);
      if (result?.body?.length === 0) {
        return true; // no duplicate => is unique
      } else if (stockId && result?.body?.length === 1 && result?.body[0]?.stockId === stockId) {
        return true; // found itself and only itself => is unique
      }
      return false; // all other cases, not unique
    } catch (error) {
      const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
      this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} validation inventory code. ` + instruction, 'OK', 10000);
      return false;
    }
  }

  public async searchStockByMultipleSearchExpr(loadOptions: LoadOptions): Promise<ICustomStoreLoadResult<StockView>> {
    let search;
    if (loadOptions.searchValue) {
      search = {
        $or: [{ name: { $iLike: '*' + (loadOptions.searchValue as string) + '*' } }]
      };
      if (!isNaN(loadOptions.searchValue)) {
        search.$or.push({ inventoryCode: { $eq: Number(loadOptions.searchValue) } } as object);
      }
    }
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const stockRes = await this.stockService.searchStock(null, null,
      parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take, loadOptions.skip, null, search);
    const value: ICustomStoreLoadResult<StockView> = {
      data: stockRes.body,
      totalCount: +stockRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  public async searchStockByRecipes(loadOptions: LoadOptions, view?: string): Promise<ICustomStoreLoadResult<StockView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const recipesSearchExpr = { $or: [{ type: { $eq: StockTypes['Recipe(SalesBased)'] } }, { type: { $eq: StockTypes['Recipe(ProductionBased)'] } }] };
    if (parsedLoadOptions.search) {
      parsedLoadOptions.search = {
        $and: [parsedLoadOptions.search, recipesSearchExpr]
      };
    } else {
      parsedLoadOptions.search = recipesSearchExpr;
    }
    const stockRes = await this.stockService.searchStock(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take,
      loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<StockView> = {
      data: parsedLoadOptions.group ? (stockRes.body as unknown as IGroupQueryResultBody).data : stockRes.body,
      totalCount: +stockRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  public async searchStockExceptSalesBasedRecipeAndPackage(loadOptions: LoadOptions, view?: string): Promise<ICustomStoreLoadResult<StockView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const searchExpr = { $and: [{ type: { $not: StockTypes['Recipe(SalesBased)'] } }, { type: { $not: StockTypes.Package } }] };
    if (parsedLoadOptions.search) {
      parsedLoadOptions.search = {
        $and: [parsedLoadOptions.search, searchExpr]
      };
    } else {
      parsedLoadOptions.search = searchExpr;
    }
    const stockRes = await this.stockService.searchStock(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take,
      loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<StockView> = {
      data: parsedLoadOptions.group ? (stockRes.body as unknown as IGroupQueryResultBody).data : stockRes.body,
      totalCount: +stockRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  public async searchStockByMultipleSearchExprWithStockId(loadOptions: LoadOptions): Promise<ICustomStoreLoadResult<StockView>> {
    let search;
    if (loadOptions.searchValue) {
      search = {
        $or: [{ name: { $iLike: '*' + (loadOptions.searchValue as string) + '*' } }]
      };
      if (!isNaN(loadOptions.searchValue)) {
        search.$or.push({ inventoryCode: { $eq: Number(loadOptions.searchValue) } } as object);
        search.$or.push({ stockId: { $eq: Number(loadOptions.searchValue) } } as object);
      }
    }
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const stockRes = await this.stockService.searchStock(null, null,
      parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take, loadOptions.skip, null, search);
    const value: ICustomStoreLoadResult<StockView> = {
      data: stockRes.body,
      totalCount: +stockRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  public async searchStockByMultipleSearchExprAndStockType(loadOptions: LoadOptions, view?: string, stockType?: StockTypes): Promise<ICustomStoreLoadResult<StockView>> {
    let search;
    if (loadOptions.searchValue) {
      search = {
        $or: [{ name: { $iLike: '*' + (loadOptions.searchValue as string) + '*' } }]
      };
      if (!isNaN(loadOptions.searchValue)) {
        search.$or.push({ inventoryCode: { $eq: Number(loadOptions.searchValue) } } as object);
      }
    }
    const type = stockType === StockTypes.Package ? stockType : { $not: StockTypes.Package };
    search = {
      ...search,
      type
    };
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const stockRes = await this.stockService.searchStock(null, null,
      parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take, loadOptions.skip, null, search);
    const value: ICustomStoreLoadResult<StockView> = {
      data: stockRes.body,
      totalCount: +stockRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }
}
