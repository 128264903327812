/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MixAndMatchPromotionBulkInsertInner } from './mixAndMatchPromotionBulkInsertInner';

/**
 * Mix and Match is a promotional tool that allows a customer to 'mix and match' items to meet the desired criteria for a promotion, with great flexibility.
 */
export interface MixAndMatchPromotionBulkInsert extends Array<MixAndMatchPromotionBulkInsertInner> { 
}