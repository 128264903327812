/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

export interface StocktakeBulkInsertViewInsertedObject { 
    /**
     * The unique identifier of this record.
     */
    stocktakeId?: number;
    /**
     * A reference to the primary key in the VenueLocations Table
     */
    locationId?: number;
    status?: string;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    datePrepared?: Date;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    dateProcessed?: Date;
    discrepancyValue?: number;
    syswan?: number;
    eventId?: number;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    prepared?: boolean;
    processed?: boolean;
    sheetsPrinted?: boolean;
    dataEntered?: boolean;
    reportPrinted?: boolean;
    prepareVerified?: StocktakeBulkInsertViewInsertedObject.PrepareVerifiedEnum;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    preparedDateTime?: Date;
    prepareFailedReason?: string;
    /**
     * calculated field - The total number of stocktakeItems of the stocktake
     */
    itemsInStocktake?: number;
    /**
     * calculated field - The difference between the counted stocktakeItems and the total number of stocktakeItems of the stocktake, itemsCounted - itemsInStocktake
     */
    itemVariance?: number;
    /**
     * calculated field - The total cost of items as at the date of preparation, sum of (qtyAtStocktake * AvgCost) of all stocktakeItems that links to the stocktake
     */
    valueAtStocktake?: number;
    /**
     * calculated field - the total value of items counted, sum of (qtyStockEntered * AvgCost) of all stocktakeItems that links to the stocktake
     */
    valueCounted?: number;
    /**
     * calculated field - The difference between the total cost of items selected at stocktake and the items counted, valueCounted - valueAtStocktake
     */
    valueVariance?: number;
}
export namespace StocktakeBulkInsertViewInsertedObject {
    export type PrepareVerifiedEnum = 'not prepared' | 'not verified' | 'verified' | 'failed';
    export const PrepareVerifiedEnum = {
        NotPrepared: 'not prepared' as PrepareVerifiedEnum,
        NotVerified: 'not verified' as PrepareVerifiedEnum,
        Verified: 'verified' as PrepareVerifiedEnum,
        Failed: 'failed' as PrepareVerifiedEnum
    };
}