/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

export interface PurchaseOrderDetailView { 
    /**
     * The unique identifier of this record.
     */
    purchaseOrderDetailId?: number;
    /**
     * A reference to the primary key in the PurchaseOrderHeaders Table
     */
    purchaseOrderHeaderId?: number;
    /**
     * A reference to the primary key in the Stock Table
     */
    stockId?: number;
    /**
     * A reference to the primary key in the SupplierInventoryCodes Table
     */
    supplierInventoryCodeId?: number;
    unitType?: PurchaseOrderDetailView.UnitTypeEnum;
    /**
     * A reference to the primary key in the Factors Table
     */
    factorId?: number;
    unitQtyToOrder?: number;
    qtyToOrder?: number;
    totalInnersToOrder?: number;
    costPerOuter?: number;
    totalCost?: number;
    lastUpdateByUserId?: LastUpdateByUserId;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
    qtyInStock?: number;
    qtyRequired?: number;
    qtyOnOrder?: number;
}
export namespace PurchaseOrderDetailView {
    export type UnitTypeEnum = 'Factor' | 'Inner' | 'Outer';
    export const UnitTypeEnum = {
        Factor: 'Factor' as UnitTypeEnum,
        Inner: 'Inner' as UnitTypeEnum,
        Outer: 'Outer' as UnitTypeEnum
    };
}