/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SupplierInsert { 
    code: string;
    name?: string;
    comment?: string;
    useSupplierCode?: boolean;
    depositIncludedInLine?: boolean;
    wholesaleTaxIncludedInLine?: boolean;
    freightIncludedInLine?: boolean;
    organisationId: string;
    surname?: string;
    firstName?: string;
    otherNames?: string;
    salutation?: string;
    companyName?: string;
    addressLine1?: string;
    addressLine2?: string;
    suburbName?: string;
    postcode?: string;
    state?: string;
    country?: string;
    workPhone?: string;
    homePhone?: string;
    mobilePhone?: string;
    fax?: string;
    email?: string;
    www?: string;
    /**
     * This field will accept any date accepted by the javascript function Date.Parse(). You may want to include your timezone e.g. '12 jan 2020 5:30:20 GMT+10:30'
     */
    dateOfLastActivity?: Date;
    deliveryAddressLine1?: string;
    deliveryAddressLine2?: string;
    deliverySuburb?: string;
    deliveryPostcode?: string;
    deliveryState?: string;
    accountNumber?: string;
    salesTaxIncludedInLine?: boolean;
    abn?: string;
    salesTaxFree?: boolean;
    representativeName?: string;
    representativePhone?: string;
    representativeFax?: string;
    headOfficeCode?: string;
    /**
     * A reference to the primary key in the SupplierTypes Table
     */
    supplierTypeId?: number;
    acn?: string;
    receiveStockColumnsToSkip?: string;
    preferredSupplier?: boolean;
    xrefCode?: string;
    stockOrdersColumnsToSkip?: string;
    ordersRequireSupplierItemCode?: boolean;
    enforceValidFactorSupplierItemCode?: boolean;
    clublineId?: number;
    supplierLeadTime?: number;
    deliveryInstructions1?: string;
    deliveryInstructions2?: string;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
    /**
     * A reference to the primary key in the Suppliers Table
     */
    masterSupplierId?: number;
}