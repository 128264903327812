/**
 * @author Sam Tukey
 * @email sam.tuckey@hlaustralia.com.au
 * @create date 2019-07-30 11:57:01
 * @modify date 2019-07-30 11:57:01
 * @desc Configures and exports a logger for use within applications.
 *  To Configure:
 *  Uses the following AppConfiguration settings:
 * 'LOG_LEVEL' - Will log all logs >= to this level - default 'info'
 * 'LOG_TO_CONSOLE' - default is false, if true will log to console
 * 'LOG_TO_CONSOLE_LEVEL'; // - defaults to 'LOG_LEVEL' (which by default is info), can be 'info', 'silly', 'verbose', 'warn', 'error'
 *
 *  To use for logging purposes:
 *  import logger from '{path_to_hal_common}/hal.common/utilities/Logger'
 *  logger.info("Info message");
 *  logger.info({ cool: "json/javascript object man" });
 *  logger.warn("Warn message");
 *  logger.error("Error message");
 */
// tslint:disable-next-line: variable-name
import { AppConfig } from './AppConfig';
import { rsh } from './AppConfig/helpers/RequestStorageHandler';
import ILogger from './ILogger';

export class HLLogger implements ILogger {

    public static sensitiveFields = [
        'password', 'username', 'authorization', 'cardnumber', 'ccnum',
        'cc-number', 'token', 'bearer', 'bearerToken', 'accessToken',
        'idToken', 'cvv', 'ccv'];
    private logToConsole = false;
    private logLevel = 'info';

    private logLevels = {
        debug: 1,
        info: 2,
        warn: 3,
        error: 4,
    };

    /**
     * Configures teh logger with settings from the AppConfiguration
     * @param appConfig
     */
    public configureLogging(appConfig: AppConfig) {
        // clear out the old transports
        if (!appConfig) {
            return;
        }
        // keep console but dont log to it if false
        if (appConfig.GetConfiguration(appConfig.LOG_TO_CONSOLE)) {
            this.logToConsole = true;
        }

        this.logLevel = 'info';
        const tempLogLevel = appConfig.GetConfiguration(appConfig.LOG_LEVEL);
        if (tempLogLevel && this.logLevels[tempLogLevel]) {
            this.logLevel = tempLogLevel;
        }
    }
    private copyCleanObject(inMessage) {
        let message = copyObject(inMessage);
        // get rid of sensitive data from logging
        message = removeJSONMetaFromString(message, HLLogger.sensitiveFields);

        removeObjectMeta(message, HLLogger.sensitiveFields);
        return message;

    }
    private formatter(message: any, level) {
        const d = new Date().toUTCString();

        const logObject = {
            rid: rsh.getStrVal('rid') !== '' ? rsh.getStrVal('rid') :  undefined,
            level,
            message,
            d,
        };
        return JSON.stringify(logObject);
    }

    /**
     * Allows app to close.
     */
    public close(): Promise<Array<void>> {
        return Promise.resolve([]);
    }

    private logMessage(inMessage, intendedLogLevel) {
        if (this.logToConsole && this.logLevels[this.logLevel] <= this.logLevels[intendedLogLevel]) {

            const message = this.copyCleanObject(inMessage);
            const messageToLog = this.formatter(message, intendedLogLevel);
            if (typeof message === 'object') {
                console.log(messageToLog, message);
            } else {
                console.log(messageToLog);
            }
        }

    }
    public debug(message: any) {
        this.logMessage(message, 'debug');
    }
    public info(message: any) {
        this.logMessage(message, 'info');
    }
    public warn(message: any) {
        this.logMessage(message, 'warn');
    }
    public error(message: any) {
        this.logMessage(message, 'error');
    }
    /**
     * log a message
     * @param level  level of message
     * @param message  actual message that can be interpolated with meta
     */
    public log(level: string, message: any, ...meta: any[]) {
        this.logMessage(message, level);
    }
}

function copyObject(obj) {
    if (typeof obj !== 'object') {
        return obj;
    }

    return JSON.parse(JSON.stringify(obj));
}
/**
 * Removes fields data from an object. Will recurse the object structure by default.
 * @param obj - the object you want fields removed from (at any level)
 * @param fieldNames - the names of the fields you want removed.
 * @param caseInsensitive - whether you want a case insensitive removal done.
 * @param recurse - whether you want to recurse the object
 */
export function removeJSONMetaFromString(logMessage: any, fieldNames: string[]) {

    if (typeof logMessage !== 'string') {
        return logMessage;
    }
    let returnString = logMessage as string;

    fieldNames.forEach((fieldName) => {
        const re = new RegExp(`"${fieldName}":".*?"`, 'g');
        returnString = returnString.replace(re, '"' + fieldName + '":""');
    });

    return returnString;
}

/**
 * Removes fields data from an object. Will recurse the object structure by default.
 * @param obj - the object you want fields removed from (at any level)
 * @param fieldNames - the names of the fields you want removed.
 * @param caseInsensitive - whether you want a case insensitive removal done.
 * @param recurse - whether you want to recurse the object
 */
function removeObjectMeta(obj: any, fieldNames: string[], caseInsensitive = true, recurse = true) {

    if (typeof obj !== 'object') {
        return;
    }

    if (caseInsensitive) {
        fieldNames = fieldNames.map((val) => val.toLowerCase());
    }

    // tslint:disable-next-line: forin
    for (const prop in obj) {
        let match = prop;
        if (caseInsensitive) {
            match = prop.toLowerCase();
        }
        if (fieldNames.indexOf(match) >= 0) {
            delete obj[prop];
        }
        else if (typeof obj[prop] === 'object' && recurse) {
            removeObjectMeta(obj[prop], fieldNames);
        }
    }
}

const logger = new HLLogger();

// logger.configureLogging(AppConfiguration);

// AppConfiguration.onChange(() => {
//     logger.configureLogging(AppConfiguration);
// });

export default logger;
