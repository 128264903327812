<dx-popup #scannerPopup [(visible)]="showBarcode" [title]="scanPopupConfig.title" [showCloseButton]="true"
    [hideOnOutsideClick]="false" [shading]="true" shadingColor="rgba(0, 0, 0, 0.3)"
    [fullScreen]="globals?.isMobile" (onHiding)="scannerClosing()">
        <div id="barcode-scanner-ui">
            <app-barcode-camera *ngIf="showBarcode" (scanSuccess)="scanSuccessHandler($event)"></app-barcode-camera>
        </div>
</dx-popup>

<dx-popup [(visible)]="assignBarcodePopupConfig.visible" [title]="assignBarcodePopupConfig.title" [showCloseButton]="false"
    [hideOnOutsideClick]="false" [shading]="true" shadingColor="rgba(0, 0, 0, 0.3)" [maxWidth]="400" [maxHeight]="assignBarcodePopupConfig.maxHeight"
    (onHidden)="assignBarcodePopupConfig.onHidden($event)" [wrapperAttr]="{ class: 'assign-barcode-popup' }"
    [fullScreen]="globals?.isMobile">
    <div class="assign-barcode-popup-container">
      <div class="assign-barcode-container">
        <div class="assign-barcode-content">
        <dx-form [formData]="assignBarcodeForm" labelLocation="top" [elementAttr]="{ class: 'assign-barcode-form' }">
              <dxi-item [template]="'barcodeTemplate'" [label]="{text: 'Enter Barcode', showColon: false}"
              [validationRules]="validationRules.barcode"></dxi-item>
              <dxi-item *ngIf="itemType === 'Stock'" dataField="stockId" [template]="'stockTemplate'" [label]="{text: 'Stock Item', showColon: false}"
                  [validationRules]="validationRules.stockId">
              </dxi-item>
              <dxi-item *ngIf="itemType === 'Stock'" dataField="factorId" [template]="'assignBarcodeFactorTemplate'" [label]="{text: 'Factor', showColon: false}"
                  [validationRules]="validationRules.factorId">
              </dxi-item>
              <dxi-item *ngIf="itemType === 'Product'" dataField="productId" [template]="'productTemplate'" [label]="{text: 'Product Name', showColon: false}"
                  [validationRules]="validationRules.productId">
              </dxi-item>
              
    
              <div *dxTemplate="let data of 'barcodeTemplate'">
                  <dx-text-box [(value)]="barcodeInput" [inputAttr]="{ inputmode: 'numeric', pattern: '[0-9]*' }"
                    [validationStatus]="barcodeInputValidationStatus" [validationErrors]="inputValidationErrors">
                  </dx-text-box>
              </div>
              <div *dxTemplate="let data of 'stockTemplate'">
                  <dx-lookup #assignBarcodeStockLookup [dataSource]="stockDataSource" [displayExpr]="displayExprStock" valueExpr="stockId"
                      placeholder="Select" searchPlaceholder="Search" [showClearButton]="false" [(value)]="assignBarcodeForm.stockId"
                      (onValueChanged)="onStockSelected($event)" [validationStatus]="stockInputValidationStatus" [validationErrors]="inputValidationErrors">
                      <dxo-drop-down-options [fullScreen]="globals?.isMobile" [hideOnOutsideClick]="true">
                      </dxo-drop-down-options>
                  </dx-lookup>
              </div>
              <div *dxTemplate="let data of 'assignBarcodeFactorTemplate'">
                  <dx-lookup #assignBarcodeFactorsLookup [items]="assignBarcodeFormFactors" [displayExpr]="displayExprFactors"
                      valueExpr="factorId" placeholder="Select" searchPlaceholder="Search Description" [showClearButton]="false"
                      [disabled]="!assignBarcodeForm.stockId" [(value)]="assignBarcodeForm.factorId" searchExpr="Container.description"
                      [validationStatus]="factorInputValidationStatus" [validationErrors]="inputValidationErrors">
                      <dxo-drop-down-options [fullScreen]="globals?.isMobile" [hideOnOutsideClick]="true">
                      </dxo-drop-down-options>
                  </dx-lookup>
              </div>
              <div *dxTemplate="let data of 'productTemplate'">
                <dx-lookup #assignBarcodeProductLookup [dataSource]="productDataSource" displayExpr="fullName" valueExpr="productId" searchExpr="fullName"
                    placeholder="Select" searchPlaceholder="Search" [showClearButton]="false" [(value)]="assignBarcodeForm.productId"
                    [validationStatus]="productInputValidationStatus" [validationErrors]="inputValidationErrors">
                    <dxo-drop-down-options [fullScreen]="globals?.isMobile" [hideOnOutsideClick]="true">
                    </dxo-drop-down-options>
                </dx-lookup>
            </div>
          </dx-form>
        
        </div>
        <div class="form-btn-container">
          <dx-button class="form-save" stylingMode="contained" text="Save" (onClick)="assignBarcode()"
              [disabled]="assignBarcodeLoadPanelVisible">
          </dx-button>
          <dx-button class="form-cancel" stylingMode="outlined" text="Cancel" (onClick)="cancelAssignBarcode()">
          </dx-button>
        </div>
      </div>
    </div>
</dx-popup>