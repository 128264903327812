/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MixAndMatchPromotionBulkInsertInner { 
    organisationId?: string;
    /**
     * Used to identify the promotion
     */
    name?: string;
    /**
     * The date that the promotion starts.
     */
    startDate?: Date;
    /**
     * The date that the promotion ends.
     */
    endDate?: Date;
    /**
     * The total price of the promotion. You can choose to use totalPrice or itemsPriceLevel.
     */
    totalPrice?: number;
    /**
     * The price level to use for items in the promotion. The promotion price will be calculated using the sum of the items at the price level chosen. You can choose to use itemsPriceLevel or totalPrice.
     */
    itemsPriceLevel?: MixAndMatchPromotionBulkInsertInner.ItemsPriceLevelEnum;
    /**
     * The discount percentage value is only used when using items price level. The discount percentage is applied to each item.
     */
    discountItemsPercentValue?: number;
    /**
     * The discount items amount value is only used when using items price level. The discount is evenly shared between the items purchased.
     */
    discountItemsAmountValue?: number;
    /**
     * This is the id of product that is given away with this promotion. The sale price would be recorded as $0.
     */
    giveawayProductId?: number;
    /**
     * If set the total of all discounts are written to this product as a negative value when recording a sale and won't be attributed to individual items.
     */
    discountAttributionProductId?: number;
    /**
     * Determines if the promotion only available to members.
     */
    membersOnly?: boolean;
    /**
     * If this is set to true it is available to all locations, otherwise lookup the available locations in MixAndMatchLocations.
     */
    availableAllLocations?: boolean;
    /**
     * Specifies how to deal with items already on special. 'do not include' will not include the discounted item in the mix and match promotion. 'include' will allow the items to be included with all discount rules applied. 'include_no_further_discount' will not apply any further discounts to the items.
     */
    itemsOnSpecialBehaviour?: MixAndMatchPromotionBulkInsertInner.ItemsOnSpecialBehaviourEnum;
    /**
     * When applying a mix and match promotion, this setting determines how the pos should behave. If 'prompt user' the user will have the option of applying the promotion. If 'auto apply with warn' the promotion will be applied, but the user will be warned. If 'auto apply no warn' the promotion will be applied without warning.
     */
    posWarningBehaviour?: MixAndMatchPromotionBulkInsertInner.PosWarningBehaviourEnum;
    /**
     * Determines whether further discounting is allowed on this promotion.
     */
    allowFurtherDiscountsAtPos?: boolean;
    /**
     * Determines whether this promotion can only be used once per sale or if it can be used multiple times in one sale.
     */
    singleUsePerSale?: boolean;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
}
export namespace MixAndMatchPromotionBulkInsertInner {
    export type ItemsPriceLevelEnum = 'a' | 'b' | 'c' | 'd' | 'e' | 'null';
    export const ItemsPriceLevelEnum = {
        A: 'a' as ItemsPriceLevelEnum,
        B: 'b' as ItemsPriceLevelEnum,
        C: 'c' as ItemsPriceLevelEnum,
        D: 'd' as ItemsPriceLevelEnum,
        E: 'e' as ItemsPriceLevelEnum,
        Null: 'null' as ItemsPriceLevelEnum
    };
    export type ItemsOnSpecialBehaviourEnum = 'do not include' | 'include' | 'include_no_further_discount';
    export const ItemsOnSpecialBehaviourEnum = {
        DoNotInclude: 'do not include' as ItemsOnSpecialBehaviourEnum,
        Include: 'include' as ItemsOnSpecialBehaviourEnum,
        IncludeNoFurtherDiscount: 'include_no_further_discount' as ItemsOnSpecialBehaviourEnum
    };
    export type PosWarningBehaviourEnum = 'promp user' | 'auto-apply with-warn' | 'auto-apply no-warn';
    export const PosWarningBehaviourEnum = {
        PrompUser: 'promp user' as PosWarningBehaviourEnum,
        AutoApplyWithWarn: 'auto-apply with-warn' as PosWarningBehaviourEnum,
        AutoApplyNoWarn: 'auto-apply no-warn' as PosWarningBehaviourEnum
    };
}