import { Injectable } from '@angular/core';
import { GlobalsService } from '@services/global.service';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { HttpResponse } from '@angular/common/http';
import { ProductsService as SDKProductsService } from '@modules/SDKs/sysnetApi/api/products.service';
import { GroupQuery, GroupSummaryQuery } from '@modules/SDKs/sysnetApi';
import { ProductView } from '@modules/SDKs/sysnetApi/model/productView';
import { ProductInsert } from '@modules/SDKs/sysnetApi/model/productInsert';
import { ProductUpdate } from '@modules/SDKs/sysnetApi/model/productUpdate';
import { ServicesWrappingSysnetSDK } from './ServicesWrappingSysnetSDK';

@Injectable({
    providedIn: 'root'
})
export class ProductsService extends ServicesWrappingSysnetSDK {
    private orgId: string;

    constructor(private sdkProductsService: SDKProductsService, private globals: GlobalsService) {
        super([sdkProductsService], globals);
        this.globals.$state.subscribe((newState) => {
            if (newState) { this.orgId = newState.selectedOrganisation?._id; }
        });
    }

    // Get Product
    public getProduct(productId: number, view?: string): Promise<HttpResponse<ProductView>> {
        return this.sdkProductsService.productsIdGet(productId, AppConfiguration.rsh.getStrVal('rid'), view, 'response').toPromise();
    }

    // Search Products
    public searchProducts(group?: GroupQuery, groupSummary?: GroupSummaryQuery, totalSummary?: GroupSummaryQuery,
                          sort?: string, limit?: number, offset?: number, view?: string, search?: object, searchDistinct?: string): Promise<HttpResponse<ProductView[]>> {
        return this.sdkProductsService.productsGet(AppConfiguration.rsh.getStrVal('rid'), view,
        this.stringify(group), this.stringify(groupSummary), this.stringify(totalSummary), searchDistinct, sort, limit, offset, this.stringify(search),
        null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,
        null, null, null, null, null, null, null, null, null, null, null, null, null, null, this.orgId, null, null, 'response').toPromise();
    }

    // Insert Product
    public insertProduct(product: ProductInsert): Promise<HttpResponse<ProductView>> {
        return this.sdkProductsService.productsPost(product, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Update Product
    public updateProduct(productId: number, product: ProductUpdate): Promise<HttpResponse<ProductView[]>> {
        return this.sdkProductsService.productsIdPut(productId, product, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Delete Product
    public deleteProduct(productId: number): Promise<HttpResponse<ProductView>> {
        return this.sdkProductsService.productsIdDelete(productId, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Search Products
    public searchProductsWithPriceListPricesOnly(priceNameId: number, priceChangeHeaderId: number,
                                                 group?: GroupQuery, groupSummary?: GroupSummaryQuery, totalSummary?: GroupSummaryQuery,
                                                 sort?: string, limit?: number, offset?: number, view?: string, search?: object,
                                                 searchDistinct?: string): Promise<HttpResponse<ProductView[]>> {
        return this.sdkProductsService.productsGet(AppConfiguration.rsh.getStrVal('rid'), view,
        this.stringify(group), this.stringify(groupSummary), this.stringify(totalSummary), searchDistinct, sort, limit, offset, this.stringify(search),
        null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,
        null, null, null, null, null, null, null, null, null, null, null, null, null, null, this.orgId, priceNameId, priceChangeHeaderId, 'response').toPromise();
    }

}
