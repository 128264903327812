/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UnitType } from './unitType';

export interface CalculatedLineCosts { 
    /**
     * The supplier inventory code
     */
    supplierInventoryCode?: string;
    unitType?: UnitType;
    /**
     * The choses stock factor. Only returned when unit type is Factor
     */
    factorId?: number;
    /**
     * The Id of the stock item we are calculating line costs for.
     */
    stockId?: number;
    /**
     * The total for the line including taxes.
     */
    lineTotal?: number;
    /**
     * The dollar value of the wholesaleTax 0, In australia this would generally be used for WET.
     */
    wholesaleTax0?: number;
    /**
     * The dollar value of the wholesaleTax1.
     */
    wholesaleTax1?: number;
    /**
     * The dollar value of the wholesaleTax2.
     */
    wholesaleTax2?: number;
    /**
     * The dollar value of the wholesaleTax3.
     */
    wholesaleTax3?: number;
    /**
     * The dollar value of the salesTax 0, In australia this would generally be used for GST.
     */
    salesTax0?: number;
    /**
     * The dollar value of the sales tax 1.
     */
    salesTax1?: number;
    /**
     * The dollar value of the sales tax 2.
     */
    salesTax2?: number;
    /**
     * The dollar value of the sales tax 3.
     */
    salesTax3?: number;
    /**
     * The number of items received
     */
    unitQtyReceived?: number;
    /**
     * The total value of the line including all taxes.
     */
    total?: number;
    /**
     * Whether sales tax is included in the line total
     */
    salesTaxIncluded?: number;
    /**
     * Whether wholesale tax is included in the line total
     */
    wholesaleTaxIncluded?: number;
    /**
     * Whether freight is included in LineTotal. Defaults to false.
     */
    freightIncluded?: boolean;
    /**
     * The cost of the freight. Default to zero.
     */
    freight?: number;
    /**
     * Extra costs that are associated with the line item e.g packaging. That include a GST component. Defaults to zero.
     */
    taxableSundries?: number;
    /**
     * Extra costs that are associated with the line item that do not have a component. Defaults to zero.
     */
    nonTaxableSundries?: number;
    /**
     * Not currently used for calculations.
     */
    depositIncluded?: boolean;
    /**
     * The cost of the deposit. Default to zero. Not currently used for calculations.
     */
    deposit?: number;
    /**
     * The total inners received.
     */
    totalInnersReceived?: number;
}