/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TaxRateUpdate { 
    wholesaleTax0?: number;
    wholesaleTax1?: number;
    wholesaleTax2?: number;
    wholesaleTax3?: number;
    salesTax0?: number;
    salesTax1?: number;
    salesTax2?: number;
    salesTax3?: number;
    qtyTaxFree?: number;
    isSalesTaxCumulative?: boolean;
    name?: string;
    code?: string;
    organisationId?: string;
    /**
     * The unique external key set by the current user accessing the API
     */
    externalKey?: string;
}