import { Injectable } from '@angular/core';
import { ICustomStoreLoadResult } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { FactorView } from '@modules/SDKs/sysnetApi';
import { FactorsService } from '@services/sysnet/factors.service';
import { LoadOptions } from 'devextreme/data';
import { LoadOptionsHelper } from 'src/app/utilities/LoadOptionsHelper';
import { IGroupQueryResultBody } from 'src/app/types/interfaces/GeneralService';
import { IStock } from './stock-data-grid.service';

export interface IFactor {
  factorId: string;
  stockId: number;
  divider: number;
  Container: {
    containerId: number;
    description: string;
  };
  Stock: IStock;
}

export interface IDefaultOptions {
    factorId: string | number;
    Container: {
        description: string;
    };

}

@Injectable({
  providedIn: 'root'
})
export class FactorsDataGridService {

  constructor(
    private factorsService: FactorsService
  ) {
  }

  // Get Factor
  public async getFactor(key, view?: string): Promise<FactorView> {
    if (key) {
      const factorRes = await this.factorsService.getFactor(key, view);
      return factorRes.body;
    }
  }

  // Search Factors
  public async searchFactors(loadOptions: LoadOptions, view?: string): Promise<ICustomStoreLoadResult<FactorView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const factorsRes = await this.factorsService.searchFactors(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take,
      loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<FactorView> = {
      data: parsedLoadOptions.group ? (factorsRes.body as unknown as IGroupQueryResultBody).data : factorsRes.body,
      totalCount: +factorsRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  // Insert Factor
  public async insertFactor(values): Promise<FactorView> {
    const factorResult = await this.factorsService.insertFactor(values);
    return factorResult.body;
  }

  // Update Factor
  public async updateFactor(key, values): Promise<void> {
    if (Object.keys(values).length !== 0) {
      await this.factorsService.updateFactor(key, values);
    }
  }

  // Delete Factor
  public async deleteFactor(key): Promise<void> {
    if (key) {
      await this.factorsService.deleteFactor(key);
    }
  }

  public async searchFactorsByStockId(loadOptions: LoadOptions, view?: string, stockId?: number): Promise<ICustomStoreLoadResult<FactorView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    if (stockId === undefined) {
      return { data: [], totalCount: 0, summary: null, groupCount: null };
    }
    const stockIdSearchExpr = { stockId: { $eq: stockId } };
    if (parsedLoadOptions.search) {
      parsedLoadOptions.search = {
        $and: [parsedLoadOptions.search, stockIdSearchExpr]
      };
    } else {
      parsedLoadOptions.search = stockIdSearchExpr;
    }
    const factorsRes = await this.factorsService.searchFactors(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take,
      loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<FactorView> = {
      data: parsedLoadOptions.group ? (factorsRes.body as unknown as IGroupQueryResultBody).data : factorsRes.body,
      totalCount: +factorsRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }

  public async searchFactorsByStockIdWithDefaultOptionInner(loadOptions: LoadOptions, view?: string, stockId?: number): Promise<ICustomStoreLoadResult<FactorView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const defaultOptionInner = { factorId: null, Container: { description: 'Inner' } };
    const stockIdSearchExpr = { stockId: { $eq: stockId } };
    if (parsedLoadOptions.search) {
      parsedLoadOptions.search = {
        $and: [parsedLoadOptions.search, stockIdSearchExpr]
      };
    } else {
      parsedLoadOptions.search = stockIdSearchExpr;
    }
    const factorsRes = await this.factorsService.searchFactors(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take,
      loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<FactorView> = {
      data: parsedLoadOptions.group ? (factorsRes.body as unknown as IGroupQueryResultBody).data : factorsRes.body,
      totalCount: +factorsRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    if (loadOptions.skip === 0) {
      (value.data as [IDefaultOptions, ...FactorView[]]).unshift(defaultOptionInner as IDefaultOptions);
    }
    return value;
  }

  public async searchFactorsByStockIdWithDefaultOption(loadOptions: LoadOptions, view?: string, stockId?: number): Promise<ICustomStoreLoadResult<FactorView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    const defaultOptionInner = { factorId: 'Inner', Container: { description: 'Inner' } };
    const defaultOptionOuter = { factorId: 'Outer', Container: { description: 'Outer' } };
    const stockIdSearchExpr = { stockId: { $eq: stockId } };
    if (parsedLoadOptions.search) {
      parsedLoadOptions.search = {
        $and: [parsedLoadOptions.search, stockIdSearchExpr]
      };
    } else {
      parsedLoadOptions.search = stockIdSearchExpr;
    }
    const factorsRes = await this.factorsService.searchFactors(
      parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take,
      loadOptions.skip, view, parsedLoadOptions.search);
    const value: ICustomStoreLoadResult<FactorView> = {
      data: parsedLoadOptions.group ? (factorsRes.body as unknown as IGroupQueryResultBody).data : factorsRes.body,
      totalCount: +factorsRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    if (loadOptions.skip === 0) {
      (value.data as [IDefaultOptions, ...FactorView[]]).unshift(defaultOptionInner as IDefaultOptions, defaultOptionOuter as IDefaultOptions);
    }
    return value;
  }

  public async searchFactorsByStockIdAndReturnAllData(loadOptions: LoadOptions, view?: string, stockId?: number): Promise<ICustomStoreLoadResult<FactorView>> {
    const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
    if (stockId === undefined) {
      return { data: [], totalCount: 0, summary: null, groupCount: null };
    }
    const stockIdSearchExpr = { stockId: { $eq: stockId } };
    if (parsedLoadOptions.search) {
      parsedLoadOptions.search = {
        $and: [parsedLoadOptions.search, stockIdSearchExpr]
      };
    } else {
      parsedLoadOptions.search = stockIdSearchExpr;
    }
    const take = 200;
    let skip = 0;
    let factors = [];
    let factorsRes;

    do {
      factorsRes = await this.factorsService.searchFactors(
        parsedLoadOptions.group, parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, take, skip, view, parsedLoadOptions.search);
      if (factorsRes?.body?.length > 0) {
        factors = factors.concat(factorsRes.body);
        skip += take;
      }
    } while (factors.length < +factorsRes.headers.get('total_available_results_count'));
    const value: ICustomStoreLoadResult<FactorView> = {

      data: parsedLoadOptions.group ? (factorsRes.body).data : factorsRes.body,
      totalCount: +factorsRes.headers.get('total_available_results_count'),
      summary: null,
      groupCount: null
    };
    return value;
  }
}
