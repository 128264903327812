import { Injectable, OnDestroy } from '@angular/core';
import { ICustomStoreLoadResult } from '@components/generic/generic-data-grid/generic-data-grid.component';
import { StockGroupView } from '@modules/SDKs/sysnetApi';
import { GlobalsService } from '@services/global.service';
import { StockGroupsService } from '@services/sysnet/stock-groups.service';
import { LoadOptions } from 'devextreme/data';
import { Subscription } from 'rxjs';
import { IGroupQueryResultBody } from 'src/app/types/interfaces/GeneralService';
import { LoadOptionsHelper } from 'src/app/utilities/LoadOptionsHelper';

@Injectable({
    providedIn: 'root'
})
export class StockGroupsDataGridService implements OnDestroy {
    private $state: Subscription;
    private orgId: string;

    constructor(
        private globals: GlobalsService,
        private stockGroupsService: StockGroupsService
    ) {
        this.$state = this.globals.$state.subscribe((newState) => {
            if (newState) { this.orgId = newState.selectedOrganisation?._id; }
        });
    }

    public ngOnDestroy(): void {
        if (this.$state) { this.$state.unsubscribe(); }
    }

    // Get StockGroup
    public async getStockGroup(key): Promise<StockGroupView> {
        if (key) {
            const stockGroupRes = await this.stockGroupsService.getStockGroup(key);
            return stockGroupRes.body;
        }
    }

    // Search StockGroups
    public async searchStockGroups(loadOptions: LoadOptions, view?: string): Promise<ICustomStoreLoadResult<StockGroupView>> {
        const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
        const stockGroupsRes = await this.stockGroupsService.searchStockGroups(parsedLoadOptions.group, parsedLoadOptions.groupSummary,
            parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take, loadOptions.skip, view, parsedLoadOptions.search);
        const value: ICustomStoreLoadResult<StockGroupView> = {
            data: parsedLoadOptions.group ? (stockGroupsRes.body as unknown as IGroupQueryResultBody).data : stockGroupsRes.body,
            totalCount: +stockGroupsRes.headers.get('total_available_results_count'),
            summary: null,
            groupCount: null
        };
        return value;
    }

    // Insert StockGroup
    public async insertStockGroup(values): Promise<StockGroupView> {
        const body = {
            ...values,
            organisationId: this.orgId
        };
        const stockGroupResult = await this.stockGroupsService.insertStockGroup(body);
        return stockGroupResult.body;
    }

    // Update StockGroup
    public async updateStockGroup(key, values): Promise<void> {
        if (Object.keys(values).length !== 0) {
            await this.stockGroupsService.updateStockGroup(key, values);
        }
    }

    // Delete StockGroup
    public async deleteStockGroup(key): Promise<void> {
        if (key) {
            await this.stockGroupsService.deleteStockGroup(key);
        }
    }

    public async searchStockGroupsByMultipleSearchExpr(loadOptions: LoadOptions): Promise<ICustomStoreLoadResult<StockGroupView>> {
        let search;
        if (loadOptions.searchValue) {
            search = {
                $or: [{ name: { $iLike: '*' + (loadOptions.searchValue as string) + '*' } }]
            };
            if (!isNaN(loadOptions.searchValue)) {
                search.$or.push({ number: { $eq: Number(loadOptions.searchValue) } } as object);
            }
        }
        const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
        const stockGroupsRes = await this.stockGroupsService.searchStockGroups(null, null,
            parsedLoadOptions.totalSummary, parsedLoadOptions.sort, loadOptions.take, loadOptions.skip, null, search);
        const value: ICustomStoreLoadResult<StockGroupView> = {
            data: stockGroupsRes.body,
            totalCount: +stockGroupsRes.headers.get('total_available_results_count'),
            summary: null,
            groupCount: null
        };
        return value;
    }

    public async searchStockGroupsReturnAllDataIfNoPagination(loadOptions: LoadOptions, view?: string): Promise<ICustomStoreLoadResult<StockGroupView>> {
        const parsedLoadOptions = LoadOptionsHelper.loadOptionsParser(loadOptions);
        const take = loadOptions.take || 25;

        let skip = loadOptions.skip || 0;
        const hasPagination = loadOptions.hasOwnProperty('take') && loadOptions.hasOwnProperty('skip');
        let groups = [];
        let stockGroupRes;
        do {
            stockGroupRes = await this.stockGroupsService.searchStockGroups(parsedLoadOptions.group,
                parsedLoadOptions.groupSummary, parsedLoadOptions.totalSummary, parsedLoadOptions.sort, take, skip, view, parsedLoadOptions.search);
            if (stockGroupRes?.body?.length > 0) {
                groups = groups.concat(stockGroupRes.body);
                skip += take;
            }
        } while ((!hasPagination && groups.length < +stockGroupRes.headers.get('total_available_results_count')));
        const value: ICustomStoreLoadResult<StockGroupView> = {
            data: (parsedLoadOptions.group && hasPagination) ? (groups as unknown as IGroupQueryResultBody).data : groups,
            totalCount: +stockGroupRes.headers.get('total_available_results_count'),
            summary: null,
            groupCount: null
        };
        return value;
    }
}
