import * as request from 'xhr-request';
import { IRequestPromiseOptions, IRequestPromiseResponse } from '../utilities/AppConfig';

const requestPromise: (options: IRequestPromiseOptions) => Promise<any> = (options: IRequestPromiseOptions) => {

    return new Promise<any>((resolve, reject) => {

        if (options.headers && options.headers['User-Agent'] !== undefined) {
            delete options.headers['User-Agent'];
        }
        request(options.uri, options, (err, data, responseObj: any) => {
            const emptyJson = (err && (err.message === 'Unexpected end of JSON input' ||
                                       err.message === 'JSON.parse: unexpected end of data at line 1 column 1 of the JSON data' ||
                                       err.message === 'JSON Parse error: Unexpected EOF' ));

            if (err && !emptyJson) {
                reject(err);
            }
            if (options.resolveWithFullResponse) {
                const res: IRequestPromiseResponse = responseObj;
                if (!emptyJson) {
                    res.body = data;
                    res.headers = responseObj.headers;
                }
                else {
                    res.body = undefined;
                }

                if ((res.statusCode + '').startsWith('2') && (res.statusCode + '').length === 3) {
                    resolve(res);
                }
                else {
                    res.error = res.body;
                    reject(res);
                }

            }
            else {
                if (emptyJson) {
                    resolve(undefined);
                    return;
                }
                resolve(data);
            }
        });
    });

};

export default requestPromise;
