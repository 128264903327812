import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserResponse as User } from '@modules/SDKs/portalApi';
import { User as UserUtilities } from '@hal.common.ui/utilities/User';
import { GlobalsService, IState } from '@services/global.service';
import { UtilityService } from '@services/utility.service';
import { LocationResponse as Location, LocationResponseOrganisation as Organisation } from '@hal.common.ui/modules/portalSDK/api';
import { DefaultSettingsService } from '@services/sysnet/default-settings.service';
import { DEFAULT_HOME_ROUTE } from '../Constants';
import { UsersService } from '../services/users.service';

@Injectable()
export class BaseGuard implements CanActivate {

  constructor(
    protected router: Router,
    protected userService: UsersService,
    protected globals: GlobalsService,
    protected utils: UtilityService,
    protected defaultSettingsService: DefaultSettingsService
  ) { }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
      return true;
  }

  public async checkPermissionAtCurrentOrg(accessIds: string, defaultRoute = ['reports', 'Sales Dashboard'], errMsg?: string): Promise<boolean | UrlTree> {
    const selectedOrgId = localStorage.getItem('organisation');
    if (!selectedOrgId) {
      return Promise.resolve(this.router.createUrlTree(defaultRoute));
    }
    const org: Organisation = { _id: selectedOrgId };

    return this.checkPermissions(accessIds, defaultRoute, undefined, org, errMsg);
  }

  public async checkPermissions(accessIds: string, defaultRoute = ['reports', 'Sales Dashboard'],
                                location?: Location, organisation?: Organisation, errMsg?: string): Promise<boolean | UrlTree> {
    let user: User;
    try {
      user = await this.userService.getLoggedInUser();
    } catch (userErr) {
      // do nothing, for now...
    }
    if (user) {
      let hasAccess = false;
      const userUtils = new UserUtilities(user);
      if (location || organisation) {

        hasAccess = userUtils.hasDataAccesCodesPermissionLocation(accessIds, undefined, location, organisation);
      } else {
        hasAccess = userUtils.hasDataAccesCodes(accessIds);
      }
      if (!hasAccess) {
        if (errMsg) {
          this.utils.openSnackbar(errMsg, 'OK', 5000);
        }

        return Promise.resolve(this.router.createUrlTree(defaultRoute));
      }
      return Promise.resolve(hasAccess);
    } else {
      // if there's no logged in user, do not allow access.

      return Promise.resolve(this.router.createUrlTree(defaultRoute));
    }
  }

  public async checkOrganisationSettingPermission(accessId: string, defaultRoute = DEFAULT_HOME_ROUTE): Promise<boolean | UrlTree> {
    const selectedOrgId = localStorage.getItem('organisation');
    const hasAccess = await this.defaultSettingsService.getOrganisationSettingValue(accessId, selectedOrgId) === 'true';
    return hasAccess ? Promise.resolve(hasAccess) : Promise.resolve(this.router.createUrlTree(defaultRoute));
  }
}
