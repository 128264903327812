/* eslint-disable max-lines */
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from '@services/utility.service';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data';
import dxDataGrid, { Column, Properties as dxDataGridOptions, EditorPreparingEvent, EditorPreparedEvent, Row, Selection, RowKeyInfo, Summary } from 'devextreme/ui/data_grid';
import { Item } from 'devextreme/ui/menu';
import { BreakpointsTriggers, GridConstants, ILookupDataSourceConfig, ListDetailTabIndex } from 'src/app/Constants';
import { ErrorMessages } from 'src/app/utilities/ErrorMessages';
import { IExtendedEditorPreparingEvent, IExtendedTabItem, IExtendedRow, IExtendedStateStoring, IGroupQueryResultBody, ISummary } from 'src/app/types/interfaces/GeneralService';
import { ClickEvent } from 'devextreme/ui/button';

export interface ICustomStoreLoadResult<T={}> {
  data: T[] | IGroupQueryResultBody['data'];
  totalCount: number;
  summary: IGroupQueryResultBody['summary'] | null;
  groupCount: number[] | number | null;
}
export interface IJoinTableColumnConfig<T={}> {
  [dataFieldName: string]: {
    joinModelName: string;
    joinTableDataSource: ILookupDataSourceConfig<T>;
    joinTableDataSourceLoadMode?: 'raw' | 'processed';
    joinTableDataSourceService: T | object;
    joinTableDataSourceServiceSearchFnView?: string;
    joinTableDataSourceServiceSearchFnRouteId?: number;
    joinTableDataSourceServiceSearchFn: string;
    joinTableDataSourceServiceGetFnView?: string;
    joinTableDataSourceServiceGetFn: string;
    joinTablePrimaryKey?: string;
    joinTableDataSortBy?: string;
    cachedObjects?: { [key: string]: string|number|boolean|object }[] // only needed if a data grid has two lookups referencing same table
    joinTablePrimaryKeyAlias?: string; // Use this property when the joined tables share the same primary key but have different names for that key in their respective tables.
  };
}

export interface IDataSourceConfig<T={}> {
  dataSourceName: string;
  dataSourcePrimaryKey: string;
  dataSourceLoadMode: 'raw' | 'processed';
  dataSourceService: T | object; // service for the main data source e.g. stock list data grid - stockService
  dataSourceServiceSearchFnView?: string;
  dataSourceServiceSearchFnRouteId?: number | string;
  dataSourceServiceSearchFnArgs?: {};
  dataSourceServiceSearchFn: string;
  dataSourceServiceInsertFn?: string;
  dataSourceServiceUpdateFn?: string;
  dataSourceServiceDeleteFn?: string;
}

export interface IDataGridActionColumn {
  enabled: boolean;
  actionMenu?: [Item]; // length 1
  processActionMenu?: ((cellInfo, actionMenu: [Item]) => [Item]);
  onActionItemClick?: ($event, cellInfo) => void;
  onSaveClick?: (cellInfo) => boolean;
  onCancelClick?: () => boolean;
}

export { EditorPreparedEvent as IEditorPrepared, EditorPreparingEvent as IEditorPreparingEvent };

interface IDataGridColumnCustomConfig {
  _castToIntWhenSearch?: boolean;
  saveOnEnterKey?: boolean;
}
interface IStandardDataGridCustomConfig {
  hasEditMode?: boolean;
  onReset?: () => void;
  onLoading?: (loadOptions: LoadOptions) => void;
  onLoaded?: (result: ICustomStoreLoadResult) => void;
}

export interface IDataGridColumnConfig extends Column, IDataGridColumnCustomConfig { }
export interface IStandardDataGridConfig extends dxDataGridOptions, IStandardDataGridCustomConfig { }

@Component({
  selector: 'app-generic-data-grid',
  templateUrl: './generic-data-grid.component.html',
  styleUrls: ['./generic-data-grid.component.scss']
})
export class GenericDataGridComponent implements OnInit {
  public selectedRowKey; // for single selection
  public filterRowVisible = true;
  public isEditMode = false;
  public lastSelectedRowIndex = 0;
  public lastSelectedColumnIndex = 0;
  public lastEditorIndex: number;
  public actionColumnIndex: number;
  public currentRowIndex = 0;
  public currentColumnIndex = 0;
  public nextColumnIndex = 0;
  public defaultSelectFirstRow = true;
  public rowDirection = 1; // +1 for next row, -1 for previous row
  public previousRowComponent: dxDataGrid;
  public onEditCanceledCalled = false;
  public onLoadedCalled = false;
  public fromEnterArrowTab = false;
  public saveOnSameRowFocusChanging = false;
  public initialDataGridSelectionConfig: Selection;
  @Input() public dataSourceConfig: IDataSourceConfig;
  @Input() public joinTableColumnConfig: IJoinTableColumnConfig = {}; // services for other data source e.g. stock list data grid - categoryService, allergenService
  @Input() public dataGridConfig: IStandardDataGridConfig = {}; // data grid config options
  @Input() public dataGridActionColumn: IDataGridActionColumn = { enabled: false };
  @Input() public tabIndex = ListDetailTabIndex.List;

  @ViewChild(DxDataGridComponent, { static: false }) public dataGridComponent: DxDataGridComponent;

  constructor(
    private utils: UtilityService,
    private breakpointObserver: BreakpointObserver
  ) { }

  public ngOnInit(): void {
    this.setupGenericLookupDataSource();
    this.setupGenericDataGridDataSource();
    this.setupGenericDataGrid();
    this.breakpointObserver.observe(BreakpointsTriggers.isMobileTrigger).subscribe((result) => {
      // toggle between horizontal scrolling (desktop) and column hiding (mobile)
      // NOTE: disable because it will cause a bug that devextreme cannot resolve so far
      // https://supportcenter.devexpress.com/ticket/details/t228778/how-to-scroll-a-detail-row-instead-of-a-master-aspxgridview
      // this.dataGridConfig.columnResizingMode = result.matches ? 'nextColumn' : 'widget';
      // this.dataGridConfig.columnHidingEnabled = result.matches;

      // toggle between edit popup fullscreen mode
      this.dataGridConfig.editing.popup.fullScreen = result.matches;
      this.dataGridComponent?.instance.option('editing.popup.fullScreen', result.matches);
    });
}

  public updateSelection(selectedRowKeys): void {
    this.dataGridComponent?.instance.selectRows(selectedRowKeys, false);
  }

  public clearSelection(): void {
    this.selectedRowKey = null;
    this.dataGridComponent?.instance.clearSelection();
  }

  public clearStates(): void {
    this.dataGridComponent?.instance.clearFilter();
    this.dataGridComponent?.instance.cancelEditData();
    this.dataGridComponent?.instance.repaint();
    this.dataGridComponent?.instance.state(null);
    this.dataGridComponent?.instance.clearSelection();
    this.dataGridComponent?.instance.refresh();
    if (this.dataGridConfig.hasOwnProperty('onReset')) { this.dataGridConfig.onReset(); }
    this.onEditCanceledCalled = false;
  }

  public showLoader(messageText = 'Loading...'): void {
    this.dataGridComponent?.instance.beginCustomLoading(messageText);
  }
  public hideLoader(): void {
    this.dataGridComponent?.instance.endCustomLoading();
  }
  public refresh(): void { // will reload
    this.dataGridComponent?.instance.refresh();
  }
  public repaint(): void { // without reload
    this.dataGridComponent?.instance.repaint();
  }
  public cancelEditData(): void {
    this.dataGridComponent?.instance.cancelEditData();
  }
  public hideColumnChooser(): void {
    this.dataGridComponent?.instance.hideColumnChooser();
  }

  public onSwitchChanged(event: { value: boolean }): void {
    this.isEditMode = event.value;
    this.dataGridConfig.allowColumnReordering = !this.isEditMode;
    this.dataGridConfig.selection = {
      ...this.initialDataGridSelectionConfig,
      mode: this.isEditMode ? 'none' : this.initialDataGridSelectionConfig.mode
    };

    this.dataGridConfig.keyboardNavigation = {
      enabled: this.isEditMode,
      editOnKeyPress: true,
      enterKeyAction: 'startEdit'
    };

    if (!this.isEditMode) {
      this.dataGridComponent?.instance.clearSelection();
      this.dataGridComponent?.instance.option('focusedRowIndex', -1);
      this.dataGridComponent?.instance.cancelEditData();
      this.nextColumnIndex = 0;
      this.rowDirection = 1;
      this.onEditCanceledCalled = false;
      return;
    }
    this.currentRowIndex = null;
    const firstEditableDataField = this.dataGridComponent?.instance.getVisibleColumns().filter((column: Column) => column.allowEditing)[0]?.dataField;
    const firstEditableRow = this.dataGridComponent?.instance.getVisibleRows().find((row: Row) => row.rowType !== 'group').rowIndex;
    const cellElement = this.dataGridComponent.instance.getCellElement(firstEditableRow, firstEditableDataField);
    this.dataGridComponent.instance.focus(cellElement);
  }

  public navigateToCell(rowIndex: number, columnIndex: number, editState = true): void {
    if (!this.dataGridConfig.editing.allowUpdating) { return; }
    this.dataGridComponent.instance.editCell(rowIndex, columnIndex);
    const cellElement = this.dataGridComponent.instance.getCellElement(rowIndex, columnIndex);
    this.dataGridComponent.instance.focus(cellElement);
    if (!editState) {
      this.dataGridComponent.instance.closeEditCell();
    }
  }

  // get the caption of the last editable column
  public getLastEditorCaption(columns: Column[]): string {
    return columns.filter((column: Column) => column.allowEditing).pop().caption; // Using caption here as dataField can be duplicated
  }

  // get the index of the action column
  public getActionColumnIndex(columns: Column[]): number {
    return columns.findIndex((column) => column.type === 'buttons');
  }

  public onKeyDown(event, cellInfo): void {
    // capture the event when tabbing through the action column into the next row
    if (event.key === 'Tab' && !event.shiftKey) {
      // check if the current row is the entry row and it has inputed data, and the current grid has no other row
      const rows = this.dataGridComponent.instance.getVisibleRows();

      // check if the current row is the last row and it has been modified
      if (cellInfo.rowIndex === rows.length - 1) {
        cellInfo.component.saveEditData();
      }
    }
  }

  // eslint-disable-next-line complexity
  private setupGenericDataGrid(): void {
    /*
     * Appearance related
     */
    this.dataGridConfig.showBorders = this.dataGridConfig.hasOwnProperty('showBorders') ? this.dataGridConfig.showBorders : true;
    this.dataGridConfig.showColumnLines = this.dataGridConfig.hasOwnProperty('showColumnLines') ? this.dataGridConfig.showColumnLines : true;
    this.dataGridConfig.showRowLines = this.dataGridConfig.hasOwnProperty('showRowLines') ? this.dataGridConfig.showRowLines : true;
    this.dataGridConfig.rowAlternationEnabled = this.dataGridConfig.hasOwnProperty('rowAlternationEnabled') ? this.dataGridConfig.rowAlternationEnabled : true;
    this.dataGridConfig.repaintChangesOnly = this.dataGridConfig.hasOwnProperty('repaintChangesOnly') ? this.dataGridConfig.repaintChangesOnly : true;
    this.dataGridConfig.errorRowEnabled = this.dataGridConfig.hasOwnProperty('errorRowEnabled') ? this.dataGridConfig.errorRowEnabled : false;

    /*
     * Columns related
     */
    this.dataGridConfig.columns = this.dataGridConfig.hasOwnProperty('columns') ? this.dataGridConfig.columns : [];
    this.dataGridConfig.allowColumnReordering = this.dataGridConfig.hasOwnProperty('allowColumnReordering') ? this.dataGridConfig.allowColumnReordering : true;
    this.dataGridConfig.allowColumnResizing = this.dataGridConfig.hasOwnProperty('allowColumnResizing') ? this.dataGridConfig.allowColumnResizing : true;
    this.dataGridConfig.columnResizingMode = this.dataGridConfig.hasOwnProperty('columnResizingMode') ? this.dataGridConfig.columnResizingMode : 'widget';
    this.dataGridConfig.columnHidingEnabled = this.dataGridConfig.hasOwnProperty('columnHidingEnabled') ? this.dataGridConfig.columnHidingEnabled : false;
    this.dataGridConfig.syncLookupFilterValues = this.dataGridConfig.hasOwnProperty('syncLookupFilterValues') ? this.dataGridConfig.syncLookupFilterValues : false;
    this.dataGridConfig.columnChooser = {
      enabled: true,
      mode: 'select',
      ...this.dataGridConfig.columnChooser // overwritten by implementation
    };
    this.dataGridConfig.columnFixing = {
      enabled: true,
      ...this.dataGridConfig.columnFixing
    };

    /*
     * this.dataGridConfig.onSelectionChanged
     */
    if (this.dataGridConfig.hasOwnProperty('onSelectionChanged')) {
      const customOnSelectionChanged = this.dataGridConfig.onSelectionChanged;
      this.dataGridConfig.onSelectionChanged = (e) => {
        this.defaultOnSelectionChanged(e);
        customOnSelectionChanged(e);
      };
    } else {
      this.dataGridConfig.onSelectionChanged = (e) => this.defaultOnSelectionChanged(e);
    }

    /*
     * this.dataGridConfig.onContextMenuPreparing
     */
    this.dataGridConfig.onContextMenuPreparing = this.dataGridConfig.hasOwnProperty('onContextMenuPreparing') ? this.dataGridConfig.onContextMenuPreparing : (e) => { };

    /*
     * this.dataGridConfig.onEditorPreparing
     */
    const customOnEditorPreparing = this.dataGridConfig.onEditorPreparing;
    this.dataGridConfig.onEditorPreparing = (e: IExtendedEditorPreparingEvent) => {
      if (customOnEditorPreparing) { customOnEditorPreparing(e); }
      if (!this.isEditMode) { return; }
      this.previousRowComponent = e.component;
      if (e.editorName === 'dxNumberBox') { e.editorOptions.step = 0; }
      if (e.parentType === 'dataRow' && e.allowEditing) {

        // navigation using arrow up and down when in editing state
        // only works for dxNumberBox and dxTextBox for now
        if (['dxNumberBox', 'dxTextBox', 'dxCheckBox'].includes(e.editorName)) {

          if (e.editorName !== 'dxCheckBox') {
            e.editorOptions.onFocusIn = (args) => {
              const input = args.element.querySelector('.dx-texteditor-input');
              if (input != null) {
                input.select();
              }
            };
          }

          // eslint-disable-next-line complexity
          e.editorOptions.onKeyDown = (arg) => {
            const argsEvent = e.editorName === 'dxCheckBox' ? arg : arg.event;
            if (!argsEvent.key && e.editorName === 'dxCheckBox') {
              this.checkValueChange(e.editorOptions.value, arg.currentTarget.ariaChecked === 'true', e.dataType, e.component);
            }
            if (argsEvent.key === 'Enter') {
              const oldValue = e.editorOptions.value;
              const currentValue = argsEvent.currentTarget.value;
              const newValue = e.dataType === 'number' ? currentValue === '' ? null : Number(currentValue) : currentValue;
              if (newValue !== oldValue) {
                e.component.cellValue(this.currentRowIndex, this.currentColumnIndex, newValue);
              }
              this.nextColumnIndex = this.currentColumnIndex;
              this.fromEnterArrowTab = true;
              const hasGroupType = e.component.getVisibleRows().some((row) => row.rowType === 'group');
              if (hasGroupType) {
                this.defaultSelectFirstRow = false;
              }
              if (newValue !== oldValue || this.dataGridComponent.instance?.hasEditData()) {
                setTimeout(() => {
                  e.component.saveEditData();
                }, 0);
              }
            }
            if ((argsEvent.key === 'ArrowUp' || argsEvent.key === 'ArrowDown')) {
              let newValue;
              let oldValue;

              if (e.editorName !== 'dxCheckBox') {
                oldValue = e.editorOptions.value;
                const currentValue = argsEvent.currentTarget.value;
                newValue = e.dataType === 'number' ? currentValue === '' ? null : Number(currentValue) : currentValue;
                if (newValue !== oldValue) {
                  e.component.cellValue(this.currentRowIndex, this.currentColumnIndex, newValue);
                }
              } else {
                oldValue = String(e.editorOptions.value);
                newValue = argsEvent.currentTarget.firstChild.value;
              }

              this.nextColumnIndex = this.currentColumnIndex;
              this.rowDirection = argsEvent.key === 'ArrowUp' ? -1 : 1;
              this.fromEnterArrowTab = true;
              const hasGroupType = e.component.getVisibleRows().some((row) => row.rowType === 'group');
              if (hasGroupType) {
                this.defaultSelectFirstRow = false;
              }

              if (newValue !== oldValue || this.dataGridComponent.instance?.hasEditData()) {
                setTimeout(() => {
                  e.component.saveEditData();
                }, 0);
              }
            }
            if (argsEvent.key === 'Tab' && !argsEvent.shiftKey) {
              if (!this.isEditMode) { return; }
              const lastEditableFieldCaption = this.getLastEditorCaption(e.component.getVisibleColumns());
              if (lastEditableFieldCaption !== e.caption) { return; }
              const hasGroupType = e.component.getVisibleRows().some((row) => row.rowType === 'group');
              if (hasGroupType) {
                this.defaultSelectFirstRow = false;
              }
              this.checkValueChange(e.editorOptions.value, argsEvent.currentTarget.value, e.dataType, e.component);
              const actionColumnIndex = this.getActionColumnIndex(e.component.getVisibleColumns());
              const actionColumn = this.dataGridComponent.instance.getCellElement(this.currentRowIndex, actionColumnIndex);
              e.component.focus(actionColumn);
              argsEvent.preventDefault();
              argsEvent.stopImmediatePropagation();
            }
          };
        }

        // auto open dropdown when in editing state
        if (['dxSelectBox', 'dxDateBox'].includes(e.editorName)) {
          e.editorOptions.onKeyDown = (arg) => {
            if (arg.event.key === 'Enter') {
              this.nextColumnIndex = this.currentColumnIndex;
              const hasGroupType = e.component.getVisibleRows().some((row) => row.rowType === 'group');
              if (hasGroupType) {
                this.defaultSelectFirstRow = false;
              }
            }
            if (arg.event.key === 'Tab' && !arg.event.shiftKey) {
              if (!this.isEditMode) { return; }
              const lastEditableFieldCaption = this.getLastEditorCaption(e.component.getVisibleColumns());
              if (lastEditableFieldCaption !== e.caption) { return; }
              const hasGroupType = e.component.getVisibleRows().some((row) => row.rowType === 'group');
              if (hasGroupType) {
                this.defaultSelectFirstRow = false;
              }
              const actionColumnIndex = this.getActionColumnIndex(e.component.getVisibleColumns());
              const actionColumn = this.dataGridComponent.instance.getCellElement(this.currentRowIndex, actionColumnIndex);
              e.component.focus(actionColumn);
              arg.event.preventDefault();
              arg.event.stopImmediatePropagation();
            }
          };

          if (['dxSelectBox'].includes(e.editorName)) {
            e.editorOptions.onFocusIn = (args) => {
              if (args.component.isOpen) { return; }
              args.component.open();
              args.component.isOpen = true;
            };

            e.editorOptions.onFocusOut = (args) => {
              if (!args.component.isOpen) { return; }
              args.component.close();
              args.component.isOpen = false;
            };
          }
        }

      }

    };

    this.dataGridConfig.onEditorPrepared = this.dataGridConfig.hasOwnProperty('onEditorPrepared') ? this.dataGridConfig.onEditorPrepared : (e) => { };

    /*
     * this.dataGridConfig.onSaving
     */
    this.dataGridConfig.onSaving = this.dataGridConfig.hasOwnProperty('onSaving') ? this.dataGridConfig.onSaving : (e) => { };
    this.dataGridConfig.onRowPrepared = this.dataGridConfig.hasOwnProperty('onRowPrepared') ? this.dataGridConfig.onRowPrepared : (e) => { };
    this.dataGridConfig.onContentReady = this.dataGridConfig.hasOwnProperty('onContentReady') ? this.dataGridConfig.onContentReady : (e) => { };

    /*
     * this.dataGridConfig.onSaved
     */
    this.dataGridConfig.onSaved = this.dataGridConfig.hasOwnProperty('onSaved') ? this.dataGridConfig.onSaved : (e) => { };
    /*
     * this.dataGridConfig.onRowRemoved
     */
    this.dataGridConfig.onRowRemoved = this.dataGridConfig.hasOwnProperty('onRowRemoved') ? this.dataGridConfig.onRowRemoved : (e) => { };
    /*
     * this.dataGridConfig.onContentReady
     */
    this.dataGridConfig.onContentReady = this.dataGridConfig.hasOwnProperty('onContentReady') ? this.dataGridConfig.onContentReady : (e) => { };
    /*
     * this.dataGridConfig.onContentReady
     */
    const customOnEditCanceled = this.dataGridConfig.onEditCanceled;
    this.dataGridConfig.onEditCanceled = (e) => {
      if (customOnEditCanceled) { customOnEditCanceled(e); }
      if (!this.isEditMode) { return; }
      if (this.onEditCanceledCalled) { return; }
      this.onEditCanceledCalled = true;
      setTimeout(() => {
        if (this.tabIndex === ListDetailTabIndex.Detail) { return; }
        let nextRowIndex = this.currentRowIndex + this.rowDirection;
        const visibleRows = this.dataGridComponent.instance.getVisibleRows();
        // Check if the next row is of type 'group', if so, check the next one
        while (visibleRows[nextRowIndex] && visibleRows[nextRowIndex].rowType === 'group') {
          nextRowIndex += this.rowDirection;
        }
        if (nextRowIndex < 0 || nextRowIndex >= visibleRows.length) {
          this.onEditCanceledCalled = false;
          this.dataGridComponent?.instance.editRow(nextRowIndex - 1);
          return;
        }
        const firstEditableDataField = this.dataGridComponent?.instance.getVisibleColumns().filter((column: Column) => column.allowEditing)[0]?.dataField;
        const cellElement = this.dataGridComponent.instance.getCellElement(nextRowIndex, this.nextColumnIndex ? this.nextColumnIndex as unknown as string : firstEditableDataField);
        this.dataGridComponent.instance.focus(cellElement);
        this.nextColumnIndex = 0;
        this.rowDirection = 1;
        this.onEditCanceledCalled = false;
      }, 0);
    };

    /*
     * this.dataGridConfig.onRowValidating
     */
    const customOnRowValidating = this.dataGridConfig.onRowValidating;
    this.dataGridConfig.onRowValidating = (e) => {
      if (customOnRowValidating) { customOnRowValidating(e); }
      if (!this.isEditMode) { return; }
      const rowIndex = this.dataGridComponent.instance.getRowIndexByKey(e.key);
      const rows: IExtendedRow[] = this.dataGridComponent.instance.getVisibleRows();
      rows[rowIndex].isValid = e.isValid;
      if (this.dataGridConfig.editing.allowAdding) {
        const editingRows = this.dataGridComponent.instance.getVisibleRows().filter((row: Row & { modified: boolean }) => row.isNewRow || row.modified);
        if (rowIndex === 0 && editingRows.length > 1) {
          // ignore entry row
          e.isValid = true;
          return;
        }
      }
      if (e.isValid) { return; }
      if (e.brokenRules.length === 0) { return; }
      const invalidFieldCaption = (e.brokenRules[0] as unknown as { column: { caption: string } }).column.caption;
      const columnIndex = rows[rowIndex].cells.findIndex((cell) => cell.column.caption === invalidFieldCaption);
      setTimeout(() => {
        this.lastSelectedRowIndex = rowIndex;
        this.lastSelectedColumnIndex = columnIndex;
        this.navigateToCell(rowIndex, columnIndex);
      }, 0);
    };

    /*
     * this.dataGridConfig.onInitNewRow
     */
    this.dataGridConfig.onInitNewRow = this.dataGridConfig.hasOwnProperty('onInitNewRow') ? this.dataGridConfig.onInitNewRow : (e) => { };

    /*
     * this.dataGridConfig.onEditingStart
     */
    this.dataGridConfig.onEditingStart = this.dataGridConfig.hasOwnProperty('onEditingStart') ? this.dataGridConfig.onEditingStart : (e) => { };

    /*
    /*
     * this.dataGridConfig.onCellPrepared
     */
    this.dataGridConfig.onCellPrepared = this.dataGridConfig.hasOwnProperty('onCellPrepared') ? this.dataGridConfig.onCellPrepared : (e) => { };

    /*
    /*
     * this.dataGridConfig.onCellDblClick
     */
    this.dataGridConfig.onCellDblClick = this.dataGridConfig.hasOwnProperty('onCellDblClick') ? this.dataGridConfig.onCellDblClick : (e) => { };

    /*
     * this.dataGridConfig.onToolbarPreparing
     */
    if (this.dataGridConfig.hasOwnProperty('onToolbarPreparing')) {
      const customOnToolbarPreparing = this.dataGridConfig.onToolbarPreparing;
      this.dataGridConfig.onToolbarPreparing = (e) => {
        this.defaultOnToolBarPreparing(e);
        customOnToolbarPreparing(e);
      };
    } else {
      this.dataGridConfig.onToolbarPreparing = (e) => this.defaultOnToolBarPreparing(e);
    }

    /*
     * scrolling
     */
    this.dataGridConfig.scrolling = {
      columnRenderingMode: 'standard' // horizontal scrolling,
    };

    /*
     * renderAsync - Specifies whether to render rows after a user stops scrolling or at the same time
     * as the user scrolls the datagrid.
     */
    this.dataGridConfig.renderAsync = true;

    /*
     * selection
     */
    this.dataGridConfig.selection = {
      mode: 'none',
      ...this.dataGridConfig.selection
    };
    this.initialDataGridSelectionConfig = { ...this.dataGridConfig.selection };
    if (this.dataGridConfig.selection?.mode === 'single' && !this.dataGridConfig.selection?.showCheckBoxesMode && this.dataGridConfig.selection?.showCheckBoxesMode !== 'none') {
      this.dataGridConfig.columns.unshift({
        cellTemplate: 'singleSelectCellTemplate',
        width: 50,
        allowReordering: false,
        allowResizing: false,
        allowExporting: false,
        allowEditing: false,
        allowSorting: false,
        allowHiding: false,
        fixed: true,
        fixedPosition: 'left',
        alignment: 'center'
      });
    }
    if (this.dataGridConfig.selection?.mode === 'multiple') {
      this.dataGridConfig.selection = {
        showCheckBoxesMode: 'always',
        deferred: true,
        ...this.dataGridConfig.selection
      };
    }

    /*
     * pagination
     */
    this.dataGridConfig.pager = {
      visible: true,
      displayMode: 'adaptive',
      showInfo: true,
      showPageSizeSelector: true,
      allowedPageSizes: GridConstants.ALLOWED_PAGE_SIZES,
      ...this.dataGridConfig.pager
    };
    this.dataGridConfig.paging = {
      pageSize: 25,
      ...this.dataGridConfig.paging
    };

    /*
     * sorting
     */
    this.dataGridConfig.sorting = {
      mode: 'multiple',
      ...this.dataGridConfig.sorting
    };

    /*
     * editing
     */
    this.dataGridConfig.editing = {
      mode: 'row',
      allowUpdating: false,
      allowDeleting: false,
      allowAdding: false,
      ...this.dataGridConfig.editing,
      form: {
        customizeItem: (item: IExtendedTabItem) => {
          if (item.dataField === 'createDateTime' || item.dataField === 'updateDateTime') {
            item.visible = false;
          }
        },
        ...this.dataGridConfig.editing?.form
      },
      popup: {
        fullScreen: false,
        showTitle: true,
        shading: true,
        showCloseButton: true,
        hideOnOutsideClick: false,
        shadingColor: 'rgba(0, 0, 0, 0.3)',
        ...this.dataGridConfig.editing?.popup
      }
    };

    /*
     * export
     */
    this.dataGridConfig.export = {
      enabled: false,
      allowExportSelectedData: false,
      ...this.dataGridConfig.export
    };

    /*
     * search & filtering
     */
    this.dataGridConfig.searchPanel = {
      visible: true,
      placeholder: 'Search...',
      searchVisibleColumnsOnly: true,
      ...this.dataGridConfig.searchPanel
    };
    this.dataGridConfig.filterPanel = {
      visible: true,
      ...this.dataGridConfig.filterPanel
    };
    this.dataGridConfig.headerFilter = {
      visible: true,
      ...this.dataGridConfig.headerFilter
    };
    this.dataGridConfig.filterRow = {
      visible: false,
      ...this.dataGridConfig.filterRow
    };

    /*
     * grouping & summary
     */
    this.dataGridConfig.groupPanel = {
      visible: false,
      allowColumnDragging: true,
      emptyPanelText: '',
      ...this.dataGridConfig.groupPanel
    };
    this.dataGridConfig.grouping = {
      contextMenuEnabled: false,
      ...this.dataGridConfig.grouping
    };
    this.dataGridConfig.summary = {
      // WARNING! Avoid setting recalculateWhileEditing to true as it is breaking async validation on Enter key press!
      groupItems: [],
      totalItems: [],
      ...this.dataGridConfig.summary
    };

    /*
     * state storing
     */
    this.dataGridConfig.stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: this.dataSourceConfig.dataSourceName + 'GridSettings',
      ignoreColumnOptionNames: [],
      ...this.dataGridConfig.stateStoring
    } as IExtendedStateStoring;

    /*
     * Action Column
     */
    if (this.dataGridActionColumn.enabled) {
      this.dataGridConfig.columns.push({
        type: 'buttons', // this will overwrite the default command column e.g. Edit btn, Delete Btn
        cellTemplate: 'actionColumnTemplate',
        showInColumnChooser: false,
        minWidth: 90
      });
      if (!this.dataGridActionColumn.hasOwnProperty('actionMenu')) { // generate default action menu if not specified
        // by default contains Edit and Delete
        this.dataGridActionColumn.actionMenu = [{
          icon: 'more',
          items: (this.dataGridConfig.editing.mode === 'cell') ? [{ text: 'Delete' }] : [{ text: 'Edit' }, { text: 'Delete' }]
        }];
      }
      if (!this.dataGridActionColumn.hasOwnProperty('processActionMenu')) { // return action menu if not specified
        this.dataGridActionColumn.processActionMenu = () => this.dataGridActionColumn.actionMenu;
      }
      if (!this.dataGridActionColumn.hasOwnProperty('onActionItemClick')) { // generate default function if not specified
        this.dataGridActionColumn.onActionItemClick = ($event, cellInfo) => {
          if (!$event.itemData.items) {
            if ($event.itemData?.text === 'Edit') { this.dataGridComponent?.instance.editRow(cellInfo.rowIndex); }
            if ($event.itemData?.text === 'Delete') { this.dataGridComponent?.instance.deleteRow(cellInfo.rowIndex); }
          }
        };
      }
      if (!this.dataGridActionColumn.hasOwnProperty('onSaveClick')) { // generate default function if not specified
        this.dataGridActionColumn.onSaveClick = () => {
          const hasGroupType = this.dataGridComponent?.instance.getVisibleRows().some((row) => row.rowType === 'group');
          if (hasGroupType) {
            this.defaultSelectFirstRow = false;
          }
          this.nextColumnIndex = 0;
          this.dataGridComponent?.instance.saveEditData();
          return false;
        };
      }

      if (!this.dataGridActionColumn.hasOwnProperty('onCancelClick')) { // generate default function if not specified
        this.dataGridActionColumn.onCancelClick = () => {
          this.nextColumnIndex = 0;
          this.dataGridComponent?.instance.cancelEditData();
          return false;
        };
      }
    }

    this.dataGridConfig.onFocusedCellChanging = (e) => {
      if (!this.isEditMode) { return; }
      if (!this.dataGridConfig.editing.allowUpdating) { return; }
      if (e.prevRowIndex < 0) { return; }
      if (e.prevRowIndex > e.newRowIndex && e.rows[e.newRowIndex].isNewRow) {
        if (e.prevRowIndex === 1) {
          // adding a new record while at row 1, will eventually become 1(prevRowIndex) 1(newRowIndex) when tabbed through to next row.
          // Therefore force saving in this case
          this.saveOnSameRowFocusChanging = true;
        }
        return;
      }
      if (e.prevRowIndex === e.newRowIndex) {
        let rowIndex = e.newRowIndex;
        // checks if the row is a group row, if so, find the first row that is not a group row
        if (e.rows[rowIndex]?.rowType === 'group') {
          rowIndex = this.dataGridComponent?.instance.getVisibleRows().find((row: Row) => row.rowType !== 'group').rowIndex;
          const cellElement = this.dataGridComponent.instance.getCellElement(rowIndex, this.currentColumnIndex);
          this.dataGridComponent.instance.focus(cellElement);
        }
        if (!this.saveOnSameRowFocusChanging) { return; }
      }
      this.saveOnSameRowFocusChanging = false;
      if (this.previousRowComponent && this.previousRowComponent?.hasEditData()) {
        e.cancel = true;
        this.nextColumnIndex = 0;
        this.fromEnterArrowTab = true;
        this.previousRowComponent.saveEditData();
        this.previousRowComponent = null;
      }

      setTimeout(() => {
        e.cancel = true;
       }, 0);
    };

    const customOnFocusedCellChanged = this.dataGridConfig.onFocusedCellChanged;
    this.dataGridConfig.onFocusedCellChanged = (e) => {
      if (customOnFocusedCellChanged) { customOnFocusedCellChanged(e); }
      if (!this.isEditMode) { return; }
      if (e.rowIndex === -1) { return; }
      if (e.column.allowEditing || e.column.type === 'buttons') {
        this.lastSelectedRowIndex = e.rowIndex;
        this.lastSelectedColumnIndex = e.columnIndex;
      }
      if (e.column.type === 'buttons' && !e.row.isNewRow) {
        setTimeout(() => {
          const firstButton = e.cellElement[0]?.children[0]?.children[0] as HTMLAnchorElement;
          if (firstButton) {
            firstButton.focus();
          }
        }, 0);
      }
      const changedToNewRow = this.currentRowIndex !== e.rowIndex;
      if (changedToNewRow) {
        this.currentRowIndex = e.rowIndex;
        this.dataGridComponent?.instance.editRow(e.rowIndex);
      }
      this.currentColumnIndex = e.columnIndex;
      if (e.row.isNewRow) { return; }
      const firstEditableDataField = this.dataGridComponent?.instance.getVisibleColumns().filter((column: Column) => column.allowEditing)[0]?.dataField;
      const focusToColumn: string | number = changedToNewRow && this.currentColumnIndex === 0 ? firstEditableDataField : this.currentColumnIndex;
      const cellElement = this.dataGridComponent.instance.getCellElement(e.rowIndex, focusToColumn as string);
      this.dataGridComponent.instance.focus(cellElement);
    };
  }

  private setupGenericLookupDataSource(): void {
    Object.keys(this.joinTableColumnConfig).forEach((joinTableColumn) => {
      const thisColumn = this.joinTableColumnConfig[joinTableColumn];
      Object.assign(thisColumn.joinTableDataSource, {
        store: new CustomStore({
          key: thisColumn.joinTablePrimaryKey,
          loadMode: thisColumn.joinTableDataSourceLoadMode || 'raw',
          load: async (loadOptions: LoadOptions) => {
            try {
              const view = thisColumn.joinTableDataSourceServiceSearchFnView;
              const routeId = thisColumn.joinTableDataSourceServiceSearchFnRouteId;
              if (loadOptions.searchExpr && loadOptions.searchValue && loadOptions.searchOperation === 'contains') {
                this.processLookupSearch(this.dataGridConfig.columns, thisColumn.joinTableDataSource, loadOptions);
              }
              if (loadOptions.searchExpr && loadOptions.sort) {
                this.processLookupSort(this.dataGridConfig.columns, thisColumn.joinTableDataSource, loadOptions);
              }
              return await thisColumn.joinTableDataSourceService[thisColumn.joinTableDataSourceServiceSearchFn](loadOptions, view, routeId);
            } catch (error) {
              this.showJoinTableDataSourceCrudError(error, 'load', thisColumn.joinModelName);
            }
          },
          byKey: async (key) => {
            try {
              if (!key) { return null; }
              const view = thisColumn.joinTableDataSourceServiceGetFnView;
              return await thisColumn.joinTableDataSourceService[thisColumn.joinTableDataSourceServiceGetFn](key, view);
            } catch (error) {
              this.showJoinTableDataSourceCrudError(error, 'load', thisColumn.joinModelName);
            }
          }
        }),
        sort: thisColumn.joinTableDataSortBy,
        pageSize: 10,
        paginate: true
      });
    });
  }

  private showJoinTableDataSourceCrudError(error: ErrorEvent, operation: 'load' | 'insert' | 'update' | 'delete', joinModelName: string): void {
    const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
    this.utils.openSnackbar('Failed to ' + operation + ' record from ' + joinModelName + ' . ' + instruction, 'OK', 10000);
  }

  private setupGenericDataGridDataSource(): void {
    this.dataGridConfig.dataSource = new CustomStore(
      {
        key: this.dataSourceConfig.dataSourcePrimaryKey,
        loadMode: this.dataSourceConfig.dataSourceLoadMode || 'raw',
        load: async (loadOptions: LoadOptions) => {
          const view = this.dataSourceConfig.dataSourceServiceSearchFnView;
          const routeId = this.dataSourceConfig.dataSourceServiceSearchFnRouteId;
          const customFilter = this.dataSourceConfig.dataSourceServiceSearchFnArgs ?? undefined;
          loadOptions.take = loadOptions.take || this.dataGridComponent.paging.pageSize;
          try {
            return await this.dataSourceConfig.dataSourceService[this.dataSourceConfig.dataSourceServiceSearchFn](loadOptions, view, routeId, customFilter);
          } catch (error) {
            this.showDataSourceCrudError(error, 'load');
            return {
              data: [],
              totalCount: 0,
              summary: null,
              groupCount: null
            };
          }
        },
        insert: async (values) => {
          try {
            if (this.dataSourceConfig.dataSourceServiceInsertFn) {
              await this.dataSourceConfig.dataSourceService[this.dataSourceConfig.dataSourceServiceInsertFn](values);
            }
          } catch (error) {
            this.showDataSourceCrudError(error, 'insert');
            return Promise.reject();
          }
        },
        update: async (key, values) => {
          try {
            if (this.dataSourceConfig.dataSourceServiceUpdateFn) {
              await this.dataSourceConfig.dataSourceService[this.dataSourceConfig.dataSourceServiceUpdateFn](key, values);
            }
          } catch (error) {
            this.showDataSourceCrudError(error, 'update');
            return Promise.reject();
          }
        },
        remove: async (key) => {
          try {
            if (this.dataSourceConfig.dataSourceServiceDeleteFn) {
              await this.dataSourceConfig.dataSourceService[this.dataSourceConfig.dataSourceServiceDeleteFn](key);
            }
          } catch (error) {
            this.showDataSourceCrudError(error, 'delete');
          }
        },
        onLoading: (loadOptions: LoadOptions) => {
          try {
            if (this.dataGridConfig.hasOwnProperty('onLoading')) {
              this.dataGridConfig.onLoading(loadOptions);
            }
          } catch (error) {
            const instruction = ErrorMessages.STANDARD_ERROR_MESSAGE;
            this.utils.openSnackbar(`${ErrorMessages.SNACK_BAR_ERROR_OCCURRED_WHILE} on loading ` +
              this.dataSourceConfig.dataSourceName + ' data grid. ' + instruction, 'OK', 10000);
          }
        },
        onLoaded: (result) => {
          try {
            if (this.dataGridConfig.hasOwnProperty('onLoaded')) {
              this.dataGridConfig.onLoaded(result as unknown as ICustomStoreLoadResult);
            }
            if (!this.isEditMode) { return; }

            if (this.fromEnterArrowTab) {
              this.fromEnterArrowTab = false;
              return;
            }

            const dataGridInstance = this.dataGridComponent?.instance;

            dataGridInstance?.clearSelection();
            dataGridInstance?.option('focusedRowIndex', -1);

            const hasGroupType = dataGridInstance?.getVisibleRows().some((row) => row.rowType === 'group');
            if (hasGroupType) {

              if (this.onLoadedCalled) {
                this.onLoadedCalled = false;
                return;
              }
              this.onLoadedCalled = true;
            }

            if (!this.defaultSelectFirstRow) {
              this.defaultSelectFirstRow = true;
              return;
            }

            setTimeout(() => {
              const firstEditableDataField = dataGridInstance?.getVisibleColumns().filter((column: Column) => column.allowEditing)[0]?.dataField;
              const firstEditableRow = dataGridInstance?.getVisibleRows().find((row: Row) => row.rowType !== 'group').rowIndex;
              const cellElement = dataGridInstance?.getCellElement(firstEditableRow, firstEditableDataField);
              dataGridInstance?.editRow(firstEditableRow);
              dataGridInstance?.focus(cellElement);
              this.defaultSelectFirstRow = true;
              this.onLoadedCalled = false;
            }, 0);
          } catch (error) {
            const instruction = ErrorMessages.STANDARD_ERROR_MESSAGE;
            this.utils.openSnackbar('An error occurred after loading ' + this.dataSourceConfig.dataSourceName + ' data grid. ' + instruction, 'OK', 10000);
          }
        }
      }
    );
  }

  private showDataSourceCrudError(error: ErrorEvent, operation: 'load' | 'insert' | 'update' | 'delete'): void {
    const instruction = (error?.error?.summary) ? ('Err: ' + (error.error.summary as string)) : ErrorMessages.STANDARD_ERROR_MESSAGE;
    this.utils.openSnackbar('Failed to ' + operation + ' record from ' + this.dataSourceConfig.dataSourceName + ' data grid. ' + instruction, 'OK', 10000);
  }

  private processLookupSearch(columns: (Column | string)[], targetLookUpDataSource: ILookupDataSourceConfig | string, loadOptions: LoadOptions): void {
    columns.forEach((column) => {
      if ((column as Column).columns) { // multi level header
        this.processLookupSearch((column as Column).columns, targetLookUpDataSource, loadOptions);
      } else {
        // if this column has lookup and the lookup is the one that being search
        // check if this lookup is a number type (by checking if _castToIntWhenSearch is set to true )
        if ((column as Column).lookup?.dataSource === targetLookUpDataSource && (column as Column).lookup?.displayExpr === loadOptions.searchExpr &&
          (column as IDataGridColumnConfig)._castToIntWhenSearch) {
          loadOptions.searchValue = Number(loadOptions.searchValue);
          loadOptions.searchOperation = '=';
        }
      }
    });
  }

  private processLookupSort(columns: (Column | string)[], targetLookUpDataSource: ILookupDataSourceConfig | string, loadOptions: LoadOptions): void {
    columns.forEach((column) => {
      if ((column as Column).columns) { // multi level header
        this.processLookupSort((column as Column).columns, targetLookUpDataSource, loadOptions);
      } else {
        if ((column as Column).lookup?.dataSource === targetLookUpDataSource && (column as Column).lookup?.displayExpr === loadOptions.searchExpr) {
          loadOptions.sort = (column as Column).lookup.displayExpr;
        }
      }
    });
  }

  private defaultOnSelectionChanged(e): void {
    if (this.dataGridConfig.selection?.mode === 'single') { // for single selection
      const selectedRowKeys = e.component.getSelectedRowKeys();
      if (selectedRowKeys.length > 0) {
        this.selectedRowKey = selectedRowKeys[0]; // select first by default
      }
    }
  }

  private defaultOnToolBarPreparing(e): void {
    const toolbarItems = e.toolbarOptions.items;
    const customItems = [];
    customItems.push({
      locateInMenu: 'auto',
      location: 'after',
      showText: 'inMenu',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        text: 'Refresh',
        hint: 'Refresh',
        onClick: () => this.refresh()
      }
    });
    if (this.dataGridConfig?.stateStoring?.enabled) {
      customItems.push({
        locateInMenu: 'auto',
        location: 'after',
        showText: 'always',
        widget: 'dxButton',
        cssClass: 'reset-grid-btn',
        options: {
          text: 'Reset Grid',
          hint: 'Reset Grid',
          icon: 'undo',
          onClick: () => this.clearStates()
        }
      });
    }
    const searchPanelIndex = toolbarItems.findIndex((item) => item.name === 'searchPanel');
    if (searchPanelIndex > -1) {
      toolbarItems.splice(searchPanelIndex, 0, ...customItems);
    } else {
      toolbarItems.push(...customItems);
    }
    if (this.dataGridConfig.hasEditMode && this.dataGridConfig.editing.allowUpdating) {
      toolbarItems.unshift({
        locateInMenu: 'auto',
        location: 'after',
        showText: 'inMenu',
        template: 'editModeSwitchTemplate'
      });
    }
  }
  private defaultOnContextMenuPreparing(e): void {
    if (e.target === 'header' || e.target === 'content') {
      // e.items can be undefined
      if (!e.items) { e.items = []; }

      // Add a custom menu item
      e.items.push();
    }
  }

  private getContextMenuConfigForSumByThisColumn(e): object {
    return {
      text: 'Sum By This Column',
      onItemClick: () => {
        let summary = {
          column: e.column.dataField,
          summaryType: 'sum',
          displayFormat: 'Total: {0}'
        } as ISummary;
        if (e.column.format === 'currency') { summary = { ...summary, valueFormat: { type: 'currency', precision: 2 } }; }
        const groupItems = e.component.option('summary.groupItems');
        const foundGroupItem = groupItems.find((gi) => gi.column === e.column.dataField && gi.summaryType === 'sum');
        if (!foundGroupItem) { groupItems.push({ ...summary, showInGroupFooter: true }); }
        e.component.option('summary.groupItems', groupItems);
        const totalItems = e.component.option('summary.totalItems');
        const foundTotalItem = totalItems.find((gi) => gi.column === e.column.dataField && gi.summaryType === 'sum');
        if (!foundTotalItem) { totalItems.push(summary); }
        e.component.option('summary.totalItems', totalItems);
      }
    };
  }
  private getContextMenuConfigForMaxOfThisColumn(e): object {
    return {
      text: 'Max Of This Column',
      onItemClick: () => {
        let summary = {
          column: e.column.dataField,
          summaryType: 'max'
        } as ISummary;
        if (e.column.format === 'currency') { summary = { ...summary, valueFormat: { type: 'currency', precision: 2 } }; }
        const groupItems = e.component.option('summary.groupItems');
        const foundGroupItem = groupItems.find((gi) => gi.column === e.column.dataField && gi.summaryType === 'max');
        if (!foundGroupItem) { groupItems.push({ ...summary, alignByColumn: true }); }
        e.component.option('summary.groupItems', groupItems);
        const totalItems = e.component.option('summary.totalItems');
        const foundTotalItem = totalItems.find((gi) => gi.column === e.column.dataField && gi.summaryType === 'max');
        if (!foundTotalItem) { totalItems.push(summary); }
        e.component.option('summary.totalItems', totalItems);
      }
    };
  }
  private getContextMenuConfigForCountByThisColumn(e): object {
    return {
      text: 'Count By This Column',
      onItemClick: () => {
        const summary = {
          column: e.column.dataField,
          summaryType: 'count',
          displayFormat: '{0} items'
        };
        const groupItems = e.component.option('summary.groupItems');
        const foundGroupItem = groupItems.find((gi) => gi.column === e.column.dataField && gi.summaryType === 'count');
        if (!foundGroupItem) { groupItems.push(summary); }
        e.component.option('summary.groupItems', groupItems);
        const totalItems = e.component.option('summary.totalItems');
        const foundTotalItem = totalItems.find((gi) => gi.column === e.column.dataField && gi.summaryType === 'count');
        if (!foundTotalItem) { totalItems.push(summary); }
        e.component.option('summary.totalItems', totalItems);
      }
    };
  }

  private checkValueChange(oldValue, currentValue, dataType: string, component): void {
    const newValue = dataType === 'number' ? currentValue === '' ? null : Number(currentValue) : currentValue;
    if (newValue === oldValue) { return; }
    component.cellValue(this.currentRowIndex, this.currentColumnIndex, newValue);
  }

}
