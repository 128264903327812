<!-- Dynamsoft Barcode
<span style='font-size:x-large' *ngIf="!barcodeLibLoaded" i18n="Loading Scanner|@@Loading_Scanner">Loading Scanner...</span>
<div style="position:relative;width:100%;height:100%;min-width:100px;min-height:100px;background:#ddd;">
	<svg class="dce-bg-loading"
		style="display:none;position:absolute;left:0;top:0;right:0;bottom:0;margin:auto;width:40%;height:40%;fill:#aaa;animation:1s linear infinite dce-rotate;"
		viewBox="0 0 1792 1792">
		<path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
	</svg>
	<svg class="dce-bg-camera"
		style="display:none;position:absolute;left:0;top:0;right:0;bottom:0;margin:auto;width:40%;height:40%;fill:#aaa;"
		viewBox="0 0 2048 1792">
		<path d="M1024 672q119 0 203.5 84.5t84.5 203.5-84.5 203.5-203.5 84.5-203.5-84.5-84.5-203.5 84.5-203.5 203.5-84.5zm704-416q106 0 181 75t75 181v896q0 106-75 181t-181 75h-1408q-106 0-181-75t-75-181v-896q0-106 75-181t181-75h224l51-136q19-49 69.5-84.5t103.5-35.5h512q53 0 103.5 35.5t69.5 84.5l51 136h224zm-704 1152q185 0 316.5-131.5t131.5-316.5-131.5-316.5-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5z" />
	</svg>
	<div class="dce-video-container" style="position:absolute;left:0;top:0;width:100%;height:100%;"></div>
	<div class="dce-scanarea" style="position:absolute;left:0;top:0;width:100%;height:100%;">
		<div class="dce-scanlight" style="display:none;position:absolute;width:100%;height:3%;border-radius:50%;box-shadow:0px 0px 2vw 1px #00e5ff;background:#fff;animation:3s infinite dce-scanlight;user-select:none;"></div>
	</div>
	<div style="position:absolute;left:0;top:0;">
		<select class="dce-sel-camera" style="display:block;"></select>
		<select class="dce-sel-resolution" style="display:block;margin-top:5px;"></select>
	</div>
</div>
<style>
	@keyframes dce-rotate {
		from { transform: rotate(0turn); }
		to { transform: rotate(1turn); }
	}
	@keyframes dce-scanlight {
		from { top: 0; }
		to { top: 97%; }
	}
</style>
Dynamsoft Barcode -->

<!-- DataSymbol Barcode -->
<div class="datasymbol-barcode-wrapper">
	<span style='font-size:x-large' *ngIf="!barcodeLibLoaded" i18n="Loading Scanner|@@Loading_Scanner">Loading Scanner...</span>
	<div class="scanner-config">
		<select (change)="onDeviceChange($event.target.value)">
			<option value="user" i18n="Front|@@Front" [selected]="selectedDeviceId === 'user'">Front</option>
			<option value="environment" i18n="Back|@@Back" [selected]="selectedDeviceId === 'environment'">Back</option>
			<option *ngFor="let device of camDevices" [(value)]="device.id" [selected]="device.id === selectedDeviceId">{{device.label}}</option>
		</select>
		<dx-button icon="../../../../assets/img/icons/barcode-settings.svg" (onClick)="openScannerConfiguration()">
		</dx-button>
	</div>
	<div id="datasymbol-barcode-viewport"></div>
</div>

<dx-popup [(visible)]="popupConfig.visible" [title]="popupConfig.title" [showCloseButton]="true"
    [hideOnOutsideClick]="false" [shading]="true" shadingColor="rgba(0, 0, 0, 0.3)"
    [fullScreen]="globalsService?.isMobile">
    <div *dxTemplate="let data of 'content'">
		<div class="barcode-type-config-wrapper">
			<div class="action">
				<dx-button text="Reset" (onClick)="resetBarcodeTypeConfig()" type="default">
				</dx-button>
			</div>
			<dx-form #barcodeTypeConfigForm labelLocation="left" [colCount]="8" [screenByWidth]="screenByWidth" [scrollingEnabled]="true">
				<dxi-item itemType="group" [colCount]="8" [colSpan]="8" caption="1D" [colCountByScreen]="colCountByScreen" [items]="barcodeType1Ds"></dxi-item>
				<dxi-item itemType="group" [colCount]="8" [colSpan]="8" caption="2D" [colCountByScreen]="colCountByScreen" [items]="barcodeType2Ds"></dxi-item>
			</dx-form>
		</div>
    </div>
</dx-popup>
<!-- DataSymbol Barcode -->