<!-- eslint-disable @angular-eslint/template/i18n -->
<body class="bg">
  <mat-card [class.mat-card-create-account]="!smallScreen">
    <div>
      <img src="../../../../assets/img/branding/hl-cloud/H&L-Cloud-LRG-Transparent.png" class="left signup-cloud-logo" [class.smallscreen-signup-cloud-logo]="smallScreen">
    </div>
    <mat-card-header class="full-width">
      <mat-card-title>
        <h2 i18n="Create your H&L Cloud Account |@@signup_heading">Create your H&L Cloud Account</h2>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div [class.create-account-form]="!smallScreen">
        <h2 i18n="failToLoadMsg |@@signup_failToLoadMsg" id='failToLoadMsg' style="text-align: center" class="failed" *ngIf="failToLoad"> {{failToLoadMsg}} </h2>
        <form *ngIf="!failToLoad" class="full-width" [formGroup]="createAccountForm">
            <mat-form-field [class.half-width-left-create-account]="!smallScreen" [class.smallscreen-create-account-field]="smallScreen">
              <input id="firstName" matInput placeholder="First Name" i18n-placeholder="First Name|@@signup_firstName" formControlName="firstName" required>
              <mat-error id="firstNameErr" *ngIf="createAccountForm.get('firstName').invalid" i18n="firstNameErr |@@signup_firstNameErr">{{formErrorMessage('firstName')}}</mat-error>
              <button type="button" id='fnClear' mat-button *ngIf="createAccountForm.get('firstName').value" matSuffix mat-icon-button
                aria-label="Clear" (click)="createAccountForm.get('firstName').setValue('')">
                <mat-icon  i18n="Sysnet Signup- Close First Name Error message|@@SysnetSignup-CloseFirstNameErrMsg">close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field [class.half-width-right-create-account]="!smallScreen" [class.smallscreen-create-account-field]="smallScreen">
              <input id="lastName" matInput placeholder="Last Name" i18n-placeholder="Last Name|@@signup_lastName" formControlName="lastName" required>
              <mat-error id="lastNameErr" *ngIf="createAccountForm.get('lastName').invalid" i18n="lastNameErr |@@signup_lastNameErr">{{formErrorMessage('lastName')}}</mat-error>
              <button type="button" id='lnClear' mat-button *ngIf="createAccountForm.get('lastName').value" matSuffix mat-icon-button
                aria-label="Clear" (click)="createAccountForm.get('lastName').setValue('')">
                <mat-icon i18n="Sysnet Signup- Close Last Name Error message|@@SysnetSignup-CloseLastNameErrMsg">close</mat-icon>
              </button>
            </mat-form-field>
  
            <mat-form-field [class.half-width-left-create-account]="!smallScreen" [class.smallscreen-create-account-field]="smallScreen">
              <input id="email" matInput placeholder="Email" i18n-placeholder="Email|@@signup_email" formControlName="email" required>
              <mat-error id="emailErr" *ngIf="createAccountForm.get('email').invalid" i18n="emailErr |@@signup_emailErr">{{formErrorMessage('email')}}</mat-error>
            </mat-form-field>

            <mat-form-field [class.half-width-right-create-account]="!smallScreen" [class.smallscreen-create-account-field]="smallScreen">
              <input id="mobile" matInput placeholder="Mobile" i18n-placeholder="Mobile|@@signup_mobile" formControlName="mobile" required>
              <mat-error id="mobileErr" *ngIf="createAccountForm.get('mobile').invalid" i18n="mobileErr |@@signup_mobileErr">{{formErrorMessage('mobile')}}</mat-error>
              <button type="button" id='mobileClear' mat-button *ngIf="createAccountForm.get('mobile').value" matSuffix mat-icon-button
                aria-label="Clear" (click)="createAccountForm.get('mobile').setValue('')">
                <mat-icon i18n="Sysnet Signup- Close Mobile Error message|@@SysnetSignup-CloseMobileErrMsg">close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field [class.half-width-left-create-account]="!smallScreen" [class.smallscreen-create-account-field]="smallScreen">
              <input id="password" type="password" matInput placeholder="Password" i18n-placeholder="Password|@@signup_password" formControlName="password" autocomplete="new-password">
              <mat-error id="passwordErr" *ngIf="createAccountForm.get('password').invalid" i18n="passwordErr |@@signup_passwordErr">{{formErrorMessage('password')}}</mat-error>
              <button type="button" id='togglePwdVis' mat-button *ngIf="createAccountForm.get('password').value" matSuffix mat-icon-button
                aria-label="Clear" (click)="togglePwdVisible();">
                <mat-icon id='pwdToVisible' *ngIf="!pwdVisible" matTooltip="Show Password" i18n="Show Password|@@signup_showPassword">visibility</mat-icon>
                <mat-icon id='pwdToNotVisible' *ngIf="pwdVisible" matTooltip="Hide Password" i18n="Hide Password|@@signup_hidePassword">visibility_off</mat-icon>
              </button>
              <button type="button" id='pwdClear' mat-button *ngIf="createAccountForm.get('password').value" matSuffix mat-icon-button
                aria-label="Clear" (click)="createAccountForm.get('password').setValue('')">
                <mat-icon i18n="Sysnet Signup- Close Password Error message|@@SysnetSignup-ClosePasswordErrMsg">close</mat-icon>
              </button>
              <div class="pwd-hint" *ngIf="showPwdHint" id='pwdHint'>
                  <div id='lengthHint' i18n="lengthHint |@@signup_lengthHint"> 
                      <mat-icon id='lengthHintPassed' class="passed valid-icon" *ngIf="!lengthFailed">check_circle</mat-icon> 
                      <mat-icon id='lengthHintFailed' class="failed valid-icon" *ngIf="lengthFailed">cancel</mat-icon> 
                      At least 8 characters in length
                  </div>
                  <div id='OptHint' i18n="OptHint |@@signup_OptHint"> 
                      <mat-icon id='OptHintPassed' class="passed valid-icon" *ngIf="!optFailed">check_circle</mat-icon> 
                      <mat-icon id='OptHintFailed' class="failed valid-icon" *ngIf="optFailed">cancel</mat-icon> 
                      Contain at least 3 of the following 4 types of characters:
                  </div>
                  <div id='lowerHint' i18n="lowerHint |@@signup_lowerHint"> 
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <mat-icon id='lowerHintPassed' class="passed valid-icon" *ngIf="!lowerFailed">check_circle</mat-icon> 
                      <mat-icon id='lowerHintFailed' class="not-checked valid-icon" *ngIf="lowerFailed">more_horiz</mat-icon> 
                      Lower case letters (a-z)
                  </div>
                  <div id='upperHint' i18n="upperHint |@@signup_upperHint"> 
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <mat-icon id='upperHintPassed' class="passed valid-icon" *ngIf="!upperFailed">check_circle</mat-icon> 
                      <mat-icon id='upperHintFailed' class="not-checked valid-icon" *ngIf="upperFailed">more_horiz</mat-icon> 
                      Upper case letters (A-Z)
                  </div>
                  <div id='numHint' i18n="numHint |@@signup_numHint"> 
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <mat-icon id='numHintPassed' class="passed valid-icon" *ngIf="!numFailed">check_circle</mat-icon> 
                      <mat-icon id='numHintFailed' class="not-checked valid-icon" *ngIf="numFailed">more_horiz</mat-icon> 
                      Numbers (i.e. 0-9)
                  </div>
                  <div id='specialHint' i18n="specialHint |@@signup_specialHint"> 
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <mat-icon id='specialHintPassed' class="passed valid-icon" *ngIf="!specialFailed">check_circle</mat-icon> 
                      <mat-icon id='specialHintFailed' class="not-checked valid-icon" *ngIf="specialFailed">more_horiz</mat-icon> 
                      Special characters (e.g. !&#64;#$%^&*)
                  </div>
              </div>
            </mat-form-field>

            <mat-form-field [class.half-width-right-create-account]="!smallScreen" [class.smallscreen-create-account-field]="smallScreen" [class.margin-for-pwd-hint]="smallScreen && showPwdHint">
              <input id="cfmPassword"type="password"  matInput placeholder="Confirm Password" i18n-placeholder="Confirm Password|@@signup_confirmPassword" formControlName="confirmPassword">
              <mat-error id="confirmPasswordErr" *ngIf="createAccountForm.get('confirmPassword').invalid" i18n="confirmPasswordErr |@@signup_confirmPasswordErr">{{formErrorMessage('confirmPassword')}}</mat-error>
              <button type="button" id='cfmPwdClear' mat-button *ngIf="createAccountForm.get('confirmPassword').value" matSuffix mat-icon-button
                aria-label="Clear" (click)="createAccountForm.get('confirmPassword').setValue('')">
                <mat-icon i18n="Sysnet Signup- Close First confirmPassword message|@@SysnetSignup-CloseConfirmPasswordErrMsg">close</mat-icon>
              </button>
            </mat-form-field>
        </form>
      </div>
    </mat-card-content>
    <mat-card-actions class="mat-card-actions-create-account" *ngIf="!failToLoad">
      <button id="create-account-save" mat-raised-button color="primary" class="right" (click)="save();" [disabled]="!canSave" i18n="Create Account Btn |@@signup_createAccountBtn">Create Account</button>
    </mat-card-actions>
    <div *ngIf="showRedirectOpt" class="failed" id='redirect' i18n="Redirect Option |@@signup_redirectOpt">
        Looks like you've got an account already, you can click <a id="redirectLink" href="" (click)="clickLink();">here</a> to sign in.
    </div>
  </mat-card>
</body>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="large"
  color="#fff"
  type="pacman"
  fullScreen="true">
 <p style="font-size: 20px; color: white" i18n="Please Wait |@@pleaseWait">Please Wait...</p>
</ngx-spinner>
