<dx-popup [(visible)]="popupConfig.visible" [title]="popupConfig.title" [showCloseButton]="true"
    [hideOnOutsideClick]="false" [shading]="true" shadingColor="rgba(0, 0, 0, 0.3)"
    [fullScreen]="globalsService?.isMobile" (onShowing)="popupConfig.onShowing($event)">
    <div class="multi-item-barcode-selection-grid-wrapper">
        <dx-data-grid class="multi-item-barcode-selection-grid" #multiItemBarcodeSelectionListDataGrid
            [dataSource]="barcodeGridData" [columns]="multiItemBarcodeSelectionListDataGridConfig.columns"
            [repaintChangesOnly]="true" [allowColumnReordering]="true" [allowColumnResizing]="true"
            [columnFixing]="multiItemBarcodeSelectionListDataGridConfig.columnFixing" columnResizingMode="widget"
            [showBorders]="true" [showColumnLines]="true" [showRowLines]="true" [rowAlternationEnabled]="true"
            [columnHidingEnabled]="false" [searchPanel]="multiItemBarcodeSelectionListDataGridConfig.searchPanel"
            [remoteOperations]="multiItemBarcodeSelectionListDataGridConfig.remoteOperations"
            [scrolling]="multiItemBarcodeSelectionListDataGridConfig.scrolling"
            [selection]="multiItemBarcodeSelectionListDataGridConfig.selection"
            [columnChooser]="multiItemBarcodeSelectionListDataGridConfig.columnChooser"
            [syncLookupFilterValues]="false"
            (onSelectionChanged)="multiItemBarcodeSelectionListDataGridConfig.onSelectionChanged($event)">
            <div *dxTemplate="let data of 'singleSelectCellTemplate'">
                <dx-radio-group [value]="selectedRowKey" [valueExpr]="barcodeId" [items]="[data.data]">
                </dx-radio-group>
            </div>
        </dx-data-grid>
    </div>

    <div class="form-btn-container">
        <dx-button class="form-save" stylingMode="contained" text="Select" (onClick)="select()">
        </dx-button>
        <dx-button class="form-cancel" stylingMode="outlined" text="Cancel" (onClick)="hidePopup()">
        </dx-button>
    </div>
</dx-popup>