import { Injectable } from '@angular/core';
import { GlobalsService } from '@services/global.service';
import AppConfiguration from '@hal.common.ui/utilities/AppConfig';
import { HttpResponse } from '@angular/common/http';
import { StocktakesService as SDKStocktakeService } from '@modules/SDKs/sysnetApi/api/stocktakes.service';
import { GroupQuery, GroupSummaryQuery, StocktakeInsert, StocktakeUpdate, StocktakeView } from '@modules/SDKs/sysnetApi';
import { ServicesWrappingSysnetSDK } from './ServicesWrappingSysnetSDK';

@Injectable({
    providedIn: 'root'
})
export class StocktakeService extends ServicesWrappingSysnetSDK {
    private orgId: string;

    constructor(private sdkStocktakeService: SDKStocktakeService, private globals: GlobalsService) {
        super([sdkStocktakeService], globals);
        this.globals.$state.subscribe((newState) => {
            if (newState) { this.orgId = newState.selectedOrganisation?._id; }
        });
    }

    // Get Stocktake
    public getStocktake(stocktakeId: number, view?: string): Promise<HttpResponse<StocktakeView>> {
        return this.sdkStocktakeService.stocktakesIdGet(stocktakeId, AppConfiguration.rsh.getStrVal('rid'), view, 'response').toPromise();
    }

    // Search Stocktake
    public searchStocktake(group?: GroupQuery, groupSummary?: GroupSummaryQuery, totalSummary?: GroupSummaryQuery,
                           sort?: string, limit?: number, offset?: number, view?: string, search?: object, searchDistinct?: string,
                           venueId?: string): Promise<HttpResponse<StocktakeView[]>> {
        return this.sdkStocktakeService.stocktakesGet(AppConfiguration.rsh.getStrVal('rid'), view,
        this.stringify(group), this.stringify(groupSummary), this.stringify(totalSummary), searchDistinct, sort, limit, offset, this.stringify(search),
        null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,
        this.orgId, venueId, 'response').toPromise();
    }

    // Insert Stocktake
    public insertStocktake(stocktake: StocktakeInsert): Promise<HttpResponse<StocktakeView>> {
        return this.sdkStocktakeService.stocktakesPost(stocktake, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Update Stocktake
    public updateStocktake(stocktakeId: number, stocktake: StocktakeUpdate): Promise<HttpResponse<StocktakeView[]>> {
        return this.sdkStocktakeService.stocktakesIdPut(stocktakeId, stocktake, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

    // Delete Stocktake
    public deleteStocktake(stocktakeId: number): Promise<HttpResponse<StocktakeView>> {
        return this.sdkStocktakeService.stocktakesIdDelete(stocktakeId, AppConfiguration.rsh.getStrVal('rid'), 'response').toPromise();
    }

}
