/**
 * Sysnet API
 *  ## Authorization We use bearer auth throughout the API. Generally, API requests should have the follow header:   {       \"content-type\": \"application/json\",       \"Authorization\": \"Bearer TOKEN_GOES_HERE\"   }   Please refer to the Auth section immediately below for details on getting your token.
 *
 * OpenAPI spec version: 1.0.4
 * Contact: support@hlpos.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateDateTime } from './createDateTime';
import { ExternalKey } from './externalKey';
import { ExternalKeys } from './externalKeys';
import { LastUpdateByUserId } from './lastUpdateByUserId';
import { UpdateDateTime } from './updateDateTime';

export interface ExpenseGroupBulkInsertViewInsertedObject { 
    /**
     * The unique identifier of this record.
     */
    expenseGroupId?: number;
    number?: number;
    name?: string;
    glCode?: string;
    includeInReports?: boolean;
    glName?: string;
    glType?: number;
    glPrefix?: string;
    accountGroupCode?: string;
    accountGroupDesc?: string;
    accountTypeCode?: string;
    accountTypeDesc?: string;
    /**
     * A reference to the primary key in the TaxRates Table
     */
    taxRateId?: number;
    percentageRate?: number;
    includeInPettyCash?: boolean;
    organisationId?: string;
    createDateTime?: CreateDateTime;
    updateDateTime?: UpdateDateTime;
    lastUpdateByUserId?: LastUpdateByUserId;
    externalKeys?: ExternalKeys;
    externalKey?: ExternalKey;
}